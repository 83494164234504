import React, { useEffect, useState } from "react";
import ReferralTable from "./ReferralTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import { Dropdown } from 'primereact/dropdown';
import PaymentPeriodModal from "./modals/PaymentPeriodModal";

import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap";
import { getFormattedUTCDate } from 'helpers/functions';

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { referralRefresh, referrals, setReferrals, setReferralLoading, setTotalRecords, lazyState } = usePromotion();
  const { auth } = useAuth();
  const [selectedMode, setSelectedMode] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState('month')

  const today = new Date()
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  endOfMonth.setHours(23, 59, 59, 999)

  const startDate = getFormattedUTCDate(startOfMonth)
  const endDate = getFormattedUTCDate(endOfMonth)
  const [options, setOptions] = useState({
    startDate: startDate,
    endDate: endDate,
  });
  useEffect(() => {
    setReferralLoading(true);

    const getReferrals = async () => {
      try {
        const response = await axiosApi.get(`/api/admin/marketing/referral`);
        setReferralLoading(false);
        const allOption = { name: 'All', code: '' };

        // Merge "All" option with the response data result
        const referralsWithAll = [allOption, ...response.data.result];

        // Set referrals with "All" option
        setReferrals(referralsWithAll);

        if (response.data.result) {
          setSelectedMode(referralsWithAll[0])
        }
        setTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getReferrals();

  }, [referralRefresh, lazyState]);
  const changeMode = (e) => {
    setSelectedMode(e.value);
  }
  return (
    <React.Fragment>
      <Card>
        <CardTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'right' }} className="my-3">
            {/* <Label className="form-label">Name :</Label> */}
            <Dropdown
              value={selectedMode || ''}
              onChange={changeMode}
              options={referrals}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
            <PaymentPeriodModal
              selectedPeriod={selectedPeriod}
              mainOptions={options}
              setMainOptions={setOptions}
              setSelectedPeriod={setSelectedPeriod}
            />
          </div>
        </CardTitle>
        <CardBody>
          <ReferralTable referral={selectedMode} selectedPeriod={selectedPeriod} options={options} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AdminList;
