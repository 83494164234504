import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

// import { store } from "./app/store";
import store from "./store";
import { AuthProvider } from "context/AuthProvider";

// if (['dev', 'prod'].includes(process.env.REACT_APP_ENV)) {
//   console.log = () => {};
//   console.debug = () => {};
//   console.info = () => {};
//   console.warn = () => {};
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();