import useBox from 'hooks/useBox';

import { Button, FormFeedback, Input, Label, Form, Offcanvas, OffcanvasBody, OffcanvasHeader, FormGroup, Container, Row, Col} from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import TimePicker from 'react-time-picker';

const OpeningHours = () => {
    const { timezones, openingHoursArray, daysInWeek, setOpeningHoursArray, changeTimezone } = useBox();
    const isOpen = (day) => {
        const result = openingHoursArray.findIndex((data) => {
            return data.day === day.name
        })
        return openingHoursArray[result]?.isOpen || false
    }
    const openingTime = (day, type) => {
        const result = openingHoursArray.findIndex((data) => {
            return data.day === day.name
        })
        return openingHoursArray[result] === undefined ? '00:00' : openingHoursArray[result][type]
    }
    const toggleChange = (e) => {
        const day = e.target.dataset.day;
        const updatedOpeningHours = openingHoursArray.map(item => {
            if (item.day === day) {
                return { ...item, isOpen: e.target.checked };
            }
            return item;
        });
        console.log(updatedOpeningHours)
        setOpeningHoursArray(updatedOpeningHours)
    }
    const changeTime = (e) => {
        const value = e.target.value;
        const day = e.target.dataset.day;
        const type = e.target.dataset.type;
        const updatedOpeningHours = openingHoursArray.map(item => {
            if (item.day === day) {
                return {
                    ...item,
                    [type]: value
                };
            }
            return item;
        });
        setOpeningHoursArray(updatedOpeningHours)
    }

    return (
        <div className="my-3">
            <div className="d-flex align-items-center gap-2">
                <p>Opening Hours :</p>
                <Dropdown
                    value={openingHoursArray[0]?.timezone || ''}
                    onChange={changeTimezone}
                    options={timezones}
                    optionLabel="name"
                    className="h-1 payment-status-dropdown"
                />
            </div>
            <div>
                <ul>
                    {daysInWeek.map((day, i) => (
                        <Container fluid key={i}>
                            <Row className='align-items-center'>
                                <Col className='p-0' xs={3} md={5}>
                                    <li key={day.name}>
                                        <Label className="form-label mb-0">{day.name} :</Label>
                                        <div>
                                            <Input
                                                id={`isopen-${day.name}`}
                                                data-day={day.name}
                                                name={day.name}
                                                type="checkbox"
                                                checked={isOpen(day)}
                                                onChange={toggleChange}
                                                className="me-2"
                                            />
                                            <Label className="form-label" htmlFor={`isopen-${day.name}`}> Open</Label>
                                        </div>
                                    </li>
                                </Col>
                                <Col xs={4} md={3} className="p-0">
                                    <Input id={`open-${day.name}`} data-day={day.name} data-type="open" type="time" value={openingTime(day, 'open')} onInput={changeTime} className="input" disabled={!isOpen(day)} step="900" />
                                </Col>
                                <Col xs={1}>to</Col>
                                <Col xs={4} md={3} className="p-0">
                                    <Input id={`close-${day.name}`} data-day={day.name} data-type="close" type="time" value={openingTime(day, 'close')} onInput={changeTime} className="input" disabled={!isOpen(day)} step="900" />
                                    {/* <TimePicker
                                        onChange={changeTime}
                                        value={openingTime(day, 'close')}
                                        format="HH:mm"
                                        minDetail="minute"
                                        clockIcon={null}
                                        disableClock
                                        step={15} // Set the step to 15 minutes
                                    /> */}
                                </Col>
                            </Row>
                        </Container>
                    ))}
                </ul>
            </div>
            
        </div>
    )
}

export default OpeningHours