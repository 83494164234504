import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import withRouter from 'components/Common/withRouter';

import { logoutUser } from "../../store/actions";
//redux
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { post, del, get, put } from "../../helpers/api_helper";
import useAuth from 'hooks/useAuth';


const Logout = props => {
  const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect( () => {
    // dispatch(logoutUser(navigate));
    try {
      const response = get('/admin/logout');
      localStorage.removeItem("authUser");
      setAuth({});
      navigate('/login')
    } catch (error) {
      console.log(error);
    }
  }, [navigate]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object
};


export default withRouter(Logout);
