import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";


const LeaderExportCSV = ({ lazyState, startDate, endDate }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Leaderboard_${startDate}_${endDate}.csv`

    const headers = [
        { label: "#", key: "increment" },
        { label: "Name", key: "name" },
        { label: "Username", key: "username" },
        { label: "Volume (liter)", key: "totalVolume" },
        { label: "Weight (kg)", key: "totalWeight" },
        { label: "Amount (excl. Bonus)", key: "totalAmount" },
        { label: "Currency", key: "currency" },
        { label: "Current Month’s Bonus", key: "monthlyBonus" },
        { label: "Total Bonus", key: "totalReceivedBonus" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/support/leaderboard?startDate=${startDate}&endDate=${endDate}`);
            const result = response.data.result;
            var inc = 1;
            result.forEach(obj => {
                obj.increment = inc;
                obj.totalVolume = simplify(obj.totalVolume);
                obj.totalWeight = simplify(obj.totalWeight);
                obj.monthlyBonus = simplify(obj.monthlyBonus);
                obj.totalReceivedBonus = simplify(obj.totalReceivedBonus);

                inc++;
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data.result)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default LeaderExportCSV