import { createContext, useState } from "react";

const CurrencyContext = createContext({});

export const CurrencyProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [datas, setDatas] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    // const [lazyState, setlazyState] = useState({
    //     config: {
    //         first: 0,
    //         rows: 5,
    //         page: 0,
    //         sortField: null,
    //         sortOrder: null,
    //         filters: {
    //             name: { value: '', matchMode: 'contains' },
    //         }
    //     }
    // });

    return (
        <CurrencyContext.Provider value={{datas, setDatas, totalRecords, setTotalRecords, expandedRows, setExpandedRows, refresh, setRefresh, loading, setLoading, updating, setUpdating}}>
            {children}
        </CurrencyContext.Provider>
    )
}

export default CurrencyContext;