import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import useAuth from "hooks/useAuth";

const UserExportCSV = ({ lazyState }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { identityTypes } = useAsset();

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `People_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { label: "Type", key: "persontype_name" },
        { label: "Company", key: "company_name" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Phone Number", key: "phone" },
        { label: "Identity Type", key: "identity_type" },
        { label: "ID Number", key: "identity_number" },
        { label: "Country", key: "locationcountry_name" },
        { label: "Region", key: "locationregion_name" },
        { label: "City", key: "locationcity_name" },
        { label: "Address", key: "address" },
        { label: "Postcode", key: "postcode" },
        { label: "isActive", key: "is_active" },
        { label: "Photo", key: "photo" },

    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            // console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/erp/asset/persons?searchText=${lazyState.searchText}&show_all=true`);
            const result = response.data.results;
            result.forEach(obj => {
                obj.name = obj.first_name + ' ' + obj.last_name;
                obj.identity_type = identityTypes.find(element => element.value === obj.identity_type)?.name ?? '-'

            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default UserExportCSV