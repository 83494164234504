import { useEffect, useState, useRef } from "react";
import {
    // Dropdown,
    // DropdownItem,
    // DropdownMenu,
    // DropdownToggle,
    Button,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    InputGroup,
    InputGroupText,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import makeid from "helpers/random";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { handleThousandSeparatorChange, removeCommas, formatThousandSeparator } from "helpers/functions";
import Select from "react-select"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import "../../../../assets/css/style.css";
import useVolumePartner from "hooks/useVolumePartner";
import useAuth from "hooks/useAuth";

const EditVolumePartner = ({ rowData }) => {
    const { showToast } = useAuth()
    const axiosApi = useAxiosPrivate();
    const { setRefresh } = useVolumePartner();

    const [modal_center, setmodal_center] = useState(false);
    const [username, setUsername] = useState(rowData.user?.username);
    const [currency, setCurrency] = useState(rowData.user?.currency);
    const [userId, setUserId] = useState(rowData.user_id);
    const [directPayment, setDirectPayment] = useState(rowData.direct_payment_enabled);

    const [enablePrice, setEnablePrice] = useState(rowData.is_fixed_price);
    const [fixPrice, setFixPrice] = useState(rowData.fixed_price)
    const [partnerAccount, setPartnerAccount] = useState(rowData.partner_account)
    const [expectedVolume, setExpectedVolume] = useState(rowData.expected_volume)

    const [results, setResults] = useState([]);

    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const searchUser = async (username) => {
        try {
            setLoading(true);
            const response = await axiosApi.get(
                `/api/admin/support/search-user?username=${username}`
            );
            // console.log(response.data);

            setResults(response.data.result);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        resetModalForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const handleUsername = async (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if (value) {
            await searchUser(value);
            setShowResults(true);
        } else {
            setShowResults(false);
            setUserId("");
        }
        setUsername(e.target.value);
    };

    const handleUsernameBlur = (e) => {
        validation.handleBlur(e);
        setTimeout(() => {
            setShowResults(false);
        }, 150);
    };

    const handleResultClick = (user, e) => {
        e.stopPropagation();
        setUsername(user.username);
        setUserId(user._id);
        setCurrency(user.currency);
        setShowResults(false);
    };

    const resetModalForm = () => {
        setUsername(rowData.user?.username);
        setCurrency(rowData.user?.currency);
        setUserId(rowData.user_id);
        setDirectPayment(rowData.direct_payment_enabled);
        setEnablePrice(rowData.is_fixed_price);
    };

    const handlePartnerAccount = (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        validation.values.partner_account = value
        setPartnerAccount(value);
    };

    const handleFixPrice = (e) => {
        validation.handleChange(e); let { name, value } = e.target;

        // Allow only digits and decimal points
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        // Ensure value is more than 0
        if (value === "" || parseFloat(value) <= 0) {
            value = ""; // Optional: Reset value to empty if it's not valid
        }

        const formattedValue = formatThousandSeparator(value);

        setFixPrice(formattedValue);
    };
    const handleExpectedVolume = (e) => {
        validation.handleChange(e);
        let { name, value } = e.target;

        // Allow only digits and decimal points
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        // Ensure value is more than 0
        if (value === "" || parseFloat(value) <= 0) {
            value = ""; // Optional: Reset value to empty if it's not valid
        }

        const formattedValue = formatThousandSeparator(value);

        setExpectedVolume(formattedValue);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            partner_account: partnerAccount,
            username: username,
            price: formatThousandSeparator(String(fixPrice ?? 0)),
            expected_volume: formatThousandSeparator(String(expectedVolume ?? 0)),
        },
        validationSchema: Yup.object().shape({
            partner_account: Yup.string().required("This value is required"),
            username: Yup.string().required("This value is required"),
            expected_volume: Yup.string().required("This value is required"),
            price: enablePrice
                ? Yup.string().required("This value is required")
                : Yup.string().nullable(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    user_id: userId,
                    fixed_price: enablePrice ? values.price ? removeCommas(values.price) : null : null,
                    is_fixed_price: enablePrice,
                    direct_payment_enabled: directPayment,
                    expected_volume: removeCommas(values.expected_volume),
                    partner_account: values.partner_account
                };

                const response = await axiosApi.put('/api/admin/partner-volume/' + rowData._id, opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response.data);
                resetModalForm();
            } catch (error) {
                // console.log(error);
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        },
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    Edit Volume Partner
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="mb-3">
                            <Label className="form-label">Partner Account Name</Label>
                            <Input
                                name="partner_account"
                                placeholder="Enter Partner Account Name"
                                type="text"
                                onChange={handlePartnerAccount}
                                onBlur={validation.handleBlur}
                                value={validation.values.partner_account || ""}
                                invalid={
                                    validation.touched.partner_account && validation.errors.partner_account ? true : false
                                }
                            />
                            {validation.touched.partner_account && validation.errors.partner_account ? (
                                <FormFeedback type="invalid">{validation.errors.partner_account}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3 position-relative">
                            <Label className="form-label">Username</Label>
                            <InputGroup>
                                <Input
                                    name="username"
                                    placeholder="Enter Username"
                                    type="text"

                                    autoComplete="off"
                                    onChange={handleUsername}
                                    onBlur={handleUsernameBlur}
                                    value={validation.values.username || ""}
                                    invalid={
                                        validation.touched.username && validation.errors.username
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.username}
                                    </FormFeedback>
                                ) : null}
                            </InputGroup>
                            {loading ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    style={{ top: "100%", zIndex: 1 }}
                                >
                                    <ListGroupItem key={"load"} className="d-flex">
                                        <ProgressSpinner
                                            style={{ width: "30px", height: "30px" }}
                                            strokeWidth="2"
                                            fill="var(--surface-ground)"
                                            animationDuration=".5s"
                                            className="align-self-center"
                                        />
                                    </ListGroupItem>
                                </ListGroup>
                            ) : showResults && results.length > 0 ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    role="button"
                                    style={{ top: "100%", zIndex: 1 }}
                                >
                                    {results.map((user) => (
                                        <ListGroupItem
                                            key={user._id}
                                            onClick={(e) => handleResultClick(user, e)}
                                            className="hover-list-item"
                                        >
                                            {user.username}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            ) : null}
                            {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.username}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <div className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
                                <Label className="form-label" style={{ marginRight: '10px' }}>
                                    Fixed Price / liter (optional)
                                </Label>
                                <InputSwitch checked={enablePrice} onChange={(e) => setEnablePrice(e.value)} />
                            </div>
                            {enablePrice && (
                                <InputGroup
                                    className={
                                        validation.touched.price && validation.errors.price
                                            ? "is-invalid mb-3"
                                            : "mb-3"
                                    }
                                    style={{ width: "300px" }}
                                >
                                    <Input
                                        name="price"
                                        placeholder="Enter Fixed Price"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleFixPrice}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.price || ""}
                                        invalid={
                                            validation.touched.price && validation.errors.price
                                                ? true
                                                : false
                                        }
                                    />
                                    <InputGroupText>
                                        {currency}
                                    </InputGroupText>
                                    {validation.touched.price && validation.errors.price ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.price}
                                        </FormFeedback>
                                    ) : null}
                                </InputGroup>
                            )}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Expected Monthly Volume</Label>
                            <InputGroup
                                className={
                                    validation.touched.expected_volume && validation.errors.expected_volume
                                        ? "is-invalid mb-3"
                                        : "mb-3"
                                }
                                style={{ width: "300px" }}
                            >
                                <Input
                                    name="expected_volume"
                                    placeholder="Enter Expected Monthly Volume"
                                    type="text"
                                    autoComplete="off"
                                    inputMode="numeric"
                                    onChange={handleExpectedVolume}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.expected_volume || ""}
                                    invalid={
                                        validation.touched.expected_volume && validation.errors.expected_volume
                                            ? true
                                            : false
                                    }
                                />
                                <InputGroupText>
                                    liter
                                </InputGroupText>
                                {validation.touched.expected_volume && validation.errors.expected_volume ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.expected_volume}
                                    </FormFeedback>
                                ) : null}
                            </InputGroup>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Direct Payment</Label><br />
                            <InputSwitch checked={directPayment} onChange={(e) => setDirectPayment(e.value)} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={validation.isSubmitting}
                            >
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default EditVolumePartner;
