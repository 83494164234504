import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, } from "reactstrap"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from 'helpers/random';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAdmin from "hooks/useAdmin";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useNotifLog from "hooks/useNotifLog";

const SendNotificationModal = () => {
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh, refresh } = useNotifLog();
    const { auth, showToast } = useAuth();
    const axiosApi = useAxiosPrivate();

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: String(""),
            body: String(""),
            url: String(""),
        },
        validationSchema: Yup.object().shape({
            title: Yup.string()
                .required('This field is required'),
            body: Yup.string()
                .required('This field is required'),
            url: Yup.string()
                .test(
                    'url-or-custom',
                    'Allowed only empty field, valid URL, "promos" or "leaderboard',
                    value => {
                        if (!value) return true; // Allow empty field
                        const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value); // Check if it's a valid URL
                        const isCustomValue = value === "promos" || value === "leaderboard"; // Check if it's "promos" or "leaderboard"
                        return isValidURL || isCustomValue;
                    }
                )
                
            // .required('This field is required')
            ,

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { title, body, url } = values;

                const obj = { title: title, body: body, payload: url, admin_id: auth?.auth._id }

                const response = await axiosApi.post('/api/support/broadcast-notification', obj)
                resetForm();
                setRefresh(makeid(5));
                tog_center();
                showToast(response.data);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        },
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Send Broadcast Notification </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Send New Broadcast Notification</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">Message Title</Label>
                            <Input
                                name="title"
                                placeholder="Enter title..."
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={validation.touched.title && !!validation.errors.title} // Update this line

                            />
                            {
                                validation.touched.title ? (
                                    <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Message Body</Label>
                            <Input
                                name="body"
                                placeholder="Enter message..."
                                type="textarea"
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.body || ""}
                                invalid={validation.touched.body && !!validation.errors.body} // Update this line
                            />
                            {
                                validation.touched.body ? (
                                    <FormFeedback type="invalid">{validation.errors.body}</FormFeedback>
                                ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Url</Label>
                            <Input
                                name="url"
                                placeholder="Enter Value..."
                                type="text"
                                title="Please enter a valid URL"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.url || ""}
                                invalid={validation.touched.url && !!validation.errors.url} // Update this line

                            />
                            {
                                validation.touched.url ? (
                                    <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                                ) : null}
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default SendNotificationModal
