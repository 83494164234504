import '../../../assets/css/style.css'
import moment from "moment"
import { Link } from "react-router-dom"

import useOverview from "hooks/useOverview";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'reactstrap';
const Collections = () => {
    const { collections, loading } = useOverview();

    const items = Array.from({ length: 10 }, (v, i) => i);

    const inputProgress = data => {
        return data.collection_detail_percentage + '%'
    }
    const idLink = (data) => {
        return <Link to={'/collections?tab=1'} state={data.collection_code}> {data.collection_code}</Link>
    }
    const columns = [
        // { field: '', header: '#', body: incrementId },
        { field: 'collection_code', header: 'Collection ID', body: idLink, className: '' },
        { field: 'collection_type', header: 'Type', body: '', className: '' },
        { field: 'destination_tank.tank_name', header: 'Destination', body: '', className: '' },
        // { field: 'max_capacity', header: 'Target Volume (liter)', body: '', className: '' },
        { field: 'collection_real_weight', header: 'Actual Weight (kg)', body: '', className: '' },
        { field: '', header: 'Progress', body: inputProgress, className: '' },

    ]
    return <>
        <DataTable
            value={loading ? items : collections}
            stripedRows
            showGridlines
            size={'normal'}
            metaKeySelection={true}

            scrollable
        >
            {columns.map((col, i) => (
                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
            ))}
        </DataTable>
        <div className="d-flex flex-wrap gap-2 justify-content-end py-2">
            <Link to={'/collections'}>See More ...</Link>
        </div>
    </>
}

export default Collections