import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import { post, del, get, put } from "../../../../helpers/api_helper";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { InputSwitch } from 'primereact/inputswitch';
import Select from "react-select";
import ConfirmationModal from "./ConfirmationModal";
const IncidentModal = ({ rowData, title, id, setRefresh, loading, setLoading }) => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { showToast } = useAuth();
    const [selectedType, setSelectedType] = useState("");
    const [reasonTypeList, setReasonTypeList] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const getReasonTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/uco-unit-statuses`);
            var locList = response.data.results;
            var typeList = []
            // locList.unshift({ id: 0, name: "None", value: "None" })
            if (response.data.results.length > 0) {
                for (let idx = 0; idx < locList.length; idx++) {
                    if (locList[idx].id == 3) {
                        setSelectedType(locList[idx]);
                    }
                    if (locList[idx].id == 3 || locList[idx].id == 4) {
                        typeList.push(locList[idx])
                    }
                }
            }
            setReasonTypeList(typeList);

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (modal_center) {
            getReasonTypes();
        }

    }, [modal_center]);
    const changeReasonType = (e) => {
        setSelectedType(e?.value);
        validation.setFieldValue("lost_weight", '')

    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const handleConfirm = async () => {
        if (loading) return;
        try {
            setLoading(true)
            setIsModalOpen(false)
            const values = modalData
            const opts = {
                ...values,
                tank_id: id,
                uco_unit_status_id: (selectedType == "" ? null : selectedType.id),
            };
            // console.log('opts', opts);
            tog_center();
            const response = await post('/api/erp/inventory/incidents/', opts);
            setRefresh(makeid(5));
            // resetForm();
            showToast(response);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }

        }
            
        setLoading(false)
    }
    const handleLostWeightChange = async (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if(value > rowData.capacity){
            validation.setFieldValue('lost_weight', rowData.capacity)
        }        
    };

    const handleCancel = () => {
        setIsModalOpen(false)
        setModalData(null)
    }
    const getValidationSchema = (selectedType) => {
        return Yup.object().shape({
            lost_weight: selectedType.id === 3
                ? Yup.number()
                    .required("Lost Weight is required")
                    .min(0, "Lost Weight must be greater than or equal to 0")
                    .max(rowData.capacity, "Lost Weight must be less than or equal to " + rowData.capacity)
                : Yup.mixed().notRequired(), // Use mixed type to make it optional when not needed
            // add other fields if needed
        });
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            description: '',
            lost_weight: '',
        },
        validationSchema: getValidationSchema(selectedType),
        onSubmit: async (values, { resetForm }) => {
            setIsModalOpen(true)
            // const opts = { ...values, withdrawal: withdrawal }
            setModalData(values)
        }
    });

    return (
        <>
            <div className="my-2">
                <Button style={{ backgroundColor: "black", color: "white" }} onClick={tog_center}>
                    <i className="fas fa-exclamation"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="md">
                <ModalHeader className="mt-0" toggle={tog_center}>Add Incident | {title}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <Label className="form-label">Incident Type</Label>
                                    <div className="col">
                                        <Dropdown
                                            value={selectedType || ''}
                                            onChange={changeReasonType}
                                            options={reasonTypeList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Reason</Label>
                                    <Input
                                        name="description"
                                        placeholder="Please Enter Reason"
                                        type="textarea"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={validation.touched.description && validation.errors.description}
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* Max Capacity */}
                                {selectedType.id == 3 &&
                                    <div>
                                        <div className="mb-3">
                                            <Label className="form-label">Lost Weight (Current Weight : {rowData.capacity})</Label>
                                            <Input
                                                name="lost_weight"
                                                placeholder="Enter Value"
                                                type="number"
                                                onChange={handleLostWeightChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.lost_weight}
                                                invalid={validation.touched.lost_weight && validation.errors.lost_weight}
                                            />
                                            {validation.touched.lost_weight && validation.errors.lost_weight ? (
                                                <FormFeedback type="invalid">{validation.errors.lost_weight}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                New Capacity : {
                                                    Number.isFinite(validation.values.lost_weight) && (rowData.capacity - validation.values.lost_weight) % 1 !== 0
                                                        ? (rowData.capacity - validation.values.lost_weight).toFixed(2)
                                                        : rowData.capacity - validation.values.lost_weight
                                                }
                                            </Label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            {isModalOpen && (
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onConfirm={handleConfirm}
                    toggle={handleCancel}
                    loading={loading}
                    title={title}
                    newStatus={selectedType.name}
                />
            )}
        </>
    )

}

export default IncidentModal
