import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap"
import useIncident from "hooks/useIncident";
import { useLocation } from "react-router-dom";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const location = useLocation();
  const propsData = location.state;
  const queryParams = new URLSearchParams(location.search);
  const searchText = queryParams.get('id');

  const { refresh, setDatas, setLoading, setTotalRecords } = useIncident();
  const { auth } = useAuth();
  const [selectedPeriodName, setSelectedPeriodName] = useState('This Month');
  const [lazyState, setLazyState] = useState({
    searchText: searchText || propsData || '',
    startDate: '',
    endDate: '',
    config: {
      first: 0,
      rows: 5,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  useEffect(() => {
    setLoading(true);

    const getPartners = async () => {
      try {
        // const response = await axiosApi.get(`/api/admin/marketing/partner?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
        const response = await axiosApi.get(`/api/erp/inventory/incidents?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
        setLoading(false);
        setDatas(response.data.results)
        setTotalRecords(response.data.total_records)

      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
    getPartners();

  }, [refresh, lazyState]);
  document.title = `${process.env.REACT_APP_NAME || ''} | Incidents`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={4} sm={4}>
                <h6 className="page-title">Incidents</h6>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Inventory</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    Incidents
                  </li>
                </ol>
              </Col>

              <Col
                md={8}
                sm={8}
                className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap"
              >
                {/* <PaymentPeriodModal
                  mainOptions={lazyState}
                  setMainOptions={setLazyState}
                  selectedPeriodName={selectedPeriodName}
                  setSelectedPeriodName={setSelectedPeriodName}
                /> */}
              </Col>
            </Row>
          </div>

          <DataTable id={searchText || propsData} lazyState={lazyState} setLazyState={setLazyState} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminList;
