import { useEffect, useRef, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import { Toast } from 'primereact/toast';

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";

const TransactionHistoryModal = ({ data }) => {
    const toast = useRef(null);
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const checkBox = (data) => {
        return data?.box[0]?.name || 'deleted box'
    }

    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }

    const roundedVol = (data) => {
        return simplify(data.TA_UCO_Volume + data?.TA_Slops_Volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.TA_UCO_Weight + data?.TA_Slops_Weight)
    }

    const transactionAmount = (data) => {
        return balanceFormatter(data?.amount || 0, data?.user[0]?.currency)
    }
    const totalAmount = (data) => {
        return balanceFormatter(data?.totalAmount || 0, data?.user[0]?.currency)
    }


    const bonusAmount = (data) => {
        return balanceFormatter(data?.bonus || 0, data?.user[0]?.currency)
    }

    const columns = [
        { field: 'TA_ID', header: 'Transaction ID', body: '' },
        { field: 'box_name', header: 'Box', body: checkBox },
        { field: 'TA_UCO_Volume', header: 'Volume (liter)', body: roundedVol },
        { field: 'TA_UCO_Weight', header: 'Weight (kg)', body: roundedWeight },
        { field: 'TA_Start_Time', header: 'Date', body: startTime },
        // { field: 'amount', header: 'Amount', body: transactionAmount },
        { field: 'totalAmount', header: 'Amount', body: totalAmount },

    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const showExtraData = (data,exDataName) => {
        const val = data?.extraData[exDataName];
        if (val === 0) {
            return '0';
        } 
        return val?? null
    }


    const boxFullAddress = (data) => {
        return data?.box[0]?.address || '-'
    }

    const boxAddressCoordinate = (data) => {
        if (data?.box[0]?.location.latitude) {
            return data?.box[0]?.location.latitude + ' ' + data?.box[0]?.location.longitude
        } else {
            return '-'
        }
    }
    const transactionBonus = (data) => {
        return balanceFormatter(data?.bonus || 0, data?.user[0]?.currency)
    }

    const transactionFare = (data) => {
        return balanceFormatter(data?.fare || 0, data?.user[0]?.currency)
    }
    const copyToClipboard = async data => {
        try {
            const id = data.TA_ID;
            const { protocol, hostname, port } = window.location;

            const pathname = window.location.pathname; // e.g., "/accounting/3231-0105" or "/accounting/"
            const segments = pathname.split('/').filter(segment => segment !== ''); // Filter out empty segments
            let basepath ='/transactions/';
            // if (segments.length > 0) {
            //     // Take the first segment and construct the base path
            //     basepath = `/${segments[0]}/`;
            // } else {
            //     basepath = '/'; // In case of an empty path
            // }
            const fullBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${basepath}${id}`;
            await navigator.clipboard.writeText(fullBaseUrl);
            toast.current.show({ severity: 'info', summary: 'Link copied to clipboard' });
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    const transactionMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual";
        } else if (method == "pickup") {
            return "Pickup"
        } else if (method == "promotion") {
            return "Promotion Reward";
        } else if (method == "manual") {
            return "Box";
        }
    }
    const transactionTotalAmount = (data) => {
        var amount = 0;
        var bonus = 0;
        if (data.totalAmount && data.totalAmount != 0) {
            amount = data.totalAmount;
        } else {
            if (data.bonus) {
                bonus = data.bonus;
            }
            amount = data.amount + bonus;
        }
        return balanceFormatter(data.totalAmount || 0, data?.user[0]?.currency)
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Transaction ID : {data.TA_ID}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <label>Box :</label>
                        <ul>
                            <li>ID : {data.boxId}</li>
                            <li>Name : {checkBox(data)}</li>
                            <li>Address : {boxFullAddress(data)}</li>
                            <li>Coordinate : {boxAddressCoordinate(data)}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        {/* <label>The amount stored on box :</label> */}
                        <ul>
                            UCO :
                            <li>Weight : {simplify(data.TA_UCO_Weight + data?.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_UCO_Volume + data?.TA_Slops_Volume)} liter</li>
                        </ul>

                        <ul>
                            <label>Reward :</label>
                            <li>Amount : {transactionAmount(data)}</li>
                            <li>Bonus : {transactionBonus(data)}</li>
                            {transactionMethod(data) == "Pickup" &&
                                <li>Fare: {transactionFare(data)}</li>
                            }
                            <li><b className="f16">Total : {transactionTotalAmount(data)}</b></li>

                            <li>{data?.UCO_Approved ? "This Transaction is Approved" : "This transaction is rejected"}</li>

                            {/* Slops :
                            <li>Weight : {simplify(data.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_Slops_Volume)} liter</li> */}
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Transaction Time : </label>
                        <ul>
                            <li>Start : {startTime(data)}</li>
                            <li>End : {endTime(data)}</li>
                            <li>Duration : {duration(data)}</li>
                        </ul>
                        {data?.extraData &&
                            <><label>Extra Information</label><ul>
                                {showExtraData(data, "hydrocarbon_level") &&
                                    <li>Hydrocarbon Level : {showExtraData(data, "hydrocarbon_level")}</li>}
                                {showExtraData(data, "alcohol_level") &&
                                    <li>Alcohol Level : {showExtraData(data, "alcohol_level")}</li>}
                                {showExtraData(data, "raw_max_level") &&
                                    <li>Raw Max Level : {showExtraData(data, "raw_max_level")}</li>}
                                {showExtraData(data, "raw_interface_level") &&
                                    <li>Raw Interface Level : {showExtraData(data, "raw_interface_level")}</li>}
                                {showExtraData(data, "density") &&
                                    <li>Density : {showExtraData(data, "density")} kg/m³</li>}
                                {showExtraData(data, "detail_transaction") &&
                                    <li>Transaction Detail : {showExtraData(data, "detail_transaction")}</li>}
                            </ul></>
                        }
                       
                        <Toast ref={toast} />

                        <div className="my-2">
                            <Button color="info"
                                onClick={() => {
                                    copyToClipboard(data)
                                }} >
                                Copy Link
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        );
    };
    const rowClassName = (rowData) => {
        if (!(rowData == null || rowData == undefined)) {
            if (!rowData?.UCO_Approved) {
                return 'redBg';
            }
        }
        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/admin/support/transaction?id=${data?._id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const result = response.data.result;
            console.log(response.data)
            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
        <Button type="button" color="primary" onClick={tog_center}>
            Show all transactions
        </Button>{" "}
        <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
            <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0">Transaction History | {data?.name}</h4>
                    <div className="d-flex align-items-center gap-3 me-2">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={onSearchTextChange} placeholder="ID / Box" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                        </span>
                        <TransactionHistoryExportCSV lazyState={lazyState} data={data} />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="min-modal-body">
                <DataTable
                    lazy
                    value={loading ? items : trans}
                    stripedRows
                    paginator
                    rows={lazyState.config.rows}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    size={'normal'}
                    onPage={onPage}
                    totalRecords={totalRecords}
                    first={lazyState.config.first}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    scrollable
                    scrollHeight = '500px'
                    expandedRows={loading ? null : expandedRows}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={onRowToggle}
                    rowClassName={rowClassName}
                    dataKey={loading ? '' : 'TA_ID'}
                >
                    <Column expander={true} style={{ width: '3rem' }} />
                    {columns.map((col, i) => (
                        <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                    ))}
                </DataTable>
            </ModalBody>
        </Modal>
        </>
    )
}

export default TransactionHistoryModal
