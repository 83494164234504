import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import { post, del, get, put } from "../../../../../helpers/api_helper";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { InputSwitch } from 'primereact/inputswitch';
import Select from "react-select";

const EditModal = ({ rowData }) => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { boxRefresh, setBoxRefresh, locationRefresh, refresh } = useAsset();
    const { showToast } = useAuth();
    const [selectedStatus, setSelectedStatus] = useState(rowData.is_active)
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedLocation, setSelectedLocation] = useState("None");
    const [selectedPhysicalBox, setSelectedPhysicalBox] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [physicalBoxList, setPhysicalBoxList] = useState([]);
    const [selectedCapacityType, setSelectedCapacityType] = useState('');
    const [capacityTypeList, setCapacityTypeList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState('');

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        setSelectedStatus(rowData.is_active)
    };

    const changeType = (e) => {
        setSelectedType(e.value)
    }
    const changeCapacityType = (e) => {
        setSelectedCapacityType(e.value)
    }

    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const changeLocation = (e) => {
        setSelectedLocation(e?.value ?? "None")
        validation.setFieldValue("address", e.value.address)
        validation.setFieldValue("city", e.value.city)
        validation.setFieldValue("latitude", e.value.latitude)
        validation.setFieldValue("longitude", e.value.longitude)
    }
    const changePhysicalBox = (value) => {
        setSelectedPhysicalBox(value)
        validation.setFieldValue("name", value.name)
        validation.setFieldValue("model", value.model)
        validation.setFieldValue("tank_code", value.internal_id)
        validation.setFieldValue("max_capacity", value.capacity?.uco)
    }
    const getLocations = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tank-locations?is_active=true&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ id: 0, name: "None", value: "None" })
            setLocationList(locList);
            if (response.data.results.length > 0) {
                for (let idx = 0; idx < locList.length; idx++) {
                    if (locList[idx].id == rowData.tank_location_id) {
                        setSelectedLocation(locList[idx])
                        break;
                    }
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            // locList.unshift({ name: "None", value: "", id: '' })
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                if (index == 0) {
                    setSelectedCompany(detail);
                } else if (element.id === rowData.company_id) {
                    setSelectedCompany(detail)
                }
                allData.push(detail);

            })
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }
    const getBoxTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/tank-types`);
            const allType = response.data.results.filter(item => item.id == '1')

            setTypeList(allType);
            if (allType.length > 0)
                setSelectedType(allType[0])
        } catch (error) {
            console.log(error);
        }
    }
    const getBoxCapacityTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/tank-capacity-types`);
            setCapacityTypeList(response.data.results);
            if (response.data.results.length > 0) {
                for (let idx = 0; idx < response.data.results.length; idx++) {
                    if (idx == 0) {
                        setSelectedCapacityType(response.data.results[idx])
                    } else if (response.data.results[idx].id == rowData.tank_capacity_type_id) {
                        setSelectedCapacityType(response.data.results[idx])
                        break;
                    }
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    const getPhysicalBox = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/engineer/getBoxes`);
            const dataList = response.data
            // dataList.unshift({ name: "None", value: "None" })

            const locList = dataList.map(box => ({ ...box, label: box.name, value: box.name }));
            setPhysicalBoxList(locList);
            setSelectedPhysicalBox(locList[0])

            if (response.data.length > 0) {
                for (let idx = 0; idx < locList.length; idx++) {
                    if (locList[idx].internal_id == rowData.tank_code) {
                        setSelectedPhysicalBox(locList[idx])
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (modal_center) {
            getBoxTypes();
            getCompanies();
            getBoxCapacityTypes();
            getLocations();
            getPhysicalBox();
        }

    }, [boxRefresh, modal_center]);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            tank_code: rowData.tank_code,
            name: rowData.name,
            // address: rowData.address,
            max_capacity: rowData.max_capacity,
            // city: rowData.city,
            model: rowData.model,
            // latitude : rowData.latitude,
            // longitude : rowData.longitude,
            // active: "ACTIVE",
        },
        validationSchema: Yup.object().shape({
            tank_code: Yup.string().required("Internal Box ID is required"),
            name: Yup.string().required("Box name is required"),
            // address: Yup.string().required("Address is required"),
            // city: Yup.string().required("City is required"),
            model: Yup.string().required("Model is required"),
            // latitude: Yup.string().required("Latitude is required"),
            // longitude: Yup.string().required("Longitude is required"),
            max_capacity: Yup.string().required("Capacity is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    ...values,
                    // physical_box: (selectedPhysicalBox == "None"? null : selectedPhysicalBox.id),
                    images: (selectedPhysicalBox == "None" ? null : selectedPhysicalBox.images),
                    // tank_capacity_type_id: selectedCapacityType.id,
                    tank_type_id: selectedType.id,
                    company_id: selectedCompany.id,
                    tank_location_id: (selectedLocation == "None" ? null : selectedLocation.id),
                    is_active: selectedStatus
                };
                // console.log(opts);
                const response = await put('/api/erp/asset/tanks/' + rowData.id, opts);
                // status: status == true ? 1 : 0
                setBoxRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="md">
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Box</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        <div className="row">
                            <div className="col-md-12">

                                {/* Physical BOx ID */}
                                <div className="mb-3 px-2">
                                    <Label className="form-label">Physical Box</Label>
                                    <div className="col">
                                        <Select
                                            value={selectedPhysicalBox}
                                            onChange={changePhysicalBox}
                                            options={physicalBoxList} // Map the array of objects to an array of options
                                            optionLabel="label"
                                            classNamePrefix="select2-selection"
                                        />
                                        {/* <Dropdown
                                            value={selectedPhysicalBox || 'None'}
                                            onChange={changePhysicalBox}
                                            options={physicalBoxList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        /> */}
                                    </div>
                                </div>

                                {/* Internal Box ID */}
                                <div className="mb-3">
                                    <Label className="form-label">Internal Box ID</Label>
                                    <Input
                                        name="tank_code"
                                        placeholder="Please Select a Physical Box"
                                        type="text"
                                        disabled
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.tank_code || ""}
                                        invalid={validation.touched.tank_code && validation.errors.tank_code}
                                    />
                                    {validation.touched.tank_code && validation.errors.tank_code ? (
                                        <FormFeedback type="invalid">{validation.errors.tank_code}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* Name  */}
                                <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                        name="name"
                                        placeholder="Please Select a Physical Box"
                                        type="text"
                                        disabled
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={validation.touched.name && validation.errors.name}
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* Model  */}
                                <div className="mb-3">
                                    <Label className="form-label">Model</Label>
                                    <Input
                                        disabled
                                        name="model"
                                        placeholder="Please Select a Physical Box"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.model || ""}
                                        invalid={validation.touched.model && validation.errors.model}
                                    />
                                    {validation.touched.model && validation.errors.model ? (
                                        <FormFeedback type="invalid">{validation.errors.model}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="row">
                                    <div className="col-6">

                                        {/* Location ID */}
                                        <div className="mb-3">
                                            <Label className="form-label">Location (optional)</Label>
                                            <div className="col">
                                                <Dropdown
                                                    value={selectedLocation || 'None'}
                                                    onChange={changeLocation}
                                                    options={locationList}
                                                    optionLabel="name"
                                                    className="h-1 payment-status-dropdown"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Tank Capacity */}
                                {/* <div className="mb-3">
                                    <Label className="form-label">Capacity Type</Label>
                                    <div className="col">
                                        <Dropdown
                                            value={selectedCapacityType || ''}
                                            onChange={changeCapacityType}
                                            options={capacityTypeList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        />
                                    </div>
                                </div> */}
                                {/* Max Capacity */}
                                <div className="mb-3">
                                    <Label className="form-label">Capacity</Label>
                                    <Input
                                        name="max_capacity"
                                        placeholder="Enter Capacity"
                                        type="number"
                                        min={0}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.max_capacity}
                                        invalid={validation.touched.max_capacity && validation.errors.max_capacity}
                                    />
                                    {validation.touched.max_capacity && validation.errors.max_capacity ? (
                                        <FormFeedback type="invalid">{validation.errors.max_capacity}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Companies */}
                            <div className="mb-3">
                                <Label className="form-label">Company</Label>
                                <div className="col">
                                    <Select
                                        name={`company_id`}
                                        value={selectedCompany}
                                        onChange={value => {
                                            setSelectedCompany(value)
                                        }}
                                        options={companyList}
                                        optionLabel="label"
                                        classNamePrefix="select2-selection"
                                    />
                                    {/* <Dropdown
                                        value={selectedCompany || ''}
                                        onChange={changeCompany}
                                        options={companyList}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    /> */}
                                </div>
                            </div>
                            <div className="col-6">
                                {/* Status */}
                                <div className="mb-3">
                                    <Label className="form-label">Active</Label><br />
                                    <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting || selectedCapacityType == "" || selectedType == ""}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
