import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import CountryFilter from "pages/CountryFilter";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import UserTable from "./UserTable";
import useUser from "hooks/useUser";
import { useLocation } from "react-router-dom";
import { ref } from "yup";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Link, useParams } from "react-router-dom";


import newUser from "../../assets/images/services-icon/new-user.png";
import activeUser from "../../assets/images/services-icon/active-user.png";
import inactiveUser from "../../assets/images/services-icon/inactive-user.png";
import userEarning from "../../assets/images/services-icon/user-earning.png";
import deletedUser from "../../assets/images/services-icon/deleted-user.png";
import unconfirmedUser from "../../assets/images/services-icon/unconfirmed-user.png";
import { getFormattedUTCDate } from 'helpers/functions';
import PaymentPeriodModal from "pages/PaymentPeriodModal";


import UserPeriodModal from "./modals/UserPeriodModal";
const UserList = () => {
    const location = useLocation();
    const propsData = location.state;
    const { id } = useParams();
    const [idParamStatus, setIdParamStatus] = useState(id ? false : true)

    const [refresh, setRefresh] = useState('');
    const { setUsers, setTotalRecords, expandedRows, setExpandedRows, selectedCurrency, setSelectedCurrency } = useUser();
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    endOfMonth.setHours(23, 59, 59, 999)
    const startDate = getFormattedUTCDate(startOfMonth)
    const endDate = getFormattedUTCDate(endOfMonth)
    const [lazyState, setlazyState] = useState({
        searchText: propsData || id || '',
        searchMode: 'all',
        periodName: "This Month",
        startDate: startDate,
        endDate: endDate,
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: "",
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();
    const [totalNewUser, setTotalNewUser] = useState(0);
    const [totalActiveUser, setTotalActiveUser] = useState(0);
    const [totalInactiveUser, setTotalInactiveUser] = useState(0);
    const [totalUnconfirmedUser, setTotalUnconfirmedUser] = useState(0);
    const [totalUserEarningIdr, setTotalUserEarningIdr] = useState(0);
    const [totalUserEarningSgd, setTotalUserEarningSgd] = useState(0);
    const [totalDeletedUser, setTotalDeletedUser] = useState(0);
    const [selectedPeriodName, setSelectedPeriodName] = useState('This Month');
    const [sortField, setSortField] = useState([]);

    const getUsersOverview = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/support/getUserOverview?startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&currency=${selectedCurrency}`);
            setTotalNewUser(response.data.newUser || 0)
            setTotalActiveUser(response.data.activeUser || 0)
            setTotalInactiveUser(response.data.inactiveUser || 0)
            setTotalUnconfirmedUser(response.data.unconfirmedUser || 0)
            setTotalDeletedUser(response.data.deletedUser || 0)
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getUsers = async () => {
        setLoading(true);
        try {
            // const sortFields = sortField && sortField.length>0?sortField.join(','):"";
            const sortFields = sortField;
            const queryParams = {
                rows: lazyState.config.rows,
                skip: lazyState.config.first,
                searchText: lazyState.searchText || '', // Default to empty string if not present
                searchMode: lazyState.searchMode || '', // Default to empty string if not present
                startDate: lazyState.startDate || '', // Default to empty string if not present
                endDate: lazyState.endDate || '', // Default to empty string if not present
                sorts: sortFields || '', // Default to empty string if not present
                currency: selectedCurrency || '', // Default to empty string if not present
            };

            // Apply conditions dynamically
            if (!lazyState.startDate) delete queryParams.startDate; // Remove startDate if not set
            if (!lazyState.endDate) delete queryParams.endDate;     // Remove endDate if not set
            if (lazyState.searchMode=='all') delete queryParams.searchMode;     // Remove searchMode if not set

            // Make the API call
            const response = await axiosApi.get('/api/admin/support/getUsers', { params: queryParams });
            setUsers(response.data.users);
            if (!idParamStatus) {
                if (response.data.users.length > 0) {
                    setExpandedRows({ [response.data.users[0]._id]: true })
                    setIdParamStatus(true)
                }
            }
            setTotalRecords(response.data.totalRecords)
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);

    }
    useEffect(() => {
        getUsersOverview();
    }, [lazyState, refresh, selectedCurrency]);

    useEffect(() => {
        getUsers();
    }, [lazyState, refresh, sortField, selectedCurrency]);

    document.title = `${process.env.REACT_APP_NAME || ''} | Users List`;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Users" breadcrumbItem="App User List" /> */}
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8} sm={12}>
                                <h6 className="page-title">App User List</h6>
                                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Users</a></li><li className="active breadcrumb-item" aria-current="page">App User List</li></ol>
                            </Col>

                            <Col md={4} sm={12}>
                                <div className="d-flex flex-wrap justify-content-end align-items-center w-100">
                                    <CountryFilter selectedFilter={selectedCurrency} setSelectedFilter={setSelectedCurrency} field='currency' />
                                    &nbsp;
                                    <PaymentPeriodModal
                                        mainOptions={lazyState}
                                        setMainOptions={setlazyState}
                                        selectedPeriodName={selectedPeriodName}
                                        setSelectedPeriodName={setSelectedPeriodName}
                                    />
                                    {/* <UserPeriodModal lazyState={lazyState} setlazyState={setlazyState} setRefresh={setRefresh} /> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <p>Overview</p>
                    <Row>
                        <Col lg={3} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={newUser} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            New&nbsp;Users
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {totalNewUser}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={activeUser} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Active&nbsp;Users
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {totalActiveUser}
                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={inactiveUser} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Inactive&nbsp;User
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {totalInactiveUser}
                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg={'2'} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={userEarning} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            User&nbsp;Earnings
                                        </h5>
                                        <h5 className="fw-medium font-size-24">
                                           IDR:&nbsp;{currencyFormatter(totalUserEarningIdr, 'IDR')}
                                        </h5>
                                        <h5>
                                          SGD:&nbsp;{currencyFormatter(totalUserEarningSgd,'SGD')}
                                        </h5>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col lg={3} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={deletedUser} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Deleted&nbsp;Users
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {totalDeletedUser}
                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col lg={3} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={unconfirmedUser} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Unregistered&nbsp;Users
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {totalUnconfirmedUser}
                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>

                    <UserTable setLoading={setLoading} loading={loading} idParam={id} id={propsData} lazyState={lazyState} setlazyState={setlazyState} setRefresh={setRefresh} sortField={sortField} setSortField={setSortField} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default UserList;
