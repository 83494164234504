import React, { useEffect, useState } from "react"
import LoadingOverlay from 'react-loading-overlay';
import ConfirmationModal from "./modals/ConfirmationModal";
import useAxiosPrivate from "hooks/useAxiosPrivate"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import useAuth from "hooks/useAuth"
import { isNonEmptyObject } from "helpers/common"

const PartnerSetting = () => {
  const { showToast } = useAuth()
  const [refresh, setRefresh] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [rawData, setRawData] = useState(false)
  const [sendReport, setSendReport] = useState(false)
  const [autoRewardPayout,setAutoRewardPayout] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [levels, setLevels] = useState([]);

  const axiosApi = useAxiosPrivate()

  const getAppSettings = async () => {
    try {
      // if (isSuccess) {
      //   return;
      // }
      const response = await axiosApi.get("/api/admin/setting/get-app-settings")
      if (response.data.setting) {
        const default_setting = {
          setting: {
            withdrawal_limit: { min: {}, max: {} },
            auto_withdrawal: true,
            // app_min_version: 0,
            withdrawal_threshold: { min_withdraw_amount: {}, withdraw_fee: {} },
            withdrawal_partner: {
              payout_time: null,
              max_payout_amount: {},
            },
            partner_auto_reward_payout: false,
            partner_send_report: false,
            partner_report_email_admin_role:null
          },
        }

        // Assuming response.data has the same structure as default_setting
        Object.assign(default_setting.setting, response.data.setting)
        const { partner_auto_reward_payout, partner_send_report } = default_setting.setting
       
        const defaultData = {};
        defaultData.partner_auto_reward_payout = default_setting.setting.partner_auto_reward_payout ?? false;
        defaultData.partner_send_report = default_setting.setting.partner_send_report ?? false;
        defaultData.partner_report_email_admin_role = default_setting.setting?.partner_report_email_admin_role ?? [];
        setRawData(defaultData);        
        setLevels(response.data.setting?.partner_report_email_admin_role ? JSON.parse(response.data.setting?.partner_report_email_admin_role) : [])

        if (partner_auto_reward_payout) {
            setAutoRewardPayout(partner_auto_reward_payout)
        }
        if (partner_send_report) {
            setSendReport(partner_send_report)
        }        
      }
      setIsSuccess(true);

    } catch (error) {
      if (error.response && error.response.data && error.response.data.state) {
        let response = error.response.data;
        response.state = "error";
        response.toast = true;
        showToast(response);
      } else {
        let response = {};
        response.state = "error";
        response.toast = true;
        response.message = "Internal Server Error"
        showToast(response);
      }
      setIsSuccess(true);

    }

  }
  const getPermissions = async () => {
    try {
      const response = await axiosApi.get('/api/admin/permission');
      var permissionList = [];
      response.data.result.forEach(element => {
        if (element.title.toLowerCase() !== 'superadmin')
          permissionList.push(element)
      });
      setPermissions(response.data.result);

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    setIsSuccess(false)
    setTimeout(() => {
      getPermissions();
      getAppSettings();
    }, 300);
  }, [refresh])

  const updateLevel = (value) => {
    setLevels(prevLevels => {
      if (!prevLevels.includes(value)) {
        return [...prevLevels, value];
      } else {
        return prevLevels.filter(level => level !== value);
      }
    });
  };
  const handleConfirm = async () => {
    try {    
      const obj = {
        partner_auto_reward_payout:autoRewardPayout,
        partner_send_report:sendReport,
        partner_report_email_admin_role: JSON.stringify(levels)

      }

      const response = await axiosApi.post(
        "/api/admin/setting/update-app-settings",
        obj
      )
      showToast(response.data)
      const {
        random,
        setting: {
            partner_auto_reward_payout,
            partner_send_report
        },
      } = response.data
      setRefresh(random)

      setAutoRewardPayout(partner_auto_reward_payout)
      setSendReport(partner_send_report)

      setIsModalOpen(false)
    } catch (error) {
      if (error.response && error.response.data) {
        validation.setErrors(error.response.data)
      } else {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setModalData(null)
  }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      setIsModalOpen(true)      
      const opts = { ...values, sendReport: sendReport, autoRewardPayout: autoRewardPayout, partner_report_email_admin_role: JSON.stringify(levels) }
      setModalData(opts)
    },
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <LoadingOverlay
            active={!isSuccess}
            spinner
            text='Loading your content...'
          >
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col lg={4} md={4}>
                      <div className="mb-5">
                        <h4 className="card-title mb-3">&#8226; Send Report :</h4>
                        <InputSwitch
                          checked={sendReport}
                          onChange={e => setSendReport(e.value)}
                        />
                      </div>
                  
                      <div className="mb-5">
                        <h4 className="card-title mb-3">&#8226; Automatic Reward Payouts :</h4>
                        <InputSwitch
                          checked={autoRewardPayout}
                          onChange={e => setAutoRewardPayout(e.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={8} md={8}>
                      <div>
                        <Label className="form-label">BCC Reports Emails sent to:</Label>
                        <FormGroup className="">
                          {isSuccess && permissions?.map((permission) => (
                            <FormGroup check key={permission.key}>
                              <Input id={permission.key} name="permission[]" type="checkbox" defaultChecked={levels.includes(permission.key)} value={permission.key} onChange={(e) => {
                                if (e.target.checked) {
                                  const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                  updateLevel(clickedValue);
                                } else {
                                  const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                  updateLevel(clickedValue);
                                }
                              }} />
                              <Label htmlFor={permission.key}>{permission.title}</Label>
                              {validation.touched.permission && validation.errors.permission ? (
                                <FormFeedback type="invalid">{validation.errors.permission}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          ))}
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={validation.isSubmitting}
                    >
                      {validation.isSubmitting ? "Submitting..." : "Submit"}
                    </Button>{" "}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          toggle={handleCancel}
          onConfirm={handleConfirm}
          data={modalData}
          type={"partner"}
          rawData={rawData}
          permissions={permissions}

        />
      )}

    </React.Fragment>
  )
}

export default PartnerSetting
