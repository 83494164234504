import React, { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';


import '../../../../../assets/css/style.css'
const PreviewImageModal = ({ imageUrl }) => {

    const [modal_center, setmodal_center] = useState(false);
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
    };

    return (
        <>
            <a href="#/" onClick={tog_center} className="mt-2 align-self-center">
                <img src={imageUrl} width={80} height={80} alt="Thumbnail" />
            </a>

            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Preview Image</ModalHeader>
                <ModalBody>
                    <img src={imageUrl} className="img-fluid" alt="Large Preview" />

                </ModalBody>
            </Modal>
        </>
    )

}

export default PreviewImageModal
