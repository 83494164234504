import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import qrIcon from "../../../../assets/images/services-icon/qr-icon.png";

import '../../../../assets/css/style.css'
import PickupManualTransaction from "./PickupManualTransaction";

const PickupQrModal = ({ pickup, setRefresh }) => {
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
    };


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    QR Code
                    <img src={qrIcon} style={{ filter: 'invert(100%)' }} alt="QR Code" width={20} height={20} />

                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="sm">

                <ModalHeader className="mt-0" toggle={tog_center}>Pick Up Qr Code</ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center">
                    <Row>
                        <PickupManualTransaction user={pickup} setRefresh={setRefresh} />

                        <img
                            src={`${process.env.REACT_APP_API_URL}/web/qrpickup/${pickup._id}`}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} // Adjust styles to fit the image within the modal
                        />
                    </Row>


                </ModalBody>
            </Modal>
        </>
    )

}

export default PickupQrModal
