import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { InputSwitch } from 'primereact/inputswitch';

import '../../../../assets/css/style.css'
import useGroup from "hooks/useGroup";
import { Dropdown } from 'primereact/dropdown';

const EditGroupModal = ({ group }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { setRefresh, groupType } = useGroup();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const [status, setStatus] = useState(group.active);
    const [selectedType, setSelectedType] = useState(group.type);

    const changeType = (e) => {
        setSelectedType(e?.value)
    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: group.name,
            color: group.color,
            description: group.description,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            color: Yup.string().required(
                "This value is required"
            ),
            // description: Yup.string().required(
            //     "This value is required"
            // ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                // const opts = { ...values, active: status == true ? 1 : 0 };
                const opts = { ...values, type: selectedType };
                const response = await put('/api/admin/engineer/box-groups/' + group._id, opts);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Group</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>

                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Type</Label>
                            <div className="col">
                                <Dropdown
                                    value={selectedType || 'None'}
                                    onChange={changeType}
                                    options={groupType}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label>Description</Label>
                            <Input
                                name="description"
                                placeholder="Enter Description"
                                rows="5"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                    validation.touched.description && validation.errors.description ? true : false
                                }
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Color</Label>
                            <div className="d-flex align-items-center">
                                <Input
                                    name="color"
                                    placeholder="Enter Color"
                                    type="color"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.color || ""}
                                    invalid={
                                        validation.touched.color && validation.errors.color ? true : false
                                    }
                                    style={{ marginRight: '10px', width: '100px', fontSize: '12px' }} // Adjust the styles here
                                />
                                <div style={{ fontSize: '12px' }}> {/* Adjust the font size here */}
                                    <span>Hex Value: </span>
                                    <span>{validation.values.color}</span>
                                </div>
                            </div>
                            {validation.touched.color && validation.errors.color ? (
                                <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                            ) : null}
                        </div>
                      {/*  <div className="mb-5">
                            <h4 className="card-title mb-3">Active</h4>
                            <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                        </div>*/} 
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditGroupModal
