import { useState, useRef, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Link } from "react-router-dom";

import { formatThousandSeparator, debounce, formatTimeToUTC, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";

import '../../../assets/css/style.css'
import usePartner from "hooks/usePartner";
import TransactionHistoryModal from "../Overview/modals/TransactionHistoryModal";
import { Calendar } from 'primereact/calendar';

const ReportTable = ({ id, lazyState, setlazyState, maxDate, filterPeriod, setFilterPeriod }) => {
    const [search, setSearch] = useState(id || '')
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState
    
    const parseStartEndDate = (inp) => {
        if (!inp) return {
            startDate: '',
            endDate: '',
        };
        if (!inp) return '';

        const date = new Date(inp.toString());
        date.setHours(0, 0, 0, 0);

        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setHours(23, 59, 59, 999);

        const formattedFirstDay = formatTimeToUTC(firstDay);
        const formattedLastDay = formatTimeToUTC(lastDay);
        return {
            startDate: formattedFirstDay,
            endDate: formattedLastDay,
        };
    };
    const changePeriod = (e) => {

        let value = e.value ? e.value : maxDate;
        setFilterPeriod(value);

        const { startDate, endDate } = parseStartEndDate(e.value);
        let _filters = { ...lazyState };
        _filters.startDate = startDate;
        _filters.endDate = endDate;
        setlazyState(_filters)
    }
    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    const { dataTotalRecords, setDataLoading, dataLoading, dataTable } = usePartner();
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const delay = 1000;

    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value; // Update the search text
        setlazyState(_filters);      // Set the updated lazyState
    }
    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setDataLoading(true);
        debouncedSearch(value);
    }
    const actualVolume = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="volume" />
                {" / "}
                {formatThousandSeparator(String(data.partner_volume?.expected_volume ?? 0))}
            </>
        );
    }
    const actualUser = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="user" />
                {" / "}
                {formatThousandSeparator(String(data.user_partner?.user_count ?? 0))}
            </>
        );
    }
    const actualLocation = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="location" />
                {" / "}
                {formatThousandSeparator(String(data.location_partner?.location_count ?? 0))}
            </>
        );
    }
    const AppUsername = (data) => {
        if (data?.username && data?.username != '-') {
            return <Link to={`/userlist/${data?.username}`}> {data?.username} </Link>
        } else {
            return data?.username;
        }
    }

    const bodyPartnerShare = (data) => {
        return balanceFormatter(data?.partner_share_earning ?? 0, 'IDR');
    }
    const bodyPartnerName = (data) => {
        const partnerName = data.partner_account;
        const partnerTypeList = data.partner_type_list || [];

        const formattedPartnerTypes = (
            <div>
                {partnerTypeList.map((type) => {
                    if (type === 'location_partner') {
                        return <span className="badge bg-secondary me-2" style={{ color: '#6610f2' }} key={type}>Loc</span>;
                    } else if (type === 'user_partner') {
                        return <span className="badge bg-active text-t-active me-2" key={type}>User</span>;
                    } else if (type === 'volume_partner') {
                        return <span className="badge bg-offline text-t-offline me-2" key={type}>Vol</span>;
                    }
                    return null;
                })}
            </div>
        );

        return (
            <div>
                <span>{partnerName}</span>
                {formattedPartnerTypes}
            </div>
        );
    };

    const bodyAmountDue = (data) => {
        return balanceFormatter(data?.amount_due ?? 0, 'IDR');
    }
    const bodyAmountPaid = (data) => {
        return balanceFormatter(data?.amount_paid ?? 0, 'IDR');
    }
    const bodyAmountOutstanding = (data) => {
        return balanceFormatter(data?.outstanding_balance ?? 0, 'IDR');
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Partner Name', body: bodyPartnerName, className: '' },
        { field: 'username', header: 'Username', body: AppUsername, className: '' },
        { field: 'amount_due', header: 'Amount Due', body: bodyAmountDue, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: 'amount_paid', header: 'Amount Paid', body: bodyAmountPaid, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' },   },
        { field: 'outstanding_balance', header: 'Outstanding Balance', body: bodyAmountOutstanding, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } ,sortable: true },
    ]

    return (
        <Card >
            <CardBody>
                <Row className="align-items-end mb-2">
                    <Col lg={6} className=" d-flex align-items-center">
                        {/* <h5>Report Period</h5> */}
                        <Calendar locale="en" maxDate={maxDate} value={filterPeriod} onChange={changePeriod} view="month" dateFormat="mm/yy" showIcon />
                    </Col>
                    <Col lg={6} className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                            {/* <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ width: '240px' }} /> */}
                        </span>
                    </Col>
                </Row>
                <DataTable
                    // lazy
                    removableSort
                    value={dataLoading ? items : dataTable}
                    stripedRows
                    paginator
                    rows={lazyState.config.rows}
                    rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                    size={'normal'}
                    // onPage={onPage}
                    totalRecords={dataTotalRecords}
                    first={lazyState.config.first}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    dataKey={dataLoading ? '' : 'id'}

                >
                    {columns.map((col, i) => (
                        <Column
                            key={col.field}
                            sortable={col.sortable}
                            field={col.field}
                            header={col.header}
                            headerClassName={col.headerClassName}
                            style={col.style}
                            body={dataLoading ? <Skeleton></Skeleton> : col.body}
                            bodyClassName={col.className}
                        />
                    ))}
                </DataTable>
            </CardBody>
        </Card>
    )
}

export default ReportTable