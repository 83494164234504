import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";
import useAsset from "hooks/useAsset";

const UserList = ({ setTotalTabRecords }) => {
    const location = useLocation();
    const { company } = location.state || {};
    const propsData = company;
    const { setDatas, setTotalRecords, expandedRows, setExpandedRows, refresh, setRefresh } = useAsset();
    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 20,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getDatas = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setDatas(response.data.results);
            setTotalRecords(response.data.total_records??0)
            setTotalTabRecords(response.data.total_records??0)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getDatas();
        }, 300);
    }, [lazyState, refresh]);

    return (
        <React.Fragment>
            <DataTable loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} setRefresh={setRefresh} />
        </React.Fragment>
    );
};

export default UserList;
