import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import useBox from "hooks/useBox";
import makeid from "helpers/random";


const DataExportCSV = ({ groupDropdown }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { groupFilter } = useBox();

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let groupFilterName = "";
    if (groupFilter) {
        groupFilterName = groupDropdown.find((item) => item.value === groupFilter).name
    }
    groupDropdown
    let filename = `Box_List_${date}${groupFilterName ? `_${groupFilterName}` : ''}.csv`

    const headers = [
        { label: "Box ID", key: "internal_id" },
        { label: "Box Model", key: "model" },
        { label: "Box Name", key: "name" },
        { label: "Group", key: "group_name" },
        { label: "Box address", key: "address" },
        { label: "Latitude", key: "gpsLatitude" },
        { label: "Longitude", key: "gpsLongitude" },
   ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            // console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            var response = null;
            if (groupFilter !== 'all') {
                response = await axiosApi.get(`/api/admin/engineer/getBoxes?group=${groupFilter}`);
            } else {
                response = await axiosApi.get('/api/admin/engineer/getBoxes');
            }
            const result = response.data;
            result.forEach(obj => {
                // obj.location = obj.gpsLatitude + "," + obj.gpsLongitude;
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                style={{
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding:'0.75rem 0.75rem',
                }}
                rounded
                onClick={() => fetchDataReq()}
            >
                {loading ? <i className="pi pi-spin pi-spinner"></i> : <i className="mdi mdi-24px mdi-file"></i>}
            </Button>
                <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV