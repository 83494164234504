import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useUser from "hooks/useUser";
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useAsset from "hooks/useAsset";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";
const DataTables = ({ loading, id, lazyState, setlazyState, setRefresh }) => {
    const { datas, totalRecords, expandedRows, setExpandedRows } = useAsset();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;

    const axiosApi = useAxiosPrivate();

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }


    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }


    const checkName = (data) => {
        return data.name;

    }

    const checkLegalName = (data) => {
        return data.legal_name;
    }
    const checkAddress = (data) => {
        return data.address;
    }
    const checkCity = (data) => {
        return data.locationcity_name;
    }

    const checkCompanyId = (data) => {
        return data.company_code;
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditModal rowData={data} />
                </div>
                <div>
                    <DeleteModal rowData={data} />
                </div>
            </div>
        )
    }
    const badgeStatus = (data) => {
        let badgeText = data.is_active?"ACTIVE":"INACTIVE";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_active ===true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'company_code', header: 'Company ID', body: checkCompanyId, className: '' },
        { field: 'name', header: 'Name', body: checkName, className: '' },
        { field: 'legal_name', header: 'Legal Name', body: checkLegalName, className: '' },
        { field: 'address', header: 'Street Address', body: checkAddress, className: 'text-end' },
        { field: 'city', header: 'City', body: checkCity, className: '' },
        { field: '', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    const rowClassName = (rowData) => {
        if (!rowData.is_active) {
            return 'redBg';
        }
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <div className="d-flex flex-row justify-content-around p-3">
                    <div>
                        <ul>
                            <li>Company ID : {data.company_code}</li>
                            <li>Name : {checkName(data)}</li>
                            <li>Phone : {data.phone}</li>
                            <li>Contact Name : {data.contact_name}</li>
                            <li>Contact Email : {data.contact_email}</li>
                            <li>Contact Number : {data.contact_phone}</li>
                            <li>Legal Name : {checkLegalName(data)}</li>
                            <li>Tax Number : {data.tax_number}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <ul>
                            <li>Country : {data.locationcountry_name}</li>
                            <li>Region : {data.locationregion_name}</li>
                            <li>City : {checkCity(data)}</li>
                            <li>Address : {checkAddress(data)}</li>
                            <li>Postcode : {data.postcode}</li>
                        </ul>
                    </div>
                </div>

            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddModal />

                            </Col>
                            <Col md={5} xs={10}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={2}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            lazy
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={loading ? '' : 'id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DataTables
