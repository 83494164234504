import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";
import useOrder from "hooks/useOrder";

const ContentTabs = ({ paramStatus, totalRecords, setTotalRecords }) => {
    const location = useLocation();
    const propsData = location.state;
    const queryParams = new URLSearchParams(location.search);
    const searchText = queryParams.get('search');

    const { refresh, setRefresh } = useOrder();
    const [datas, setDatas] = useState();
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    // const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        searchText: searchText || propsData || '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const axiosApi = useAxiosPrivate();

    const getDatas = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/orders?status=${paramStatus}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setDatas(response.data.results);
            setTotalRecords(response.data.total_records ?? 0)

            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getDatas();
        }, 300);
    }, [lazyState, refresh]);

    return (
        <React.Fragment>
            <DataTable id={searchText || propsData} lazyState={lazyState} setlazyState={setlazyState} datas={datas} expandedRows={expandedRows} setExpandedRows={setExpandedRows} totalRecords={totalRecords} loading={loading} paramStatus={paramStatus} />
        </React.Fragment>
    );
};

export default ContentTabs;
