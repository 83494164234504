import React from "react"
import AddPermissionModal from "./modals/AddPermissionModal";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import EditPermissionModal from "./modals/EditPermissionModal";
import DeletePermissionModal from "./modals/DeletePermissionModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import usePermission from "hooks/usePermission";

const PermissionTable = () => {
    const { permissions, loading } = usePermission();

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const menuList = (data) => {
        if (data.menu.length > 1) {
            return (
                <ul>
                    {data.menu.map(menu => (
                        <li key={menu._id}>{menu.title}</li>
                    ))}
                </ul>
            );
        } else {
            return (
                <div>
                    {data.menu.map(menu => (
                        <div>{menu.title}</div>
                    ))}
                </div>
            );
        }
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditPermissionModal permission={data} />
                </div>
                <div>
                    <DeletePermissionModal permission={data} />
                </div>
            </div>
        )
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'title', header: 'Title', body: '' },
        { field: '', header: 'Menu', body: menuList },
        { field: '', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddPermissionModal />

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            {/* <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Title" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col> */}
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            value={permissions}
                            stripedRows
                            size={'normal'}
                        >
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PermissionTable
