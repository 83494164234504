import { Card, CardBody, Col, Row } from "reactstrap"
import useAuth from "hooks/useAuth"
import FillingLevel from "./charts/FillingLevel"
import { simplify } from "helpers/functions"
import WeightChart from "./charts/WeightChart"
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const Weight = ({ lazyState, groupFilter, refresh, boxIdFilter }) => {
    const axiosApi = useAxiosPrivate()
    const [currentMonthWeight, setCurrentMonthWeight] = useState(0);
    const [lastMonthWeight, setLastMonthWeight] = useState(0);

    const [graphOptions, setGraphOptions] = useState(null)
    const [series, setSeries] = useState([
        {
            name: 'Weight',
            data: [],
        }
    ])
    const getTransactionGraph = async () => {
        try {
            var response;
            setGraphOptions(null)
            if (boxIdFilter) {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${boxIdFilter}`);
            } else if (groupFilter && groupFilter != "all") {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${lazyState?.boxArr}&group=${groupFilter}`);
            } else {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${lazyState?.boxArr}`);
            }
            const currentReport = response.data.response?.currentReport;
            if (currentReport.length > 0) {
                const currentMonth = new Date().getMonth() + 1;
                var previousMonth = currentMonth - 1;
                if (currentMonth == 1) {
                    previousMonth = 12
                }

                const filteredThisMonth = currentReport.filter(item => item._id.month === currentMonth);
                const totalWeightThisMonth = filteredThisMonth.length > 0 ? filteredThisMonth[0].totalWeight : 0;
                const filteredLastMonth = currentReport.filter(item => item._id.month === previousMonth);
                const totalWeightLastMonth = filteredLastMonth.length > 0 ? filteredLastMonth[0].totalWeight : 0;


                setLastMonthWeight(totalWeightLastMonth)
                setCurrentMonthWeight(totalWeightThisMonth)

            } else {
                setLastMonthWeight(0)
                setCurrentMonthWeight(0)

            }
            setSeries([
                {
                    name: 'Weight',
                    data: response.data.response.chartWeight,
                }
            ])

            setGraphOptions(
                {
                    colors: ['#70ad47'],
                    chart: {
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        },
                        type: 'area',
                        stroke: {
                            curve: 'smooth',
                            width: 0.1,
                        },
                    },
                    legend: {
                        show: false
                    },

                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    grid: {
                        borderColor: '#f8f8fa',
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: response.data.response.chartPeriod,
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: true,
                            rotate: -60,
                            rotateAlways: response.data.response.chartPeriod.length > 20 ? true : false,
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                return simplify(value ?? 0)
                            }
                        }
                    }
                }
            )



        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        getTransactionGraph();
    }, [lazyState, refresh, groupFilter, boxIdFilter]);

    const { thisMonth, lastMonth } = useAuth();
    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Monthly UCO Collected</h4>
                        <Row>
                            <Col lg={7}>
                                <div>
                                    {graphOptions && <WeightChart series={series} options={graphOptions} />
                                    }

                                </div>
                            </Col>
                            <Col lg={5}>
                                <Row>
                                    <Col md={6}>
                                        <div className="text-center">
                                            <p className="text-muted mb-4">This month</p>
                                            <h3>{simplify(currentMonthWeight || 0)} kg</h3>
                                            <p className="text-muted mb-4">
                                                Current Filling Levels.
                                            </p>
                                            <FillingLevel level={((currentMonthWeight || 0) / 100) * 100} />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-center">
                                            <p className="text-muted mb-4">Last month</p>
                                            <h3>{simplify(lastMonthWeight || 0)} kg</h3>
                                            <p className="text-muted mb-4">
                                                Overall Filling Levels.
                                            </p>
                                            <FillingLevel level={((lastMonthWeight || 0) / 100) * 100} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Weight