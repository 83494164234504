import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import DecryptBank from "./DecryptBank";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { currencyFormatter, inputDateTime, methodId, userAddress, debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import PaymentHistoryExportCSV from "../exports/PaymentHistoryExportCSV";

const PaymentHistoryModal = ({ data }) => {
    const initialLazyState = {
        searchText: '',
        status: 'all',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                status: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [payment, setPayment] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [expandedRows, setExpandedRows] = useState(null);
    const [search, setSearch] = useState('')
    const paymentStatus = [
        { name: 'All', value: 'all' },
        { name: 'Open', value: 'open' },
        { name: 'Pending', value: 'pending' },
        { name: 'Success', value: 'success' },
        { name: 'Cancelled', value: 'cancelled' }
    ];
    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);
    const delay = 300;

    const badgeStatus = (data) => {
        return (
            <span className={`badge bg-${data.status} text-t-${data.status}`}>
                {data.status}
            </span>
        )
    }

    const userEmail = (data) => {
        return data?.user[0]?.email || '-'
    }

    const paymentMethod = (data) => {
        let method;
        switch (data.method) {
            case 'banktrf':
                method = 'Bank Transfer';
                break;
            case 'ewallet':
                method = 'E-Wallet';
                break;
        }
        return method;
    }

    const amountFormat = (data) => {
        return currencyFormatter(data.amount, data.currency)
    }

    const columns = [
        { field: 'reqId', header: 'ID', body: '' },
        { field: 'createdAt', header: 'Input Date Time', body: inputDateTime },
        { field: 'amount', header: 'Amount', body: amountFormat },
        { field: 'currency', header: 'Currency', body: '' },
        { field: 'status', header: 'Status', body: badgeStatus },
        { field: 'method', header: 'Method', body: paymentMethod },
        { field: 'mtId', header: 'Method Transaction ID / ARN', body: methodId },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const changeStatus = (e) => {
        setSelectedStatus(e.value);
        let _filters = { ...lazyState };
        _filters.status = e.value;
        _filters.config.first = 0; //back to page 1
        setlazyState(_filters);
        setExpandedRows(null)
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Payment Request ID : {data.reqId}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div style={{ 'maxWidth': '250px' }}>
                        <label>User :</label>
                        <ul>
                            <li>Address : {userAddress(data)}</li>
                            <li>Email : {userEmail(data)}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Description :</label>
                        <ul>
                            <li>Currency : {data.currency}</li>
                            <li>Amount : {currencyFormatter(data?.amount, data.currency)}</li>
                            <li>Method : {paymentMethod(data)}</li>
                            <li>{data.method === 'banktrf' ? 'Bank Name' : 'Type'} : {data?.type}</li>
                            {data?.account_encrypted != null &&
                                <li>
                                <DecryptBank data={data} ></DecryptBank>
                                </li>
                            }
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <p>Request Date : {inputDateTime(data)}</p>
                        <p className="mb-0">ARN : {methodId(data)}</p>
                        <p>Status : {badgeStatus(data)}</p>
                    </div>
                </div>
            </div>
        );
    };

    const getPaymentHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/admin/support/payment-request?id=${data?._id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&status=${lazyState.status}&searchText=${lazyState.searchText}`);
            const result = response.data.result;
            setPayment(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSelectedStatus('all')
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getPaymentHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
            <Button type="button" color="primary" onClick={tog_center}>
                Show all payments
            </Button>{" "}
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">Payment History | {data?.name}</h4>
                        <div className="d-flex align-items-center gap-3 me-2">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                            </span>
                            <PaymentHistoryExportCSV lazyState={lazyState} data={data} />
                        </div>

                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <Dropdown value={selectedStatus} onChange={changeStatus} options={paymentStatus} optionLabel="name" className="h-1 payment-status-dropdown mb-2" />
                    <DataTable
                        lazy
                        value={loading ? items : payment}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                        scrollable
                        scrollHeight='500px'
                        expandedRows={loading ? null : expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowToggle={onRowToggle}
                        dataKey={loading ? '' : 'reqId'}
                    >
                        <Column expander={true} style={{ width: '3rem' }} />
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default PaymentHistoryModal
