import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, Row, Col } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { InputSwitch } from 'primereact/inputswitch';
import AddressForm from "pages/AddressForm";
import MapPicker from "../MapPicker";
import Select from "react-select";

const EditModal = ({ rowData }) => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setLocationRefresh, locationRefresh, ownershipTypes } = useAsset();
    const { showToast } = useAuth();
    const [selectedType, setSelectedType] = useState('');
    const [selectedOwnershipType, setSelectedOwnershipType] = useState(rowData.tank_owner_type);
    // const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedPostcode, setSelectedPostcode] = useState(rowData.postcode);
    const [selectedAddress, setSelectedAddress] = useState(rowData.address);
    const [selectedCompany, setSelectedCompany] = useState();
    const [selectedPerson, setSelectedPerson] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [personList, setPersonList] = useState([]);
    const [latitude, setLatitude] = useState(rowData.latitude);
    const [longitude, setLongitude] = useState(rowData.longitude);
    const [mapPicker, setMapPicker] = useState(false);
    // const [companyList, setCompanyList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(rowData.is_active);

    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            // locList.unshift({ name: "None", value: "", id: '' })
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
                if (element.id === rowData.tank_owner_company_id) {
                    setSelectedCompany(detail)
                }
            })
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }
    const getPersons = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/persons?is_active=true&show_all=true`);
            var dataList = response.data.results;
            let allData = [];
            if (response.data.results.length > 0) {
                dataList.forEach((element, index) => {

                    var boxDetail = {
                        ...element,
                        label: element.first_name + " " + element.last_name,
                    }
                    if (index == 0) {
                        setSelectedPerson(boxDetail);
                    }
                    allData.push(boxDetail);
                    if (element.id == rowData.tank_owner_person_id) {
                        setSelectedPerson(boxDetail)
                        // break;
                    }
                })
            }
            setPersonList(allData);

        } catch (error) {
            console.log(error);
        }
    }
    const getLocationTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/tank-location-types`);
            setTypeList(response.data.results);
            if (response.data.results.length > 0) {
                for (let idx = 0; idx < response.data.results.length; idx++) {
                    if (response.data.results[idx].id == rowData.tank_location_type_id) {
                        setSelectedType(response.data.results[idx])
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (modal_center) {
            getLocationTypes();
            getCompanies();
            getPersons();
        }
    }, [locationRefresh, modal_center]);


    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();

        setMapPicker(false);
        setLatitude(rowData.latitude);
        setLongitude(rowData.longitude);
        setSelectedStatus(rowData.is_active)
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };


    const changeType = (e) => {
        setSelectedType(e.value)
    }
    const changeOwnershipType = (e) => {
        setSelectedOwnershipType(e.value)
    }
    const changePerson = (e) => {
        setSelectedPerson(e.value)
    }

    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const changeMapPicker = (e) => {
        const modalElement = document.getElementsByClassName("modalForm");
        if (modalElement.length > 0) {
            const closestParent = modalElement[0].closest('.modal');

            if (!mapPicker) {
                closestParent.style.overflow = 'hidden';
                closestParent.style.height = `${window.innerHeight}px`;
            } else {
                closestParent.style.overflow = 'auto';
                closestParent.style.removeProperty('height');
            }
        }
        setMapPicker(!mapPicker)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            // location_id: rowData.location_id,
            name: rowData.name,
            // company_id: rowData.company_id,
            latitude: latitude,
            longitude: longitude,
            address: rowData.address,
            // city: rowData.city,
            // country: rowData.country,
            postcode: rowData.postcode,
            // active: true,
        },
        validationSchema: Yup.object().shape({
            // location_id: Yup.string().required("Location ID is required"),
            name: Yup.string().required("Name is required"),
            // company_id: Yup.string().required("Company ID is required"),
            address: Yup.string().required("Address is required"),
            latitude: Yup.string().required("Latitude is required"),
            longitude: Yup.string().required("Longitude is required"),
            postcode: Yup.string().required("Postcode is required"),
            // city: Yup.string().required("City is required"),
            // country: Yup.string().required("Country is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, tank_owner_type: selectedOwnershipType, tank_owner_person_id: selectedOwnershipType === 'individual' ? selectedPerson.id : null, tank_owner_company_id: selectedOwnershipType === 'individual' ? null : selectedCompany.id, tank_location_type_id: selectedType.id, location_city_id: selectedCity.id, location_region_id: selectedRegion.id, location_country_id: selectedCountry.id, address: selectedAddress, postcode: selectedPostcode, is_active: selectedStatus };
                // console.log(opts);
                const response = await put('/api/erp/asset/tank-locations/' + rowData.id, opts);
                setLocationRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    // console.log('test');
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal className="modalForm" isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Location</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <Row>
                            {/* Location ID */}
                            {/* <div className="mb-3">
                                <Label className="form-label">Location ID</Label>
                                <Input
                                    name="location_id"
                                    placeholder="Enter Location ID"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.location_id || ""}
                                    invalid={validation.touched.location_id && validation.errors.location_id}
                                />
                                {validation.touched.location_id && validation.errors.location_id ? (
                                    <FormFeedback type="invalid">{validation.errors.location_id}</FormFeedback>
                                ) : null}
                            </div> */}
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                                {/* Type */}
                                <div className="mb-3">
                                    <Label className="form-label">Type</Label>
                                    <div className="col">
                                        <Dropdown
                                            value={selectedType || ''}
                                            onChange={changeType}
                                            options={typeList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        />
                                    </div>
                                </div>
                                {/* Ownership Type */}
                                <div className="mb-3 px-2">
                                    <Label className="form-label">Ownership Type</Label>
                                    <div className="col">
                                        <Dropdown
                                            value={selectedOwnershipType || ''}
                                            onChange={changeOwnershipType}
                                            options={ownershipTypes}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Company ID */}
                            <div className="mb-3">
                                <Label className="form-label">{selectedOwnershipType == 'individual' ? "Individual" : "Company"}</Label>
                                {selectedOwnershipType == 'company' ?
                                    <div className="col">
                                        <Select
                                            name={`company_id`}
                                            value={selectedCompany}
                                            onChange={value => {
                                                setSelectedCompany(value)
                                            }}
                                            options={companyList}
                                            optionLabel="label"
                                            classNamePrefix="select2-selection"
                                        />
                                        {/* <Dropdown
                                            value={selectedCompany || ''}
                                            onChange={changeCompany}
                                            options={companyList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        /> */}
                                    </div> :
                                    <div className="col">
                                        <Select
                                            value={selectedPerson}
                                            onChange={value => {
                                                setSelectedPerson(value)
                                            }}
                                            options={personList}
                                            optionLabel="label"
                                            classNamePrefix="select2-selection"
                                        />
                                        {/* <Dropdown
                                            value={selectedPerson || ''}
                                            onChange={changePerson}
                                            options={personList}
                                            optionLabel="label"
                                            className="h-1 payment-status-dropdown"
                                        /> */}
                                    </div>
                                }

                            </div>
                            {/* Name */}
                            <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                    name="name"
                                    placeholder="Enter Name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={validation.touched.name && validation.errors.name}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}
                            </div>
                            {/* Location Coordinates */}
                            <div className="mb-3">
                                <Label className="form-label">Coordinates</Label>
                                <ul className="d-flex" style={{ padding: 0, listStyleType: "none" }}>
                                    <li style={{ marginRight: "10px" }}>
                                        <Label className="form-label">Latitude :</Label>
                                        <Input
                                            name="latitude"
                                            placeholder="Enter Latitude"
                                            type="text"
                                            onChange={(e) => {
                                                // validation.handleChange(e);
                                                setLatitude(e.target.value)
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={latitude}
                                            invalid={
                                                validation.touched.latitude && validation.errors.latitude ? true : false
                                            }
                                        />

                                        {
                                            validation.touched.latitude && validation.errors.latitude ? (
                                                <FormFeedback type="invalid">{validation.errors.latitude}</FormFeedback>
                                            ) : null}
                                    </li>
                                    <li>
                                        <Label className="form-label">Longitude :</Label>
                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                            <div className="col">
                                                <Input
                                                    name="longitude"
                                                    placeholder="Enter Longitude"
                                                    type="text"
                                                    onChange={(e) => {
                                                        // validation.handleChange(e);
                                                        setLongitude(e.target.value)

                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={longitude}
                                                    invalid={
                                                        validation.touched.longitude && validation.errors.longitude ? true : false
                                                    }
                                                />
                                                {
                                                    validation.touched.longitude && validation.errors.longitude ? (
                                                        <FormFeedback type="invalid">{validation.errors.longitude}</FormFeedback>
                                                    ) : null}
                                            </div>
                                            <div className="px-2">
                                                <Button type="button" color="primary" onClick={changeMapPicker}>
                                                    {mapPicker ? 'OK' : <i className="fa fas fa-map-marked-alt"></i>}
                                                </Button>
                                            </div>
                                        </div>

                                    </li>
                                    <li>

                                    </li>
                                </ul>
                            </div>
                            {mapPicker &&
                                <MapPicker setLatitude={setLatitude} latitude={latitude} longitude={longitude} setLongitude={setLongitude} />
                            }

                            <AddressForm
                                refresh={modal_center}
                                validation={validation}
                                selectedCountry={selectedCountry}
                                selectedCity={selectedCity}
                                selectedRegion={selectedRegion}
                                selectedPostcode={selectedPostcode}
                                selectedAddress={selectedAddress}
                                setSelectedCountry={setSelectedCountry}
                                setSelectedCity={setSelectedCity}
                                setSelectedRegion={setSelectedRegion}
                                setSelectedPostcode={setSelectedPostcode}
                                setSelectedAddress={setSelectedAddress}
                                countryId={rowData.location_country_id}
                                regionId={rowData.location_region_id}
                                cityId={rowData.location_city_id}
                            />
                            {/* Status */}
                            <div className="mb-3">
                                <Label className="form-label">Active</Label><br />
                                <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                            </div>

                        </Row>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting || selectedCity == '' || selectedCountry == ''}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
