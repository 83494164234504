import React, { useEffect, useState } from "react";
import PermissionTable from "./PermissionTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import usePermission from "hooks/usePermission";

const PermissionList = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setPermissions, setLoading } = usePermission();
  const { auth } = useAuth();

  const getPermissions = async () => {
    try {
      const response = await axiosApi.get('/api/admin/permission');
      setLoading(false); 
      setPermissions(response.data.result);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setLoading(true);
    getPermissions();
  },[refresh]);

  return (
    <React.Fragment>
          <PermissionTable />
    </React.Fragment>
  );
};

export default PermissionList;
