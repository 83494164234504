import useBox from 'hooks/useBox';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const BoxUsage = ({value,color}) => {
  const [options, setOptions] = useState({
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '16px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    colors: [ color ],
  })
  
  return (
    <React.Fragment>
      <div className='mt-2'>
      <ReactApexChart options={options} series={[value]} type="radialBar" height="230" />
      </div>
    </React.Fragment>
  );
};

export default BoxUsage;
