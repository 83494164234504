import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { InputSwitch } from 'primereact/inputswitch';
import InputMask from "react-input-mask";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import deleteIcon from "../../../../assets/images/delete-icon.png";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useCollection from "hooks/useCollection";

const EditModal = ({ rowData }) => {
    const { showToast } = useAuth();
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useCollection();
    const [orderList, setOrderList] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [defaultId, setDefaultId] = useState(rowData.collection_code);


    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        setDefaultId(rowData.collection_code)

    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const getOrders = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/orders?status=active&show_all=true&collection_id=${rowData.id}`);
            var locList = response.data.results;
            locList.unshift({ order_code: "None", value: "", id: '' })
            let allList = []
            if (locList)
                locList.forEach((element, index) => {
                    var location = {
                        ...element,
                        label: element.order_code
                    }
                    allList.push(location);

                    if (rowData.order_id == element.id) {
                        setSelectedOrder(location)
                    } else if (index == 0) {
                        setSelectedOrder(location)
                    }
                });
            setOrderList(allList);

        } catch (error) {
            console.log(error);
        }
    }

    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    useEffect(() => {
        if(modal_center){
            getOrders();
        }

    }, [refresh, modal_center]);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        // enableReinitialize: true,
        initialValues: {
            collection_code: rowData.collection_code,
            max_capacity: rowData.max_capacity,

        },
        validationSchema: Yup.object().shape({
            collection_code: Yup.string().required("This value is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    ...values,
                    max_capacity: values.max_capacity ? values.max_capacity : 0,
                    order_id: selectedOrder ? selectedOrder.id == "" ? null : selectedOrder.id : null,

                };
                // console.log("opts", opts);
                const response = await put('/api/erp/collections/' + rowData.id, opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Collection</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Collection ID</Label>
                            {/* <Input
                                name="collection_code"
                                placeholder="Enter Collection ID"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.collection_code || ""}
                                invalid={validation.touched.collection_code && validation.errors.collection_code}
                            /> */}
                            <InputMask
                                name="collection_code"
                                mask="aaaa-aa-aaaa-99999"  // Mask for date format
                                value={defaultId}  // Controlled input value
                                onChange={handleIdChange}  // Update state on change
                                invalid={validation.touched.collection_code && validation.errors.collection_code}
                                maskChar={null}  // Removes default mask characters (like "_")
                            >
                                {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                            </InputMask>
                            {validation.touched.collection_code && validation.errors.collection_code ? (
                                <FormFeedback type="invalid">{validation.errors.collection_code}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Order ID */}
                        <div className="mb-3">
                            <Label className="form-label">Order</Label>
                            <Select
                                name='order_id'
                                onChange={value => {
                                    setSelectedOrder(value);
                                    validation.setFieldValue("max_capacity", value.weight ?? 0)
                                }}
                                options={orderList}
                                value={selectedOrder}
                                classNamePrefix="select2-selection"
                            />
                        </div>
                        {/* Collection Capacity */}
                        <div className="mb-3">
                            <Label className="form-label">To Be Collected Quantity</Label>
                            <Input
                                name="max_capacity"
                                min={0}
                                placeholder="Enter Value"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.max_capacity || ""}
                                invalid={validation.touched.max_capacity && validation.errors.max_capacity}
                            />
                            {validation.touched.max_capacity && validation.errors.max_capacity ? (
                                <FormFeedback type="invalid">{validation.errors.max_capacity}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal