import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

const OTPDisabledMiddleware = (props) => {
    const { auth } = useAuth();

    if (!auth?.auth?.otp_enabled) {
        return (
        <Navigate to={{ pathname: "/profile", state: { from: props.location } }} />
        );
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>);
};

export default OTPDisabledMiddleware;
