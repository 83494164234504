import { useState } from "react";
import { Button,Row,Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import useBox from "hooks/useBox";

import makeid from 'helpers/random';
import { INITIAL_OPENING_HOURS } from "constants/value";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const ConnectBoxModal = ({ modal_center, tog_center, box }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const [code,setCode]= useState("");

    const refreshContent = () => {
        // setBox(null);
        // setBoxImages([]);
        // setPin(true);
        // setRefresh(makeid(5));
        // filterTransaction(null, transaction, setVolume, setWeight, setSeries)
        // setSelectedMode('');
        // setOpeningHoursArray(INITIAL_OPENING_HOURS);
    }

    // Form validation 
    // const validation2 = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,

    //     initialValues: {
    //         id: box?.id,
    //     },

    //     onSubmit: async (values, { resetForm }) => {
    //         try {
    //             const response = await axiosApi.post('/api/admin/engineer/box/delete', values);
    //             setRefresh(response.random);
    //             setBox(null)
    //             setBoxImages([])
    //             setSelectedMode('')
    //             setOpeningHoursArray(INITIAL_OPENING_HOURS)
    //             resetForm();
    //             tog_center();
    //             toggleRightCanvas();
    //             refreshContent();
    //             showToast(response);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // });
    const connect = async () => {
        try {
            const opt =  { code:code,boxId:box?.id };
            // console.log("opt",opt);
            // console.log("box", box);
            const response = await axiosApi.post(`/api/admin/engineer/box/connect`,opt);    
            showToast(response);
            tog_center();
        } catch (error) {
            console.log(error);
        }
        setCode("")
    }

    const curEnv = () => {
        //check current domain if contain api-dev
        if (window.location.hostname.includes('manage-dev')) {
            return 'DEV'
        } else if (window.location.hostname.includes('manage-qa')) {
            return 'QA'
        } else if (window.location.hostname.includes('manage.noovoleum')) {
            return 'LIVE'
        } else {
            return "Other / localhost"
        }
    }

    return (
        <>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Connect Box</ModalHeader>
                <ModalBody>
                    <p>Please enter a Code that is shown on the Box Screen</p>
                    <span class="text-muted">Make sure it is on the correct environment. <a href="/uco-reset-env-tutorial.png">Click here to learn more</a><br></br>
                    Current Environment : <b>{curEnv()}</b> </span>
                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} class="huge-input"></input>
                    <hr></hr>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="button" onClick={() => connect()} color="primary" >
                               Connect
                            </Button>{" "}
                        </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ConnectBoxModal
