import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import Flatpickr from "react-flatpickr";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { post, del, get, put } from "../../../../helpers/api_helper";
import deleteIcon from "../../../../assets/images/delete-icon.png";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useOrder from "hooks/useOrder";

const ApprovalModal = ({ rowData }) => {
    const { showToast } = useAuth();

    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useOrder();
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusList, setStatusList] = useState([]);
    const getOrderStatus = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/order-statuses`);
            var locList = response.data.results;
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                }
                if(detail.id<rowData.order_status_id){
                    detail.disabled = true;
                }
                if(index == 0){
                    setSelectedStatus(detail)
                } else if (detail.id === rowData.order_status_id) {
                    setSelectedStatus(detail)
                }
                allData.push(detail);
            });
            setStatusList(allData);
            // for (let idx = 0; idx < response.data.results.length; idx++) {
            //     if (response.data.results[idx].id === rowData.order_status_id) {
            //         setSelectedStatus(response.data.results[idx])
            //         break;
            //     }
            // }
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    useEffect(() => {
        if(modal_center){
            getOrderStatus();
        }
    }, [refresh, modal_center]);


    const changeStatus = (e) => {
        setSelectedStatus(e.value)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {

        },
        validationSchema: Yup.object().shape({


        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    order_status_id: selectedStatus ? selectedStatus.id : null
                };
                // console.log("opts", opts);
                const response = await put('/api/erp/orders/' + rowData.id, opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="warning" onClick={tog_center}>
                    <i className="fas fa-check"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Change Order Status | {rowData.order_code}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        {/* Status ID */}
                        <div className="mb-3">
                            <Label className="form-label">Status</Label>
                            <div className="col">
                                <Dropdown
                                    value={selectedStatus || ''}
                                    onChange={changeStatus}
                                    options={statusList}
                                    optionLabel="name"
                                    optionDisabled="disabled" // Assuming your component supports this prop
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default ApprovalModal
