import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Input, Label, FormFeedback } from 'reactstrap';
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Select from "react-select";

const AddressForm = ({
    refresh,
    selectedCountry,
    setSelectedCountry,
    selectedCity,
    setSelectedCity,
    selectedRegion,
    setSelectedRegion,
    selectedPostcode,
    setSelectedPostcode,
    selectedAddress,
    setSelectedAddress,
    countryId,
    regionId,
    cityId,
    rowData,
}) => {
    const axiosApi = useAxiosPrivate();

    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);

    const changeRegion = (e) => {
        setSelectedRegion(e.value)
    }
    const changeCountry = (e) => {
        setSelectedCountry(e.target.value)
        getRegions({ id: e.target.value.id });
    }
    const getCities = async ({ id }) => {
        try {
            const response = await axiosApi.get(`/api/erp/master/location-cities?location_region_id=${id}`);
            let allData = []
            var dataList = response.data.results;
            if (dataList.length > 0) {
                dataList.forEach((element, index) => {
                    var boxDetail = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: element.name,
                    }
                    if (element.id == cityId) {
                        setSelectedCity(boxDetail)
                    } else if (index == 0) {
                        setSelectedCity(boxDetail)
                    }
                    allData.push(boxDetail);
                });
            }
            else {
                setSelectedCity(null)
            }
            setCityList(allData);

        } catch (error) {
            console.log(error);
        }
    }
    const getRegions = async ({ id }) => {
        try {
            const response = await axiosApi.get(`/api/erp/master/location-regions?location_country_id=${id}`);
            let allData = []
            var dataList = response.data.results;
            if (dataList.length > 0) {
                dataList.forEach((element, index) => {
                    var boxDetail = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: element.name,
                    }

                    if (element.id == regionId) {
                        setSelectedRegion(boxDetail)
                        getCities({ id: boxDetail.id });
                    } else if (index == 0 && (regionId == null || regionId == "")) {
                        setSelectedRegion(boxDetail)
                        getCities({ id: boxDetail.id });
                    }
                    allData.push(boxDetail);
                });
            } else {
                setSelectedRegion(null)
                getCities({ id: null });
            }

            setRegionList(allData);
        } catch (error) {
            console.log(error);
        }
    }
    const getCountries = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/location-countries`);
            var dataList = response.data.results;
            let allData = []
            if (dataList.length > 0) {
                dataList.forEach((element, index) => {
                    if (element.id == countryId) {
                        setSelectedCountry(element)
                        getRegions({ id: element.id });
                    } else if (index == 0 && (countryId == null || countryId == "")) {
                        setSelectedCountry(element)
                        getRegions({ id: element.id });
                    }
                    allData.push(element);
                });
            }
            else {
                setSelectedCountry(null)
                getRegions({ id: null });
            }
            setCountryList(allData);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // getCities();
        if (refresh) {
            getCountries();
        }
    }, [refresh]);

    return (
        <div>
            {/* Country */}
            <div className="mb-3 ">
                <Label className="form-label">Country</Label>
                <div className="col">
                    <Dropdown
                        value={selectedCountry || ''}
                        onChange={changeCountry}
                        options={countryList}
                        optionLabel="name"
                        className="h-1 payment-status-dropdown"
                    />
                </div>
            </div>

            {/* Region */}
            <div className="mb-3 ">
                <Label className="form-label">Region</Label>
                <div className="col">
                    <Select
                        name='city_id'
                        value={selectedRegion}
                        onChange={value => {
                            setSelectedRegion(value)
                            getCities({ id: value.id });
                        }}
                        options={regionList}
                        classNamePrefix="select2-selection"
                    />
                </div>
            </div>
            {/* <div className="mb-3 ">
                <Label className="form-label">Region</Label>
                <div className="col">
                    <Dropdown
                        value={selectedRegion || ''}
                        onChange={changeRegion}
                        options={regionList}
                        optionLabel="name"
                        className="h-1 payment-status-dropdown"
                    />
                </div>
            </div> */}
            {/* City */}
            <div className="mb-3 ">
                <Label className="form-label">City</Label>
                <div className="col">
                    <Select
                        name='city_id'
                        value={selectedCity}
                        onChange={value => {
                            setSelectedCity(value)
                        }}
                        options={cityList}
                        classNamePrefix="select2-selection"
                    />
                </div>
            </div>
            {/* Address */}
            <div className="mb-3">
                <Label className="form-label">Address</Label>
                <Input
                    name="address"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Enter Address"
                    type="textarea"
                    style={{ resize: "none" }}
                />

            </div>
            {/* Postcode */}
            <div className="mb-3">
                <Label className="form-label">Postal Code</Label>
                <Input
                    name="postcode"
                    placeholder="Enter Postal Code"
                    type="number"
                    value={selectedPostcode}
                    onChange={(e) => {
                        const { value } = e.target;
                        const maxLength = 5;
                        // Check if the input value is numeric and within the max length
                        if (/^\d*$/.test(value) && value.length <= maxLength) {
                            setSelectedPostcode(value)
                        }
                    }}

                />

            </div>
        </div>
    );
};

export default AddressForm;