import React, { useEffect, useState } from "react";
import UserPartnerTable from "./UserPartnerTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import AddPartnerModal from "./modals/AddPartnerModal";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import { Card, CardBody, Col, Row } from "reactstrap"
import { getFormattedUTCDate } from "helpers/functions"
import usePartner from "hooks/usePartner";
import { useParams } from "react-router-dom";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { id } = useParams();
  const today = new Date()
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  endOfMonth.setHours(23, 59, 59, 999)
  const startDate = getFormattedUTCDate(startOfMonth)
  const endDate = getFormattedUTCDate(endOfMonth)
  const [selectedPeriodName, setSelectedPeriodName] = useState('This Month');

  const [lazyState, setLazyState] = useState({
    searchText: id || '',
    periodName: selectedPeriodName,
    startDate: startDate,
    endDate: endDate,
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const { partnerRefresh } = usePartner();

  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    setLoading(true);

    const getPartners = async () => {
      try {
        // const response = await axiosApi.get(`/api/admin/marketing/partner?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
        const response = await axiosApi.get(`/api/admin/marketing/partner?&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
        setLoading(false);
        setDatas(response.data.result)
        setTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getPartners();

  }, [partnerRefresh, lazyState]);
  document.title = `${process.env.REACT_APP_NAME || ''} | User Partners`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={4} sm={4}>
                <h6 className="page-title">User Partners</h6>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Partners</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    User Partners
                  </li>
                </ol>
              </Col>

              <Col
                md={8}
                sm={8}
                className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap"
              >
                <PaymentPeriodModal
                  mainOptions={lazyState}
                  setMainOptions={setLazyState}
                  selectedPeriodName={selectedPeriodName}
                  setSelectedPeriodName={setSelectedPeriodName}
                />
              </Col>
            </Row>
          </div>
          {/* <AddPartnerModal /> */}

          <UserPartnerTable datas={datas} searchId={id} loading={loading} totalRecords={totalRecords} lazyState={lazyState} setLazyState={setLazyState} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminList;
