import { Spinner } from 'reactstrap'

const Loading = () => {
    const spinnerStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional
        zIndex: 9999
    };
    return (
        <div style={spinnerStyle}>
            <Spinner color="primary" />
        </div>
    )
}

export default Loading