import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
} from "helpers/functions"
const ConfirmationModal = ({ isOpen, toggle, onConfirm, data, type, rawData, permissions=[] }) => {    
    let sortedRawData = rawData?.partner_report_email_admin_role?.length>0 ? JSON.parse(rawData.partner_report_email_admin_role).slice().sort() : [];
    let sortedData = JSON.parse(data.partner_report_email_admin_role).slice().sort();
    const partnerEmailAreEqual = JSON.stringify(sortedRawData) === JSON.stringify(sortedData);
    const findTitleById = (id) => {
        if (permissions.length === 0) return 'Title not found';
        const menu = permissions.find(menuItem => menuItem.key === id);
        if (menu) {
            return menu.title;
        }

        return 'Title not found';
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Your Settings</ModalHeader>
            <ModalBody>
                {type == 'withdrawal' &&
                    <div>
                        <div>
                            <h5>Withdrawal Limits</h5>
                            {rawData.withdrawal_limit.min.IDR == data.min_idr
                                ? <p> Minimum IDR : <b>{currencyFormatter(data.min_idr, 'IDR')}</b></p>
                                : <p>Minimum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.IDR, 'IDR')}</s> → <span className='text-primary'>{currencyFormatter(data.min_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.min.SGD == data.min_sgd
                                ? <p>Minimum SGD : <b>{currencyFormatter(data.min_sgd, 'SGD')}</b></p>
                                : <p>Minimum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_sgd, 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.IDR == data.max_idr
                                ? <p>Maximum IDR : <b>{currencyFormatter(data.max_idr, 'IDR')}</b></p>
                                : <p>Maximum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.SGD == data.max_sgd
                                ? <p>Maximum SGD : <b>{currencyFormatter(data.max_sgd, 'SGD')}</b></p>
                                : <p>Maximum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Withdrawal Thresholds</h5>
                            {rawData.withdrawal_threshold.min_withdraw_amount.IDR == data.withdrawal_threshold_min_idr
                                ? <p>Minimum Amount IDR : <b>{currencyFormatter(data.withdrawal_threshold_min_idr, 'IDR')}</b></p>
                                : <p>Minimum Amount IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_min_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.min_withdraw_amount.SGD == data.withdrawal_threshold_min_sgd
                                ? <p> Minimum Amount SGD : <b>{currencyFormatter(data.withdrawal_threshold_min_sgd, 'SGD')}</b></p>
                                : <p>Minimum Amount SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_min_sgd, 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.IDR == data.withdrawal_threshold_fee_idr
                                ? <p> Withdraw Fee IDR : <b>{currencyFormatter(data.withdrawal_threshold_fee_idr, 'IDR')}</b></p>
                                : <p>Withdraw Fee IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_fee_idr, 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.SGD == data.withdrawal_threshold_fee_sgd
                                ? <p> Withdraw Fee SGD: <b>{currencyFormatter(data.withdrawal_threshold_fee_sgd, 'SGD')}</b></p>
                                : <p>Withdraw Fee SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.withdrawal_threshold_fee_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Auto Withdrawal</h5>
                            {rawData.auto_withdrawal == data.withdrawal
                                ? <p><b>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.withdrawal ? 'Enabled' : 'Disabled'}</span></p>
                            }
                        </div>
                        <div>
                            <h5> Volume Partner Payout</h5>
                            {rawData?.withdrawal_partner?.payout_time == data.partner_payout_time
                                ? <p>Time : <b>{data.partner_payout_time}</b></p>
                                : <p>Time : <s className='text-danger'>{rawData?.withdrawal_partner?.payout_time}</s> →  <span className='text-primary'>{data.partner_payout_time}</span></p>
                            }
                            {rawData?.withdrawal_partner.max_payout_amount?.IDR == data.max_partner_payout_idr
                                ? <p> Maximum Payout IDR : <b>{currencyFormatter(data.max_partner_payout_idr, 'IDR')}</b></p>
                                : <p> Maximum Payout IDR : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_partner?.max_payout_amount?.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_partner_payout_idr, 'IDR')}</span></p>
                            }
                            {rawData?.withdrawal_partner?.max_payout_amount?.SGD == data.max_partner_payout_sgd
                                ? <p> Maximum Payout SGD : <b>{currencyFormatter(data.max_partner_payout_sgd, 'SGD')}</b></p>
                                : <p> Maximum Payout SGD : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_partner?.max_payout_amount?.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.max_partner_payout_sgd, 'SGD')}</span></p>
                            }

                        </div>
                    </div>
                }
                {type == 'general' &&
                    <div>
                        <div>
                            <h5>App Versions</h5>
                            {rawData.version.android == data.android_v
                                ? <p>Android Version : <b>{data.android_v}</b></p>
                                : <p>Android Version : <s className='text-danger'>{rawData.version.android}</s> → <span className='text-primary'>{data.android_v}</span></p>
                            }
                            {rawData.version.ios == data.ios_v
                                ? <p>iOS Version : <b>{data.ios_v}</b></p>
                                : <p>iOS Version : <s className='text-danger'>{rawData.version.ios}</s> →  <span className='text-primary'>{data.ios_v}</span></p>
                            }
                        </div>
                    </div>
                }
                {type == 'pickup' &&
                    <div>
                        <div>
                            <h5>Minimum Balance</h5>
                            {rawData.pickup.balance.min.IDR == data.min_balance_idr
                                ? <p>IDR : <b>{currencyFormatter(data.min_balance_idr, 'IDR')}</b></p>
                                : <p>IDR : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_idr, 'IDR')}</span></p>}
                            {rawData.pickup.balance.min.SGD == data.min_balance_sgd
                                ? <p>SGD : <b>{currencyFormatter(data.min_balance_sgd, 'SGD')}</b></p>
                                : <p>SGD : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_sgd, 'SGD')}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Quantity (liter)</h5>
                            {rawData.pickup.quantity.min == data.min_qty
                                ? <p> Minimum : <b>{data.min_qty}</b></p>
                                : <p>Minimum : <s className='text-danger'>{rawData.pickup.quantity.min}</s> →  <span className='text-primary'>{data.min_qty}</span></p>
                            }
                            {rawData.pickup.quantity.max == data.max_qty
                                ? <p> Minimum : <b>{data.max_qty}</b></p>
                                : <p>Minimum : <s className='text-danger'>{rawData.pickup.quantity.max}</s> →  <span className='text-primary'>{data.max_qty}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Maximum Distance (km): </h5>
                            {rawData.pickup.distance.max == data.max_distance
                                ? <p><b>{data.max_distance}</b></p>
                                : <p> <s className='text-danger'>{rawData.pickup.distance.max}</s>  →  <span className='text-primary'>{data.max_distance}</span></p>
                            }
                        </div>
                        <div>
                            <h5>Pick up Service (All User) </h5>
                            {rawData.pickup.service_available == data.pickupAvailable
                                ? <p><b>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.pickup.service_available ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</span></p>
                            }
                        </div>
                    </div>
                }
                {type == 'partner' &&
                    <div>
                        <div>
                            <h5>Send Report</h5>
                            {rawData.partner_send_report == data.sendReport
                                ? <p><b>{rawData.partner_send_report ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.partner_send_report ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.sendReport ? 'Enabled' : 'Disabled'}</span></p>
                            }
                            <h5>Automatic Reward Payouts</h5>
                            {rawData.partner_auto_reward_payout == data.autoRewardPayout
                                ? <p><b>{rawData.partner_auto_reward_payout ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.partner_auto_reward_payout ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.autoRewardPayout ? 'Enabled' : 'Disabled'}</span></p>
                            }
                            <h5>Partner Report BCC Recipients</h5>

                            {partnerEmailAreEqual
                                ?
                                <b>
                                    <ul>
                                        {sortedRawData.map((item, index) => (
                                            <li key={index}>{findTitleById(item)}</li>
                                        ))}
                                    </ul>
                                </b>

                                :
                                <div className='d-flex flex-row gap-2'>
                                    <s className='text-danger'>
                                        <ul>
                                            {sortedRawData.map((item, index) => (
                                                <li key={index}>{findTitleById(item)}</li>
                                            ))}
                                        </ul>
                                    </s> →
                                    <div className='text-primary'>
                                        <ul>
                                            {sortedData.map((item, index) => (
                                                <li key={index}>{findTitleById(item)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Submit</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
