import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { REFRESH_SIDEBAR } from "./actionTypes";
import { refreshSidebar } from "./actions";

function* refreshSidebarMenu() {
 refreshSidebar();
  
}


function* menuSaga() {
  yield takeEvery(REFRESH_SIDEBAR, refreshSidebarMenu);
}

export default menuSaga;
