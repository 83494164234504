import { createContext, useState } from "react";

const OverviewContext = createContext({});

export const OverviewProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [collections, setCollections] = useState(null);
    const [ucoUnits, setUcoUnits] = useState(null);
    const [currentStock, setCurrentStock] = useState(0)
    const [stocks, setStocks] = useState(null);
    const [stockCategories, setStockCategories] = useState(null);

    return (
        <OverviewContext.Provider value={{
            refresh, setRefresh, loading, setLoading, collections, setCollections, ucoUnits, setUcoUnits, orders, setOrders, currentStock, setCurrentStock, stocks, setStocks, stockCategories, setStockCategories
        }}>
            {children}
        </OverviewContext.Provider>
    )
}

export default OverviewContext;