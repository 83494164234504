import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";

const TransactionHistoryExportCSV = ({ lazyState, data, partnerLazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partner_Transactions_List_${date}_${data?.name}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { label: "ID", key: "TA_ID" },
        { label: "Box", key: "box[0].name" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Rejected Weight (kg)", key: "TA_Slops_Weight" },
        { label: "Rejected Volume (liter)", key: "TA_Slops_Volume" },
        { label: "Currency", key: "user[0].currency" },
        { label: "Amount", key: "amount" },
        { label: "Start", key: "TA_Start_Time" },
        { label: "End", key: "TA_End_Time"},
        { label: "Address", key: "address.address"},
        { label: "Postcode", key: "address.postcode"},
        { label: "City", key: "address.city"},
        { label: "Country", key: "address.country"},
        { label: "Latitude", key: "address.lat"},
        { label: "Longitude", key: "address.long"},
               { label: "Method", key: "method"},
 { label: "Alcohol Level", key: "extraData.alcohol_level" },
        { label: "Hydrocarbon Level", key: "extraData.hydrocarbon_level" },
        { label: "Raw Max Level", key: "extraData.raw_max_level" },
        { label: "Raw Interface Level", key: "extraData.raw_interface_level" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/engineer/export-partner-transaction?user_list=${data?.userList}&searchText=${lazyState.searchText}&startDate=${partnerLazyState.startDate}&endDate=${partnerLazyState.endDate}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.TA_UCO_Weight = simplify(obj.TA_UCO_Weight);
                obj.TA_UCO_Volume = simplify(obj.TA_UCO_Volume);
                obj.TA_Slops_Weight = simplify(obj.TA_Slops_Weight);
                obj.TA_Slops_Volume = simplify(obj.TA_Slops_Volume);
                obj.address.lat = obj.address.lat || obj?.box[0]?.location?.latitude || "";
                obj.address.long = obj.address.long || obj?.box[0]?.location?.longitude || "";
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>
        
    )
}

export default TransactionHistoryExportCSV