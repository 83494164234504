import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import useUser from "hooks/useUser";

const UserExportCSV = ({lazyState}) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { selectedCurrency } = useUser();

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Users_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { label: "ID", key: "_id" },
        { label: "Name", key: "name" },
        { label: "Username", key: "username" },
        { label: "Address", key: "address" },
        { label: "City", key: "city" },
        { label: "Country", key: "country" },
        { label: "Postcode", key: "postcode" },
        { label: "Phone", key: "phone.number" },
        { label: "Currency", key: "currency" },
        { label: "Balance", key: "balance" },
        { label: "Joined", key: "createdAt" },
        { label: "Accepted UCO (liter)", key: "trans_status.accepted"},
        { label: "Rejected UCO (liter)", key: "trans_status.rejected"},
        { label: "Total Request", key: "countPayments" },
        { label: "QR Hash", key: "qr_hash" },
        { label: "Referral", key: "referral_code" },
        { label: "isActive", key: "active" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const queryParams = {
                searchText: lazyState.searchText || '', // Default to empty string if not present
                searchMode: lazyState.searchMode || '', // Default to empty string if not present
                startDate: lazyState.startDate || '', // Default to empty string if not present
                endDate: lazyState.endDate || '', // Default to empty string if not present
                currency: selectedCurrency || '', // Default to empty string if not present
            };

            // Apply conditions dynamically
            if (!lazyState.startDate) delete queryParams.startDate; // Remove startDate if not set
            if (!lazyState.endDate) delete queryParams.endDate;     // Remove endDate if not set
            if (lazyState.searchMode == 'all') delete queryParams.searchMode;     // Remove searchMode if not set

            // Make the API call
            const response = await axiosApi.get('/api/admin/support/getUsers', { params: queryParams });
            const result = response.data.users;
            result.forEach(obj => {
                obj.trans_status.accepted = simplify(obj.trans_status.accepted);
                obj.trans_status.rejected = simplify(obj.trans_status.rejected);
                obj.referral_code = obj.referral_code??'-';

            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>
        
    )
}

export default UserExportCSV