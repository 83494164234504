import {
  REFRESH_SIDEBAR,
} from "./actionTypes"

export const refreshSidebar = (value) => {
  return {
    type: REFRESH_SIDEBAR,
    payload: { value },
  }
}
