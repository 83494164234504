import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import axiosApi from 'helpers/api_helper';

const UserVerified = ({ data }) => {
    const userId = data._id;
    var isVerified = false;
    if (data && data.hasOwnProperty('verified')) {
        isVerified = data.verified;
    }
    const [verified, setVerified] = useState(isVerified);
    const { showToast } = useAuth();

    const handleSwitchChange = async (value) => {
        setVerified(value);

        const body = { verified: value, user_id: userId };
        const response = await axiosApi.post('/api/admin/support/user-verified', body)
        showToast(response.data);
    };

    return (
        <div>
            <InputSwitch checked={verified} onChange={(e) => handleSwitchChange(e.value)} />
            {/* Other JSX */}
        </div>
    );
};

export default UserVerified;
