import { Row, Modal, ModalBody, ModalHeader } from "reactstrap"
import React, { useEffect, useState } from "react";
import axiosApi from 'helpers/api_helper';
import { Column } from "primereact/column";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";

import notFoundImage from "../../../../assets/images/users/user-blank.png";

const UserDetailModal = ({ user }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [users, setUsers] = useState(null);
    const tog_center = () => {
        setmodal_center(!modal_center);
    };
    const getUser = async (username) => {
        try {
            const response = await axiosApi.get(`/api/admin/support/getUserDetail/${user.user_id}`);
            // console.log(response);
            setUsers(response.data.users);
        } catch (error) {
        }
    }

    useEffect(() => {
        getUser();
    }, []);


    return (
        <>
            <div className="my-2">
                <a href="#" onClick={tog_center}>
                    {user.address.name}
                </a>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>User Details</ModalHeader>
                <ModalBody>
                    {users && (
                        <>
                            <div className="mb-1 d-flex align-items-center justify-content-center">
                                <div className="col-3 mr-1 me-3">
                                    <img src={users.picture ? users.picture : notFoundImage} alt="User's Picture" style={{ borderRadius: '0%', width: "100%" }} />
                                </div>
                                <div className="col-9">
                                    <table style={{ height: "100%" }}>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Name</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{users.name}</td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Username</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{users.username}</td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Phone</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{
                                                users.phone?.number ? (
                                                    <a href={`https://api.whatsapp.com/send?phone=${users.phone?.prefix.substring(1)}${users.phone?.number}`} target="_blank">
                                                        {`${users.phone?.prefix} ${users.phone?.number}`}
                                                        &nbsp;
                                                        <i className="success fab fa-whatsapp"></i>
                                                    </a>
                                                ) : (
                                                    '-'
                                                )
                                            } </td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Email</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{users.email}</td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Address</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{users.address}, {users.postcode}, {users.city}, {users.country} </td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Currency</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{users.currency}</td>
                                        </tr>
                                        <tr style={{ height: "30px" }}>
                                            <td style={{ width: "10%" }}>Balance</td>
                                            <td style={{ width: "2%" }}>:</td>
                                            <td>{currencyFormatter(users.balance, users.currency)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </>

                    )}



                </ModalBody>
            </Modal>
        </>
    )

}

export default UserDetailModal
