import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik, useFormikContext } from "formik";
import { Dropdown } from 'primereact/dropdown';
import { post, del, get, put } from "../../../../../helpers/api_helper";
import InputMask from "react-input-mask";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { InputSwitch } from 'primereact/inputswitch';
import Select from "react-select";

const AddModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { tankRefresh, setTankRefresh, locationRefresh, refresh } = useAsset();
    const { showToast } = useAuth();
    const [selectedStatus, setSelectedStatus] = useState(true)
    const [selectedLocation, setSelectedLocation] = useState("None");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [companyList, setCompanyList] = useState([]);

    const [selectedCapacityType, setSelectedCapacityType] = useState('');
    const [capacityTypeList, setCapacityTypeList] = useState([]);
    const [defaultId, setDefaultId] = useState(null);

    const getDefaultID = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tanks/get-code`);
            setDefaultId(response.data.results.tank_code)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
        }
    }
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const changeCapacityType = (e) => {
        setSelectedCapacityType(e.value)
    }
    const changeLocation = (e) => {
        setSelectedLocation(e?.value ?? "None")
        validation.setFieldValue("address", e.value.address)
        validation.setFieldValue("city", e.value.city)
        validation.setFieldValue("latitude", e.value.latitude)
        validation.setFieldValue("longitude", e.value.longitude)
    }

    const getLocations = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tank-locations?is_active=true&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ name: "None", value: "None" })
            setLocationList(locList);
            setSelectedLocation("None")

        } catch (error) {
            console.log(error);
        }
    }
    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
            });
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }

    const getTankCapacityTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/tank-capacity-types`);
            setCapacityTypeList(response.data.results);
            if (response.data.results.length > 0)
                setSelectedCapacityType(response.data.results[0])
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (modal_center) {
            getCompanies();
            getTankCapacityTypes();
            getLocations();
            getDefaultID();
        }
    }, [tankRefresh, modal_center]);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            tank_code: defaultId ?? "",
            name: "",
            // address: "",
            max_capacity: "",
            // city: "",
            model: "",
            // latitude:"",
            // longitude:"",
            // active: "ACTIVE",
        },
        validationSchema: Yup.object().shape({
            tank_code: Yup.string().required("Tank ID is required"),
            name: Yup.string().required("Tank name is required"),
            // address: Yup.string().required("Address is required"),
            // city: Yup.string().required("City is required"),
            // model: Yup.string().required("Model is required"),
            // latitude: Yup.string().required("Latitude is required"),
            // longitude: Yup.string().required("Longitude is required"),
            max_capacity: Yup.number().required("Capacity is required").min(0, "Capacity should be greater than 0"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    ...values,
                    // physical_box: (selectedPhysicalTank == "None"? null : selectedPhysicalTank.id),
                    // tank_capacity_type_id: selectedCapacityType.id,
                    tank_type_id: 2,
                    company_id: selectedCompany.id ?? null,
                    tank_location_id: (selectedLocation == "None" ? null : selectedLocation.id),
                    is_active: selectedStatus
                };
                console.log(opts);
                const response = await post('/api/erp/asset/tanks', opts);
                // status: status == true ? 1 : 0
                setTankRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Tank </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="md">
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Tank</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        <div className="row">
                            <div className="col-md-12">
                                {/* Company ID */}
                                <div className="mb-3">
                                    <Label className="form-label">Tank ID</Label>
                                    <InputMask
                                        placeholder="Enter Tank ID"
                                        name="tank_code"
                                        mask="aaaa-aa-aaa-aa-99999"  // Mask for date format
                                        value={defaultId}  // Controlled input value
                                        onChange={handleIdChange}  // Update state on change
                                        invalid={validation.touched.tank_code && validation.errors.tank_code}
                                        maskChar={null}  // Removes default mask characters (like "_")
                                    >
                                        {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                                    </InputMask>
                                    {validation.touched.tank_code && validation.errors.tank_code ? (
                                        <FormFeedback type="invalid">{validation.errors.tank_code}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* Name  */}
                                <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                        name="name"
                                        placeholder="Enter Name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={validation.touched.name && validation.errors.name}
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* Model  */}
                                <div className="mb-3">
                                    <Label className="form-label">Model</Label>
                                    <Input
                                        name="model"
                                        placeholder="Enter Model"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.model || ""}
                                        invalid={validation.touched.model && validation.errors.model}
                                    />
                                    {validation.touched.model && validation.errors.model ? (
                                        <FormFeedback type="invalid">{validation.errors.model}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="row">
                                    <div className="col-6">

                                        {/* Location ID */}
                                        <div className="mb-3">
                                            <Label className="form-label">Location (optional)</Label>
                                            <div className="col">
                                                <Dropdown
                                                    value={selectedLocation || 'None'}
                                                    onChange={changeLocation}
                                                    options={locationList}
                                                    optionLabel="name"
                                                    className="h-1 payment-status-dropdown"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Location Coordinates */}
                                {/* <div className="mb-3">
                                    <ul className="d-flex" style={{ padding: 0, listStyleType: "none" }}>
                                        <li style={{ marginRight: "10px" }}>
                                            <Label className="form-label">Latitude :</Label>
                                            <Input
                                                name="latitude"
                                                placeholder="Enter Latitude"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.latitude || ""}
                                                invalid={
                                                    validation.touched.latitude && validation.errors.latitude ? true : false
                                                }
                                            />
                                            {
                                                validation.touched.latitude && validation.errors.latitude ? (
                                                    <FormFeedback type="invalid">{validation.errors.latitude}</FormFeedback>
                                                ) : null}
                                        </li>
                                        <li>
                                            <Label className="form-label">Longitude :</Label>
                                            <Input
                                                name="longitude"
                                                placeholder="Enter Longitude"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.longitude || ""}
                                                invalid={
                                                    validation.touched.longitude && validation.errors.longitude ? true : false
                                                }
                                            />
                                            {
                                                validation.touched.longitude && validation.errors.longitude ? (
                                                    <FormFeedback type="invalid">{validation.errors.longitude}</FormFeedback>
                                                ) : null}
                                        </li>
                                    </ul>
                                </div> */}
                                {/* Address */}
                                {/* <div className="mb-3">
                                    <Label className="form-label">Address</Label>
                                    <Input
                                        name="address"
                                        placeholder="Enter Address"
                                        type="textarea"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address || ""}
                                        invalid={validation.touched.address && validation.errors.address}
                                        style={{ resize: "none" }} // Adding this inline style

                                    />
                                    {validation.touched.address && validation.errors.address ? (
                                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                    ) : null}
                                </div> */}
                                {/* Tank Capacity */}
                                {/* <div className="mb-3">
                                    <Label className="form-label">Capacity Type</Label>
                                    <div className="col">
                                        <Dropdown
                                            value={selectedCapacityType || ''}
                                            onChange={changeCapacityType}
                                            options={capacityTypeList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown"
                                        />
                                    </div>
                                </div> */}
                                {/* Capacity */}
                                <div className="mb-3">
                                    <Label className="form-label">Capacity</Label>
                                    <Input
                                        name="max_capacity"
                                        placeholder="Enter Capacity"
                                        type="number"
                                        min={0}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.max_capacity}
                                        invalid={validation.touched.max_capacity && validation.errors.max_capacity}
                                    />
                                    {validation.touched.max_capacity && validation.errors.max_capacity ? (
                                        <FormFeedback type="invalid">{validation.errors.max_capacity}</FormFeedback>
                                    ) : null}
                                </div>

                                {/* City */}
                                {/* <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                    <Input
                                        name="city"
                                        placeholder="Enter City"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={validation.touched.city && validation.errors.city}
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </div>
                            */}

                            </div>
                        </div>
                        <div className="row">

                            {/* Companies */}
                            <div className="mb-3">
                                <Label className="form-label">Company</Label>
                                <div className="col">
                                    <Select
                                        name={`company_id`}
                                        value={selectedCompany}
                                        onChange={value => {
                                            setSelectedCompany(value)
                                        }}
                                        options={companyList}
                                        optionLabel="label"
                                        classNamePrefix="select2-selection"
                                    />
                                    {/* <Dropdown
                                        value={selectedCompany || ''}
                                        onChange={changeCompany}
                                        options={companyList}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    /> */}
                                </div>
                            </div>
                            <div className="col-6">
                                {/* Status */}
                                <div className="mb-3">
                                    <Label className="form-label">Active</Label><br />
                                    <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting || selectedCapacityType == ""}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddModal
