import { useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Link } from "react-router-dom"

import { simplify, startTime, endTime, debounce, balanceFormatter, currencyFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import TransactionExportCSV from "./exports/TransactionExportCSV";
const TransactionsTable = ({ transDatas, totalRecords,  loading }) => {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'user.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
       
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const items = Array.from({ length: 5 }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 300;

    const promotionName = (data) => {
        return data.promotion_name ?? "-";
      
    }
    const checkUser = (data) => {
        return data?.user?.name || 'deleted user'
    }

    const promotionLink = (data) => {
        return <Link to={'/promotions'} state={data.promotion_id}> {data.promotion_name}</Link>
    }
    const userLink = (data) => {
        return <Link to={'/userlist'} state={data.TA_USER_ID}> {data?.user?.name || 'deleted user'} </Link>
    }
    const transactionTotalAmount = (data) => {
        var amount = 0;
        var bonus = 0;
        if (data.totalAmount && data.totalAmount != 0) {
            amount = data.totalAmount;
        } else {
            if (data.bonus) {
                bonus = data.bonus;
            }
            amount = data.amount + bonus;
        }
        return balanceFormatter(data.totalAmount || 0, 'IDR')
    }
    const trxId = (data) => {
        return <Link to={'/transactions/' + data.TA_ID} > {data.TA_ID} </Link>

    }
    const columns = [
        { field: 'TA_ID', header: 'Transaction ID', body: trxId, className: '' },
        { field: '', header: 'User', body: userLink, className: '' },
        { field: '', header: 'Promotion Name', body: promotionLink, className: '' },
         { field: 'TA_Start_Time', header: 'Date', body: startTime, className: '' },
        { field: 'totalAmount', header: 'Amount', body: transactionTotalAmount, className: 'text-end bold', sortable:true},
    ]

    // const onPage = (event) => {
    //     let _filters = { ...lazyState };
    //     _filters.config = event;
    //     setlazyState(_filters);
    // }

    // const changeFilter = (value) => {
    //     let _filters = { ...lazyState };
    //     _filters.searchText = value;
    //     setlazyState(_filters)
    // }

    // Create a debounced search function
    // const debouncedSearch = debounce(changeFilter, delay);

    // const onSearchTextChange = (e) => {
    //     const value = e.target.value;
    //     setSearch(value);
    //     debouncedSearch(value);
    // }

    const rowClassName = (rowData) => {
        if (!(rowData == null || rowData == undefined)) {
            if (!rowData?.UCO_Approved) {
                return 'redBg';
            }
        }
        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardTitle>
                        Reward Transaction History
                    </CardTitle>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={12} className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap">
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="TA_ID / User" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} className="order-md-last mt-2 mt-md-0"> {/* Use order-md-last to ensure export icon comes last on large screens */}
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <TransactionExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            removableSort
                            value={ transDatas}
                            stripedRows
                            paginator
                            filters={filters} 
                            globalFilterFields={['TA_ID', 'user.name']} 
                            rows={5}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            // onPage={onPage}
                            totalRecords={totalRecords}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            rowClassName={rowClassName}
                            dataKey={ 'TA_ID'}

                        >
                            {columns.map((col, i) => (
                                <Column
                                    sortable={col.sortable} 
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={ col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default TransactionsTable