import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
import usePartner from "hooks/usePartner";
const PartnerExportCSV = ({ lazyState }) => {
    const { entityList, sectorList } = usePartner();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
  
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partners_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`
  
    const headers = [
        { label: "Account Name", key: "name" },
        { label: "Type", key: "abbreviation" },
        { label: "Sector Type", key: "sector_name" },
        {label: "Referral Codes", key: "referral_codes" },
        { label: "Promotions", key: "promotions" },
        { label: "Signups", key: "signups" },
        { label: "Transactions", key: "totalAmount" },
        { label: "UCO (liter)", key: "totalVolume" },
        { label: "Address", key: "address" },
        { label: "E-mail", key: "email" },
        { label: "Phone", key: "phone" }, 
        { label: "Bank Name", key: "bank_full_name" },
        { label: "Bank Account", key: "account" },
        // { label: "Bank Account/E-wallet", key: "account" },
    ];
   
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
        
            const response = await axiosApi.get(`/api/admin/marketing/partner?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                const entity = entityList.find((item) => item.value === obj.type);
                const sector = sectorList.find((item) => item.value === obj.sector_type);

                obj.abbreviation = entity ? entity.abbr : obj.type;
                obj.sector_name = sector ? sector.name : obj.sector_type;
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default PartnerExportCSV