import moment from "moment"
import { createContext, useRef, useState } from "react"
import { CURRENT_WITHDRAWAL_PLATFORM } from "constants/value"

const PaymentContext = createContext({})

export const PaymentProvider = ({ children }) => {
  const [refresh, setRefresh] = useState("")
  const [totalRecords, setTotalRecords] = useState(0)
  const [totalOpen, setTotalOpen] = useState(0)
  const [totalOpenIdr, setTotalOpenIdr] = useState(0)
  const [totalAmounts, setTotalAmounts] = useState(0)
  const [totalAmountIdr, setTotalAmountIdr] = useState(0)
  const [autoWithdrawal, setAutoWithdrawal] = useState(false)
  const [totalUserBalance, setTotalUserBalance] = useState(0)
  const [totalUserBalanceIdr, setTotalUserBalanceIdr] = useState(0)
  const [totalBonus, setTotalBonus] = useState(0)
  const [totalBonusIdr, setTotalBonusIdr] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState("all")
  const [selectedPeriod, setSelectedPeriod] = useState("month")
  const [selectedPeriodName, setSelectedPeriodName] = useState("")
  const [ACBalance, setACBalance] = useState(0)
  const [XDBalance, setXDBalance] = useState(0)
  const [withdrawalPlatform, setWithdrawalPlatform] = useState(
    CURRENT_WITHDRAWAL_PLATFORM
  )

  const paymentStatus = [
    { name: "All", value: "all" },
    { name: "Open", value: "open" },
    { name: "Pending", value: "pending" },
    { name: "Success", value: "success" },
    { name: "Cancelled", value: "cancelled" },
  ]

  const paymentPeriod = [
    { name: "All Time", value: "all" },
    { name: "Today", value: "today" },
    { name: "Last 7 Days", value: "week" },
    { name: "This Month", value: "month" },
    { name: "Last Month", value: "lastMonth" },
    { name: "This Year", value: "year" },
    // { name: 'Custom', value: 'custom' },
  ]

  return (
    <PaymentContext.Provider
      value={{
        totalOpen,
        setTotalOpen,
        ACBalance,
        setACBalance,
        totalRecords,
        setTotalRecords,
        paymentStatus,
        selectedStatus,
        setSelectedStatus,
        totalAmounts,
        setTotalAmounts,
        refresh,
        setRefresh,
        totalUserBalance,
        setTotalUserBalance,
        autoWithdrawal,
        setAutoWithdrawal,
        paymentPeriod,
        selectedPeriod,
        setSelectedPeriod,
        selectedPeriodName,
        setSelectedPeriodName,
        totalOpenIdr,
        setTotalOpenIdr,
        totalAmountIdr,
        setTotalAmountIdr,
        totalUserBalanceIdr,
        setTotalUserBalanceIdr,
        totalBonus,
        setTotalBonus,
        totalBonusIdr,
        setTotalBonusIdr,
        XDBalance,
        setXDBalance,
        withdrawalPlatform,
        setWithdrawalPlatform,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export default PaymentContext
