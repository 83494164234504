import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import useAuth from 'hooks/useAuth';
import OverviewCards from 'pages/Dashboard/OverviewCards';
import useAxiosPrivate from "hooks/useAxiosPrivate";
import TransactionTable from './TransactionTable';
import PickupTable from './PickupTable';
import DashboardPeriodModal from './modals/DashboardPeriodModal';
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import Select from "react-select"
import Weight from 'pages/Admin-Engineer/Box-Management/Weight';

const Dashboard = () => {
  const axiosApi = useAxiosPrivate();
  const [refresh, setRefresh] = useState('');
  const [pickupRefresh, setPickupRefresh] = useState('');
  const [menu, setMenu] = useState(false);
  const [volume, setVolume] = useState([]);
  const [weight, setWeight] = useState([]);
  const [datas, setDatas] = useState([]);
  const [transDatas, setTransDatas] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pickupDatas, setPickupDatas] = useState([]);
  const [totalPickupRecords, setTotalPickupRecords] = useState(0);
  const [loadingPickup, setLoadingPickup] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const [boxList, setBoxList] = useState([]);

  const [series, setSeries] = useState([
    {
      name: 'Volume',
      data: [],
    }
  ])
  const [selectedPeriodName, setSelectedPeriodName] = useState('All Time');

  const [lazyState, setlazyState] = useState({
    searchText: '',
    boxArr: '',
    startDate: '',
    endDate: '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });


  const [lazyStatePickup, setlazyStatePickup] = useState({
    searchText: '',
    config: {
      first: 0,
      rows: 5,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });

  const toggle = () => {
    setMenu(!menu);
  };

  const getOverview = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/overview?startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&boxArr=${lazyState.boxArr}`);
      setDatas(response.data);
    } catch (error) {
      // console.log(error);
    }
  }

  // const getTransactionGraph = async () => {
  //   try {
  //     const response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&boxArr=${lazyState.boxArr}`);
  //     // console.log(`datas`, response.data.datas)
  //     filterTransaction(null, response.data.datas, setVolume, setWeight, setSeries);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }

  const getTransactions = async () => {
    try {
      setLoading(true)
      const response = await axiosApi.get(`/api/admin/engineer/getTransaction?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&boxArr=${lazyState.boxArr}`);
      const result = response.data.result;
      // console.log(response.data)
      setTransDatas(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setTotalRecords(response.data.totalRecords)
      } else {
        setTotalRecords(0)
      }
      setLoading(false)
    } catch (error) {
      // console.log(error);
    }
  }

  const getPickups = async () => {
    try {
      setLoadingPickup(true)
      const response = await axiosApi.get(`/api/admin/pickup/getPickups?rows=${lazyStatePickup.config.rows}&skip=${lazyStatePickup.config.first}&searchText=${lazyStatePickup.searchText}`);
      const result = response.data.result;
      // console.log(result)
      setPickupDatas(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setTotalPickupRecords(response.data.totalRecords)
      } else {
        setTotalPickupRecords(0)
      }
      setLoadingPickup(false)
      // console.log(pickupDatas)
      // console.log(totalPickupRecords)
    } catch (error) {
      // console.log(error);
    }
  }
  const getBoxList = async () => {
    try {
      const response = await axiosApi.get('/api/admin/engineer/group-box-list');
      var boxList = []
      if (response)
        response.data.result.forEach((element, index) => {
          var box = {
            ...element
          }
          if (element.boxList.length == 0) {
            box.boxList = '-'
          }
          boxList.push(box)
        })
      boxList.unshift({ name: "All", value: '', label: "All", boxList: '' })

      setSelectedBox(boxList[0])

      setBoxList(boxList)
    } catch (err) {
      console.log(err)
    }
  }


  // const filterTransaction = (id, result, setVolume, setWeight, setSeries) => {
  //   let resultVolume = [];
  //   let resultWeight = [];
  //   result.map((data, index) => {
  //     let totalVolume = 0;
  //     let totalWeight = 0;
  //     data.map((dt, i) => {
  //       if (id === null || id === dt?.boxId) {
  //         totalVolume += dt?.TA_UCO_Volume || 0;
  //         totalWeight += dt?.TA_UCO_Weight || 0;
  //       }
  //     })
  //     resultVolume[index] = totalVolume || 0;
  //     resultWeight[index] = totalWeight || 0;
  //   })

  //   setVolume(resultVolume);
  //   setSeries([
  //     {
  //       name: 'Weight',
  //       data: resultWeight,
  //     }
  //   ]);
  //   setWeight(resultWeight);
  // }

  useEffect(() => {
    // getPickups();
  }, [lazyStatePickup, pickupRefresh]);

  useEffect(() => {
    getOverview();
    // getTransactionGraph();
    getTransactions();
  }, [lazyState, refresh]);

  useEffect(() => {
    getBoxList();
  }, [refresh]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Dashboard`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col lg={6} sm={12}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to the Dashboard</li>
                </ol>
              </Col>
              <Col lg={6} sm={12}>
                <div className="d-flex flex-wrap justify-content-end align-items-center w-100">
                  <div className="flex-grow-1 me-2 mb-3" style={{ maxWidth: "300px" }}> {/* Flexible width for Select with max-width */}
                  <Select
                    value={selectedBox || ""}
                    onChange={val => {
                      setSelectedBox(val);
                      let _filters = { ...lazyState };
                      _filters.boxArr = val.boxList;
                      setlazyState(_filters);
                    }}
                    options={boxList}
                    optionLabel="name"
                    classNamePrefix="select2-selection"
                    style={{ width: "100%" }} 
                  />
                </div>
                <div> {/* Auto width for PaymentPeriodModal */}
                  <PaymentPeriodModal
                    mainOptions={lazyState}
                    setMainOptions={setlazyState}
                    selectedPeriodName={selectedPeriodName}
                    setSelectedPeriodName={setSelectedPeriodName}
                  />
                </div>
              </div>
            </Col>
            </Row>
          </div>
          <OverviewCards datas={datas} />
          <Weight lazyState={lazyState} groupFilter={null} refresh={refresh} boxIdFilter ={null} />
          {/* <PickupTable pickupDatas={pickupDatas} totalRecords={totalPickupRecords} lazyState={lazyStatePickup} setlazyState={setlazyStatePickup} loading={loadingPickup} setRefresh={setPickupRefresh} /> */}
          <TransactionTable transDatas={transDatas} totalRecords={totalRecords} lazyState={lazyState} setlazyState={setlazyState} loading={loading} setLoading={setLoading} setRefresh={setRefresh} />

        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
