import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';
import DetailModal from "./modals/DetailModal";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useUser from "hooks/useUser";
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, formatDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useAsset from "hooks/useAsset";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";
import SyncModal from "./modals/SyncModal";
import IncidentModal from "../modals/IncidentModal";
import LoadingOverlay from "react-loading-overlay";
const DataTables = ({ loading, id, lazyState, setlazyState, setRefresh, lastSync }) => {
    const { boxDatas, boxTotalRecords } = useAsset();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const [submitLoading, setSubmitLoading] = useState(false);
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }


    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }


    const checkName = (data) => {
        return data.name;

    }

    const checkLegalName = (data) => {
        return data.legal_name;
    }
    const checkAddress = (data) => {
        return data.address;
    }
    const checkCity = (data) => {
        return data.city;
    }
    const checkCountry = (data) => {
        return data.country;
    }

    const checkCompanyId = (data) => {
        return data.company_id;
    }
    const parseCapacity = (data) => {
        //divide it by 1000 and add ton suffix
        return <DetailModal data={data} />
    }
    const parseMaxCapacity = (data) => {
        //divide it by 1000 and add ton suffix
        let capacity = data.max_capacity;
        return capacity;
    }
    const boxLink = (data) => {
        return <Link to={`/deployments`} state={data.name} >
            {data.name}
        </Link>
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <IncidentModal title={data.name} rowData={data} id={data.id} setRefresh={setRefresh} loading={submitLoading} setLoading={setSubmitLoading} />
                </div>
                <div>
                    <EditModal rowData={data} />
                </div>
                <div>
                    <DeleteModal rowData={data} />
                </div>
            </div>
        )
    }

    const parseFillLevel = (data) => {
        //divide it by 1000 and add ton suffix
        let capacity = data.fill_level_percentage;
        return capacity + '%'
    }
    const badgeStatus = (data) => {
        let badgeText = data.is_active ? "ACTIVE" : "INACTIVE";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_active === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'tank_code', header: 'Box ID', body: '', className: '' },
        { field: 'name', header: 'Pyhsical Box Name', body: boxLink, className: '' },
        { field: 'tanklocation_name', header: 'Location', body: '', className: '' },
        { field: 'company_name', header: 'Company', body: '', className: '' },
        // { field: 'tankcapacitytype_name', header: 'Capacity Type', body: '', className: '' },
        { field: '', header: 'Capacity', body: parseMaxCapacity, className: '' },
        { field: '', header: 'Quantity', body: parseCapacity, className: '' },
        { field: '', header: ' Fill Levels ', body: parseFillLevel, className: '' },
        { field: '', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const rowClassName = (rowData) => {
        if (!rowData.is_active) {
            return 'redBg';
        }
    }
    return (
        <LoadingOverlay
            active={submitLoading}
            spinner
            text='Processing...'
        >
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row className="align-items-center mb-2">
                                <Col md={6} className="d-flex flex-row align-items-center">
                                    {/* <AddModal /> */}
                                    <SyncModal /> &nbsp;
                                    <p className="font-size-12 text-muted d-flex align-items-center mb-0">
                                        Last Synced : &nbsp;
                                        <span className={`badge bg-active text-t-active`}>
                                            {formatDateTime(lastSync)}
                                        </span>
                                    </p>
                                </Col>
                                <Col md={5} xs={10}>
                                    <span className="p-input-icon-left float-start float-md-end">
                                        <i className="pi pi-search" />
                                        <InputText value={search} onChange={onSearchTextChange} placeholder="ID / Pyhsical Box Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                    </span>
                                </Col>
                                <Col md={1} xs={2}>
                                    <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                        <DataExportCSV lazyState={lazyState} />
                                    </div>
                                </Col>
                            </Row>
                            <DataTable
                                lazy
                                value={loading ? items : boxDatas}
                                stripedRows
                                paginator
                                rows={lazyState.config.rows}
                                rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM}
                                size={'normal'}
                                onPage={onPage}
                                totalRecords={boxTotalRecords}
                                first={lazyState.config.first}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                                // expandedRows={loading ? null : expandedRows}
                                // rowExpansionTemplate={rowExpansionTemplate}
                                // onRowToggle={onRowToggle}
                                rowClassName={rowClassName}
                                dataKey={loading ? '' : '_id'}
                            >

                                {columns.map((col, i) => (
                                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                                ))}
                            </DataTable>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

export default DataTables
