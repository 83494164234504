import { createContext, useState } from "react";

const InventorySettingContext = createContext({});

export const InventorySettingProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [blacklistUsers, setBlacklistUsers] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    return (
        <InventorySettingContext.Provider value={{ refresh, setRefresh, blacklistUsers, setBlacklistUsers, loading, setLoading, totalRecords, setTotalRecords }}>
            {children}
        </InventorySettingContext.Provider>
    )
}

export default InventorySettingContext;