import React, { useEffect, useState } from "react"
import LoadingOverlay from 'react-loading-overlay';
import ConfirmationModal from "./modals/ConfirmationModal";
import useAxiosPrivate from "hooks/useAxiosPrivate"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import useAuth from "hooks/useAuth"
import { isNonEmptyObject } from "helpers/common"

const WithdrawalSettings = () => {
  const { showToast } = useAuth()
  const [refresh, setRefresh] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [rawData, setRawData] = useState(false)
  const [withdrawal, setWithdrawal] = useState(false)
  const [partnerPayoutTime, setPartnerPayoutTime] = useState(null)

  const [limit, setLimit] = useState({
    min: {
      IDR: 0,
      SGD: 0,
    },
    max: {
      IDR: 0,
      SGD: 0,
    },
  })
  const [withdrawThreshold, setWithdrawThreshold] = useState({
    min_withdraw_amount: {
      IDR: 0,
      SGD: 0,
    },
    withdraw_fee: {
      IDR: 0,
      SGD: 0,
    },
  })
  const [withdrawPartner, setWithdrawPartner] = useState({
    payout_time: null,
    max_payout_amount: {
      IDR: 0,
      SGD: 0,
    },
  })
  const axiosApi = useAxiosPrivate()

  const getAppSettings = async () => {
    try {
      // if (isSuccess) {
      //   return;
      // }
      const response = await axiosApi.get("/api/admin/setting/get-app-settings")
      if (response.data.setting) {
        const default_setting = {
          setting: {
            withdrawal_limit: { min: {}, max: {} },
            auto_withdrawal: true,
            // app_min_version: 0,
            withdrawal_threshold: { min_withdraw_amount: {}, withdraw_fee: {} },
            withdrawal_partner: {
              payout_time: null,
              max_payout_amount: {},
            }
          },
        }

        // Assuming response.data has the same structure as default_setting
        Object.assign(default_setting.setting, response.data.setting)
        const { auto_withdrawal } = default_setting.setting
        const { min, max } = default_setting.setting.withdrawal_limit
        const { min_withdraw_amount, withdraw_fee } = default_setting.setting.withdrawal_threshold
        const { max_payout_amount, payout_time } = default_setting.setting?.withdrawal_partner

        const defaultData = {}
        defaultData.auto_withdrawal = default_setting.setting.auto_withdrawal ?? false;
        defaultData.withdrawal_limit = default_setting.setting.withdrawal_limit;
        defaultData.withdrawal_threshold = default_setting.setting.withdrawal_threshold;
        defaultData.withdrawal_partner = default_setting.setting.withdrawal_partner;
        setRawData(defaultData)
        if (auto_withdrawal) {
          setWithdrawal(auto_withdrawal)
        }
        if (isNonEmptyObject(min) && isNonEmptyObject(max)) {
          setLimit({ min, max })
        }

        if (
          isNonEmptyObject(min_withdraw_amount) &&
          isNonEmptyObject(withdraw_fee)
        ) {
          setWithdrawThreshold({ min_withdraw_amount, withdraw_fee })
        }
        if (isNonEmptyObject(payout_time) && isNonEmptyObject(max_payout_amount)) {          
          setWithdrawPartner({ payout_time, max_payout_amount })
        }
        setPartnerPayoutTime(payout_time)
      }
      setIsSuccess(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.state) {
        let response = error.response.data;
        response.state = "error";
        response.toast = true;
        showToast(response);
        setIsSuccess(true);
      } else {
        let response = {};
        response.state = "error";
        response.toast = true;
        response.message = "Internal Server Error"
        showToast(response);
      }
    }

  }

  useEffect(() => {
    setTimeout(() => {
      getAppSettings()
    }, 300);
  }, [refresh])

  const handleConfirm = async () => {
    try {
      const values = modalData
      const {
        min_idr,
        min_sgd,
        max_idr,
        max_sgd,
        withdrawal_threshold_min_idr,
        withdrawal_threshold_min_sgd,
        withdrawal_threshold_fee_idr,
        withdrawal_threshold_fee_sgd,
        partner_payout_time,
        max_partner_payout_idr,
        max_partner_payout_sgd,
      } = values
      const withdrawal_limit = {
        min: {
          IDR: parseFloat(min_idr),
          SGD: parseFloat(min_sgd),
        },
        max: {
          IDR: parseFloat(max_idr),
          SGD: parseFloat(max_sgd),
        },
      }

      const withdrawal_threshold = {
        min_withdraw_amount: {
          IDR: parseFloat(withdrawal_threshold_min_idr),
          SGD: parseFloat(withdrawal_threshold_min_sgd),
        },
        withdraw_fee: {
          IDR: parseFloat(withdrawal_threshold_fee_idr),
          SGD: parseFloat(withdrawal_threshold_fee_sgd),
        },
      }
      const withdrawal_partner = {
        payout_time: partner_payout_time,
        max_payout_amount: {
          IDR: parseFloat(max_partner_payout_idr),
          SGD: parseFloat(max_partner_payout_sgd),
        },
      }

      const obj = {
        withdrawal_limit,
        withdrawal_threshold,
        auto_withdrawal: withdrawal,
        withdrawal_partner

      }

      const response = await axiosApi.post(
        "/api/admin/setting/update-app-settings",
        obj
      )
      showToast(response.data)
      const {
        random,
        setting: {
          auto_withdrawal,
          withdrawal_limit: { min, max },
          withdrawal_threshold: { min_withdraw_amount, withdraw_fee },
        },
      } = response.data
      setLimit({ min, max })
      setRefresh(random)
      setWithdrawal(auto_withdrawal)
      setWithdrawThreshold({ min_withdraw_amount, withdraw_fee })
      setIsModalOpen(false)
    } catch (error) {
      if (error.response && error.response.data) {
        validation.setErrors(error.response.data)
      } else {
        console.error(error)
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setModalData(null)
  }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      min_idr: String(limit.min.IDR),
      min_sgd: String(limit.min.SGD),
      max_idr: String(limit.max.IDR),
      max_sgd: String(limit.max.SGD),
      withdrawal_threshold_min_idr: String(
        withdrawThreshold.min_withdraw_amount.IDR
      ),
      withdrawal_threshold_min_sgd: String(
        withdrawThreshold.min_withdraw_amount.SGD
      ),
      withdrawal_threshold_fee_idr: String(withdrawThreshold.withdraw_fee.IDR),
      withdrawal_threshold_fee_sgd: String(withdrawThreshold.withdraw_fee.SGD),
      max_partner_payout_idr: String(withdrawPartner.max_payout_amount.IDR),
      max_partner_payout_sgd: String(withdrawPartner.max_payout_amount.SGD),
      partner_payout_time:''
    },
    validationSchema: Yup.object().shape({
      min_idr: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      min_sgd: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      max_idr: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      max_sgd: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      withdrawal_threshold_min_idr: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      withdrawal_threshold_min_sgd: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      withdrawal_threshold_fee_idr: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      withdrawal_threshold_fee_sgd: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      max_partner_payout_idr: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      max_partner_payout_sgd: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      partner_payout_time: partnerPayoutTime == null || partnerPayoutTime == ''
        ? Yup.string()
          .required("Partner Payout Time is required")
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsModalOpen(true)      
      const opts = { ...values, withdrawal: withdrawal, partner_payout_time: partnerPayoutTime }
      setModalData(opts)
    },
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <LoadingOverlay
            active={!isSuccess}
            spinner
            text='Loading your content...'
          >
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row>
                    <Col lg={6} md={6}>

                      <div className="mb-5">
                        <h4 className="card-title mb-3">
                          &#8226; Withdrawal Limit :
                        </h4>
                        <p className="mb-1">Minimum :</p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">IDR</Label>
                            <Input
                              name="min_idr"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.min_idr || ""}
                              invalid={
                                validation.touched.min_idr &&
                                  validation.errors.min_idr
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.min_idr &&
                              validation.errors.min_idr ? (
                              <FormFeedback type="invalid">
                                {validation.errors.min_idr}
                              </FormFeedback>
                            ) : null}
                          </li>
                          <li className="list-inline-item">
                            <Label className="form-label">SGD</Label>
                            <Input
                              name="min_sgd"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.min_sgd || ""}
                              invalid={
                                validation.touched.min_sgd &&
                                  validation.errors.min_sgd
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.min_sgd &&
                              validation.errors.min_sgd ? (
                              <FormFeedback type="invalid">
                                {validation.errors.min_sgd}
                              </FormFeedback>
                            ) : null}
                          </li>
                        </ul>
                        <p className="mb-1">Maximum : (0 if unlimited)</p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">IDR</Label>
                            <Input
                              name="max_idr"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.max_idr || ""}
                              invalid={
                                validation.touched.max_idr &&
                                  validation.errors.max_idr
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.max_idr &&
                              validation.errors.max_idr ? (
                              <FormFeedback type="invalid">
                                {validation.errors.max_idr}
                              </FormFeedback>
                            ) : null}
                          </li>
                          <li className="list-inline-item">
                            <Label className="form-label">SGD</Label>
                            <Input
                              name="max_sgd"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.max_sgd || ""}
                              invalid={
                                validation.touched.max_sgd &&
                                  validation.errors.max_sgd
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.max_sgd &&
                              validation.errors.max_sgd ? (
                              <FormFeedback type="invalid">
                                {validation.errors.max_sgd}
                              </FormFeedback>
                            ) : null}
                          </li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <h4 className="card-title mb-3">
                          &#8226; Withdrawal Threshold :
                        </h4>
                        <p className="mb-1">
                          Minimum amount for free: (0 if disabled)
                        </p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">IDR</Label>
                            <Input
                              name="withdrawal_threshold_min_idr"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.withdrawal_threshold_min_idr || ""
                              }
                              invalid={
                                validation.touched.withdrawal_threshold_min_idr &&
                                  validation.errors.withdrawal_threshold_min_idr
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.withdrawal_threshold_min_idr &&
                              validation.errors.withdrawal_threshold_min_idr ? (
                              <FormFeedback type="invalid">
                                {validation.errors.withdrawal_threshold_min_idr}
                              </FormFeedback>
                            ) : null}
                          </li>
                          <li className="list-inline-item">
                            <Label className="form-label">SGD</Label>
                            <Input
                              name="withdrawal_threshold_min_sgd"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.withdrawal_threshold_min_sgd || ""
                              }
                              invalid={
                                validation.touched.withdrawal_threshold_min_sgd &&
                                  validation.errors.withdrawal_threshold_min_sgd
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.withdrawal_threshold_min_sgd &&
                              validation.errors.withdrawal_threshold_min_sgd ? (
                              <FormFeedback type="invalid">
                                {validation.errors.withdrawal_threshold_min_sgd}
                              </FormFeedback>
                            ) : null}
                          </li>
                        </ul>
                        <p className="mb-1">Withdraw Fee :</p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">IDR</Label>
                            <Input
                              name="withdrawal_threshold_fee_idr"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.withdrawal_threshold_fee_idr || ""
                              }
                              invalid={
                                validation.touched.withdrawal_threshold_fee_idr &&
                                  validation.errors.withdrawal_threshold_fee_idr
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.withdrawal_threshold_fee_idr &&
                              validation.errors.withdrawal_threshold_fee_idr ? (
                              <FormFeedback type="invalid">
                                {validation.errors.withdrawal_threshold_fee_idr}
                              </FormFeedback>
                            ) : null}
                          </li>
                          <li className="list-inline-item">
                            <Label className="form-label">SGD</Label>
                            <Input
                              name="withdrawal_threshold_fee_sgd"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.withdrawal_threshold_fee_sgd || ""
                              }
                              invalid={
                                validation.touched.withdrawal_threshold_fee_sgd &&
                                  validation.errors.withdrawal_threshold_fee_sgd
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.withdrawal_threshold_fee_sgd &&
                              validation.errors.withdrawal_threshold_fee_sgd ? (
                              <FormFeedback type="invalid">
                                {validation.errors.withdrawal_threshold_fee_sgd}
                              </FormFeedback>
                            ) : null}
                          </li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <h4 className="card-title mb-3">&#8226; Auto Withdrawal :</h4>
                        <InputSwitch
                          checked={withdrawal}
                          onChange={e => setWithdrawal(e.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6}>
                      <div className="mb-5">
                        <h4 className="card-title mb-3">
                          &#8226; Volume Partner Payout :
                        </h4>
                        <p className="mb-1">Time : </p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">WIB</Label>
                            <Input
                              className="form-control"
                              type="time"
                              style={{ width: '150px' }}
                              name="partner_payout_time"
                              defaultValue={partnerPayoutTime}
                              onChange={value => {
                                if (value.target.value) {
                                  setPartnerPayoutTime(value.target.value)
                                } else {                                  
                                  setPartnerPayoutTime('')
                                }
                              }}
                              onInput={e => {
                                if (!e.target.value) {
                                  setPartnerPayoutTime('');
                                }
                              }}
                            />
                            {validation.touched.partner_payout_time &&
                              validation.errors.partner_payout_time ? (
                                <div class="error-text"> {validation.errors.partner_payout_time}</div>
                            ) : null}
                          </li>
                        </ul>
                        <p className="mb-1">Maximum : (0 if unlimited)</p>
                        <ul className="list-inline d-flex align-items-start">
                          <li className="list-inline-item">
                            <Label className="form-label">IDR</Label>
                            <Input
                              name="max_partner_payout_idr"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.max_partner_payout_idr || ""}
                              invalid={
                                validation.touched.max_partner_payout_idr &&
                                  validation.errors.max_partner_payout_idr
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.max_partner_payout_idr &&
                              validation.errors.max_partner_payout_idr ? (
                              <FormFeedback type="invalid">
                                {validation.errors.max_partner_payout_idr}
                              </FormFeedback>
                            ) : null}
                          </li>
                          <li className="list-inline-item">
                            <Label className="form-label">SGD</Label>
                            <Input
                              name="max_partner_payout_sgd"
                              placeholder="Enter Value..."
                              type="text"
                              autoComplete="off"
                              inputMode="numeric"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.max_partner_payout_sgd || ""}
                              invalid={
                                validation.touched.max_partner_payout_sgd &&
                                  validation.errors.max_partner_payout_sgd
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.max_partner_payout_sgd &&
                              validation.errors.max_partner_payout_sgd ? (
                              <FormFeedback type="invalid">
                                {validation.errors.max_partner_payout_sgd}
                              </FormFeedback>
                            ) : null}
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={validation.isSubmitting}
                    >
                      {validation.isSubmitting ? "Submitting..." : "Submit"}
                    </Button>{" "}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          toggle={handleCancel}
          onConfirm={handleConfirm}
          data={modalData}
          type={"withdrawal"}
          rawData={rawData}

        />
      )}

    </React.Fragment>
  )
}

export default WithdrawalSettings
