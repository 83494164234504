import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Progress, Row, Col } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import { post, del, get, put } from "../../../../helpers/api_helper";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import InputMask from "react-input-mask";
import { formatDate } from "helpers/functions";

// Formik validation
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

import deleteIcon from "../../../../assets/images/delete-icon.png";
import '../../../../assets/css/style.css'
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useCollection from "hooks/useCollection";
import { values } from "lodash";
import makeid from "helpers/random";

const AddModal = () => {
    const { showToast } = useAuth();
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedTank, setSelectedTank] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const addForm = (arrayHelpers, forms) => {
        let defaultAvailableBox = null;
        let usedBox = []
        if (forms.length > 0) {
            for (let i = 0; i < forms.length; i++) {
                if (forms[i].origin_tank_id) {
                    usedBox.push(forms[i].origin_tank_id.id)
                }
            }
        }
        if (selectedTank) {
            usedBox.push(selectedTank.id)
        }
        if (usedBox.length > 0) {
            defaultAvailableBox = availableBoxList.filter((element) => !usedBox.includes(element.id));

        }
        else {
            defaultAvailableBox = availableBoxList;
        }

        let object = {
            weight: '',
            origin_tank_id: '',
            availableBox: defaultAvailableBox,
        }
        arrayHelpers.push(object)
    }

    const calculateCollected = (forms) => {
        let total = 0;
        // Convert HTMLCollection to an array and sum the values
        total = Array.from(forms).reduce((sum, element) => {
            if (element.weight) {
                return sum + parseFloat(element.weight) || 0;
            } else {
                return sum; // Use parseFloat to convert to number and handle empty strings

            }
        }, 0);
        total = parseFloat(total.toFixed(3));

        setTotalCollected(total)
    }
    const reduceTotalCollected = (form) => {
        if (form.weight && form.weight > 0) {
            setTotalCollected(totalCollected - form.weight)
        }
    }
    const formatNumberFloat = (totalWeight) => {
        return isInteger(totalWeight) ? totalWeight : totalWeight.toFixed(3);

    }
    const isInteger = (value) => {
        if ((undefined === value) || (null === value)) {
            return false;
        }
        return value % 1 == 0;
    }
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useCollection();
    // const [locationList, setLocationList] = useState([]);
    const [personList, setPersonList] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState('');
    const [selectedTransport, setSelectedTransport] = useState('');
    const [transportList, setTransportList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [dueDate, setDueDate] = useState(null);

    const [availableBoxList, setAvailableBoxList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [totalCollected, setTotalCollected] = useState(0);
    const [defaultId, setDefaultId] = useState("");

    const tog_center = () => {
        // setSelectedTransport('');
        setmodal_center(!modal_center);
        setTotalCollected(0)
        setDueDate(null);
        setDefaultId("");
        removeBodyCss();
        // validation.resetForm();
    };
    const getDefaultID = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/collections/get-code`);
            setDefaultId(response.data.results.collection_code)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
        }
    }
    const getOrders = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/orders?status=active&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ order_code: "None", value: "", id: '' })
            let allList = []
            if (locList)
                locList.forEach(element => {
                    var location = {
                        ...element,
                        label: element.due_date ? `${element.order_code} - ${formatDate(element.due_date)}` : element.order_code
                    }
                    allList.push(location);
                });
            setOrderList(allList);
            setSelectedOrder(allList[0])
        } catch (error) {
            console.log(error);
        }
    }
    const getDestinations = async () => {
        try {
            var isOrder = false;
            if (selectedOrder && selectedOrder.id && selectedOrder.id != '') {
                isOrder = true;
            }
            const response = await axiosApi.get(`/api/erp/asset/tanks?is_active=true&show_all=true&is_order=${isOrder}`);
            var locList = response.data.results;
            // locList.unshift({ name: "None", value: "", id: '', capacity: 0 })
            let allBoxes = []
            if (locList)
                locList.forEach(element => {
                    var location = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: `${element.tanktype_name} - ${element.name} (${element.capacity ?? 0}/${element.max_capacity ?? 0} kg)`

                    }
                    allBoxes.push(location);

                });
            allBoxes.unshift({ name: "None", value: "", id: '', capacity: 0, label: "None" })

            setSelectedTank(allBoxes[0])
            setDestinationList(allBoxes);
            // setFieldValue('destination_tank_id',allBoxes[0])

        } catch (error) {
            console.log(error);
        }
    }

    const getAvailableTanks = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tanks/get-options?show_empty=false&is_origin=true`);
            var locList = response.data.results;
            let allBoxesDetail = []
            if (locList)
                locList.forEach(element => {
                    var boxDetail = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: `${element.tanktype_name} - ${element.name} (${element.capacity ?? 0} kg)`
                    }
                    allBoxesDetail.push(boxDetail);
                });
            setAvailableBoxList(allBoxesDetail)
            // setFieldValue('destination_tank_id',allBoxes[0])

        } catch (error) {
            console.log(error);
        }
    }


    const getTransport = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/transports?is_active=true&show_all=true`);
            var locList = response.data.results;
            let allBoxesDetail = []
            if (locList)
                locList.forEach(element => {
                    var boxDetail = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        // label: `${element.transporttype_name} - ${element.transport_code} (${element.identify_number})`
                        label: `${element.transporttype_name} - ${element.identify_number}`
                    }
                    allBoxesDetail.push(boxDetail);
                });
            allBoxesDetail.unshift({ transport_code: "Pump", value: "", id: '', label: 'Pump' })
            setTransportList(allBoxesDetail);
            setSelectedTransport(allBoxesDetail[0])

        } catch (error) {
            console.log(error);
        }
    }
    const getPersons = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/persons?is_active=true&show_all=true&person_type_id=1`);
            var locList = response.data.results;
            let allPerson = []
            if (locList)
                locList.forEach(element => {
                    var detail = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: element.first_name + " " + element.last_name
                    }
                    allPerson.push(detail);
                });
            setPersonList(allPerson);
            setSelectedDriver(allPerson[0])
            // setSelectedReceiver(response.data.result[0])
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (modal_center) {
            getPersons();
            getTransport();
            // getLocations();
            getOrders();
            getDestinations();
            getDefaultID();
            getAvailableTanks();
        }
    }, [refresh, modal_center]);

    useEffect(() => {
        if (modal_center) {
            getDestinations();
        }
    }, [selectedOrder]);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);

    };

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Collection </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Add Collection</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            collection_code: defaultId,
                            destination_tank_id: "",
                            collections: []
                        }}
                        validationSchema={Yup.object({
                            collection_code: Yup.string().required(
                                "ID is required"
                            ),
                            destination_tank_id: Yup.string().required(
                                "Destination is required"
                            ),
                            collections: Yup.array().of(
                                Yup.object().shape({
                                    origin_tank_id: Yup.object().required("Origin is required"),
                                    weight: Yup.number().required("Weight is required").moreThan(0, "Weight must be greater than 0"),
                                    // dest_type: Yup.string().required("Destination Type is required"),
                                    // dest_place: Yup.string().required("Destination Place is required")
                                })
                            ).required('Collection Points is required').min(1, 'Collection Points is required')
                        })}
                        resetForm
                        onSubmit={async (values) => {
                            setSubmitLoading(true);
                            try {
                                const updatedValues = values.collections.map(collection => ({
                                    // ...collection,
                                    weight:collection.weight,
                                    // dest_place: collection.dest_place._id,
                                    origin_tank_id: collection.origin_tank_id.id
                                }));
                                const opts = {
                                    ...values,
                                    collections:null,
                                    destination_tank_id: selectedTank ? selectedTank.id == "" ? null : selectedTank.id : null,
                                    order_id: selectedOrder ? selectedOrder.id == "" ? null : selectedOrder.id : null,
                                    max_capacity: selectedOrder ? values.max_capacity ?? 0 : 0,
                                    collection_details: updatedValues,
                                    tour_due_date: selectedTransport && selectedTransport.id ? (dueDate ?? null) : null,
                                    tour_transport_id: selectedTransport && selectedTransport.id ? selectedTransport.id : null,
                                    tour_person_id: selectedTransport && selectedTransport.id ? selectedDriver.id : null,

                                };
                                
                                
                                const response = await post('/api/erp/collections', opts);
                                tog_center();
                                setRefresh(makeid(5));
                                showToast(response);
                                setSubmitLoading(false);

                            } catch (error) {
                                if (error.response && error.response.data && error.response.data.state) {
                                    showToast(error.response.data);
                                } else {
                                    let response = {};
                                    response.state = "error";
                                    response.toast = true;
                                    response.message = "Internal Server Error"
                                    showToast(response);
                                }
                                setSubmitLoading(false)

                            }


                        }}
                        render={({ values, touched, errors, setFieldValue }) => (
                            <Form>
                                <FieldArray
                                    name="collections"
                                    render={(arrayHelpers) => {
                                        const forms = values.collections;
                                        return (
                                            <div>
                                                <Row>
                                                    <Col md={6} lg={6}>
                                                        {/* Collection ID */}
                                                        <div className="mb-3">
                                                            <Label className="form-label">Collection ID</Label>
                                                            {/* <Input
                                                                tag={Field}
                                                                name="collection_code"
                                                                placeholder="Enter Collection ID"
                                                                type="text"
                                                            /> */}
                                                            <InputMask
                                                                placeholder="Enter Collection ID"
                                                                name="collection_code"
                                                                mask="aaaa-aa-aaaa-99999"  // Mask for date format
                                                                value={defaultId}  // Controlled input value
                                                                onChange={handleIdChange}  // Update state on change
                                                                maskChar={null}  // Removes default mask characters (like "_")
                                                            >
                                                                {(inputProps) => <Input {...inputProps} tag={Field} type="text" />}
                                                            </InputMask>
                                                            <ErrorMessage component="div" className="error-text" name="collection_code" />
                                                        </div>
                                                        {/* Transport ID */}
                                                        <div className="mb-3">
                                                            <Label className="form-label">Transport</Label>
                                                            <div className="col">
                                                                <Select
                                                                    tag={Field}
                                                                    name='transport_id'
                                                                    value={selectedTransport}
                                                                    onChange={value => {
                                                                        setSelectedTransport(value)
                                                                        setFieldValue(`transport_id`, value.id)
                                                                    }}
                                                                    options={transportList}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} lg={6}>
                                                        {/* Order ID */}
                                                        <div className="mb-3">
                                                            <Label className="form-label">Order</Label>
                                                            <div className="col" styles={{ minWidth: '200px' }}>
                                                                <Select
                                                                    name='order_id'
                                                                    onChange={value => {
                                                                        setSelectedOrder(value);
                                                                        setTotalCollected(0)
                                                                        setFieldValue('max_capacity', value.weight ?? 0)
                                                                        setFieldValue('collections', []);
                                                                    }}
                                                                    options={orderList}
                                                                    value={selectedOrder}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Destination ID */}
                                                        <div className="mb-3">
                                                            <Label className="form-label">Destination</Label>
                                                            <div className="col">
                                                                <Select
                                                                    tag={Field}
                                                                    name='destination_tank_id'
                                                                    value={selectedTank}
                                                                    onChange={value => {
                                                                        setSelectedTank(value)
                                                                        setFieldValue(`destination_tank_id`, value.id)
                                                                        // setFieldValue('collections', []);
                                                                    }}
                                                                    options={destinationList}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                            <ErrorMessage component="div" className="error-text" name="destination_tank_id" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col lg={6} md={6} >
                                                        {selectedTransport && selectedTransport.id && <div>
                                                            {/* Driver ID (Person)*/}
                                                            <div className="mb-3">
                                                                <Label className="form-label">Driver</Label>
                                                                <div className="col">
                                                                    <Select
                                                                        tag={Field}
                                                                        name='person_id'
                                                                        value={selectedDriver}
                                                                        onChange={value => {
                                                                            setSelectedDriver(value)
                                                                            setFieldValue(`person_id`, value.id)
                                                                        }}
                                                                        options={personList}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {/* <Dropdown
                                                                        value={selectedDriver || ''}
                                                                        onChange={changeDriver}
                                                                        options={personList}
                                                                        optionLabel="name"
                                                                        className="h-1 payment-status-dropdown"
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <Label className="form-label">Due Date</Label>
                                                                <Flatpickr
                                                                    className="form-control d-block"
                                                                    value={dueDate}
                                                                    onChange={value => {
                                                                        if (value.length > 0) {
                                                                            setDueDate(value[0])
                                                                        } else {
                                                                            setDueDate(null)

                                                                        }
                                                                    }}
                                                                    placeholder="m/d/Y"
                                                                    options={{
                                                                        altInput: true,
                                                                        altFormat: "m/d/Y",
                                                                        dateFormat: "m/d/Y"
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>}
                                                    </Col>

                                                    <Col lg={6} md={6} >
                                                        {selectedOrder && selectedOrder.id && <div className="mb-3">
                                                            <Label className="form-label">To Be Collected Quantity (kg)</Label>
                                                            <Input
                                                                style={{ width: '250px' }}
                                                                tag={Field}
                                                                name="max_capacity"
                                                                placeholder="Enter Capacity"
                                                                type="number"
                                                            />
                                                            <ErrorMessage component="div" className="error-text" name="max_capacity" />
                                                        </div>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <div className="py-3 mb-3">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h3>Collection Points</h3>
                                                                {forms.length == 0 && <div className="d-flex flex-wrap gap-2 justify-content-end px-3">
                                                                    <Button onClick={() => addForm(arrayHelpers, forms)} color="primary" className="btn btn-primary">
                                                                        <i className="fas fa-plus">
                                                                        </i></Button>
                                                                </div>}
                                                            </div>
                                                            <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }} />

                                                            {touched.collections && errors.collections && typeof errors.collections === 'string' && (
                                                                <div className="error-text mb-3 px-3">{errors.collections}</div>
                                                            )}
                                                            {/* <ErrorMessage className="error-text mb-3 px-3" name="collections" /> */}

                                                            {forms.map((form, index) => {
                                                                return <>
                                                                    <div className="" key={index}>
                                                                        <Row>
                                                                            <Col xl={5} lg={5} md={4}>
                                                                                <div className="row mb-3 px-2">
                                                                                    <Label className="form-label">Origin</Label>

                                                                                    <Select
                                                                                        tag={Field}
                                                                                        name={`collections.${index}.origin_tank_id`}
                                                                                        value={form.origin_tank_id || ''}
                                                                                        onChange={value => {
                                                                                            setFieldValue(`collections.${index}.origin_tank_id`, value)
                                                                                            let defaultCapacity = 0
                                                                                            if (values.max_capacity > 0) {
                                                                                                let remaining = values.max_capacity - (totalCollected - form.weight);
                                                                                                if (Number.isInteger(remaining)) {
                                                                                                    remaining = remaining; // It's already an integer
                                                                                                } else {
                                                                                                    remaining = parseFloat(remaining.toFixed(3)); // Round to 3 decimal places
                                                                                                }
                                                                                                if (remaining < value.capacity) {
                                                                                                    defaultCapacity = remaining
                                                                                                } else if (value.capacity > 0) {
                                                                                                    defaultCapacity = value.capacity
                                                                                                } else {
                                                                                                    defaultCapacity = 0
                                                                                                }
                                                                                                setFieldValue(`collections.${index}.weight`, defaultCapacity)
                                                                                                form.weight = defaultCapacity
                                                                                            } else {
                                                                                                setFieldValue(`collections.${index}.weight`, value.capacity ?? 0)
                                                                                                form.weight = value.capacity ?? 0
                                                                                            }
                                                                                            // setTotalCollected(totalCollected + defaultCapacity - form.weight)
                                                                                            calculateCollected(forms)
                                                                                            // console.log('total collected values', totalCollected + defaultCapacity - form.weight);

                                                                                        }}
                                                                                        // onChange={event => handleFormChange(event, index)}
                                                                                        options={form.availableBox}
                                                                                        optionLabel="label"
                                                                                        classNamePrefix="select2-selection"
                                                                                    />
                                                                                    <ErrorMessage component="div" className="error-text" name={`collections.${index}.origin_tank_id`} />

                                                                                </div>
                                                                                {/* <div className="mb-3 px-2">
                                                                                    <h5>Detail</h5>
                                                                                    {form.origin_tank_id && (
                                                                                        <div>
                                                                                            {(() => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <p>Available UCO: {form.origin_tank_id.capacity} ltr</p>
                                                                                                    </>
                                                                                                );
                                                                                            })()}
                                                                                        </div>
                                                                                    )}
                                                                                </div> */}
                                                                            </Col>

                                                                            <Col xl={5} lg={5} md={4}>
                                                                                <div className="mb-3">
                                                                                    <Label className="form-label">Pickup Weight (max.  {form.origin_tank_id.capacity ?? 0} kg)</Label>
                                                                                    <Input tag={Field} type="number"
                                                                                        name={`collections.${index}.weight`}
                                                                                        value={form.weight}
                                                                                        className="collectedWeight"
                                                                                        onChange={(event) => {
                                                                                            const newValue = event.target.value;
                                                                                            if (newValue > form.origin_tank_id.capacity || form.origin_tank_id == '' || form.origin_tank_id.id == '') {
                                                                                                setFieldValue(`collections.${index}.weight`, form.origin_tank_id.capacity);
                                                                                                form.weight = form.origin_tank_id.capacity
                                                                                            } else {
                                                                                                setFieldValue(`collections.${index}.weight`, newValue);
                                                                                                form.weight = newValue

                                                                                            }
                                                                                            calculateCollected(forms);


                                                                                            // Perform the calculation and update other parts of the form as needed
                                                                                        }}

                                                                                    />
                                                                                    <ErrorMessage component="div" className="error-text" name={`collections.${index}.weight`} />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={2} lg={2} md={4}>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <Button className="btn btn-danger" onClick={(event) => {

                                                                                        // Update the Formik field value
                                                                                        arrayHelpers.remove(index);
                                                                                        // Perform the calculation and update other parts of the form as needed
                                                                                        reduceTotalCollected(form);
                                                                                    }}>
                                                                                        <i className="fas fa-times"></i>
                                                                                    </Button>

                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                    </div>
                                                                    {/* <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }} /> */}

                                                                </>
                                                            })}
                                                            {forms.length > 0 && (
                                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                                    {/* Check if all required fields are filled for all form instances */}
                                                                    {forms.every(form => (
                                                                        form.origin_tank_id &&
                                                                        form.weight && form.weight > 0
                                                                    )) && (selectedOrder && selectedOrder.id ? totalCollected < values.max_capacity : true) ? (
                                                                        <Button onClick={() => { addForm(arrayHelpers, forms) }} color="primary" className="btn btn-primary"><i className="fas fa-plus"></i></Button>
                                                                    ) : (
                                                                        <Button disabled color="primary" className="btn btn-primary"><i className="fas fa-plus"></i></Button>
                                                                    )}
                                                                </div>
                                                                // <div className="px-3">
                                                                //     <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                                //         <Button onClick={() => { addForm(arrayHelpers, forms) }} color="primary" className="btn btn-primary">Add</Button>
                                                                //     </div>
                                                                // </div>
                                                            )}

                                                        </div>
                                                        {selectedOrder && selectedOrder.id ?
                                                            <div className="mb-3">
                                                                <Progress color="primary" value={values.max_capacity > 0 ? ((totalCollected / values.max_capacity) * 100) : 0}>
                                                                </Progress>
                                                                {values.max_capacity > 0 ? ((totalCollected / values.max_capacity) * 100).toFixed(2) : 0} %
                                                            </div>
                                                            :
                                                            <div className="mb-3">
                                                                <h4>Total Collected: {
                                                                    formatNumberFloat(forms.reduce((sum, form) => {
                                                                        if (form.weight !== null && form.weight !== undefined && form.weight !== '') {
                                                                            return sum + parseFloat(form.weight);
                                                                        }
                                                                        return sum;
                                                                    }, 0))
                                                                } kg </h4>
                                                            </div>

                                                        }

                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }}
                                />
                                {/* Submit Button */}
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    {(values.max_capacity == 0 || values.max_capacity == null || totalCollected == values.max_capacity)&&submitLoading==false ? (
                                        <Button disabled={submitLoading} type="submit" color="primary">
                                            {submitLoading ? <span className="mdi mdi-24px mdi-loading mdi-spin"></span>:"Submit"} 
                                        </Button>
                                    ) : (
                                        <Button disabled color="primary" className="btn btn-primary">Submit</Button>
                                    )}{" "}

                                </div>
                            </Form>
                        )}
                    />
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddModal;
