import { createContext, useState } from "react";

const AssetContext = createContext({});

export const AssetProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [datas, setDatas] = useState();
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const [tankRefresh, setTankRefresh] = useState('');
    const [tankDatas, setTankDatas] = useState();
    const [tankLoading, setTankLoading] = useState(false);
    const [tankExpandedRows, setTankExpandedRows] = useState(null);
    const [tankTotalRecords, setTankTotalRecords] = useState(0);
  
    const [locationRefresh, setLocationRefresh] = useState('');
    const [locationDatas, setLocationDatas] = useState();
    const [locationLoading, setLocationLoading] = useState(false);
    const [locationExpandedRows, setLocationExpandedRows] = useState(null);
    const [locationTotalRecords, setLocationTotalRecords] = useState(0);


    const [personRefresh, setPersonRefresh] = useState('');
    const [personDatas, setPersonDatas] = useState();
    const [personLoading, setPersonLoading] = useState(false);
    const [personExpandedRows, setPersonExpandedRows] = useState(null);
    const [personTotalRecords, setPersonTotalRecords] = useState(0);

    const [transportRefresh, setTransportRefresh] = useState('');
    const [transportDatas, setTransportDatas] = useState();
    const [transportLoading, setTransportLoading] = useState(false);
    const [transportExpandedRows, setTransportExpandedRows] = useState(null);
    const [transportTotalRecords, setTransportTotalRecords] = useState(0);

    const [boxRefresh,setBoxRefresh] = useState('');
    const [boxDatas,setBoxDatas] = useState();
    const [boxLoading,setBoxLoading] = useState(false);
    const [boxExpandedRows,setBoxExpandedRows] = useState(null);
    const [boxTotalRecords,setBoxTotalRecords] = useState(0);

    const ownershipTypes = [
        { name: 'Individual', value: 'individual' },
        { name: 'Company', value: 'company' },
    ];


    const identityTypes = [
        { name: 'ID CARD', value: 'IDCARD' },
        { name: 'PASSPORT', value: 'PASSPORT' },
    ];
    return (
        <AssetContext.Provider value={{
            refresh, setRefresh, datas, setDatas, loading, setLoading, expandedRows, setExpandedRows, totalRecords, setTotalRecords,
            tankRefresh, setTankRefresh, tankDatas, setTankDatas, tankLoading, setTankLoading, tankExpandedRows, setTankExpandedRows, tankTotalRecords, setTankTotalRecords,
            locationRefresh, setLocationRefresh, locationDatas, setLocationDatas, locationLoading, setLocationLoading, locationExpandedRows, setLocationExpandedRows, locationTotalRecords, setLocationTotalRecords,
            personRefresh, setPersonRefresh, personDatas, setPersonDatas, personLoading, setPersonLoading, personExpandedRows, setPersonExpandedRows, personTotalRecords, setPersonTotalRecords,
            transportRefresh, setTransportRefresh, transportDatas, setTransportDatas, transportLoading, setTransportLoading, transportExpandedRows, setTransportExpandedRows, transportTotalRecords, setTransportTotalRecords,
            ownershipTypes, identityTypes , boxRefresh,setBoxRefresh,boxDatas,setBoxDatas,boxLoading,setBoxLoading,boxExpandedRows,setBoxExpandedRows,boxTotalRecords,setBoxTotalRecords,
       }}>
            {children}
        </AssetContext.Provider>
    )
}

export default AssetContext;