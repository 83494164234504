import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";

import { ROWS_PER_PAGE } from "constants/value";
// import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";

const RegisteredUserModal = ({ rowData }) => {
    // console.log('data',data);
    const initialLazyState = {
        searchText: '',
        startDate: '',
        endDate: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setDatas] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const balanceFormat = (data) => {
        return currencyFormatter(data.balance, data.currency)
    }

    const statusUser = (data) => {
        if (data.active == '1') {
            return 'Active';
        } else if (data.active == '3') {
            return 'Disabled';
        } else {
            return 'Inactive'
        }
    }


    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Name', body: '' },
        { field: 'username', header: 'Username', body: '' },
        // { field: 'email', header: 'Email', body: (data) => data?.email || '-' },
        { field: 'phone.number', header: 'Phone', body: (data) => (data.phone?.number) ? `${data.phone?.prefix} ${data.phone?.number}` : '-' },
        { field: 'currency', header: 'Currency', body: '' },
        { field: 'balance', header: 'Current Balance', body: balanceFormat, sortable: true },
        // { field: 'active', header: 'Status', body: statusUser },
        { field: 'joined', header: 'Joined', body: inputDateTime },
    ]


    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const getRegisteredUserData = async () => {
        try {
            setLoading(true);
            // const response = await axiosApi.get(`/api/admin/marketing/promotion/${data?._id}/transactions?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
            const response = await axiosApi.get(`/api/admin/marketing/promotion/${rowData?._id}/userlist?skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
            const result = response.data.result;
            setDatas(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getRegisteredUserData()
        };
    }, [modal_center, lazyState]);

    const rowClassName = (rowData) => {
        if (rowData.active == '2') {
            return 'redBg';
        } else if (rowData.active == '3') {
            return 'warningBg';
        } else {
            return '';
        }

        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    const userId = (data)=>{
        return <Link to={`/userlist/${data?._id}`}>{data?._id}</Link>
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <ul>
                    <li>User ID : {userId(data)}</li>
                    <li>Active : {data?.active}</li>
                    <li>Name : {data?.name}</li>
                    <li>Username : {data?.username}</li>
                    <li>Address : {data?.address || '-'}</li>
                    <li>Email : {data?.email}</li>
                    <li>Currency : {data?.currency}</li>
                    <li>Current Balance : {balanceFormat(data)}</li>
                    <li>Phone :  {
                        data.phone?.number ? (
                            <a href={`https://api.whatsapp.com/send?phone=${data.phone?.prefix.substring(1)}${data.phone?.number}`} target="_blank">
                                {`${data.phone?.prefix} ${data.phone?.number}`}
                                &nbsp;
                                <i className="success fab fa-whatsapp"></i>
                            </a>
                        ) : (
                            '-'
                        )
                    }</li>
                    <li>Status : {statusUser(data)}</li>
                    <li>Joined : {inputDateTime(data)}</li>
                    <li>Referral Code : {data?.referral_code ?? '-'}</li>
                </ul>
            </div >
        );
    };
    return (
        <>
            <span style={{ cursor: 'pointer' }} className="text-primary" onClick={tog_center}>
                {rowData?.registered_user_count}
            </span>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">Registered User | {rowData?.name}</h4>
                        <div className="d-flex align-items-center gap-3 me-2">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={onSearchTextChange} placeholder="Name / Username" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                            </span>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <DataTable
                        lazy
                        removableSort
                        value={loading ? items : trans}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        onPage={onPage}
                        expandedRows={loading ? null : expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowToggle={onRowToggle}
                        rowClassName={rowClassName}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    >
                        <Column expander={true} style={{ width: '3rem' }} />
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default RegisteredUserModal
