import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Menu from "./menu/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

import Users from "./users/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Menu,
  // Login,
  // Account,
  // ForgetPassword,
  // Profile,
  // calendar,
  // Users,
})

export default rootReducer
