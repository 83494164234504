import moment from "moment";
import { createContext, useRef, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const toast = useRef(null);
    const thisMonth = 11;
    const lastMonth = 10;
    const [isMobile, setIsMobile] = useState(false);
    const showToast = (response) => {
        if (response.toast) {
            toast.current.show({
                severity: response.state,
                summary: response.state[0].toUpperCase() + response.state.substring(1),
                detail: response.message,
            });
        }
    };
    const filterPeriod = [
        { name: "All Time", value: "all" },
        { name: "Today", value: "today" },
        { name: "Last 7 Days", value: "week" },
        { name: "This Month", value: "month" },
        { name: "Last Month", value: "lastMonth" },
        { name: "This Year", value: "year" },
        // { name: 'Custom', value: 'custom' },
    ]
    return (
        <AuthContext.Provider value={{ auth, setAuth, toast, showToast, thisMonth, lastMonth, isMobile, setIsMobile, filterPeriod }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;