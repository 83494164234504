import React, { Component, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const FillingLevel = ({level}) => {
    const [options, setOptions] = useState({
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '45%',
                },
                dataLabels: {
                    value: {
                        show: false
                    }
                }
            },
        },
        colors: ['#70ad47'],
        labels: ['']
    })

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={[level]} type="radialBar" height="140px" />
        </React.Fragment>
    );
}

export default FillingLevel;