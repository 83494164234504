import React, { useEffect, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import { currencyFormatter, simplify } from "helpers/functions";
import ReactApexChart from 'react-apexcharts';
import ChartistGraph from "react-chartist";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { MONTHS_IN_YEAR } from 'constants/value';
import { date } from "yup/lib/locale";
import TransactionTable from "pages/Admin-Marketing/Analytics/User-Promotions/TransactionsTable";
const UserPromoModal = ({ selectedPromo, selectedPeriod, options }) => {
    const axiosApi = useAxiosPrivate();
    const [loading, setLoading] = useState(false)
    const [graphOptions, setGraphOptions] = useState(null)

    const [series, setSeries] = useState([
        {
            name: 'Total User',
            data: [],
        }
    ])
    const [totalUser, setTotalUser] = useState(0)
    const [totalTransaction, setTotalTransaction] = useState(0)
    const [totalReward, setTotalReward] = useState(0)
    const [totalVolume, setTotalVolume] = useState(0)
    const [transData, setTransData] = useState([])

    useEffect(() => {
        const getReports = async () => {
            let _id = ''
            if (selectedPromo) {
                _id = selectedPromo._id
            }
            try {
                let isMonth = true;
                let date1 = true;
                let date2 = true;

                function convertToLocalTime(date) {
                    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                    return localDate;
                }
                function getDatesInRange(startDate, endDate) {
                    const dateArray = [];
                    let currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                        dateArray.push(new Date(currentDate));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                    return dateArray;
                }
                function getMonthInRange(startDate, endDate) {
                    const dateArray = [];
                    let currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                        dateArray.push(new Date(currentDate));
                        currentDate.setMonth(currentDate.getMonth() + 1);
                    }
                    return dateArray;
                }
                const response = await axiosApi.get(`/api/admin/marketing/promotion/report/User?startDate=${options.startDate}&endDate=${options.endDate}&id=${_id}`);
                setLoading(false)
                setTransData(response.data.reports)
                setTotalUser(response.data.totalUserTransaction)
                setTotalReward(response.data.totalReward)
                setTotalTransaction(response.data.totalTransaction)
                setTotalVolume(response.data.totalUCO)
                let reportRaw = response.data.reports
                let formattedDates = [];

                isMonth = false;
                if (selectedPeriod == 'all') {
                    if (reportRaw.length>0){
                        date1 = convertToLocalTime(new Date(reportRaw[0].TA_Start_Time)).toString();
                        if (reportRaw.length == 1) {
                            const date1Raw = new Date(reportRaw[0].TA_Start_Time)
                            date1Raw.setHours(23, 59, 59, 999)
                            const date2Raw = new Date(date1Raw);
                            date2Raw.setDate(date2Raw.getDate() + 1);
                            date2 = convertToLocalTime(new Date(date2Raw)).toString();
                        }else{
                            date2 = convertToLocalTime(new Date(reportRaw[reportRaw.length - 1].TA_Start_Time)).toString();
                        }
                    }
                } else {
                    date1 = convertToLocalTime(new Date(options.startDate)).toString();
                    date2 = convertToLocalTime(new Date(options.endDate)).toString();
                }
                if (reportRaw.length > 0) {
                    const datesInRange = getDatesInRange(new Date(date1), new Date(date2));
                    formattedDates = datesInRange.map(date => date.toLocaleDateString().split('T')[0]);
                }

                let trxCount = {};
                let dateList = [];
                if (formattedDates.length > 0) {
                    formattedDates.forEach(date => {
                        dateList[date] = date;
                        trxCount[date] = 0;
                    });
                }
                if (reportRaw.length > 0) {
                    reportRaw.forEach(element => {
                        let createdDate;
                        if (isMonth) {
                            let rawDate = new Date(element.TA_Start_Time);
                            let year = rawDate.getFullYear();
                            let month = (rawDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero to month if necessary
                            createdDate = `${year}-${month}`;
                        } else {
                            createdDate = new Date(element.TA_Start_Time).toLocaleDateString().split('T')[0];
                        }

                        if (!dateList[createdDate]) {
                            dateList[createdDate] = createdDate;
                        }
                        if (trxCount[createdDate]) {
                            trxCount[createdDate] += element.totalAmount;
                        } else {
                            trxCount[createdDate] = element.totalAmount;
                        }
                    });
                }
                for (let date in trxCount) {
                    if (trxCount.hasOwnProperty(date)) {
                        trxCount[date] = simplify(trxCount[date],0);
                    }
                }
                let myList = dateList ? Object.values(dateList) : []

                // console.log('trxCount', trxCount);
                // console.log('nonReferralCount', nonReferralCount);
                // console.log('dateList', myList);
                let totalRecords = response.data.reports.length;

                if (totalRecords > 0) {
                    setSeries([
                        {
                            name: 'Total Reward',
                            data: Object.values(trxCount),
                        }
                    ]);
                    setGraphOptions(
                        {
                            colors: ['#70ad47'],
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                type: 'area',
                                stroke: {
                                    curve: 'smooth',
                                    width: 0.1,
                                },
                            },
                            legend: {
                                show: true
                            },

                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2,
                            },
                            grid: {
                                borderColor: '#f8f8fa',
                                row: {
                                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: myList,
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                }
                            },
                            // yaxis: {
                            //     labels: {
                            //         formatter: (value) => {
                            //             return simplify(value)
                            //         }
                            //     }
                            // }
                        }
                    )
                } else {
                    trxCount = {};
                    dateList = [];
                    setSeries([])
                    setGraphOptions(
                        {
                            colors: ['#70ad47'],
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                type: 'area',
                                stroke: {
                                    curve: 'smooth',
                                    width: 0.1,
                                },
                            },
                            legend: {
                                show: true
                            },

                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2,
                            },
                            grid: {
                                borderColor: '#f8f8fa',
                                row: {
                                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: myList,
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                }
                            },
                            // yaxis: {
                            //     labels: {
                            //         formatter: (value) => {
                            //             return simplify(value)
                            //         }
                            //     }
                            // }
                        }
                    )
             
                }
                setLoading(true)

            } catch (err) {
                console.log(err);
            }
        }
        getReports();

    }, [selectedPromo, options]);

    return (
        <div>
            <Row>
                <Col lg={9}>
                    <h4 className="text-center">Total Rewards</h4>

                    {graphOptions && <ReactApexChart options={graphOptions} series={series} type="area" height="250" />
                    }
                </Col>
                <Col lg={3} className="d-flex flex-column justify-content-center">
                    <Row>
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">Rewarded Users</p>
                                <h3>{totalUser}</h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">User Rewards</p>
                                <h3>Rp {currencyFormatter(totalReward, 'IDR')}</h3>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <TransactionTable transDatas={transData} totalRecords={transData.length} loading={loading} />
        </div>
    )
}

export default UserPromoModal
