import React from "react";

import { Tooltip } from 'primereact/tooltip';

import {hoverStrings} from "helpers/partnerHelper";

const TooltipInfo = ({ string }) => {
  const tooltipText = hoverStrings[string];

  if (!tooltipText) {
    console.warn(`TooltipInfo: No tooltip text found for key "${string}"`);
    return null; // Return nothing if the string doesn't match
  }

  return (
   <>
    <Tooltip target=".uco-tooltip-info" />

    <i className="uco-tooltip-info mdi mdi-information"
        data-pr-tooltip={tooltipText} 
        data-pr-position="right"
        style={{color:"#70AD45" ,cursor: 'pointer' }}>

    </i>
    </>
  );
};

export default TooltipInfo;