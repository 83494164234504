import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VerticalLayout from "components/VerticalLayout";
import HorizontalLayout from "components/HorizontalLayout";

//constants
import { layoutTypes } from "../../constants/layout";

import { post, del, get, put } from "../../helpers/api_helper";
import useAuth from "hooks/useAuth";

const Authmiddleware = (props) => {

  const { auth } = useAuth();

  useEffect(() => {
    // const refreshToken = async () => {
    //   try {
    //     const response = await get('/api/refresh-token');
    //     setAuth(response);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    // refreshToken();
  }, [])
  
  // const { layoutType } = useSelector(state => ({
  //   layoutType: state.Layout.layoutType,
  // }));

  // console.log(layoutType);

  const getLayout = (layoutType) => {
    
    let Layout = VerticalLayout;
    // switch (layoutType) {
    //   case layoutTypes.VERTICAL:
    //     Layout = VerticalLayout;
    //     break;
    //   case layoutTypes.HORIZONTAL:
    //     Layout = HorizontalLayout;
    //     break;
    //   default:
    //     break;
    // }
    return Layout;
  };

  // const Layout = getLayout(layoutType);
  const Layout = VerticalLayout;

  return (
    Object.keys(auth).length != 0
      ? 
      <React.Fragment>
          <Layout>{props.children}</Layout>
      </React.Fragment>
      :
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  )

  // if (!localStorage.getItem("authUser")) {
  //   return (
  //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }

  // return (
  //   <React.Fragment>
  //     <Layout>{props.children}</Layout>
  //   </React.Fragment>);
};

export default Authmiddleware;
