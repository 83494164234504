import React, { useEffect, useState } from "react";
import GroupTable from "./GroupTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useGroup from "hooks/useGroup";
import useAuth from "hooks/useAuth";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AddGroupModal from "./modals/AddGroupModal";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setGroups, setLoading } = useGroup();
  const { auth } = useAuth();

  useEffect(() => {
    setLoading(true);

    const getGroups = async () => {
      try {
        const response = await axiosApi.get('/api/admin/engineer/box-groups');
        setLoading(false);
        setGroups(response.data.result)

      } catch (err) {
        console.log(err);
      }
    }
    getGroups();

  }, [refresh]);
  document.title = `${process.env.REACT_APP_NAME || ''} | Groups`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Engineering" breadcrumbItem="Groups" />
          <AddGroupModal />

          <GroupTable />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminList;
