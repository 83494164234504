import React, { useEffect, useState } from "react"
import LoadingOverlay from 'react-loading-overlay';
// import ConfirmationModal from "./modals/ConfirmationModal";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import useAuth from "hooks/useAuth"
import makeid from "helpers/random";

const GeneralSettings = () => {
  const { showToast } = useAuth()
  const [refresh, setRefresh] = useState(null)
  const [syncTime, setSyncTime] = useState(null)

  const [rawData, setRawData] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [version, setVersion] = useState({
    android: "20",
    ios: "20",
  })

  const axiosApi = useAxiosPrivate()

  const getAppSettings = async () => {
    try {
      // if (isSuccess) {
      //   return;
      // }

      const response = await axiosApi.get("/api/erp/settings/sync_time")
      if (response?.data?.results?.value) {
        setSyncTime(response?.data?.results?.value)
      } else {
        setSyncTime(null)
      }

      setIsSuccess(true);
    } catch (error) {
      setSyncTime(null)
      if (error.response && error.response.data && error.response.data.state) {
        let response = error.response.data;
        response.state = "error";
        response.toast = true;
        showToast(response);
        setIsSuccess(true);
      } else {
        let response = {};
        response.state = "error";
        response.toast = true;
        response.message = "Internal Server Error"
        showToast(response);
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      getAppSettings()
    }, 300);
  }, [refresh])

  // const handleConfirm = async () => {
  //   try {
  //     const values = modalData
  //     const {
  //       android_v,
  //       ios_v,
  //     } = values

  //     const app_min_version_obj = { android: android_v, ios: ios_v }

  //     const obj = {
  //       // auto_withdrawal: withdrawal,
  //       app_min_version: app_min_version_obj,
  //     }

  //     const response = await axiosApi.post(
  //       "/api/admin/setting/update-app-settings",
  //       obj
  //     )
  //     showToast(response.data)
  //     const {
  //       random,
  //       setting: {
  //         // auto_withdrawal,
  //         app_min_version,
  //       },
  //     } = response.data
  //     setRefresh(random)
  //     // setWithdrawal(auto_withdrawal)
  //     setVersion(app_min_version)
  //     setIsModalOpen(false)
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       validation.setErrors(error.response.data)
  //     } else {
  //       console.error(error)
  //     }
  //   }
  // }
  // const handleCancel = () => {
  //   setIsModalOpen(false)
  //   setModalData(null)
  // }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      sync_time: '',
    },
    validationSchema: Yup.object().shape({
      sync_time: syncTime == null || syncTime == ''
        ? Yup.string()
          .required("Sync Time is required")
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values, { resetForm }) => {
      // setIsModalOpen(true)
      try {
        const opts = { value: syncTime }
        const response = await axiosApi.put("/api/erp/settings/sync_time", opts)
        
        setRefresh(makeid(5));
        showToast(response.data);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.state) {
          showToast(error.response.data);
        } else {
          let response = {};
          response.state = "error";
          response.toast = true;
          response.message = "Internal Server Error"
          showToast(response);
        }
      }
     

      // setModalData(values)
    },
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <LoadingOverlay
            active={!isSuccess}
            spinner
            text='Loading your content...'
          >
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="d-flex flex-wrap gap-2 align-items-start justify-content-start">
                    <Label className="form-label">Sync Transaction Time</Label>
                    <div>
                      <Input
                        className="form-control"
                        type="time"
                        style={{ width: '150px' }}
                        name="sync_time"
                        defaultValue={syncTime}
                        onChange={value => {
                          console.log(value.target.value);
                          if (value.target.value) {

                            setSyncTime(value.target.value)
                          } else {
                            setSyncTime("")
                          }
                        }}
                      />
                      {validation.touched.sync_time &&
                        validation.errors.sync_time ? (
                        <div class="error-text"> {validation.errors.sync_time}</div>

                      ) : null}
                    </div>

                    <Button
                      type="submit"
                      color="primary"
                      disabled={validation.isSubmitting}
                    >
                      {validation.isSubmitting ? "Saving..." : "Save"}
                    </Button>{" "}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
      {/* {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={handleConfirm}
          toggle={handleCancel}
          data={modalData}
          type={"general"}
          rawData={rawData}

        />
      )} */}
    </React.Fragment>
  )
}

export default GeneralSettings
