import React, { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import {
    Navigate, useLocation,
} from "react-router-dom";
const Rolemiddleware = (props) => {
    const location = useLocation();
    const axiosApi = useAxiosPrivate();
    const { auth } = useAuth();
    const [isPermitted, setIsPermitted] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false); // New state variable to control navigation

    const getActiveMenu = async () => {
        try {
            var url = location.pathname.substring(1);
            var segments = url.split('/');
            var basePath = segments[0];
            const response = await axiosApi.post('/api/admin/menu/active/' + auth?.auth._id, { 'url': basePath });
            setIsPermitted(response.data.isActive);
            if (!response.data.isActive) {
                setShouldNavigate(true); // Set shouldNavigate to true if isActive is false
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getActiveMenu();
    }, [location.pathname]);

    if (shouldNavigate) {
        return (
            <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
        );
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

export default Rolemiddleware;
