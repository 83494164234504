import React, { useEffect, useState } from "react";
import PromotionTable from "./PromotionTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import { useLocation } from "react-router-dom"

const PartnerPromotion = () => {

  const location = useLocation();
  const propsData = location.state;

  const axiosApi = useAxiosPrivate();
  const { partnerPromotionRefresh, setPartnerPromotions, setPartnerPromotionLoading, setPartnerPromoTotalRecords } = usePromotion();
  const { auth } = useAuth();
  const [partnerPromoLazyState, setPartnerPromoLazyState] = useState({
    searchText: propsData || '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  useEffect(() => {
    setPartnerPromotionLoading(true);

    const getPartnerPromotions = async () => {
      try {
        const response = await axiosApi.get(`/api/admin/marketing/promotion?type=Partner&rows=${partnerPromoLazyState.config.rows}&skip=${partnerPromoLazyState.config.first}&searchText=${partnerPromoLazyState.searchText}`);
        setPartnerPromotionLoading(false);
        setPartnerPromotions(response.data.result)

        setPartnerPromoTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getPartnerPromotions();

  }, [partnerPromotionRefresh, partnerPromoLazyState]);

  return (
    <React.Fragment>
      <PromotionTable id={propsData} partnerPromoLazyState={partnerPromoLazyState} setPartnerPromoLazyState={setPartnerPromoLazyState} />
    </React.Fragment>
  );
};

export default PartnerPromotion;
