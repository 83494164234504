import React, { useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import LoadingOverlay from "react-loading-overlay";

//Import Breadcrumb
import ContactForm from "./forms/ContactForm";
import PartnerForm from "./forms/PartnerForm";
import PaymentForm from "./forms/PaymentForm";
import usePartner from "hooks/usePartner";
const AddPartner = () => {
  const { wizardLoading } = usePartner();
  const [activeTab, setactiveTab] = useState(1);
  const [sharedTabData,setSharedTabData] = useState({});

  document.title = `${process.env.REACT_APP_NAME || ''} | Add Partner`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12} sm={12}>
                <h6 className="page-title">Add Partner</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Partners</a></li><li className="breadcrumb-item"><a href="/partner-overview">Overview</a></li><li className="active breadcrumb-item" aria-current="page">Add Partner</li></ol>
              </Col>
            </Row>
          </div>
          <LoadingOverlay
            active={wizardLoading}
            spinner
            text='Processing...'
          >
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}>
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                // setactiveTab(1);
                              }}
                            >
                              <span className="number">1</span>{" "}
                              Partner Information
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 2 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                // setactiveTab(2);
                              }}
                            >
                              <span className="number">2</span>{" "}
                              Partnership Information
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 3 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                // setactiveTab(3);
                              }}
                            >
                              <span className="number">3</span>
                              Payment Sharing Settings
                            </NavLink>
                          </NavItem>
                          {/* <NavItem className={classnames({ current: activeTab === 4 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4);
                            }}
                          >
                            <span className="number">4</span>
                            Submit Form
                          </NavLink>
                        </NavItem>
                         */}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent
                          activeTab={activeTab}
                          className="body"
                        >
                          <TabPane tabId={1}>
                            <ContactForm activeTab={activeTab} setactiveTab={setactiveTab} />
                          </TabPane>
                          <TabPane tabId={2}>
                            <PartnerForm activeTab={activeTab} setactiveTab={setactiveTab} sharedTabData={sharedTabData} setSharedTabData={setSharedTabData}  />
                          </TabPane>
                          <TabPane tabId={3}>
                            <PaymentForm activeTab={activeTab} setactiveTab={setactiveTab} sharedTabData={sharedTabData} setSharedTabData={setSharedTabData}/>
                          </TabPane>
                        </TabContent>
                      </div>
                      {/* <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </LoadingOverlay>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddPartner;