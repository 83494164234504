import React, { useState } from "react"
import useAxiosPrivate from "hooks/useAxiosPrivate";


import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Button
} from "reactstrap";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
// import PaymentHistoryModal from "./modals/PaymentHistoryModal";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import UserExportCSV from "./exports/UserExportCSV";
import AddModal from "./modals/AddModal";
import useDailyTip from "hooks/useDailyTip";
import PreviewImageModal from "./modals/PreviewImageModal";
const DailyTipTable = ({ loading, id, lazyState, setlazyState }) => {
    const { datas, totalRecords, availableLanguageList } = useDailyTip();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditModal rowData={data} />
                </div>
                <div>
                    <DeleteModal rowData={data} />
                </div>
            </div>
        )
    }
    const axiosApi = useAxiosPrivate();

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const badgeStatus = (data) => {
        var badgetText = 'danger'
        var statusText = 'non active'
        if (data.active == true) {
            statusText = 'active'
            badgetText = 'success'
        }
        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>
        )
    }
    const imagePreview = (data) => {
        if (data.image) {
            return <PreviewImageModal data={data}></PreviewImageModal>

        } else {
            return null
        }
    }
    const getLanguageName = (value) => {
        const language = availableLanguageList.find(lang => lang.value === value);
        return language ? language.name : value;
    };
    const inputDescription = (data) => {
        const englishTitle = data.descriptions.find(title => title.language === 'en');

        return (
            <div>
                {englishTitle?.description}
            </div>
        )
        // if (data.descriptions) {
        //     return (
        //         <div>
        //             {data.descriptions.map((desc, index) => (
        //                 <div>
        //                     <h5>{getLanguageName(desc.language)}</h5>
        //                     <p className="px-1">{desc.description}</p>
        //                     {index < data.descriptions.length - 1 && <Divider />}
        //                 </div>
        //             ))}
        //         </div>
        //     );
        // } else {
        //     return null
        // }
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        // { field: 'category', header: 'Category', body: '' },
        { field: '', header: 'Description', body: inputDescription },
        // { field: '', header: 'Photo', body: imagePreview, className: '' },
        { field: 'status', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction, style: { width: '150px', whiteSpace: 'nowrap' } },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    // const getEducards = async () => {
    //     try {
    //         const response = await axiosApi.get(`/api/admin/education/content/${data._id}?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
    //         setContents(response.data.result);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddModal />
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder=" Description" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                        >
                            {columns.map((col, i) => (
                                <Column key={col.field} style={col.style} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DailyTipTable
