import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import servicesIcon1 from "../../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../../assets/images/services-icon/02.png";
import totalPayouts from "../../../assets/images/services-icon/the-sum-of.png";
import totalBalanceIcon from "../../../assets/images/services-icon/analysis.png";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import DonationTable from "./DonationTable";
import useDonation from "hooks/useDonation";

import { currencyFormatter, simplify } from "helpers/functions";
// import PaymentPeriodModal from "./modals/PaymentPeriodModal";

// import AyoconnectButton from "./button/ayoconnect.js"

const Donation = () => {
  const { setIsMobile } = useAuth();
  const { lazyState, totalRecords,setWallets , wallets , setTotalRecords, totalAmounts, setTotalAmounts, refresh, totalUserBalance, setTotalUserBalance } = useDonation();
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const axiosApi = useAxiosPrivate();

  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width value as needed
  };

  const fetchRequests = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/finance/donation?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&status=${lazyState.status}&searchText=${lazyState.searchText}`);
       setTotalRecords(response.data.totalRecords || 0);
      setWallets(response.data.wallets)
      setDatas(response.data.requests);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      fetchRequests();
    }, 300);
  }, [lazyState, refresh]);
  
  useEffect(() => {
    handleResize(); // Initial check on component mount
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  document.title = `${process.env.REACT_APP_NAME || ''} | Donations`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Finance" breadcrumbItem="Donations" />
    
  
          <p>Donations Wallet</p>
          <Row>
            
            <Col lg={6} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                    <span className="fi fi-id fis"></span>
                    </div>
                    <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                      Indonesia Rupiah (IDR)
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      Rp {currencyFormatter((wallets?.IDR === 0 ? 0 : simplify(wallets?.IDR)) || 0,"IDR")}
                    </h4>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                    <span className="fi fi-sg fis"></span>
                    </div>
                    <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                      Singapore Dollar (SGD)
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      S$ {currencyFormatter((wallets?.SGD === 0 ? 0 : simplify(wallets?.SGD)) || 0,"SGD")}
                    </h4>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
           
            <Col lg={'5col'} md={6} sm={6}>
              {/* <PaymentPeriodModal/> */}
            </Col>
          </Row>
          <DonationTable datas={datas} loading={loading} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Donation;
