import React, { useEffect, useState } from "react"
import {
  Button,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import moment from "moment"
import { Dropdown } from "primereact/dropdown"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import Select from "react-select"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { post, del, get, put } from "../../../../../helpers/api_helper"
import usePromotion from "hooks/usePromotion"
import deleteIcon from "../../../../../assets/images/delete-icon.png"

import "../../../../../assets/css/style.css"
import useAuth from "hooks/useAuth"
import { update } from "lodash"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { handleThousandSeparatorChange, removeCommas, formatDateWithNoUTC } from "helpers/functions";

const AddPartnerPromotionModal = () => {
  const axiosApi = useAxiosPrivate()
  const [modal_center, setmodal_center] = useState(false)
  const {
    partnerPromotionRefresh,
    setPartnerPromotionRefresh,
    typeList,
    triggeredList,
    countryList,
    frequencyList
  } = usePromotion()
  const [refCodeList, setRefCodeList] = useState([])

  const { showToast } = useAuth()
  const [selectedCode, setSelectedCode] = useState("")
  const [isShowDetail, setIsShowDetail] = useState(false)

  const tog_center = () => {
    setIsShowDetail(!isShowDetail)
    setImageUrl("")
    setStartDate(formatDate())
    setEndDate("")
    removeBodyCss()
    setmodal_center(!modal_center)
    validation.resetForm()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  function formatDate() {
    const dateObject = new Date()
    // Extracting year, month, and day
    const year = dateObject.getFullYear()
    // JavaScript months are zero-based, so we add 1
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0")
    const day = dateObject.getDate().toString().padStart(2, "0")

    // Forming the desired date string
    const formattedDate = `${month}/${day}/${year}`
    return formattedDate
  }
  const [selectedType, setSelectedType] = useState("Partner")
  const [selectedTrigger, setSelectedTrigger] = useState("Referral")
  const [selectedCurrency, setSelectedCurrency] = useState("IDR")
  const [startDate, setStartDate] = useState(formatDate())
  const [endDate, setEndDate] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [uploading, setUploading] = useState(false)
  const [selectedFrequency, setSelectedFrequency] = useState("single")
  const [selectedPartner, setSelectedPartner] = useState("")
  const [partnerList, setPartnerList] = useState([]);
  const filteredTriggeredList = selectedType === 'Partner' ? triggeredList.filter(item => item.value !== 'Registration') : triggeredList;
  const [selectedTimezone, setSelectedTimezone] = useState("")
  const [timezoneList, setTimezoneList] = useState([]);

  const getTimezones = async () => {
    try {

      var country = selectedCurrency == "IDR" ? "ID" : "SG";
      const response = await axiosApi.get(`/api/v1/app/timezonelist?country=${country}`);
      var timezones = []

      if (response.data.result)
        response.data.result.forEach((element, index) => {
          var tz = {
            ...element,
            value: element.offset,
            label: element.shortName,
          }
          timezones.push(tz)
        })
      setTimezoneList(timezones);
      if (response.data.result.length > 0)
        setSelectedTimezone(timezones[0].value)
    } catch (error) {
      console.log(error);
    }
  }
  const changePartner = (e) => {
    setSelectedPartner(e.value)
  }

  const getPartners = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/marketing/partner`);
      var partners = []

      if (response.data.result)
        response.data.result.forEach(element => {
          var partner = {
            ...element,
            value: element._id,
            label: element.name,
          }
          partners.push(partner)
        })
      setPartnerList(partners);
      if (response.data.result.length > 0)
        setSelectedPartner(partners[0])
    } catch (error) {
      console.log(error);
    }
  }
  const getReferrals = async () => {
    try {
      const response = await axiosApi.get(
        "/api/admin/marketing/active-referral?currency=" + selectedCurrency + "&partner=" + selectedPartner._id
      )
      var referrals = []

      if (response)
        response.data.result.forEach(element => {
          var referral = {
            ...element,
            value: element.code,
            label: element.code,
            partner: element.partner,
          }
          referrals.push(referral)
        })
      setSelectedCode(referrals[0])
      setRefCodeList(referrals)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (isShowDetail) {
      getTimezones()
      getReferrals()
    }
  }, [partnerPromotionRefresh, selectedCurrency, selectedPartner, isShowDetail])

  useEffect(() => {
    if (isShowDetail) {
      getPartners();
    }
  }, [partnerPromotionRefresh, isShowDetail])

  const storeFiles = async e => {
    const { files } = e.target
    const validFiles = Array.from(files)
    setUploading(true)
    await Promise.all(
      validFiles.map(async photo => {
        const formData = new FormData()
        formData.append("image", photo)
        const response = await axiosApi.post(
          "/api/admin/asset/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        setImageUrl(response.data.url)
      })
    )
    setUploading(false)
  }

  const deleteImage = e => {
    setImageUrl("")
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      partner: selectedCode ? selectedCode.partner : "",
      limit: 0,
      description: "",
      amount: "",
      percentage: "",
      start_date: "",
      end_date: "",
      deposit: 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This value is required"),
      // partner: Yup.string().required("This value is required"),
      limit: Yup.number()
        .required("This value is required"),
      deposit: Yup.number()
        .required("This value is required"),
      percentage: Yup.number()
        .nullable()
        .min(0, 'Value must be at least 0')
    }),
    onSubmit: async (values, { resetForm }) => {
      var timeZone = -1 * parseInt(selectedTimezone);
      var startDateResult = null
      if (startDate) {
        let dateObject = new Date(startDate)
        dateObject.setHours(0 + timeZone, 0, 0, 0)
        startDateResult = formatDateWithNoUTC(dateObject)
      }
      var endDateResult = null
      if (endDate) {
        let dateObject = new Date(endDate)
        dateObject.setHours(23 + timeZone, 59, 59, 999)
        endDateResult = formatDateWithNoUTC(dateObject)
      }

      try {
        const opts = {
          ...values,
          amount: values.amount ? removeCommas(values.amount) : null,
          frequency: selectedFrequency,
          currency: selectedCurrency,
          partner: selectedPartner._id,
          timezone: selectedTimezone,
          country: selectedCurrency == "IDR" ? "ID" : "SG",
          type: selectedType,
          trigger: selectedTrigger,
          referral_code:
            selectedTrigger == "Referral"
              ? selectedCode
                ? selectedCode.value
                : null
              : null,
          referral_id:
            selectedTrigger == "Referral"
              ? selectedCode
                ? selectedCode._id
                : null
              : null,
          logo_url: imageUrl,
          start_date: startDateResult,
          end_date: endDateResult,
        }

        // console.log(opts);
        const response = await post("/api/admin/marketing/promotion", opts)
        setPartnerPromotionRefresh(response.random)
        resetForm()
        tog_center()
        showToast(response)
      } catch (error) {
        if (error.response && error.response.data) {
          validation.setErrors(error.response.data)
        } else {
          console.error(error)
        }
      }
    },
  })

  return (
    <>
      <div className="my-2">
        <Button color="primary" onClick={tog_center}>
          {" "}
          Add Promotion{" "}
        </Button>
      </div>
      <Modal isOpen={modal_center} toggle={tog_center} size="xl" centered>
        <ModalHeader className="mt-0" toggle={tog_center}>
          Add New Partner Promotion
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col xl={6} lg={6}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <div className="mb-3 pr-2">
                    <Label className="form-label">Type</Label>
                    <div className="col">
                      <Dropdown
                        disabled
                        value={selectedType || ""}
                        onChange={event => {
                          setSelectedType(event.target.value)
                          setImageUrl("")
                          validation.values.description = null
                        }}
                        options={typeList}
                        optionLabel="label"
                        className="h-1 payment-status-dropdown"
                      />
                    </div>
                  </div>
                  <div className="mb-3 px-2">
                    <Label className="form-label">Frequency</Label>
                    <div className="col">
                      <Dropdown
                        value={selectedFrequency || ""}
                        onChange={event => {
                          setSelectedFrequency(event.target.value)
                        }}
                        options={frequencyList}
                        optionLabel="label"
                        className="h-1 payment-status-dropdown"
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <div className="mb-3">
                    <Label className="form-label">Country</Label>
                    <div className="col">
                      <Dropdown
                        value={selectedCurrency || ""}
                        onChange={event => {
                          setSelectedCurrency(event.target.value)
                        }}
                        options={countryList}
                        optionLabel="label"
                        className="h-1 payment-status-dropdown"
                      />
                    </div>
                  </div>
                  <div className="mb-3 px-2">
                    <Label className="form-label">Timezone</Label>
                    <div className="col">
                      <Dropdown
                        value={selectedTimezone || ""}
                        onChange={event => {
                          setSelectedTimezone(event.target.value)
                        }}
                        options={timezoneList}
                        optionLabel="label"
                        className="h-1 payment-status-dropdown"
                      />
                    </div>
                  </div>
                 

                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                  <div className="mb-3">
                    <Label className="form-label">Trigger</Label>
                    <div className="col">
                      <Dropdown
                        value={selectedTrigger || ""}
                        onChange={event => {
                          setSelectedTrigger(event.target.value)

                          // if (event.target.value == "Referral") {
                          //   validation.values.partner = selectedCode
                          //     ? selectedCode.partner
                          //     : ""
                          // } 
                        }}
                        options={filteredTriggeredList}
                        optionLabel="label"
                        className="h-1 payment-status-dropdown"
                      />
                    </div>
                  </div>
                  <div className="mb-3 px-2">
                    <Label className="form-label">
                      {selectedType == "User" ? "Referrer" : "Partner"}
                    </Label>
                    <div className="col">
                      <Select
                        name={`partner`}
                        value={selectedPartner || ""}
                        onChange={value => {
                          setSelectedPartner(value)
                        }}
                        options={partnerList}
                        optionLabel="name"
                        classNamePrefix="select2-selection"
                      />
                      {/* <Dropdown
                          value={selectedPartner || ''}
                          onChange={changePartner}
                          options={partnerList}
                          optionLabel="name"
                          className="h-1 payment-status-dropdown"
                        /> */}
                    </div>
                  </div>
                  {selectedTrigger == "Referral" && (

                    <div className="mb-3 px-2">
                      <Label className="form-label">Referral Code</Label>
                      <Select
                        name={`referral_code`}
                        value={selectedCode || ""}
                        onChange={value => {
                          setSelectedCode(value)
                          // validation.values.partner = value.partner
                        }}
                        options={refCodeList}
                        optionLabel="label"
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  )}
                </div>



                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                {selectedType == "Partner" ? (
                  <div className="mb-3">
                    <div className="d-flex gap-2 align-items-center">
                      <Label className="form-label">Logo : </Label>
                      <label
                        htmlFor="fileInput"
                        className="btn btn-primary btn-sm"
                      >
                        {imageUrl === "" ? "Add" : "Change"}
                      </label>
                      {uploading && (
                        <Label className="form-label text-danger">
                          uploading...
                        </Label>
                      )}
                      <input
                        className="d-none"
                        id="fileInput"
                        type="file"
                        multiple
                        accept="image/jpeg, image/jpg, image/png"
                        onChange={storeFiles}
                        name="image"
                      />
                    </div>
                    <div className="d-flex gap-2 box-images-container">
                      {imageUrl !== "" && (
                        <div className="image-container">
                          <img
                            src={deleteIcon}
                            className="box-delete-icon"
                            width={28}
                            height={28}
                            data-src={imageUrl}
                            onClick={deleteImage}
                            alt="delete"
                          />
                          <img
                            src={imageUrl}
                            width={200}
                            height={200}
                            className="box-image"
                            alt="documentation"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mb-3">
                    <Label>Description</Label>
                    <Input
                      name="description"
                      placeholder="Enter Description"
                      rows="5"
                      type="textarea"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                      invalid={
                        validation.touched.description &&
                          validation.errors.description
                          ? true
                          : false
                      }
                    />
                    {validation.touched.description &&
                      validation.errors.description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                )}
              </Col>
              <Col xl={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Redemption Limit (0 for no limit)</Label>
                  <Input
                    name="limit"
                    placeholder="Enter Limit"
                    type="number"
                    min={0}
                    style={{ width: "200px" }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.limit}
                    invalid={
                      validation.touched.limit && validation.errors.limit
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limit && validation.errors.limit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limit}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    Validity Period (leave empty for no expiration)
                  </Label>
                  <br />
                  <InputGroup className="align-items-center">
                    <Flatpickr
                      className="form-control d-block"
                      value={startDate}
                      onChange={value => {
                        if (value.length > 0) {
                          const dateObject = new Date(value[0])
                          // Extracting year, month, and day
                          const year = dateObject.getFullYear()
                          // JavaScript months are zero-based, so we add 1
                          const month = (dateObject.getMonth() + 1)
                            .toString()
                            .padStart(2, "0")
                          const day = dateObject
                            .getDate()
                            .toString()
                            .padStart(2, "0")

                          // Forming the desired date string
                          const formattedDate = `${month}/${day}/${year}`
                          setStartDate(formattedDate)
                        } else {
                          setStartDate("")
                          setEndDate("")
                        }
                      }}
                      placeholder="Start date"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                        maxDate: endDate || null, // Set minDate to start date or null if start date is not set
                      }}
                    />

                    <span className="px-2 align-middle">-</span>

                    <Flatpickr
                      className="form-control d-block"
                      value={endDate}
                      onChange={value => {
                        if (value.length > 0) {
                          const dateObject = new Date(value[0])
                          // Extracting year, month, and day
                          const year = dateObject.getFullYear()
                          // JavaScript months are zero-based, so we add 1
                          const month = (dateObject.getMonth() + 1)
                            .toString()
                            .padStart(2, "0")
                          const day = dateObject
                            .getDate()
                            .toString()
                            .padStart(2, "0")

                          // Forming the desired date string
                          const formattedDate = `${month}/${day}/${year}`
                          setEndDate(formattedDate)
                        } else {
                          setEndDate("")
                        }
                      }}
                      placeholder="End Date"
                      options={{
                        altInput: true,
                        altFormat: "m/d/Y",
                        dateFormat: "m/d/Y",
                        minDate: startDate || null,
                        clickOpens: startDate ? true : false,
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Reward</Label>
                  <InputGroup
                    className={
                      validation.touched.amount && validation.errors.amount
                        ? "is-invalid mb-3"
                        : "mb-3"
                    }
                    style={{ width: "300px" }}
                  >
                    <Input
                      name="amount"
                      placeholder="Enter Amount"
                      type="text"
                      // step={.00001}
                      onChange={(e) => handleThousandSeparatorChange(e, validation)}
                      onBlur={validation.handleBlur}
                      value={validation.values.amount}
                      invalid={
                        validation.touched.amount && validation.errors.amount
                          ? true
                          : false
                      }
                    />
                    <InputGroupText>
                      {selectedCurrency == "SGD" ? "SGD" : "Rp"}
                    </InputGroupText>
                  </InputGroup>
                  {validation.touched.amount &&
                    validation.errors.amount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.amount}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <InputGroup
                    className={
                      validation.touched.percentage &&
                        validation.errors.percentage
                        ? "is-invalid"
                        : ""
                    }
                    style={{ width: "200px" }}
                  >
                    <Input
                      name="percentage"
                      placeholder="Enter Percentage"
                      type="number"
                      // min={0}
                      // max={100}
                      // step={.00001}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.percentage}
                      invalid={
                        validation.touched.percentage &&
                          validation.errors.percentage
                          ? true
                          : false
                      }
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                  {validation.touched.percentage &&
                    validation.errors.percentage ? (
                    <FormFeedback type="invalid">
                      {validation.errors.percentage}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Minimum Deposit (0 for no minimum)</Label>
                  <InputGroup
                    className={
                      validation.touched.deposit && validation.errors.deposit
                        ? "is-invalid mb-3"
                        : "mb-3"
                    }
                    style={{ width: "250px" }}
                  >
                    <Input
                      name="deposit"
                      placeholder="Enter Amount"
                      type="number"
                      min={0}
                      //   step={0.00001}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.deposit}
                      invalid={
                        validation.touched.deposit && validation.errors.deposit
                          ? true
                          : false
                      }
                    />
                    <InputGroupText>Liter</InputGroupText>
                  </InputGroup>

                  {validation.touched.deposit && validation.errors.deposit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.deposit}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <div className="d-flex flex-wrap gap-2 justify-content-end">
              <Button
                type="submit"
                color="primary"
                disabled={validation.isSubmitting}
              >
                Submit
              </Button>{" "}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddPartnerPromotionModal
