import React, { useEffect, useState } from "react";
import AddBoxModal from './modals/AddBoxModal';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import '../../../assets/css/style.css'
import Volume from "./Volume";
import Weight from "./Weight";
import BoxesMap from "./BoxesMap";
import BoxesTable from "./BoxesTable";
import BoxDetail from "./BoxDetail";
import useBox from "hooks/useBox";
import TransactionTable from "./TransactionTable";
import { ref } from "yup";

const Engineer = () => {
  const { setDatas, setLoading, refresh, refreshTransaction, refreshGraph, transaction, setTransaction, box, filterTransaction, setTransDatas, setGroupOptions, setLoading2, lazyState, setTotalRecords, boxIdFilter, groupFilter } = useBox();
  const [volume, setVolume] = useState([]);
  const [weight, setWeight] = useState([]);

  const [series, setSeries] = useState([
    {
      name: 'Weight',
      data: [],
    }
  ])
  const axiosApi = useAxiosPrivate();

  const getGroups = async () => {
    try {
      setLoading(true)
      const response = await axiosApi.get('/api/admin/engineer/box-groups');
      let groupOptionsList = response.data.result.map(item => ({
        value: item._id,
        name: item.name,
        color: item.color
      }));
      setGroupOptions(groupOptionsList);

    } catch (error) {
      console.log(error);
    }
  }

  const getBoxes = async () => {
    try {
      setLoading(true)
      let response;
      if (groupFilter !== 'all') {
        response = await axiosApi.get(`/api/admin/engineer/getBoxes?group=${groupFilter}`);
      } else {
        response = await axiosApi.get('/api/admin/engineer/getBoxes');
      }
      setDatas(response.data);
      console.log(response.data);
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }
  const getTransactionGraph = async () => {
    try {
      let response;
      if (groupFilter !== 'all') {
        response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?group=${groupFilter}`);
      } else {
        response = await axiosApi.get('/api/admin/engineer/getTransactionGraph');
      }
      const result = response.data.datas;
      setTransaction(result);
      filterTransaction(box?.id || null, result, setVolume, setWeight, setSeries)
    } catch (error) {
      console.log(error);
    }
  }

  const getTransaction = async () => {
    try {
      setLoading2(true);
      var response = null;
      if (boxIdFilter != null) {
        response = await axiosApi.get(`/api/admin/engineer/box/transaction?id=${boxIdFilter}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
      } else {
        if (groupFilter !== 'all') {
          response = await axiosApi.get(`/api/admin/engineer/getTransaction?group=${groupFilter}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
        } else {
          response = await axiosApi.get(`/api/admin/engineer/getTransaction?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
        }
        // response = await axiosApi.get(`/api/admin/engineer/getTransaction?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
      }

      const result = response.data.result;
      setTransDatas(result);
      setTotalRecords(response.data.totalRecords || 0)
      setLoading2(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBoxes();
    getGroups();
  }, [refresh]);

  // useEffect(() => {
  //   getTransactionGraph();
  // }, [refreshGraph]);

  useEffect(() => {
    getTransaction();
  }, [lazyState, refreshTransaction]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Deployments`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={8}>
              <Breadcrumbs title="Engineering" breadcrumbItem="Deployments" />
            </Col>

            <Col md="4">
              {/* <div className="float-end d-none d-md-block">
                <Button color="primary">
                  <i className="mdi mdi-export"></i> Export
                </Button>
              </div> */}
            </Col>
          </Row>

          <div className="justify-content-between align-items-center mb-3" style={{ display: 'flex' }}>
            <p style={{ margin: 0 }}>UCOllect Boxes</p>
            <div className='d-flex gap-2 align-items-center'>
              <AddBoxModal />
            </div>
          </div>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody style={{ padding: '0px' }}>
                  <BoxesTable setVolume={setVolume} setWeight={setWeight} setSeries={setSeries} />
                </CardBody>

              </Card>

            </Col>
            <Col lg={6}>
              <BoxesMap setVolume={setVolume} setWeight={setWeight} setSeries={setSeries} />
            </Col>
          </Row>
          <Weight lazyState={lazyState} groupFilter={groupFilter} refresh={refresh} boxIdFilter={boxIdFilter} />
          
          {/* <Volume weight={weight} series={series} /> */}

          <TransactionTable />
        </Container>
      </div>
      <BoxDetail setVolume={setVolume} setWeight={setWeight} setSeries={setSeries} />
    </React.Fragment>
  );
};

export default Engineer;
