import React, { useEffect, useState } from "react";
import ReferralTable from "./UserPromoTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import { Dropdown } from 'primereact/dropdown';
import PaymentPeriodModal from "./modals/PaymentPeriodModal";

import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap";
import { getFormattedUTCDate } from 'helpers/functions';

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { referralRefresh, referrals, setReferrals, setReferralLoading } = usePromotion();
  const { auth } = useAuth();
  const [selectedMode, setSelectedMode] = useState('')
  const [promoTable, setPromoTable] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [refresh, setRefresh] = useState('');
  const [loading, setLoading] = useState(false);

  const today = new Date()
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  endOfMonth.setHours(23, 59, 59, 999)

  const startDate = getFormattedUTCDate(startOfMonth)
  const endDate = getFormattedUTCDate(endOfMonth)
  const [promotions, setPromotions] = useState('')
  const [selectedPeriod, setSelectedPeriod] = useState('month')

  const [lazyState, setlazyState] = useState({
    id: selectedMode || '',
    searchText:  '',
    startDate: startDate,
    endDate: endDate,
    config: {
      first: 0,
      rows: 5,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      },
    }
  });
  const getPromotion = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/marketing/promotion?type=User`);
      const allOption = { name: 'All', _id: '' };
      const partnerPromoWithAll = [allOption, ...response.data.result];
      setPromotions(partnerPromoWithAll);
      if (response.data.result) {
        setSelectedMode(partnerPromoWithAll[0])
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPromotion();
  }, []);
  

  const changeMode = (e) => {
    setSelectedMode(e.value);
  }
  
  return (
    <React.Fragment>
      <Card>
        <CardTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'right' }} className="my-3">
            {/* <Label className="form-label">Name :</Label> */}
            <Dropdown
              value={selectedMode || ''}
              onChange={changeMode}
              options={promotions}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
            <PaymentPeriodModal
              selectedPeriod={selectedPeriod}
              mainOptions={lazyState}
              setMainOptions={setlazyState}
              setSelectedPeriod={setSelectedPeriod}
            />
          </div>
        </CardTitle>
        <CardBody>
          <ReferralTable selectedPromo={selectedMode} selectedPeriod={selectedPeriod} options={lazyState} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AdminList;
