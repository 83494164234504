import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import usePayment from "hooks/usePayment";

const ChangeRequestModal = ({ action, data }) => {
    console.log(data)
    const { showToast } = useAuth();
    const { setRefresh } = usePayment();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: data._id,
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await post(`/api/admin/finance/update-payment-request/${action}`, values);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                console.log(error);
            }
        }
    });

    const labelButton = (data) => {
        if (action == "retry") {
            return "Retry Payment"

        } else if (action == "oldpayment") {
            return "Close Manually"
        }
        switch (data.status) {
            case 'open':
                return 'Process'
                break;
            case 'pending':
                return 'Payout'
                break;
            case 'success':
                return 'Done'
                break;
            case 'retry':
                return 'Retry Payment'
                break;
        }
    }

    const confirmTitle = (action, data) => {
        switch (action) {
            case 'cancel':
                return `Cancel Payment Request ID : ${data.reqId}`
                break;
            case 'process':
                return `Process Payment Request ID : ${data.reqId}`
                break;
            case 'finish':
                return `Finish Payment Request ID : ${data.reqId}`
                break;
            case 'retry':
                return `Retry Payment Request ID : ${data.reqId}`
                break;
        }
    }

    const parseAction = (action) => {
        if (action == "oldpayment" ){
            return "Manually Close"
        } else {
            return action
        }
    }

    return (
        <>
            <div className="my-2">
                <Button color={action === 'cancel' ? 'danger' : 'primary'} onClick={tog_center} disabled={action !== 'cancel' && data.status === 'success'}>
                    {action === 'cancel' ? 'Cancel' : labelButton(data)}
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>{confirmTitle(action, data)}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <h5>You sure want to {parseAction(action)} this payment request ?</h5>
                        <div className="mb-3">
                            <input type="hidden" name="id" value={validation.values.id || data._id} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" >
                                Confirm
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default ChangeRequestModal
