import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Form, FormGroup, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import makeid from "helpers/random";
import LoadingOverlay from "react-loading-overlay";
import ConfirmationModal from "./modals/ConfirmationModal";
import { formatThousandSeparator } from "helpers/functions";

const GeneralSettings = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const amountRegExp = /^[\+0-9]?[1-9]{1,4}[ \-]*(\([0-9]{2,3}\)[ \-]*|[0-9]{2,4}[ \-]*)*[0-9]{3,4}[ \-]*[0-9]{3,4}?$/

    const { showToast } = useAuth();
    const [refresh, setRefresh] = useState(null);
    const [balanceAlert, setBalanceAlert] = useState(0);
    const [permissions, setPermissions] = useState([]);
    const [levels, setLevels] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [rawData, setRawData] = useState(null)

    const getPermissions = async () => {
        try {
            const response = await axiosApi.get('/api/admin/permission');
            var permissionList = [];
            response.data.result.forEach(element => {
                if (element.title.toLowerCase() !== 'superadmin')
                    permissionList.push(element)
            });
            setPermissions(response.data.result);
            
        } catch (err) {
            console.log(err);
        }
    }
    
    const axiosApi = useAxiosPrivate();
    const handleConfirm = async () => {
        try {
            const values = modalData
            const response = await axiosApi.post('/api/admin/setting/update-app-settings', values)
            setRefresh(makeid(5))
            showToast(response.data);
            setIsModalOpen(false);

        } catch (error) {
            if (error.response && error.response.data) {
                validation.setErrors(error.response.data);
            } else {
                console.error(error);
            }
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        setModalData(null)
    }
    const removeCommas = (value) => value.replace(/,/g, "");

    const getAppSettings = async () => {
        try {
            const response = await axiosApi.get('/api/admin/setting/get-app-settings');
            if (response.data.setting) {
                const { setting: { withdrawal_limit: { min, max }, auto_withdrawal, app_min_version } } = response.data;
                setBalanceAlert(response.data.setting.balance_alert_threshold ?? 0)
                setLevels(JSON.parse(response.data.setting.alert_email_admin_role))
                setRawData(response.data.setting)
            }
        } catch (error) {
            console.log(error);
        }
        getPermissions();

        setSubmitLoading(true)
    }

    useEffect(() => {
        setSubmitLoading(false)
        getAppSettings()
    }, [refresh]);

    const updateLevel = (value) => {
        setLevels(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            balance_alert_threshold: formatThousandSeparator(String(balanceAlert)) ?? '',
        },
        validationSchema: Yup.object().shape({
            balance_alert_threshold: Yup.string()
                .required('This field is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            const opts = { ...values, balance_alert_threshold:removeCommas(values.balance_alert_threshold) , alert_email_admin_role: JSON.stringify(levels) }
            setIsModalOpen(true)
            // const opts = { ...values, withdrawal: withdrawal }
            setModalData(opts)
        },
    });

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        // Allow only digits and decimal points
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        const formattedValue = formatThousandSeparator(value);
        validation.setFieldValue(name, formattedValue); // Update formik's value
    };

    return (
        <React.Fragment>
            <LoadingOverlay
                active={!submitLoading}
                spinner
                text='Processing...'
            >
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                    <Row className=""> {/* Center the button vertically */}
                                        <Col xs={12} lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">Minimum Balance</Label>
                                                <Input
                                                    name="balance_alert_threshold"
                                                    placeholder="Enter Value..."
                                                    type="text"
                                                    className=""
                                                    autoComplete="off"
                                                    onChange={handleInputChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.balance_alert_threshold || ""}
                                                    invalid={
                                                        validation.touched.balance_alert_threshold && validation.errors.balance_alert_threshold ? true : false
                                                    }
                                                />
                                                {validation.touched.balance_alert_threshold && validation.errors.balance_alert_threshold ? (
                                                    <FormFeedback type="invalid">{validation.errors.balance_alert_threshold}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={3}>
                                            <div>
                                                <Label className="form-label">Alert Notification sent to:</Label>
                                                <FormGroup className="">
                                                    {permissions?.map((permission) => (
                                                        <FormGroup check key={permission.key}>
                                                            <Input id={permission.key} name="permission[]" type="checkbox" defaultChecked={levels.includes(permission.key)} value={permission.key} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                                                    updateLevel(clickedValue);
                                                                } else {
                                                                    const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                                                    updateLevel(clickedValue);
                                                                }
                                                            }} />
                                                            <Label htmlFor={permission.key}>{permission.title}</Label>
                                                            {validation.touched.permission && validation.errors.permission ? (
                                                                <FormFeedback type="invalid">{validation.errors.permission}</FormFeedback>
                                                            ) : null}
                                                        </FormGroup>
                                                    ))}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={5}>
                                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                                {validation.isSubmitting ? 'Submitting...' : 'Submit'}
                                            </Button>{" "}
                                        </Col>
                                    </Row>


                                </Form>
                            </CardBody>
                        </Card>
                    </Col>


                </Row>
                {isModalOpen && (
                    <ConfirmationModal
                        isOpen={isModalOpen}
                        onConfirm={handleConfirm}
                        toggle={handleCancel}
                        data={modalData}
                        permissions={permissions}
                        rawData={rawData}

                    />
                )}
            </LoadingOverlay>
        </React.Fragment>
    );
};

export default GeneralSettings;