import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
const DataExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Location_Partner_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`
   
   
    const headers = [
        { key: 'partner_account', label: 'Account Name', body: '', className: '' },
        { key: 'total_volume', label: 'Total Collected Volume'},
        { key: 'box_count', label: 'Locations'},
        { key: 'share_ratio', label: 'Share Ratio'},
        { key: 'total_share', label: 'Total Share' },
    ]
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
            const response = await axiosApi.get(`/api/admin/partner-locations?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.total_volume = (obj.total_collection_volume ?? 0) ;
                obj.box_count = obj?.detail?.box_ids?.length || 0;

            });
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV