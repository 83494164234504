import React, { useEffect, useState } from "react";
import ReferralTable from "./ReferralTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import { useLocation } from "react-router-dom"
import AddReferralModal from "./modals/AddReferralModal";
import { Card, CardBody, Col, Row } from "reactstrap"

const AdminList = () => {
  const location = useLocation();
  const propsData = location.state;
  const [sortField, setSortField] = useState([]);

  const axiosApi = useAxiosPrivate();
  const { referralRefresh, setReferrals, setReferralLoading, setTotalRecords } = usePromotion();
  const [lazyState, setLazyState] = useState({
    searchText: propsData || '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  useEffect(() => {
    setReferralLoading(true);

    const getReferrals = async () => {
      try {
        const sortFields = sortField;

        const response = await axiosApi.get(`/api/admin/marketing/referral?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&sorts=${sortFields}`);
        // const response = await axiosApi.get(`/api/admin/marketing/referral?skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
        setReferralLoading(false);
        setReferrals(response.data.result)
        setTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getReferrals();

  }, [referralRefresh, lazyState, sortField]);
  document.title = `${process.env.REACT_APP_NAME || ''} | Referrals`;

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={4} sm={4}>
                <h6 className="page-title">Referrals</h6>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Marketing</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    Referrals
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <AddReferralModal />

          <ReferralTable id={propsData} lazyState={lazyState} setLazyState={setLazyState} sortField={sortField} setSortField={setSortField} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminList;
