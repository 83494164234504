import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import useAuth from "hooks/useAuth";
import usePrice from "hooks/usePrice";

const UpdatePriceModal = ({handleConfirmSubmit, modal_center, tog_center, formValues}) => {
    const { showToast } = useAuth();
    const { currentPrice, share, comparePrice, compareShare } = usePrice();

    return (
        <>
        <Button type="button" color="primary" onClick={tog_center} disabled={(comparePrice && compareShare)}>
            Update Current Price
        </Button>{" "}
        <Modal isOpen={modal_center} toggle={tog_center} centered>
            <ModalHeader className="mt-0" toggle={tog_center}>Update Buying Price</ModalHeader>
                <ModalBody>
                <div className="mb-4">
                    <h5>Previous :</h5>
                    <ul>
                        <li>Buying price: ${currentPrice}, with {share}% of the amount paid to users.</li> 
                    </ul>
                        
                    <h5>New :</h5>
                    <ul>
                        <li>Buying price: ${formValues?.price}, with {formValues?.share}% of the amount paid to users.</li> 
                    </ul>
                </div>
                <h5>Are you sure you want to update the current price?</h5> 
                <div className="d-flex flex-wrap gap-2 justify-content-end mt-4">
                    <Button type="button" color="primary" onClick={handleConfirmSubmit}>
                        Confirm
                    </Button>{" "}
                </div>
            </ModalBody>
        </Modal>
        </>
    )
}

export default UpdatePriceModal
