import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import Flatpickr from "react-flatpickr";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { post, del, get, put } from "../../../../helpers/api_helper";
import deleteIcon from "../../../../assets/images/delete-icon.png";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useCollection from "hooks/useCollection";
import { submit } from "redux-form";

const ApprovalPointsModal = ({ rowData, submitLoading, setSubmitLoading }) => {
    const { showToast } = useAuth();

    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useCollection();
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [completionDate, setCompletionDate] = useState(rowData.order?.due_date ? new Date(rowData.order?.due_date) : new Date());

    const getOrderStatus = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/collection-detail-statuses`);
            var locList = response.data.results;
            setStatusList(locList);
            for (let idx = 0; idx < response.data.results.length; idx++) {
                if (response.data.results[idx].id === rowData.collection_detail_status_id) {
                    setSelectedStatus(response.data.results[idx])
                    break;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    useEffect(() => {
        if (modal_center) {
            getOrderStatus();
        }
    }, [refresh, modal_center]);


    const changeStatus = (e) => {
        setSelectedStatus(e.value)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            'real_weight': rowData.weight
        },
        validationSchema: Yup.object().shape({
            real_weight: Yup.number()
                .nullable()
                .moreThan(0, "Actual Weight must be greater than 0"),

        }),
        onSubmit: async (values, { resetForm }) => {
            if (submitLoading) return;
            setSubmitLoading(true);
            try {
                const opts = {
                    collection_detail_status_id: selectedStatus ? selectedStatus.id : null,
                    collected_datetime: completionDate,
                    status_message: values.status_message,
                    real_weight: selectedStatus ? selectedStatus.id == 3 ? values.real_weight : 0 : 0
                };
                // console.log("opts", opts);
                tog_center();
                const response = await put('/api/erp/collection-details/' + rowData.id, opts);
                setRefresh(makeid(5));
                resetForm();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
            setSubmitLoading(false);
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="warning" onClick={tog_center}>
                    <i className="fas fa-check"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Change Collection Point Status</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        {/* Status ID */}
                        <div className="mb-3">
                            <Label className="form-label">Status</Label>
                            <div className="col">
                                <Dropdown
                                    value={selectedStatus || ''}
                                    onChange={changeStatus}
                                    options={statusList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        {/* Status Message */}
                        <div className="mb-3">
                            <Label className="form-label">Status Message</Label>
                            <Input
                                name="status_message"
                                placeholder="Enter message..."
                                type="textarea"
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status_message || ""}
                                invalid={validation.touched.status_message && !!validation.errors.status_message} // Update this line
                            />
                            {
                                validation.touched.status_message ? (
                                    <FormFeedback type="invalid">{validation.errors.status_message}</FormFeedback>
                                ) : null}
                        </div>
                        { }
                        {selectedStatus.id == 3 && <div className="mb-3">
                            <Label className="form-label">Actual Weight (kg)</Label>
                            <Input
                                name="real_weight"
                                placeholder="Enter Actual Weight"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.real_weight || ""}
                                invalid={validation.touched.real_weight && validation.errors.real_weight}
                            />
                            {validation.touched.real_weight && validation.errors.real_weight ? (
                                <FormFeedback type="invalid">{validation.errors.real_weight}</FormFeedback>
                            ) : null}
                        </div>
                        }
                        {/* Completion Date */}
                        {selectedStatus.id == 3 && <div className="mb-3">
                            <Label className="form-label">Completion Time</Label>
                            <div style={{ width: "200px" }}>
                                <Flatpickr
                                    disabled={rowData.order?.due_date}
                                    className="form-control d-block"
                                    value={completionDate}
                                    onChange={value => {
                                        if (value.length > 0) {
                                            setCompletionDate(value[0])
                                        } else {
                                            setCompletionDate(null)

                                        }
                                    }}
                                    placeholder="m/d/Y H:i"
                                    options={{
                                        enableTime: true,
                                        altInput: true,
                                        altFormat: "m/d/Y H:i",
                                        dateFormat: "m/d/Y H:i"
                                    }}
                                />
                            </div>
                        </div>}
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default ApprovalPointsModal
