import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import classnames from "classnames";
import Tanks from "./Tank";
import Warehouses from "./Location";
import People from "./Person";
import Transport from "./Transport";
import Companies from "./Company";
import Boxes from "./Box";
import { simplify, numberFormatter } from "helpers/functions";

class TabMenu extends Component {
    constructor(props) {
        super(props);
        // Parse URL parameters to determine the active tab
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            activeGeneralTab: urlParams.get("tab") || "1",
            boxRecords: 0,
            tankRecords: 0,
            locationRecords: 0,
            transportRecords: 0,
            personRecords: 0,
            companyRecords: 0
        };


        this.toggleJustidyCustom = this.toggleJustidyCustom.bind(this);
        this.setBoxRecords = this.setBoxRecords.bind(this);
        this.setTankRecords = this.setTankRecords.bind(this);
        this.setTransportRecords = this.setTransportRecords.bind(this);
        this.setLocationRecords = this.setLocationRecords.bind(this);
        this.setPersonRecords = this.setPersonRecords.bind(this);
        this.setCompanyRecords = this.setCompanyRecords.bind(this);
    }

    toggleJustidyCustom(tab) {
        if (this.state.activeGeneralTab !== tab) {
            // Update URL with the new active tab
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('searchBox');
            searchParams.delete('searchTank');
            searchParams.delete('searchTransport');

            searchParams.set("tab", tab);
            window.history.pushState({}, "", `${window.location.pathname}?${searchParams.toString()}`);

            // Update state with the new active tab
            this.setState({
                activeGeneralTab: tab
            });
        }
    }
    setBoxRecords(count) {
        this.setState({ boxRecords: count });
    }
    setTankRecords(count) {
        this.setState({ tankRecords: count });
    }

    setBoxRecords(count) {
        this.setState({ boxRecords: count });
    }

    setLocationRecords(count) {
        this.setState({ locationRecords: count });
    }

    setTransportRecords(count) {
        this.setState({ transportRecords: count });
    }

    setPersonRecords(count) {
        this.setState({ personRecords: count });
    }
    setCompanyRecords(count) {
        this.setState({ companyRecords: count });
    }



    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody className="p-0">
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "1"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("1");
                                    }}
                                >
                                    <span className="d-sm-block">Boxes ({numberFormatter(this.state.boxRecords)})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "2"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("2");
                                    }}
                                >
                                    <span className="d-sm-block">Tanks ({numberFormatter(this.state.tankRecords)})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "3"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("3");
                                    }}
                                >
                                    <span className="d-sm-block">Locations ({numberFormatter(this.state.locationRecords)})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "4"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("4");
                                    }}
                                >
                                    <span className="d-sm-block">Transports ({numberFormatter(this.state.transportRecords)})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "5"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("5");
                                    }}
                                >
                                    <span className="d-sm-block">Individuals ({numberFormatter(this.state.personRecords)})</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.activeGeneralTab === "6"
                                    })}
                                    onClick={() => {
                                        this.toggleJustidyCustom("6");
                                    }}
                                >
                                    <span className="d-sm-block">Companies ({numberFormatter(this.state.companyRecords)})</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeGeneralTab} className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Boxes
                                    // totalRecords={this.state.boxRecords}
                                    setTotalTabRecords={this.setBoxRecords}
                                />
                            </TabPane>
                            <TabPane tabId="2">
                                <Tanks
                                    // totalRecords={this.state.tankRecords}
                                    setTotalTabRecords={this.setTankRecords}
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <Warehouses
                                    // totalRecords={this.state.locationRecords}
                                    setTotalTabRecords={this.setLocationRecords}
                                />
                            </TabPane>
                            <TabPane tabId="4">
                                <Transport
                                    // totalRecords={this.state.transportRecords}
                                    setTotalTabRecords={this.setTransportRecords}
                                />
                            </TabPane>
                            <TabPane tabId="5">
                                <People
                                    // totalRecords={this.state.personRecords}
                                    setTotalTabRecords={this.setPersonRecords}
                                />
                            </TabPane>
                            <TabPane tabId="6">
                                <Companies
                                    // totalRecords={this.state.companyRecords}
                                    setTotalTabRecords={this.setCompanyRecords}
                                />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default TabMenu;
