import React, { useEffect, useState } from "react";
import {
    Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form,
    InputGroup,
    ListGroup,
    ListGroupItem,
} from "reactstrap"
import { ProgressSpinner } from "primereact/progressspinner";
import "flatpickr/dist/themes/material_blue.css";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"

import { put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";

import '../../../../assets/css/style.css'
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePartner from "hooks/usePartner";
import { Dropdown } from 'primereact/dropdown';

const EditPartnerModal = ({ partner }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { setPartnerRefresh, partnerRefresh, sectorList, entityList } = usePartner();
    const [modal_center, setmodal_center] = useState(false);
    const [type, setType] = useState(partner.type);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [selectedSector, setSelectedSector] = useState(partner.sector_type);
    const [selectedEntity, setSelectedEntity] = useState(partner.type);
    const typeList = [
        { name: "Influencer", value: "Influencer" },
        { name: "Field Agent", value: "Field Agent" },
        { name: "Corporate", value: "Corporate" },
        // { name: 'Custom', value: 'custom' },
    ]
    const [bankList, setBankList] = useState([]);
    const [selectedBank, setSelectedBank] = useState(partner.bank_name);
    const searchType = async (username) => {
        try {
            setLoading(true);
            if (type) {
                const results = typeList.filter(types => types.name.toLowerCase().includes(username.toLowerCase()))
                    .map(type => type.value);
                setResults(results);
            } else {
                setResults([]);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    };
    const handleType = async (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if (value) {
            await searchType(value);
            setShowResults(true);
        } else {
            setShowResults(false);
        }
        setType(e.target.value);
    };

    const handleTypeBlur = (e) => {
        validation.handleBlur(e);
        setTimeout(() => {
            setShowResults(false);
        }, 150);
    };

    const handleResultClick = (user, e) => {
        e.stopPropagation();
        // console.log('click', user);
        setType(user);
        setShowResults(false);
    };

    const changeSector = (e) => {
        setSelectedSector(e.target.value)
    }
    const changeEntity = (e) => {
        setSelectedEntity(e.target.value)
    }
    const getBankList = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/support/bank-list`);
            var banks = []

            if (response)
                if (response.data.result.length > 0) {
                    response.data.result.forEach(element => {
                        var bank = {
                            value: element._id,
                            label: element.name
                        }
                        if (element._id == partner.bank_name) {
                            // console.log(element);
                            setSelectedBank(bank)

                        }
                        banks.push(bank)
                    })
                    if (!selectedBank) {
                        setSelectedBank(banks[0])
                    }
                }
            setBankList(banks)

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (modal_center) {
            getBankList()
        }
    }, [partnerRefresh, modal_center])

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();

        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: partner.name,
            address: partner.address,
            email: partner.email,
            account: partner.account,
            phone: partner.phone,
            // type: type,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            // type: Yup.string().required(
            //     "This value is required"
            // ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {

                const opts = { ...values, bank_name: selectedBank.value, type: selectedEntity, sector_type: selectedSector, };
                // const opts = { ...values, bank_name: selectedBank.value };
                const response = await put('/api/admin/marketing/partner/' + partner._id, opts);
                tog_center();
                setPartnerRefresh(response.random);
                resetForm();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });
    const handleNumericInput = (event) => {
        const { value } = event.target;
        if (!/^\+?\d*$/.test(value)) {
            event.preventDefault();
        } else {
            validation.handleChange(event);
        }
    };

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Partner</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        {/* <div className="mb-3 position-relative">
                            <Label className="form-label">Type</Label>
                            <InputGroup>
                                <Input
                                    name="type"
                                    placeholder="Enter Type"
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleType}
                                    onBlur={handleTypeBlur}
                                    value={validation.values.type}
                                    invalid={
                                        validation.touched.type && validation.errors.type
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.type}
                                    </FormFeedback>
                                ) : null}
                            </InputGroup>
                            {loading ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    style={{ top: "100%", zIndex: 1 }}
                                >
                                    <ListGroupItem key={"load"} className="d-flex">
                                        <ProgressSpinner
                                            style={{ width: "30px", height: "30px" }}
                                            strokeWidth="2"
                                            fill="var(--surface-ground)"
                                            animationDuration=".5s"
                                            className="align-self-center"
                                        />
                                    </ListGroupItem>
                                </ListGroup>
                            ) : showResults && results.length > 0 ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    role="button"
                                    style={{ top: "100%", zIndex: 1 }}
                                >
                                    {results.map((val) => (
                                        <ListGroupItem
                                            key={val}
                                            onClick={(e) => handleResultClick(val, e)}
                                            className="hover-list-item"
                                        >
                                            {val}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            ) : null}
                        </div> */}
                        <div className="mb-3 ">
                            <Label className="form-label">Entity Type</Label>
                            <div className="">
                                <Dropdown
                                    value={selectedEntity || ''}
                                    onChange={changeEntity}
                                    options={entityList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        <div className="mb-3 ">
                            <Label className="form-label">Sector Type</Label>
                            <div className="">
                                <Dropdown
                                    value={selectedSector || ''}
                                    onChange={changeSector}
                                    options={sectorList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Partner Account Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Address</Label>
                            <Input
                                name="address"
                                placeholder="Enter Address"
                                type="textarea"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={validation.touched.address && validation.errors.address}
                                style={{ resize: "none" }} // Adding this inline style

                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                                name="email"
                                placeholder="Enter Valid Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <Input
                                name="phone"
                                placeholder="Enter Phone Number"
                                type="text"
                                maxLength={15}
                                style={{ width: '200px' }}
                                onChange={handleNumericInput}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone}
                                invalid={
                                    validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Bank Name</Label>
                            <Select
                                name={`bank_name`}
                                value={selectedBank || ""}
                                onChange={value => {
                                    setSelectedBank(value)
                                }}
                                options={bankList}
                                optionLabel="label"
                                classNamePrefix="select2-selection"
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Bank Account</Label>
                            <Input
                                name="account"
                                placeholder="Enter Account"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.account || ""}
                                invalid={
                                    validation.touched.account && validation.errors.account ? true : false
                                }
                            />
                            {validation.touched.account && validation.errors.account ? (
                                <FormFeedback type="invalid">{validation.errors.account}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditPartnerModal
