import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import makeid from "helpers/random";
import useOverview from "hooks/useOverview";

const SyncModal = () => {
    const { showToast } = useAuth();
    const { setRefresh } = useOverview();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await get('/api/erp/inventory/sync-uco-stock');

                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }

            }
        }
    });


    return (
        <>
            <div className="">
                <Button color="warning" onClick={tog_center}>
                    {/* <i className="fas fa-sync"></i>  */}
                    Sync
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Sync UCO Units</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <h5>Are you sure want to sync data ?</h5>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="warning" >
                                Sync
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default SyncModal
