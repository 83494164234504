import { useEffect, useMemo, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from 'helpers/random';
import { InputText } from 'primereact/inputtext';
import { debounce } from "helpers/functions";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAdmin from "hooks/useAdmin";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useNotifLog from "hooks/useNotifLog";
import { ROWS_PER_PAGE } from "constants/value";
import moment from "moment";

const UserNotificationModal = ({ data }) => {
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh, setNotifLogs, notifLogs } = useNotifLog();
    const { auth, showToast } = useAuth();
    const axiosApi = useAxiosPrivate();
    const [userLogs, setUserLogs] = useState([]);
    const [userLogsCount, setuserLogsCount] = useState([]);

    const tog_center = () => {
        setmodal_center(!modal_center);
    };
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords] = useState(0);
    const [search, setSearch] = useState('')
    const [lazyState, setlazyState] = useState(initialLazyState);
    const propsData = location.state;

    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const getNotifLogs = async () => {
        try {
            // const response = await axiosApi.get(`/api/admin/support/getUsers?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const response = await axiosApi.get(`/api/support/broadcast-notification/${data._id}/user-feedback?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setUserLogs(response.data.userLogs)
            setuserLogsCount(response.data.totalRecords)
            setLoading(false)
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                // setExpandedRows(obj)
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (modal_center) {
            getNotifLogs()
        };
    }, [modal_center, lazyState]);

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const createdAt = (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss')
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'user_name', header: 'User Name', body: '' },
        { field: 'device_name', header: 'User Device', body: '' },
        { field: 'createdAt', header: 'Date', body: createdAt },
    ]
    return (
        <>
            <div className="my-2">
                <a href="#" onClick={tog_center}>
                    {data.user_read_count}
                </a>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0" toggle={tog_center}>List of User | {data.title}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Row className="align-items-center mb-2">
                                        <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                            {/* <h4 className="card-title mb-0">Broadcast Notification Logs</h4> */}
                                        </Col>
                                        <Col md={5}>
                                            {/* <span className="p-input-icon-left float-start float-md-end">
                                                <i className="pi pi-search" />
                                                <InputText value={search} onChange={onSearchTextChange} placeholder="Title / Message / Url" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                            </span> */}
                                        </Col>
                                        <Col md={1}>
                                            <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                                {/* <UserExportCSV lazyState={lazyState} /> */}
                                            </div>
                                        </Col>
                                    </Row>

                                    <DataTable
                                        lazy
                                        value={loading ? items : userLogs}
                                        stripedRows
                                        paginator
                                        rows={lazyState.config.rows}
                                        rowsPerPageOptions={ROWS_PER_PAGE}
                                        size={'normal'}
                                        onPage={onPage}
                                        totalRecords={userLogsCount}
                                        first={lazyState.config.first}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                                        dataKey={loading ? '' : '_id'}
                                    >
                                        {columns.map((col, i) => (
                                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                                        ))}
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )

}

export default UserNotificationModal
