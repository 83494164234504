import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";
import Collections from "./Collections";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Orders from "./Orders";
import moment from "moment";
import { convertTimeFromUTC } from "helpers/functions";
import useOverview from "hooks/useOverview";
import Units from "./Units";
import Inventory from "./Inventory";

const eRPOverview = () => {
    const { refresh, setRefresh, loading, setLoading, collections, setCollections, ucoUnits, setUcoUnits, orders, setOrders } = useOverview();
    const axiosApi = useAxiosPrivate();
    const [lazyState, setlazyState] = useState({
        searchText: '',
        startDate: '',
        endDate: '',
        config: {
            first: 0,
            rows: 20,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            }
        }
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const getOrders = async () => {
        setLoading(true);

        try {
            const response = await axiosApi.get(`/api/erp/orders?status=active&rows=4&skip=0`);
            setOrders(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const getCollections = async () => {
        setLoading(true);

        try {
            const response = await axiosApi.get(`/api/erp/collections?status=active&rows=4&skip=0`);
            setCollections(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    const getUnits = async () => {
        try {
            setLoading(true)
            const response = await axiosApi.get(`/api/erp/inventory/tank-uco-units?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
           
            const result = response.data.results;
            // console.log(response.data)
            setUcoUnits(result);
            if (response.data.total_records !== null && response.data.total_records !== undefined) {
                setTotalRecords(response.data.total_records)
            } else {
                setTotalRecords(0)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false);

            console.log(error);
        }
    }
    useEffect(() => {
        getOrders();
        getCollections();
    }, [refresh]);
    
    useEffect(() => {
        getUnits();
    }, [lazyState, refresh]);
    document.title = `${process.env.REACT_APP_NAME || ''} | Overview`;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8} sm={12}>
                                <h6 className="page-title">Overview</h6>
                                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Inventory</a></li><li className="active breadcrumb-item" aria-current="page">Overview</li></ol>
                            </Col>

                            <Col md={4} sm={12}>
                                <div className="float-md-end d-sm-block">
                                    {/* <Dropdown value={selectedRange} onChange={changeRange} options={priceRange} optionLabel="name" className="h-1 payment-status-dropdown" /> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg={12} md={12}>
                            <Inventory />
                        </Col>
                        <Col lg={6} md={6}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Collections</h4>
                                    <Collections />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} md={6}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Orders</h4>
                                    <Orders />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={12} md={12}>

                            <Units lazyState={lazyState} setlazyState={setlazyState} totalRecords={totalRecords} />

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default eRPOverview;
