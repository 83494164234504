import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import InputMask from "react-input-mask";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import deleteIcon from "../../../../../assets/images/delete-icon.png";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import { InputSwitch } from 'primereact/inputswitch';

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import AddressForm from "pages/AddressForm";
import Select from "react-select";
import { phoneRegExp, positiveNumericRegExp } from "constants/value";

const EditModal = ({ rowData }) => {
    const axiosApi = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [modal_center, setmodal_center] = useState(false);
    const { personRefresh, companyRefresh, setPersonRefresh, identityTypes } = useAsset();
    const { showToast } = useAuth();
    const [imageUrl, setImageUrl] = useState(rowData.photo);
    const [selectedType, setSelectedType] = useState(rowData.identity_type);
    const [selectedStatus, setSelectedStatus] = useState(rowData.is_active);
    const [selectedCompany, setSelectedCompany] = useState();
    const [selectedPersonType, setSelectedPersonType] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [personTypeList, setPersonTypeList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedPostcode, setSelectedPostcode] = useState(rowData.postcode);
    const [selectedAddress, setSelectedAddress] = useState(rowData.address);
    const [defaultId, setDefaultId] = useState(rowData.person_code);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
       
        validation.resetForm();
        setSelectedStatus(rowData.is_active)
        setImageUrl(rowData.photo)
        setDefaultId(rowData.person_code)
    };
    const changePersonType = (e) => {
        setSelectedPersonType(e.value)
    }
  
    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ name: "None", value: "", id: null })
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
                if (element.id === rowData.company_id) {
                    setSelectedCompany(detail)
                }
            })
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }

    const getPersonTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/person-types`);
            setPersonTypeList(response.data.results);
            if (response.data.results.length > 0)
                for (let idx = 0; idx < response.data.results.length; idx++) {
                    if (response.data.results[idx].id == rowData.person_type_id) {
                        setSelectedPersonType(response.data.results[idx])
                        break;
                    }
                }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (modal_center) {
            getCompanies();
            getPersonTypes();
        }
    }, [personRefresh, companyRefresh, modal_center]);


    const storeFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)

        setUploading(true);
        await Promise.all(
            validFiles.map(async (photo) => {
                const formData = new FormData();
                formData.append('image', photo)
                const response = await axiosApi.post('/api/admin/asset/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                setImageUrl(response.data.url)
            })
        )
        setUploading(false)
    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const changeType = (e) => {
        setSelectedType(e.value)
    }
    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    const deleteImage = (e) => {
        setImageUrl('');
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            person_code: rowData.person_code,
            first_name: rowData.first_name,
            last_name: rowData.last_name,
            phone: rowData.phone,
            email: rowData.email,
            identity_number: rowData.identity_number,
        },
        validationSchema: Yup.object().shape({
            person_code: Yup.string().required("ID is required"),
            first_name: Yup.string().required("Name is required"),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
            identity_number: Yup.string().matches(positiveNumericRegExp, 'Only input number').required("ID Number is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, identity_type: selectedType, photo: imageUrl, is_active: selectedStatus, company_id: selectedCompany.id, person_type_id: selectedPersonType.id, location_city_id: selectedCity.id, location_region_id: selectedRegion.id, location_country_id: selectedCountry.id, address: selectedAddress, postcode: selectedPostcode };
                const response = await put('/api/erp/asset/persons/' + rowData.id, opts);
                setPersonRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Individual</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        {/* Person ID */}
                        <div className="mb-3">
                            <Label className="form-label">Individual ID</Label>
                            <InputMask
                                placeholder="Enter Individual ID"
                                name="person_code"
                                mask="aaaa-aa-aa-99999"  // Mask for date format
                                value={defaultId}  // Controlled input value
                                onChange={handleIdChange}  // Update state on change
                                invalid={validation.touched.person_code && validation.errors.person_code}
                                maskChar={null}  // Removes default mask characters (like "_")
                            >
                                {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                            </InputMask>
                            {validation.touched.person_code && validation.errors.person_code ? (
                                <FormFeedback type="invalid">{validation.errors.person_code}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Person Type */}
                        <div className="mb-3">
                            <Label className="form-label">Type</Label>
                            <div className="col">
                                <Dropdown
                                    value={selectedPersonType || ''}
                                    onChange={changePersonType}
                                    options={personTypeList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        {/* Company ID */}
                        <div className="mb-3">
                            <Label className="form-label">Company</Label>
                            <div className="col">
                                <Select
                                    name={`company_id`}
                                    value={selectedCompany}
                                    onChange={value => {
                                        setSelectedCompany(value)
                                    }}
                                    options={companyList}
                                    optionLabel="label"
                                    classNamePrefix="select2-selection"
                                />
                                {/* <Dropdown
                                        value={selectedCompany || ''}
                                        onChange={changeCompany}
                                        options={companies}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    /> */}
                            </div>
                        </div>
                        {/* First Name */}
                        <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                                name="first_name"
                                placeholder="Enter First Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ""}
                                invalid={validation.touched.first_name && validation.errors.first_name}
                            />
                            {validation.touched.first_name && validation.errors.first_name ? (
                                <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Last Name */}
                        <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                                name="last_name"
                                placeholder="Enter Last Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ""}
                                invalid={validation.touched.last_name && validation.errors.last_name}
                            />
                            {validation.touched.last_name && validation.errors.last_name ? (
                                <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Contact Email */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Email</Label>
                            <Input
                                name="email"
                                placeholder="Enter Email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={validation.touched.email && validation.errors.email}
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Contact Number */}
                        <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <Input
                                name="phone"
                                placeholder="Enter Phone Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={validation.touched.phone && validation.errors.phone}
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* Identity Type */}
                            <div className="mb-3">
                                <Label className="form-label">Identity Type</Label>
                                <div className="col">
                                    <Dropdown
                                        value={selectedType || ''}
                                        onChange={changeType}
                                        options={identityTypes}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    />
                                </div>
                            </div>
                            {/* ID/Passport Number */}
                            <div className="mb-3 px-2">
                                <Label className="form-label">{selectedType} Number</Label>
                                <Input
                                    name="identity_number"
                                    placeholder={`Enter ${selectedType}`}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.identity_number || ""}
                                    invalid={validation.touched.identity_number && validation.errors.identity_number}
                                />
                                {validation.touched.identity_number && validation.errors.identity_number ? (
                                    <FormFeedback type="invalid">{validation.errors.identity_number}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                        <AddressForm
                            refresh={modal_center}
                            selectedCountry={selectedCountry}
                            selectedCity={selectedCity}
                            selectedRegion={selectedRegion}
                            selectedPostcode={selectedPostcode}
                            selectedAddress={selectedAddress}
                            setSelectedCountry={setSelectedCountry}
                            setSelectedCity={setSelectedCity}
                            setSelectedRegion={setSelectedRegion}
                            setSelectedPostcode={setSelectedPostcode}
                            setSelectedAddress={setSelectedAddress}
                            countryId={rowData.location_country_id}
                            regionId={rowData.location_region_id}
                            cityId={rowData.location_city_id}
                        />
                        {/* Status */}
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <Label className="form-label">Active : </Label>
                            <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                        </div>
                        {/* Photo */}
                        <div className="mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <Label className="form-label">Photo : </Label>
                                <label for="fileInput" className="btn btn-primary btn-sm">
                                    {imageUrl == '' ? 'Add' : "Change"}
                                </label>
                                {uploading && <Label className="form-label text-danger">uploading...</Label>}
                                <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeFiles} name="image" />
                            </div>
                            <div className="d-flex gap-2 box-images-container">
                                {imageUrl != '' && <div className="image-container">
                                    <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={imageUrl} onClick={deleteImage}></img>
                                    <img src={imageUrl} width={200} height={200} className="box-image"></img>
                                </div>}
                            </div>
                        </div>


                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
