import React, { useEffect, useRef, useState }  from "react"
import { Link } from "react-router-dom"

import {
    Row,
    Col,
    Card,
    CardBody,
    Button as ReqButton
} from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import useDonation from "hooks/useDonation";
import useAuth from "hooks/useAuth";
// import ChangeRequestModal from "./modals/ChangeRequestModal";
// import ExportCSV from "./export/ExportCSV";
import { currencyFormatter,inputDateTimeHour, inputDateTime, methodId, userAddress, debounce } from "helpers/functions";
import moment from "moment";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";

const DonationTable = ({ datas, loading }) => {
    const { isMobile } = useAuth();
    const { lazyState, setlazyState, totalRecords, paymentStatus, setSelectedStatus, selectedStatus } = useDonation();
    const [expandedRows, setExpandedRows] = useState(null);
    const [search, setSearch] = useState('')
    const dt = useRef(null);
    const delay = 300;

    const checkUser = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }

    const badgeStatus = (data) => {
        return (
            <span className={`badge bg-${data.status} text-t-${data.status}`}>
                {data.status}
            </span>
        )
    }

    const userEmail = (data) => {
        return data?.user[0]?.email || '-'
    }

    const userIP = (data) => {
        return data?.user[0]?.data.ip ?? "No Data"
    }
    const paymentAcbe = (data) => {
        return data?.actrx_id ?? "No Data"
    }

    const paymentMethod = (data) => {
        let method;
        switch (data.method) {
            case 'banktrf':
                method = 'Bank Transfer';
                break;
            case 'ewallet':
                method = 'E-Wallet';
                break;
        }
        return method;
    }

    const amountFormat = (data) => {
        if(data.currency === 'IDR') return currencyFormatter(data.amount, 'IDR')
        return currencyFormatter(data.amount)
    }

    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);

    const columns = [
        { field: 'reqId', header: 'ID', body: '' },
        { field: 'user', header: 'User', body: checkUser },
        { field: 'createdAt', header: 'Input Date Time', body: inputDateTimeHour },
        { field: 'amount', header: 'Amount', body: amountFormat ,class:"bold"},
        { field: 'currency', header: 'Currency', body: '' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const changeStatus = (e) => {
        setSelectedStatus(e.value);
        let _filters = { ...lazyState };
        _filters.status = e.value;
        _filters.config.first = 0; //back to page 1
        setlazyState(_filters);
        setExpandedRows(null);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const sendStatus = (data) => {
        switch (data.status) {
            case 'open':
                return 'process'
                break;
            case 'pending':
                return 'finish'
                break;
            default:
                break;
        }
    }

 

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <h4 className="card-title mb-0">User Donations List</h4>
                                {/* <Dropdown value={selectedStatus} onChange={changeStatus} options={paymentStatus} optionLabel="name" className="h-1 payment-status-dropdown"/> */}
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                        <InputText value={search} onChange={onSearchTextChange} placeholder="ID / User" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col> 
                            {/* {/* <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <ExportCSV />
                                </div>
                            </Col> */}
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            // rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            dataKey={loading ? '' : 'reqId'}
                            ref={dt}
                        >
                            <Column expander={false} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    className={col.class}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DonationTable
