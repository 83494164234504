import { Button } from "reactstrap"

const { CURRENT_WITHDRAWAL_PLATFORM, ENV_IS_PROD } = require("constants/value")

const BalanceButton = ({ withdrawalPlatform, balance, oldBalance }) => {
  // console.log("withdrawalPlatform", withdrawalPlatform)
  // console.log("oldBalanace ", oldBalance)
  if (withdrawalPlatform === CURRENT_WITHDRAWAL_PLATFORM) {

    const openXendit = () => {

      window.open("https://dashboard.xendit.co/", "_blank")

    }
    return (
      <>
        <div className="my-2">
          <Button color="primary" onClick={openXendit}>Balance: {balance}</Button>
        </div>
      </>
    )
  } else {
    const openAyo = () => {
      if (ENV_IS_PROD) {
        window.open("https://portal.of.ayoconnect.id/", "_blank")
      } else {
        window.open("https://sandbox.portal.of.ayoconnect.id/", "_blank")
      }
    }

    const openXendit = () => {

      window.open("https://dashboard.xendit.co/", "_blank")

    }
    return (
      <>
        <div className="my-2">
          <Button color="secondary" onClick={openAyo} className="me-1">
            AyoConnect: {oldBalance}
          </Button>
          <Button color="primary" onClick={openXendit}> Xendit: {balance}</Button>
        </div>
      </>
    )
  }
}

export default BalanceButton
