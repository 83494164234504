import {
  REFRESH_SIDEBAR
} from "./actionTypes"
import makeid from 'helpers/random';

const initialState = {
  value: makeid(5),
  loading: false,
}

const menu = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_SIDEBAR:
      state = {
        ...state,
        value: makeid(5),
        loading: true,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default menu
