import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, Row, Col, InputGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import deleteIcon from "../../../../../assets/images/delete-icon.png";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { positiveNumericRegExp } from "constants/value";

const AddLicenseModal = ({row}) => {
    const axiosApi = useAxiosPrivate();
    const [licenseUploading, setLicenseUploading] = useState(false);
    const [licenseImageUrl, setLicenseImageUrl] = useState('');
    const [modal_center, setmodal_center] = useState(false);
    const { personRefresh, setPersonRefresh, companyRefresh } = useAsset();
    const { showToast } = useAuth();
    const [selectedLicenseType, setSelectedLicenseType] = useState('');
    const [licenseTypeList, setLicenseTypeList] = useState([]);
    const [expiredDate, setExpiredDate] = useState('');

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        setLicenseImageUrl('')

    };

    const getLicenseTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/person-license-types`);
            setLicenseTypeList(response.data.results);
            if (response.data.results.length > 0)
                setSelectedLicenseType(response.data.results[0])
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        if (modal_center) {
            getLicenseTypes();
        }
    }, [personRefresh, companyRefresh, modal_center]);

    const storeLicenseFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)
        setLicenseUploading(true);
        await Promise.all(
            validFiles.map(async (photo) => {
                const formData = new FormData();
                formData.append('image', photo)
                const response = await axiosApi.post('/api/admin/asset/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                setLicenseImageUrl(response.data.url)
            })
        )
        setLicenseUploading(false)
    }

    const deleteLicenseImage = (e) => {
        setLicenseImageUrl('');
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const changeLicenseType = (e) => {
        setSelectedLicenseType(e.value)
    }

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            license_number: "",
        },
        validationSchema: Yup.object().shape({
            license_number: Yup.string().matches(positiveNumericRegExp, 'Only input number').required("License Number is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {

                const opts = { ...values, person_license_type_id: selectedLicenseType.id, license_image: licenseImageUrl, person_id: row.id, expiry_date:expiredDate };
                // console.log(opts);
                const response = await post('/api/erp/asset/person-licenses', opts);
                setPersonRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add License </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New License | {row.first_name+' '+row.last_name} </ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div style={{ display: "flex" }}>
                            {/* Identity Type */}
                            <div className="mb-3">
                                <Label className="form-label">License Type</Label>
                                <div className="col">
                                    <Dropdown
                                        value={selectedLicenseType || ''}
                                        onChange={changeLicenseType}
                                        options={licenseTypeList}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    />
                                </div>
                            </div>

                            {/* Driving License */}
                            <div className="mb-3 px-2">
                                <Label className="form-label">Driving License</Label>
                                <Input
                                    name="license_number"
                                    placeholder="Enter License No"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.license_number || ""}
                                    invalid={validation.touched.license_number && validation.errors.license_number}
                                />
                                {validation.touched.license_number && validation.errors.license_number ? (
                                    <FormFeedback type="invalid">{validation.errors.license_number}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Expiring date</Label>
                            <br />
                            <InputGroup style={{width:"200px"}}>
                                <Flatpickr
                                    className="form-control d-block"
                                    value={expiredDate}
                                    onChange={value => {
                                        if (value.length > 0) {
                                            const dateObject = new Date(value[0]);
                                            // Extracting year, month, and day
                                            const year = dateObject.getFullYear();
                                            // JavaScript months are zero-based, so we add 1
                                            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                                            const day = dateObject.getDate().toString().padStart(2, '0');

                                            // Forming the desired date string
                                            const formattedDate = `${month}/${day}/${year}`;
                                            setExpiredDate(formattedDate)

                                        } else {
                                            setExpiredDate('')

                                        }
                                    }}
                                    placeholder="m/d/Y"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y"
                                    }}
                                />
                            </InputGroup>
                        </div>
                        {/* License Photo */}
                        <div className="mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <Label className="form-label">License Photo : </Label>
                                <label for="fileInputLicense" className="btn btn-primary btn-sm">
                                    {licenseImageUrl == '' ? 'Add' : "Change"}
                                </label>
                                {licenseUploading && <Label className="form-label text-danger">uploading...</Label>}
                                <input className="d-none" id="fileInputLicense" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeLicenseFiles} name="image" />
                            </div>
                            <div className="d-flex gap-2 box-images-container">
                                {licenseImageUrl != '' && <div className="image-container">
                                    <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={licenseImageUrl} onClick={deleteLicenseImage}></img>
                                    <img src={licenseImageUrl} width={200} height={200} className="box-image"></img>
                                </div>}
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddLicenseModal
