import { useEffect, useRef, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import { Toast } from 'primereact/toast';

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, createTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";

const ActivityLogsModal = ({ data }) => {
    const toast = useRef(null);
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;


    const dataCreatedAt = (data) => {
        return createTime(data)
    }

    const dataAction = (data) => {
        return data.action
    }
    const dataDescription = (data) => {
        return data.description
    }

    const dataGetId = (data) => {
        return data._id;
    }

    const columns = [
        { field: 'time', header: 'Time', body: dataCreatedAt },
        { field: 'action', header: 'Action', body: dataAction },
        { field: 'description', header: 'Description', body: dataDescription }

    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
  

    const rowExpansionTemplate = (data) => {
        var headerValue = "";
        var extraDataValue = "";

        //foreach data.extraData if exist not empty
        if (data.extraData) {
            Object.keys(data.extraData).forEach(function (key) {
                if (data.extraData[key] != "") {
                    //check if key == object then json encode it
                    if (typeof data.extraData[key] == "object") {
                        data.extraData[key] = JSON.stringify(data.extraData[key]);
                    } 
                    extraDataValue += key + " : " + data.extraData[key] + "\n";
                }
            });
        }

        //do the same for header value
        if (data.header) {
            Object.keys(data.header).forEach(function (key) {
                if (data.header[key] != "") {
                    headerValue += key + " : " + data.header[key] + "\n";
                }
            });
        }
        return (
            <div className="trans-detail">
                <h5>Logs ID : {data._id}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-4 p-2">
                        <label>Device :</label>
                        
                        <ul>
                            <li>Device ID : {data?.device?._id}</li>
                            <li>Brand : {data?.device?.device_brand}</li>
                            <li>Model : {data?.device?.device_model}</li>
                            <li>Full Model : {data?.device?.device_full_model_parsed}</li>
                            <li>OS : {data?.device?.device_os}</li>
                            <li>OS Version : {data?.device?.device_os_version}</li>
                            <li>App Version : {data?.device?.app_version}</li>
                        </ul>
                    </div>
                    <div className="col-4 p-2">
                        <label>Header :</label>
                        <br />
                        <textarea  value={headerValue}  rows="10" className="w-100" readonly Style="resize: none; overflow: auto;"></textarea>
                
                    </div>
                    <div className="col-4 p-2">
                        <label>Extra Data :</label>
                        <br />
                        <textarea  value={extraDataValue}  rows="10" className="w-100" readonly Style="resize: none; overflow: auto;"></textarea>
                
                    </div>
                   
                
                </div>
            </div>
        );
    };
   
    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/admin/support/user-logs?id=${data?._id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const result = response.data.result;
            console.log(response.data)
            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
        <Button type="button" color="primary" onClick={tog_center}>
            Show User Activity Logs
        </Button>{" "}
        <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
            <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0">User Activity Logs | {data?.name}</h4>
                    <div className="d-flex align-items-center gap-3 me-2">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={onSearchTextChange} placeholder="ID / Box" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                        </span>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="min-modal-body">
                <DataTable
                    lazy
                    value={loading ? items : trans}
                    stripedRows
                    paginator
                    rows={lazyState.config.rows}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    size={'normal'}
                    onPage={onPage}
                    totalRecords={totalRecords}
                    first={lazyState.config.first}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    scrollable
                    scrollHeight = '500px'
                    expandedRows={loading ? null : expandedRows}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={onRowToggle}
                    dataKey={loading ? '' : '_id'}
                >
                    <Column expander={true} style={{ width: '3rem' }} />
                    {columns.map((col, i) => (
                        <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                    ))}
                </DataTable>
            </ModalBody>
        </Modal>
        </>
    )
}

export default ActivityLogsModal
