import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAuth from "hooks/useAuth";


const DataExportCSV = ({ lazyState }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    const headers = [
        { key: 'incident_code', label: 'ID' },
        { key: 'tank_name', label: 'Box / Tank' },
        { key: 'ucounitstatus_name', label: 'Status' },
        { key: 'previous_weight', label: 'Previous Weight' },
        { key: 'lost_weight', label: 'Lost Weight' },
        { key: 'current_weight', label: 'Current Weight' },
        { key: 'description', label: 'Reason' }
    ];
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Incidents_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])


    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/erp/inventory/incidents?searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&show_all=true`);
            const result = response.data.results;
            // result.forEach(obj => {
            //     let urls = JSON.parse(obj.document_images);
            //     if (urls.length > 1) {
            //         obj.documentation = urls.join(',');
            //     } else if (urls.length == 1) {
            //         obj.documentation = urls[0];
            //     } else {
            //         obj.documentation = '-';
            //     }
            // })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV