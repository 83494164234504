import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { ROWS_PER_PAGE } from "constants/value";
import '../../../assets/css/style.css'
import moment from "moment";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import useCollection from "hooks/useCollection";
import ApprovalPointsModal from "./modals/ApprovalDetailModal";
import PointDetailModal from "./modals/PointDetailModal";
const ContentTable = ({ row, paramStatus, submitLoading, setSubmitLoading }) => {
    const location = useLocation();
    const propsData = location.state;
    const [contents, setContents] = useState([]);
    const [totalRecords,setTotalRecords] = useState(0)
    const { refresh } = useCollection()
    const items = Array.from({ length: 5 }, (v, i) => i);
    const [expandedRows, setExpandedRows] = useState(null);

    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });
    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>User ID : {data._id}</h5>
            </div>

        )
    };
    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getContents = async () => {
        try {
            // const response = await axiosApi.get(`/api/erp/collection-details?collection_id=${row.id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const response = await axiosApi.get(`/api/erp/collection-details?collection_id=${row.id}&show_all=true`);
            // console.log(response)

            setContents(response.data.results);
            setTotalRecords(response.data.totalRecords)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                {data.collection_detail_status_id != 3 && data.collection_detail_status_id != 4 &&
                    <div>
                        <ApprovalPointsModal rowData={data} submitLoading={submitLoading} setSubmitLoading={setSubmitLoading}/>
                    </div>}

                {/* <div>
                    <EditLicenseModal rowData={data} />
                </div>
                <div>
                    <DeleteLicenseModal rowData={data} /> 
                </div> */}
            </div>
        )
    }

    const collectedTime = data => {
        return data.collected_datetime?moment(data.collected_datetime).format("MM/DD/YYYY HH:mm:ss"):''
    }
    const inputRealQuantity = data => {
        return paramStatus !== 'completed' ? data.real_weight ?? 0 :<PointDetailModal data={data} />;
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'origin_tank.tank_name', header: 'Origin', body: '' },
        { field: '', header: 'Actual Weight (kg)', body: inputRealQuantity },
        { field: 'weight', header: 'Quantity (kg)', body: '' },
        { field: '', header: 'Date Time', body: collectedTime },
        { field: 'status_message', header: 'Message', body: '' },
        { field: 'collectiondetailstatus_name', header: 'Status', body: '' },
        paramStatus == 'active' ? { field: '', header: 'Action', body: adminAction, className: '' } : {},
    ]

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getContents();
        }, 300);
    }, [lazyState, refresh]);

    // useEffect(() => {
    //     getContents();
    // }, [refresh]);
    return (
        <React.Fragment>
            <DataTable
                // lazy
                value={loading ? items : contents}
                stripedRows
                // paginator
                // rows={lazyState.config.rows}
                // rowsPerPageOptions={ROWS_PER_PAGE}
                size={'normal'}
                // scrollable
                // scrollHeight='450px'
                // onPage={onPage}
                // totalRecords={totalRecords}
                // first={lazyState.config.first}
                // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                // expandedRows={loading ? null : expandedRows}
                // rowExpansionTemplate={rowExpansionTemplate}
                // onRowToggle={onRowToggle}
                // rowClassName={rowClassName}
                dataKey={loading ? '' : 'id'}
            >
                {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                {columns.map((col, i) => (
                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                ))}
            </DataTable>

        </React.Fragment>
    );
};

export default ContentTable;
