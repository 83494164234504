import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { Button, Table, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Progress, Row, Col, Card, CardBody } from "reactstrap"
import useAuth from "hooks/useAuth";
import makeid from "helpers/random";
import { post, del, get, put } from "../../../helpers/api_helper";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Tag } from 'primereact/tag';

import useInventorySetting from "hooks/useInventorySetting";
import LoadingOverlay from 'react-loading-overlay';

const DataTables = () => {
    const { blacklistUsers, setBlacklistUsers, refresh, setLoading, setRefresh } = useInventorySetting();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { showToast } = useAuth();
    const [isSuccess, setIsSuccess] = useState(false)
    const axiosApi = useAxiosPrivate();

    const getNotifLogs = async () => {
        try {
            // const response = await axiosApi.get(`/api/admin/support/getUsers?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const response = await axiosApi.get(`/api/erp/settings/blacklist_email`);
            if (response?.data?.results?.value?.length > 0) {
                const emailObjects = response.data.results.value.map(email => ({ email }));
                setBlacklistUsers(emailObjects)
            } else {
                setBlacklistUsers([])
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
        setIsSuccess(true);

    }

    useEffect(() => {
        getNotifLogs();
    }, [refresh]);
    const deleteData = async (arrayHelpers, index, forms) => {
        arrayHelpers.remove(index)
        console.log('arrayHelpers', forms);
        if (index >= 0 && index < forms.length) {
            forms.splice(index, 1); // Remove one item at the specified index
        }
        try {
            var emailArr = [];
            forms.forEach(collection => {
                emailArr.push(collection.email);
            });
            const opts = { value: emailArr };

            const response = await put('/api/erp/settings/blacklist_email', opts);
            setRefresh(makeid(5));
            showToast(response);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
        }
    }

    return (
        <LoadingOverlay
            active={!isSuccess}
            spinner
            text='Loading your content...'
        >
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    blacklists: blacklistUsers ?? [],
                                    email: '',

                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string().email("Invalid email format").required("Email is required"),
                                    blacklists: Yup.array().of(
                                        Yup.object().shape({
                                            email: Yup.string()
                                                .email("Invalid email format").
                                                required("Email is required"),
                                        })
                                    ).notRequired(),
                                })}
                                resetForm
                                onSubmit={async (values) => {
                                    console.log('values', values);

                                    setSubmitLoading(true);
                                    try {
                                        var emailArr = [values.email];
                                        values.blacklists.forEach(collection => {
                                            emailArr.push(collection.email);
                                        });
                                        const opts = { value: emailArr };

                                        const response = await put('/api/erp/settings/blacklist_email', opts);
                                        setRefresh(makeid(5));
                                        showToast(response);
                                    } catch (error) {
                                        if (error.response && error.response.data && error.response.data.state) {
                                            showToast(error.response.data);
                                        } else {
                                            let response = {};
                                            response.state = "error";
                                            response.toast = true;
                                            response.message = "Internal Server Error"
                                            showToast(response);
                                        }
                                    }

                                    setSubmitLoading(false)

                                }}
                                render={({ values, touched, errors, setFieldValue }) => (

                                    <Form>
                                        <FieldArray
                                            name="blacklists"
                                            render={(arrayHelpers) => {
                                                const forms = values.blacklists;
                                                return (
                                                    <div>
                                                        <Row>
                                                            <Col lg={12} md={12}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label me-2 mb-0">Email</Label> {/* Add right margin and remove bottom margin */}
                                                                    <div className="my-3">
                                                                        <Input
                                                                            tag={Field}
                                                                            style={{ width: "300px" }}
                                                                            name="email"
                                                                            placeholder="Enter Email"
                                                                            type="text"
                                                                            className="me-2"
                                                                        />
                                                                        <ErrorMessage component="div" className="error-text" name="email" />
                                                                    </div>
                                                                    <Button disabled={submitLoading} color="primary" className="btn btn-primary">Add to List</Button>
                                                                    {/* <Button
                                                                        onClick={() => { addForm(arrayHelpers, forms) }}
                                                                        color="primary"
                                                                        className="btn btn-primary"
                                                                    >
                                                                        Add User
                                                                    </Button> */}
                                                                </div>
                                                                <hr />
                                                                <div className="mb-3">
                                                                    {touched.blacklists && errors.blacklists && typeof errors.blacklists === 'string' && (
                                                                        <div className="error-text mb-3 px-3">{errors.blacklists}</div>
                                                                    )}
                                                                    <div>
                                                                        <h4>Black List User:</h4>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap">
                                                                        {forms.map((form, index) => {
                                                                            return (
                                                                                <div>
                                                                                    <Tag className="me-2" style={{ backgroundColor: "#70AD46", color: "white" }}>
                                                                                        <div className="d-flex align-items-center gap-2">
                                                                                            <span className="text-base">{form.email}</span>
                                                                                            <Button type="button"
                                                                                                onClick={() => deleteData(arrayHelpers, index, forms)} // Replace with your handler function
                                                                                                className="bg-transparent border-0 p-0 cursor-pointer"
                                                                                                aria-label="Remove"
                                                                                                style={{ display: 'flex', alignItems: 'center',color:"white" }}
                                                                                            >
                                                                                                <i className="pi pi-times text-xs"></i>
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Tag>
                                                                                    
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </Form>
                                )}
                            />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

export default DataTables
