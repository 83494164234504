import { MONTHS_IN_YEAR } from 'constants/value';
import { simplify } from 'helpers/functions';
import React, { Component, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const WeightChart = ({ series, options }) => {
    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="area" height="250" />
        </React.Fragment>
    )
}

export default WeightChart;