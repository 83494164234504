import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
} from "helpers/functions"
const ConfirmationModal = ({ isOpen, toggle, onConfirm, data, permissions, rawData }) => {
    let sortedRawData = JSON.parse(rawData.alert_email_admin_role).slice().sort();
    let sortedData = JSON.parse(data.alert_email_admin_role).slice().sort();

    const arraysAreEqual = JSON.stringify(sortedRawData) === JSON.stringify(sortedData);
    const findTitleById = (id) => {
        if(permissions.length === 0) return 'Title not found';
        const menu = permissions.find(menuItem => menuItem.key === id);
        if (menu) {
            return menu.title;
        }

        return 'Title not found';
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Your Settings</ModalHeader>
            <ModalBody>
                {rawData.balance_alert_threshold == data.balance_alert_threshold
                    ? <p> Minimum Balance : <b>{currencyFormatter(data.balance_alert_threshold || 0, 'IDR')}</b></p>
                    : <p> Minimum Balance : <s className='text-danger'>{currencyFormatter(rawData.balance_alert_threshold, 'IDR')}</s> → <span className='text-primary'>{currencyFormatter(data.balance_alert_threshold, 'IDR')}</span></p>
                }
                {arraysAreEqual
                    ? <p> Alert Email List :
                        <b>
                            <ul>
                                {sortedRawData.map((item, index) => (
                                    <li key={index}>{findTitleById(item)}</li>
                                ))}
                            </ul>
                        </b>
                    </p>
                    : <p> Alert Emails :
                        <div className='d-flex flex-row gap-2'>
                            <s className='text-danger'>
                                <ul>
                                    {sortedRawData.map((item, index) => (
                                        <li key={index}>{findTitleById(item)}</li>
                                    ))}
                                </ul>
                            </s> →
                            <div className='text-primary'>
                                <ul>
                                    {sortedData.map((item, index) => (
                                        <li key={index}>{findTitleById(item)}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </p>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Submit</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
