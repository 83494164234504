import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";

import "chartist/dist/scss/chartist.scss";
import { Link, useParams } from "react-router-dom";
import TransactionPeriodModal from "./modals/TransactionPeriodModal";
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import CountryFilter from "pages/CountryFilter";
//i18n
import { withTranslation } from "react-i18next";
import useAuth from 'hooks/useAuth';
import TransactionsTable from './TransactionsTable';
import { useLocation } from "react-router-dom";
import useTransaction from "hooks/useTransaction";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { balanceFormatter, simplify, getFormattedUTCDate } from "helpers/functions";

import totalTransactions from "../../../assets/images/services-icon/01.png";
import ucoPerDayLogo from "../../../assets/images/services-icon/deposit-per-minute.png";
import totalUcoLogo from "../../../assets/images/services-icon/uco-collected.png";
import ucoPerBoxLogo from "../../../assets/images/services-icon/deployed-boxes.png";
const Transactions = () => {
  const { id } = useParams();
  const axiosApi = useAxiosPrivate();
  const { auth } = useAuth();
  const [refresh, setRefresh] = useState('');
  const [menu, setMenu] = useState(false);
  const [transDatas, setTransDatas] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const { expandedRows, setExpandedRows, rejectedClicked, bonusClicked } = useTransaction();
  const [idParamStatus, setIdParamStatus] = useState(id ? false : true)
  const [selectedCurrency, setSelectedCurrency] = useState('IDR');

  const [totalUco, setTotalUco] = useState(0);
  const [totalTrx, setTotalTrx] = useState(0);
  const [sortField, setSortField] = useState(null);

  const [totalUcoAmount, setTotalUcoAmount] = useState(0);
  const [ucoPerDay, setUcoPerDay] = useState(0);
  const [ucoPerBox, setUcoPerBox] = useState(0);

  const location = useLocation();
  const propsData = location.state;
  const now = new Date();
  now.setHours(0, 0, 0, 0)
  const sevenDaysAgo = new Date(now.getTime() - (6 * 24 * 60 * 60 * 1000))
  const startDate = getFormattedUTCDate(sevenDaysAgo)
  const endDate = getFormattedUTCDate(new Date())

  const [lazyState, setlazyState] = useState({
    searchText: propsData || id || '',
    periodName: '',
    startDate: startDate,
    endDate: endDate,
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  const [selectedPeriodName, setSelectedPeriodName] = useState('Last 7 Days');

  const toggle = () => {
    setMenu(!menu);
  };
  const getTrxOverview = async () => {
    try {
      setLoading(true)
      let queryParams = `skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;

      if (rejectedClicked) {
        queryParams += '&approved=false';
      }
      if (bonusClicked) {
        queryParams += '&method=bonus';
      }
      if (selectedCurrency){
        queryParams += '&currency=' + selectedCurrency;
      }

      const response = await axiosApi.get(`/api/admin/engineer/getTransaction/overview?${queryParams}`);
      const result = response.data.result;
      setUcoPerDay(response.data?.ucoPerDay || 0)
      setUcoPerBox(response.data?.ucoPerBox || 0)
      setTotalUco(response.data?.totalUCO || 0)
      setTotalTrx(response.data?.totalTransaction || 0)

      setTotalUcoAmount(response.data?.totalUCOAmount || 0)
      if (!idParamStatus) {
        if (result.length > 0) {
          setExpandedRows({ [result[0].TA_ID]: true })
          setIdParamStatus(true)
        }
      }

      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }
  const getTransactions = async () => {
    try {
      setLoading(true)
      let queryParams = `rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.searchText == '' ? lazyState.startDate : ''}&endDate=${lazyState.searchText == '' ? lazyState.endDate : ''}`;

      if (rejectedClicked) {
        queryParams += '&approved=false';
      }
      if (bonusClicked) {
        queryParams += '&method=bonus';
      }
      if (sortField) {
        queryParams += `&sorts=${sortField}`;
      }
      if (selectedCurrency) {
        queryParams += '&currency=' + selectedCurrency;
      }

      const response = await axiosApi.get(`/api/admin/engineer/getTransaction?${queryParams}`);
      const result = response.data.result;

      setTransDatas(result);
      if (!idParamStatus) {
        if (result.length > 0) {
          setExpandedRows({ [result[0].TA_ID]: true })
          setIdParamStatus(true)
        }
      }
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setTotalRecords(response.data.totalRecords)
      } else {
        setTotalRecords(0)
      }
      if (propsData) {
        let obj = {};
        obj[propsData] = true
        setExpandedRows(obj)
      }

      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getTrxOverview();
  }, [lazyState, refresh, selectedCurrency]);

  useEffect(() => {
    setLoading(true);
    getTransactions();
  }, [lazyState, refresh, sortField, selectedCurrency]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Transactions`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/*<Breadcrumbs title="Support" breadcrumbItem="Transactions" />*/}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8} sm={12}>
                <h6 className="page-title">Transactions</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Support</a></li><li className="active breadcrumb-item" aria-current="page">Transactions</li></ol>
              </Col>

              <Col md={4} sm={12}>
                <div className="d-flex flex-wrap justify-content-end align-items-center w-100">
                  <CountryFilter selectedFilter={selectedCurrency} setSelectedFilter={setSelectedCurrency} field='currency' />
                  &nbsp;
                  <PaymentPeriodModal
                    mainOptions={lazyState}
                    setMainOptions={setlazyState}
                    selectedPeriodName={selectedPeriodName}
                    setSelectedPeriodName={setSelectedPeriodName}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <p>Overview</p>
          <Row>
            <Col lg={3} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={totalTransactions} alt="" />
                    </div>
                    <br></br>
                    <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                      Total&nbsp;Transactions
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalTrx}
                    </h4>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={totalUcoLogo} alt="" />
                    </div>
                    <br></br>
                    <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                      Total&nbsp;UCO
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {simplify(totalUco)} liter
                    </h4>
                    <h5>
                      ({balanceFormatter(totalUcoAmount ?? 0, selectedCurrency)})
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={ucoPerDayLogo} alt="" />
                    </div>
                    <span style={{ paddingTop: '4px', display: 'inline-block' }}>
                      <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                        UCO<br />per Day
                      </h5>
                    </span>
                    <h4 className="fw-medium font-size-24">
                      {simplify(ucoPerDay)} liter
                    </h4>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={ucoPerBoxLogo} alt="" />
                    </div>
                    <span style={{ paddingTop: '4px', display: 'inline-block' }}>
                      <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                        UCO<br />per Box
                      </h5>
                    </span>
                    <h4 className="fw-medium font-size-24">
                      {simplify(ucoPerBox)} liter
                    </h4>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <TransactionsTable transDatas={transDatas} id={propsData} trxId={id} totalRecords={totalRecords} lazyState={lazyState} setlazyState={setlazyState} loading={loading} setLoading={setLoading} setRefresh={setRefresh} refresh={refresh} setSortField={setSortField} />
        </div>
      </div>
    </React.Fragment>
  );
};

Transactions.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Transactions);
