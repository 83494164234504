import React, { lazy, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getFormattedUTCDate, balanceFormatter } from 'helpers/functions';

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import moment from "moment";
import { convertTimeFromUTC } from "helpers/functions";
import Units from "./Units";
import useUcoUnit from "hooks/useUcoUnit";
import PaymentPeriodModal from "pages/PaymentPeriodModal";
import { useLocation } from "react-router-dom";

const ucoUnits = () => {
    const location = useLocation();
    const propsData = location.state;

    const { refresh, setLoading, setUcoUnits } = useUcoUnit();
    const axiosApi = useAxiosPrivate();
    const [selectedPeriodName, setSelectedPeriodName] = useState('All Time');
    const today = new Date()
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    endOfMonth.setHours(23, 59, 59, 999)

    const startDate = getFormattedUTCDate(startOfMonth)
    const endDate = getFormattedUTCDate(endOfMonth)
    const [lazyState, setlazyState] = useState({
        id: '',
        boxId: '',
        searchText: propsData || '',
        startDate: '',
        endDate: '',
        config: {
            first: 0,
            rows: 50,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const getUnits = async () => {
        try {
            setLoading(true)
            const response = await axiosApi.get(`/api/erp/inventory/tank-uco-units?tank_id=${lazyState.id ?? null}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);

            const result = response.data.results;
            // console.log(response.data)
            setUcoUnits(result);
            if (response.data.total_records !== null && response.data.total_records !== undefined) {
                setTotalRecords(response.data.total_records)
            } else {
                setTotalRecords(0)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        getUnits();
    }, [refresh, lazyState]);

    document.title = `${process.env.REACT_APP_NAME || ''} | Overview`;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Users" breadcrumbItem="App User List" /> */}
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8} sm={12}>
                                <h6 className="page-title">UCO Units</h6>
                                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Inventory</a></li><li className="active breadcrumb-item" aria-current="page">UCO Units</li></ol>
                            </Col>

                            <Col md={4} sm={12}>
                                <div className="float-md-end d-sm-block">
                                    <PaymentPeriodModal
                                        mainOptions={lazyState}
                                        setMainOptions={setlazyState}
                                        selectedPeriodName={selectedPeriodName}
                                        setSelectedPeriodName={setSelectedPeriodName}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Units lazyState={lazyState} setlazyState={setlazyState} totalRecords={totalRecords} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ucoUnits;
