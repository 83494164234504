import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';

import { Button as PButton, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Button } from 'primereact/button';
import '../../assets/css/style.css'

const ExportFilterModal = ({ onConfirm, totalRecords, isExport, anonymous = false }) => {
    const [fieldList, setFieldList] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [selectedAnonymous, setSelectedAnonymous] = useState(true)
    const tog_center = () => {
        setmodal_center(!modal_center);
        setFieldList([]);
    };


    const headers = [
        { key: 'uco_unit.external_transaction_code', label: 'Transaction ID' },
        { key: 'box_name', label: 'Origin Box ID' },
        { key: 'uco_unit.fullname', label: 'User Full Name' },
        { key: 'uco_unit.username', label: 'Username' },
        { key: 'uco_unit.weight', label: 'Accepted Weight (kg)' },
        { key: 'uco_unit.external_transaction_enddate', label: 'End Time' },
        { key: 'uco_unit.address', label: 'Address' },
        { key: 'coordinates', label: 'Coordinates' },
        { key: 'uco_unit.postcode', label: 'Post Code' },
        { key: 'uco_unit.city', label: 'City' },
        { key: 'uco_unit.country', label: 'Country' },
    ];

    const updateFieldList = (value) => {
        setFieldList(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
        handleSelectAllValue();
    };
    const handleSelectAllValue = () => {
        var status = true;
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                status = false;
            }
        });
        const selectAllCheckbox = document.getElementById('select-all');
        selectAllCheckbox.checked = status;
    };
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;

        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
            updateFieldList(checkbox.value);
        });

    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { fields: fieldList, isAnonymous: selectedAnonymous }
                onConfirm({ data: opts, exportHeader: headers });
                tog_center();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });
    return (<>
        <Button
            type="button"
            icon={`mdi mdi-24px ${isExport === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
            rounded
            disabled={totalRecords === 0}
            tooltip="Export as CSV"
            tooltipOptions={{ position: 'top' }}
            onClick={tog_center}
        />
        <Modal isOpen={modal_center} toggle={tog_center} centered>
            <ModalHeader toggle={tog_center}>Export Options</ModalHeader>
            <ModalBody>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="mb-3">
                        <Label className="form-label">Export Field</Label>
                        <FormGroup check>
                            <Input
                                id="select-all"
                                name="select-all"
                                type="checkbox"
                                onChange={handleSelectAll}
                            />
                            <Label htmlFor="select-all">Select All</Label>
                        </FormGroup>

                        <FormGroup className="d-flex" style={{ flexDirection: 'column' }}>
                            {headers?.map((header) => (
                                <FormGroup check key={header.key}>
                                    <Input id={header.key} name="header[]" type="checkbox" value={`${header.key}`} onChange={(e) => {
                                        const clickedValue = e.target.value;
                                        updateFieldList(clickedValue);
                                    }} />
                                    <Label htmlFor={header.key}>{header.label}</Label>
                                    {validation.touched.header && validation.errors.header ? (
                                        <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                                    ) : null}
                                </FormGroup>

                            ))}
                        </FormGroup>

                    </div>
                    {anonymous &&
                        <div className="mb-3">
                            <Label className="form-label">Anonymous</Label><br />
                            <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                        </div>
                    }
                    {/* Submit Button */}
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <PButton type="submit" color="primary" disabled={validation.isSubmitting || fieldList.length === 0}>
                            Submit
                        </PButton>{" "}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    </>

    )
}

export default ExportFilterModal
