import { useEffect, useState, useRef } from "react";
import {
    // Dropdown,
    // DropdownItem,
    // DropdownMenu,
    // DropdownToggle,
    Button,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    FormGroup,
    Row,
    Col,
    InputGroup,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import { Dropdown } from 'primereact/dropdown';

import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import useAdmin from "hooks/useAdmin";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import "../../../../assets/css/style.css";
import useAuth from "hooks/useAuth";
import { TON_TO_LITER } from "constants/value";
import { simplify } from "helpers/functions";
import Select from "react-select";

const AddTransaction = ({ refresh, setRefresh }) => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const [username, setUsername] = useState("");
    const [weight, setWeight] = useState(0);
    const [volume, setVolume] = useState(0);
    const [amount, setAmount] = useState(0);
    const [accepted, setAccepted] = useState(true);
    const [notify, setNotified] = useState(true);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedBox, setSelectedBox] = useState("");

    const [results, setResults] = useState([]);
    const [boxes, setBoxes] = useState([]);

    const [loading, setLoading] = useState(false);
    const { showToast } = useAuth();
    const [showResults, setShowResults] = useState(false);

    const searchUser = async (username) => {
        try {
            setLoading(true);
            const response = await axiosApi.get(
                `/api/admin/support/search-user?username=${username}`
            );
            // console.log(response.data);

            setResults(response.data.result);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getBoxes = async () => {
        try {
            const response = await axiosApi.get("/api/admin/engineer/getActiveBoxes");
            const mappedBoxes = response.data.map(box => ({ label: box.name, value: box.name }));
            setBoxes(mappedBoxes);
            if (mappedBoxes.length > 0) {
                setSelectedBox(mappedBoxes[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBoxes();
    }, [refresh]); // Add empty dependency array to ensure useEffect runs only once

    const conversionFactor = TON_TO_LITER / 1000;

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        resetModalForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const handleUsername = async (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if (value) {
            await searchUser(value);
            setShowResults(true);
        } else {
            setShowResults(false);
        }
        setUsername(e.target.value);
    };

    const handleAmount = async (e) => {
        validation.handleChange(e);
        setAmount(e.target.value);
    };

    const handleUsernameBlur = (e) => {
        validation.handleBlur(e);
        setTimeout(() => {
            setShowResults(false);
        }, 150);
    };

    const handleResultClick = (user, e) => {
        e.stopPropagation();
        setUsername(user.username);
        setShowResults(false);
    };

    const handleConversionWeightToVolume = (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if (value !== "") {
            const weightInput = parseFloat(value);
            setWeight(weightInput);
            setVolume(simplify(weightInput * conversionFactor, 3));
        } else {
            setWeight(0);
            setVolume(0);
        }
    };

    const handleConversionVolumeToWeight = (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        if (value !== "") {
            const volumeInput = parseFloat(value);
            setVolume(volumeInput);
            setWeight(simplify(volumeInput / conversionFactor, 3));
        } else {
            setWeight(0);
            setVolume(0);
        }
    };

    const resetModalForm = () => {
        setUsername("");
        setVolume(0);
        setWeight(0);
        setAmount(0);
        setAccepted(true);
        setNotified(true);
    };

    const handleSelectChange = (e) => {
        setSelectedBox(e.name);
    };

    const changeStatus = (e) => {
        setSelectedBox(e);

    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: username,
            weight: String(weight),
            volume: String(volume),
            amount: String(amount),
            box: selectedBox,
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required("This value is required"),
            // box: Yup.string().required("Please select a box"),
            weight: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
                .required("This value is required"),
            volume: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
                .required("This value is required"),
            amount: Yup.string(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                // Check if selectedBox is empty or not
                if (!selectedBox) {
                    validation.setErrors({ box: "Please select a box" });
                    return;
                }

                const opts = {
                    ...values,
                    volume: parseFloat(volume),
                    weight: parseFloat(weight),
                    accepted,
                    notify,
                    start: moment.utc().format(),
                    end: moment.utc().add(1, "minute").format(),
                    amount: parseFloat(amount),
                    box: selectedBox.value,
                };
                // console.log(opts);
                const response = await axiosApi.post('/api/admin/engineer/transaction/manual', opts);
                setRefresh(response.data.random);
                resetForm();
                tog_center();
                showToast(response.data);
                resetModalForm();
            } catch (error) {
                // console.log(error);
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        },
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    Add Manual Transaction
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    Add Manual Transaction
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="mb-3 position-relative">
                            <Label className="form-label">Username</Label>
                            <InputGroup>
                                <Input
                                    name="username"
                                    placeholder="Enter Username"
                                    type="text"
                                    autoComplete="off"
                                    onChange={handleUsername}
                                    onBlur={handleUsernameBlur}
                                    value={validation.values.username || ""}
                                    invalid={
                                        validation.touched.username && validation.errors.username
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.username}
                                    </FormFeedback>
                                ) : null}
                            </InputGroup>
                            {loading ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    style={{ top: "100%", zIndex: 1 }}
                                >
                                    <ListGroupItem key={"load"} className="d-flex">
                                        <ProgressSpinner
                                            style={{ width: "30px", height: "30px" }}
                                            strokeWidth="2"
                                            fill="var(--surface-ground)"
                                            animationDuration=".5s"
                                            className="align-self-center"
                                        />
                                    </ListGroupItem>
                                </ListGroup>
                            ) : showResults && results.length > 0 ? (
                                <ListGroup
                                    className="position-absolute w-100 list-group-container"
                                    role="button"
                                        style={{ top: "100%", zIndex: 1 }}
                                >
                                    {results.map((user) => (
                                        <ListGroupItem
                                            key={user._id}
                                            onClick={(e) => handleResultClick(user, e)}
                                            className="hover-list-item"
                                        >
                                            {user.username}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Box</Label><br />
                            <Select
                                value={selectedBox}
                                onChange={changeStatus}
                                options={boxes} // Map the array of objects to an array of options
                                optionLabel="label"
                                classNamePrefix="select2-selection"
                            />
                            {/* <Dropdown
                                value={selectedBox}
                                onChange={changeStatus}
                                options={boxes} // Map the array of objects to an array of options
                                optionLabel="label"
                                className="h-1 payment-status-dropdown"
                            /> */}
                            {/* <Dropdown
                                isOpen={dropdownOpen}
                                toggle={() => setDropdownOpen(!dropdownOpen)}
                                className="mt-4 mt-sm-0"

                            >   <DropdownToggle className="btn btn-secondary" caret>
                                    {selectedBox ? selectedBox : "Select a box"} <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {boxes.map((box) => (
                                        <DropdownItem
                                            key={box.id}
                                            onClick={() => handleSelectChange(box)}
                                        >
                                            {box.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>

                            </Dropdown> */}
                            {validation.touched.box && validation.errors.box ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.box}
                                </FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Row>
                                <Col xs={6}>
                                    <Label className="form-label">Weight (kg)</Label>
                                    <Input
                                        name="weight"
                                        placeholder="Enter Weight"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleConversionWeightToVolume}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.weight || ""}
                                        invalid={
                                            validation.touched.weight && validation.errors.weight
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.weight && validation.errors.weight ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.weight}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col xs={6}>
                                    <Label className="form-label">Volume (liter)</Label>
                                    <Input
                                        name="volume"
                                        placeholder="Enter Volume"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleConversionVolumeToWeight}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.volume || ""}
                                        invalid={
                                            validation.touched.volume && validation.errors.volume
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.volume && validation.errors.volume ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.volume}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col xs={12}>
                                    <Label className="form-label">Bonus (optional)</Label>
                                    <Input
                                        name="amount"
                                        placeholder="Enter Amount Override"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleAmount}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                            validation.touched.amount && validation.errors.amount
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.amount}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                        <div className="mb-3 d-flex align-items-center gap-2">
                            <Label className="form-label m-0">Accepted :</Label>
                            <InputSwitch
                                checked={accepted}
                                onChange={(e) => setAccepted(e.value)}
                            />
                        </div>
                        <div className="mb-3 d-flex align-items-center gap-2">
                            <Label className="form-label m-0">Notify user :</Label>
                            <InputSwitch
                                checked={notify}
                                onChange={(e) => setNotified(e.value)}
                            />
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={validation.isSubmitting}
                            >
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddTransaction;
