const hoverStrings = {
    partnerAccountName: "The unique name for the partner's account.",
    entityType: "The type of business entity.",
    sectorType: "The industry or sector the partner operates in.",
    ktp: "The partner's ID card number.",
    npwp: "The partner's tax ID number.",
    legalName: "The registered legal name of the partner.",
    contactName: "The name of the main contact person.",
    streetAddress: "The partner's full street address.",
    country: "The country where the partner is located.",
    region: "The partner's province or region.",
    city: "The partner's city of operation.",
    postalCode: "The postal code for the partner's address.",
    contactEmail: "The email address of the main contact person.",
    contactPhoneNumber: "The phone number of the main contact person.",
    sustainabilityReportEmail: "The email address to send sustainability reports to.",
    monthlyReportEmail: "The email address to send monthly reports to.",
  
    types: "The program type the partner is enrolled in.",
    language: "The language used for partner program communication.",
    username: "The app account username used by the partner.",
    fixedPrice: "Enable or disable fixed pricing for this partner. When enabled, the partner will have a unique UCO price different from the global pricing.",
    fixedPricePerLiter : "Enter the amount of fixed UCO price for this partner",
    expectedMonthlyVolume: "The expected volume of UCO the partner will submit each month.",
    directPayment: "If enabled, automatically withdraws the partner's balance when eligible (meets the minimum withdrawal limit).",
    box: "The box assigned to this partner.",
    compensation: "The type of compensation used to calculate the partner's earnings.",
    fixedPricePerMonth: "The fixed amount paid to the partner each month for hosting a box.",
    shareTier: "The percentage share the partner earns, which may vary based on the amount of UCO collected.",
  
    countryPayment: "The country of the payment method receiving the partner's payments.",
    bankName: "The name of the bank where the payment method is registered.",
    bankAccountNumber: "The bank account number associated with the payment method.",
    bankAccountName: "The name of the bank account holder."
  };

  module.exports = {hoverStrings};