import React, { useEffect, useState } from "react";
import AdminTable from "./AdminTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setAdmins, setLoading } = useAdmin();
  const { auth } = useAuth();

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController;

    const getAdmins = async () => {
      try {
        const response = await axiosApi.get('/api/admin/getAdmins', {
          signal: controller.signal,
        });
        setLoading(false);
        isMounted && setAdmins(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    getAdmins();

    return () => {
      isMounted = false,
        controller.abort;
    }
  }, [refresh]);

  return (
    <React.Fragment>
          <AdminTable />
    </React.Fragment>
  );
};

export default AdminList;
