import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import { post, del, get, put } from "../../../helpers/api_helper";
import useAdmin from "hooks/useAdmin";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import '../../../assets/css/style.css'
import useAuth from "hooks/useAuth";

const AddUserModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { showToast } = useAuth();

 


    return (
        <>
        <div className="my-2">
            <Button color="danger" disabled={true}>Disabling 2FA not supported</Button>
        </div>
      
        </>
    )
    
}

export default AddUserModal
