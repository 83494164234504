import axiosApi from "helpers/api_helper";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosApi.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']) {
                    config.headers['Authorization'] = auth.accessToken;
                }
                return config;
            }, (error) => Promise.reject(error)
        )
        const responseIntercept = axiosApi.interceptors.response.use(
            response => response,
            async (error) => {
                console.log(error);
                const prevRequest = error?.config;
                if(error?.response?.status === 403 && !prevRequest?.sent) {
                    console.log('sending refresh token')
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = newAccessToken;
                    return axiosApi(prevRequest);
                } 
                return Promise.reject(error)
            }
        );

        return () => {
            axiosApi.interceptors.request.eject(requestIntercept);
            axiosApi.interceptors.response.eject(responseIntercept);
        }
    
    }, [auth, refresh])
    

    return axiosApi;
}

export default useAxiosPrivate;