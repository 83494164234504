import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, statusUco } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";
import UnitExportCSV from "../exports/UnitExportCSV";
import TrackingModal from "./TrackingModal";
import useAuth from "hooks/useAuth";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import ExportFilterModal from "pages/eRP/ExportFilterModal";
const DetailModal = ({ data }) => {
    const title = `UCO Units | ${data.tank_code}`;
    const { showToast } = useAuth();
    const today = new Date();
    const month = today.getMonth() + 1; // Months are zero-indexed
    const day = today.getDate();
    const year = today.getFullYear();

    // Pad month and day with leading zeros if needed
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    const initialLazyState = {
        searchText: '',
        filterDate: '',
        config: {
            first: 0,
            rows: 50,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [datas, setDatas] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);
    const [filterDate, setFilterDate] = useState('');

    let numberCount = data.capacity ?? 0;

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const [headerFilter, setHeaderFilter] = useState(null);
    const [isExport, setIsExport] = useState(false);

    const handleConfirm = async ({ data, exportHeader }) => {
        const headers = data.fields;

        const filteredHeaders = exportHeader.filter(header =>
            headers.includes(header.key)
        );
        const result = {
            headers: filteredHeaders,
            isAnonymous: data.isAnonymous
        }

        setIsExport(true)
        setHeaderFilter(result)
        // setIsExport(false)
    }
    const roundedVol = (data) => {
        return simplify(data.volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.weight)
    }
    const ucoUnitId = (data) => {
        return <TrackingModal rowData={data} unitIdParam={data.uco_unit_code} />
    }
    const fullName = (data) => {
        return <Link to={`/userlist`} state={data?.uco_unit?.fullname} >
            {data?.uco_unit?.fullname}
        </Link>
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const startTime = (data) => {
        return moment(data?.uco_unit?.external_created_date).format("MM/DD/YYYY HH:mm:ss")
    }
    const columns = [
        { field: '', header: '#', body: incrementId, className: '' },
        { field: '', header: 'UCO Unit ID', body: ucoUnitId, className: '' },
        // { field: 'volume', header: 'Volume (ltr)', body: roundedVol, className: 'text-end' },
        { field: 'weight', header: 'Quantity (kg)', body: roundedWeight, className: 'text-end' },
        // { field: '', header: 'Box ID', body: boxLink, className: '' },
        // { field: '', header: 'Collected Date', body: startTime, className: '' },
        { field: '', header: 'Status', body: statusUco, className: '' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }


    const getData = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/erp/inventory/get-uco-units/by-tank?tank_id=${data?.id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&date=${lazyState.filterDate == '' ? formattedDate : lazyState.filterDate}`);
            const result = response.data.results;
            setDatas(result);
            setTotalRecords(response.data.total_records || 0)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            setLoading(true);
            setTimeout(() => {
                getData()
            }, 300);
        };
    }, [modal_center, lazyState]);

    return (
        <>
            {
                numberCount > 0 ?
                    <span style={{ cursor: 'pointer' }} className="text-primary" onClick={tog_center}>
                        {numberCount}
                    </span>
                    :
                    numberCount
            }
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">{title}</h4>

                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <Row className="align-items-center mb-2">
                        <Col lg={6} md={1}>
                        </Col>
                        <Col lg={6} md={11} className="d-flex flex-row align-items-center gap-3">
                            <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    onChange={value => {
                                        if (value.length > 0) {
                                            const dateObject = new Date(value[0]);
                                            // Extracting year, month, and day
                                            const year = dateObject.getFullYear();
                                            // JavaScript months are zero-based, so we add 1
                                            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                                            const day = dateObject.getDate().toString().padStart(2, '0');

                                            // Forming the desired date string
                                            const formattedDate = `${month}/${day}/${year}`;
                                            const rawDate = `${year}-${month}-${day}`
                                            setFilterDate(formattedDate)
                                            let _filters = { ...lazyState };
                                            _filters.filterDate = rawDate;
                                            setlazyState(_filters)

                                        } else {
                                            setFilterDate('')
                                            let _filters = { ...lazyState };
                                            _filters.filterDate = '';
                                            setlazyState(_filters)

                                        }
                                    }}
                                    placeholder="Filter by Date"
                                    options={{
                                        altInput: true,
                                        maxDate: "today",
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y"
                                    }}
                                />
                            </InputGroup>
                            <span className="p-input-icon-left float-start float-md-end">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={onSearchTextChange} placeholder="Search" className='p-inputtext-sm user-filter' style={{ 'width': '210px' }} />
                            </span>
                            <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                <ExportFilterModal
                                    onConfirm={handleConfirm}
                                    totalRecords={totalRecords}
                                    anonymous={true}
                                    isExport={isExport}
                                />
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        lazy
                        value={loading ? items : datas}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"

                        // expandedRows={loading ? null : expandedRows}
                        // rowExpansionTemplate={rowExpansionTemplate}
                        // onRowToggle={onRowToggle}
                        dataKey={loading ? '' : 'id'}
                    >
                        {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                    {headerFilter && isExport && <UnitExportCSV lazyState={lazyState} data={data} datas={headerFilter} isExport={isExport} setIsExport={setIsExport} />}

                </ModalBody>
            </Modal>
        </>
    )
}

export default DetailModal
