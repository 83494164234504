import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
const DataExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Volume_Partner_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`
   
    const headers = [
        { key: 'partner_account', label: 'Account Name', body: '', className: '' },
        { key: 'user.username', label: 'App Username'},
        { key: 'fixed_price', label: 'Price' },
        { key: 'monthly_volume', label: 'Month Volume (Actual / Exp.)'  },
        { key: 'total_collected_volume', label: 'Total Collected Volume' },
        { key: 'direct_payment', label: 'Direct Payment'},
        { key: 'bonus_promotion', label: 'Bonus & Promotions'},
    ]
   
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
            const response = await axiosApi.get(`/api/admin/partner-volume?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.monthly_volume = (obj.collected_volume ?? 0) + " / " + (obj.expected_volume ?? 0);
                obj.total_collected_volume = (obj.total_collected_volume ?? 0);
                obj.direct_payment = obj.direct_payment_enabled ? "ENABLED" : "DISABLED";;
                obj.bonus_promotion = obj.bonus_enabled ? "Yes" : "No";;
            });
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV