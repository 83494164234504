import { useState, useRef, useCallback } from "react";
import { Dropdown } from "primereact/dropdown"

import '../../../assets/css/style.css'

const ReportTable = ({ rowData }) => {
    const reportTypes = [
        { name: "Monthly", value: "monthly" },
        { name: "Sustainability", value: "sustainability" },

    ]
    const [selectedReportType, setSelectedReportType] = useState("monthly")
    const tog_center = () => {
    const url = selectedReportType === "monthly"
        ? rowData.download_link?.monthly
        : rowData.download_link?.sustainability;

    if (url) {
        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        
        // Set the download attribute to specify the filename (optional)
        link.download = url.split('/').pop();  // Use the file name from the URL or provide your own
        
        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);
        
        // Trigger the click event
        link.click();
        
        // Remove the link element after the download starts
        document.body.removeChild(link);
    } else {
        alert("Download URL is not available");
    }
};
    const changeReportType = e => {
        setSelectedReportType(e.value)
    }
    return (
        <div>
            <Dropdown
                value={selectedReportType}
                onChange={changeReportType}
                options={reportTypes}
                optionLabel="name"
                className="h-1 payment-status-dropdown me-2"
            />
            <a
                className={`btn btn-primary ${(selectedReportType === "monthly" && !rowData.download_link?.monthly) ||
                        (selectedReportType === "sustainability" && !rowData.download_link?.sustainability)
                        ? 'disabled' : ''
                    }`}
                color="primary"
                onClick={(e) => {
                    if (
                        (selectedReportType === "monthly" && !rowData.download_link?.monthly) ||
                        (selectedReportType === "sustainability" && !rowData.download_link?.sustainability)
                    ) {
                        e.preventDefault(); // Prevent the click event when disabled
                        return;
                    }
                    tog_center();
                }}
                style={{
                    textAlign: "left", cursor: (selectedReportType === "monthly" && !rowData.download_link?.monthly) ||
                        (selectedReportType === "sustainability" && !rowData.download_link?.sustainability)
                        ? 'not-allowed' : 'pointer'
                }}
            >
                <span className="fas fa-download"></span>
            </a>
        </div>
    )
}

export default ReportTable