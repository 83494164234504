import { useState } from "react";
import { Button,Row,Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import useBox from "hooks/useBox";

import makeid from 'helpers/random';
import { INITIAL_OPENING_HOURS } from "constants/value";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { set } from "lodash";

const RemoteBoxModal = ({ modal_center, tog_center, setVolume, setWeight, setSeries }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { box, setRefresh, setBox, toggleRightCanvas, setSelectedMode, setBoxImages, transaction, setPin, filterTransaction, setOpeningHoursArray } = useBox();
    
    //why do we need this? because the state inside an array doesnt work
    //more spaghetti code yay
    const [buttonState0, setButtonState0] = useState(false);
    const [buttonState1, setButtonState1] = useState(false);
    const [buttonState2, setButtonState2] = useState(false);
    const [buttonState3, setButtonState3] = useState(false);
    //end of spaghetti code. we need to add another state for every button. yikes

    //thanks react making thing complicated. I JUST WANT TO HANDLE BUTTON DISABLE. this should be easy. i wish i used svelte
    const updateButtonDisabledState = (buttonIndex, state) => {
        //this should be unnecessary if react can work properly
        //NOW THE CODE CAN EASILY BREAK. AND LOOK VERY SPAGGHETTI
        //adding another button will need to add LOT OF CODE 
        //not very scalable at all
        //hopefully we wont add many button in the future or i found a better solution

        switch (buttonIndex) {
            case 0:
                setButtonState0(state);
                break;
            case 1:
                setButtonState1(state);
                break;
            case 2:
                setButtonState2(state);
                break;
            case 3:
                setButtonState3(state);
                break;
            default:
                break;
        }
    }

    const confirmRemoteBox = (action, buttonIndex) => {
        //i know this look ugly. but only iot guys like langgam and marzuqi who probably will use this 
        //react is unnecessarily complicated. i dont want to spend time just on this . even figuring out the button disable issue TOOK HORS
    
        //summon ugly browser confirmation alert
        if (window.confirm(`Are you sure you want to ${action} the box?`)) {
            remoteBox(action, buttonIndex);
        }
    }

    const remoteBox = async (action,buttonIndex) => {
        var currentBS = [];

        try {
            //handle disable button
            //it is 1 line after a function with spaghetti on it
            updateButtonDisabledState(buttonIndex, true);

            const response = await axiosApi.post(`/api/admin/engineer/box/remote`, { id: box?.id,action:action,data:null });    
            showToast(response);

        } catch (error) {
            console.log(error);
        }
        //enable button after 5 second
        setTimeout(() => {
           
            updateButtonDisabledState(buttonIndex, false);
         
        }, 5000);
    }

    const boxStatus = () => {
        return <>
        <span className={"success-text"} >Connected</span>
        </>
    }

    return (
        <>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Remote {box?.name}</ModalHeader>
                <ModalBody>
                    {/* <b>Box Status : {boxStatus()}</b>
                    <hr></hr> */}
                     
                   
                        <Row>
                            <Col xs={6} sm={4}>
                            <Button  className="w-100 mb-2" type="button" disabled={buttonState0 } onClick={() => confirmRemoteBox("restart",0)} color="primary" >
                               Restart
                            </Button> 
                            <span class="text-muted mb-2">
                                Restart the Box Frontend (Chromium) and Backend (Node) Process
                            </span>
                   
                            </Col>
                            <Col xs={6} sm={4}>
                            <Button className="w-100 mb-2" type="button" disabled={buttonState1 } onClick={() => confirmRemoteBox("update",1)} color="primary" >
                               Update
                            </Button> 
                            <span class="text-muted mb-2">
                               Update the box code to the latest Backend code and do a Restart
                            </span>
                            </Col>
                            <Col xs={6} sm={4}>
                            <Button className="w-100 mb-2" type="button" disabled={buttonState2 } onClick={() => confirmRemoteBox("resetenv",2)} color="danger" >
                               Reset ENV
                            </Button> 
                            <span class="text-muted mb-2">
                               Reset the Environment Configuration. Used to switch between environment or Box Number
                            </span>
                            </Col>
                            <Col xs={6} sm={4}>
                            <Button className="w-100 mb-2" type="button" disabled={buttonState3 } onClick={() => confirmRemoteBox("reboot",3)} color="warning" >
                               Reboot
                            </Button> 
                            <span class="text-muted mb-2">
                               Hard Reboot the machine (Raspberry Pi)
                            </span>
                            </Col>                          
                        </Row>
                        {/* <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="button" onClick={!tog_center} color="secondary" >
                               Close
                            </Button>{" "}
                        </div> */}
                </ModalBody>
            </Modal>
        </>
    )
}

export default RemoteBoxModal
