import { createContext, useState } from "react";

const UcoUnitContext = createContext({});

export const UcoUnitProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [loading, setLoading] = useState(false);
    const [ucoUnits, setUcoUnits] = useState(null);
    return (
        <UcoUnitContext.Provider value={{
            refresh, setRefresh, loading, setLoading, ucoUnits, setUcoUnits
        }}>
            {children}
        </UcoUnitContext.Provider>
    )
}

export default UcoUnitContext;