import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import usePermission from "hooks/usePermission";
import makeid from 'helpers/random';

const DeletePermissionModal = ({ permission }) => {
    const { showToast } = useAuth();
    const { setRefresh } = usePermission();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: permission._id,
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await del('/api/admin/permission/' + permission._id);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                console.log(error);
            }
        }
    });


    return (
        <>
        <div className="my-2">
            <Button color="danger" onClick={tog_center}>
                <i className="fas fa-trash"></i> 
            </Button>
        </div>
        <Modal isOpen={modal_center} toggle={tog_center} centered>
            <ModalHeader className="mt-0" toggle={tog_center}>Delete Permission</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <h5>Are you sure want to delete this permission ?</h5>    
                    <div className="mb-3">
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button type="submit" color="danger" >
                            Delete
                        </Button>{" "}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
        </>
    )
    
}

export default DeletePermissionModal
