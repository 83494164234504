import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup } from "reactstrap"
import moment from "moment";
import Select from "react-select"

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from 'primereact/dropdown';

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { InputSwitch } from 'primereact/inputswitch';

import '../../../../assets/css/style.css'
import usePromotion from "hooks/usePromotion";

const EditReferralModal = ({ referral }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { setReferralRefresh, countryList, setPromotionRefresh, promotionRefresh, partnerRefresh } = usePromotion();
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const [selectedCurrency, setSelectedCurrency] = useState(referral.currency);
    const [expiredDate, setExpiredDate] = useState(referral.expired_date ? formatDate(referral.expired_date) : null);
    function formatDate(dateString) {
        const dateObject = new Date(dateString);
        // Extracting year, month, and day
        const year = dateObject.getFullYear();
        // JavaScript months are zero-based, so we add 1
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        // Forming the desired date string
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
    }
    const [selectedPartner, setSelectedPartner] = useState('');
    const [partnerList, setPartnerList] = useState([]);

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const getPartners = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/marketing/partner`);
            var partners = []

            if (response.data.result)
                response.data.result.forEach(element => {
                    var partner = {
                        ...element,
                        value: element._id,
                        label: element.name,
                    }
                    partners.push(partner)
                })
            setPartnerList(partners);
            if (partners.length > 0) {
                setSelectedPartner(partners[0])
                for (let idx = 0; idx < partners.length; idx++) {
                    if (partners[idx]._id == referral.partner) {
                        setSelectedPartner(partners[idx])
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const changePartner = (e) => {
        setSelectedPartner(e.value)
    }
    useEffect(() => {
        if(modal_center){
            getPartners();
        }
    }, [promotionRefresh, partnerRefresh, modal_center]);
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: referral.name,
            limit: referral.limit ?? 0,
            code: referral.code
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            limit: Yup.number()
                .required("This value is required"),
            code: Yup.string().required(
                "This value is required"
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                let expiredDateResult = null
                if (expiredDate) {
                    let dateObject = new Date(expiredDate);
                    dateObject.setHours(23, 59, 59, 999);
                    expiredDateResult = dateObject.toISOString();
                }

                const opts = {
                    ...values, currency: selectedCurrency,
                    country: selectedCurrency == 'IDR' ? 'ID' : 'SG',
                    partner: selectedPartner._id,
                    expired_date: expiredDateResult,
                };

                // console.log(opts);
                const response = await put('/api/admin/marketing/referral/' + referral._id, opts);
                setReferralRefresh(response.random);
                setPromotionRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Referral</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>

                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Referrer</Label>
                            <div className="col">
                                <Select
                                    name={`partner`}
                                    value={selectedPartner || ""}
                                    onChange={value => {
                                        setSelectedPartner(value)
                                    }}
                                    options={partnerList}
                                    optionLabel="name"
                                    classNamePrefix="select2-selection"
                                />
                                {/* <Dropdown
                                    value={selectedPartner || ''}
                                    onChange={changePartner}
                                    options={partnerList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                /> */}
                            </div>
                            {validation.touched.partner && validation.errors.partner ? (
                                <FormFeedback type="invalid">{validation.errors.partner}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Code</Label>
                            <Input
                                name="code"
                                placeholder="Enter Code"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                    validation.touched.code && validation.errors.code ? true : false
                                }
                            />
                            {validation.touched.code && validation.errors.code ? (
                                <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Limit (0 for no limit)</Label>
                            <Input
                                name="limit"
                                placeholder="Enter Limit"
                                min={0}
                                type="number"
                                style={{ width: '200px' }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.limit}
                                invalid={
                                    validation.touched.limit && validation.errors.limit ? true : false
                                }
                            />
                            {validation.touched.limit && validation.errors.limit ? (
                                <FormFeedback type="invalid">{validation.errors.limit}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3 pr-2">
                            <Label className="form-label">Country</Label>
                            <div className="col">
                                <Dropdown
                                    value={selectedCurrency || ''}
                                    onChange={event => {
                                        setSelectedCurrency(event.target.value)
                                    }}
                                    options={countryList}
                                    optionLabel="label"
                                    className="h-1 payment-status-dropdown"
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Expiring date (leave empty for no expiration)</Label>
                            <br />
                            <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    value={expiredDate}
                                    onChange={value => {
                                        if (value.length > 0) {
                                            const dateObject = new Date(value[0]);
                                            // Extracting year, month, and day
                                            const year = dateObject.getFullYear();
                                            // JavaScript months are zero-based, so we add 1
                                            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                                            const day = dateObject.getDate().toString().padStart(2, '0');

                                            // Forming the desired date string
                                            const formattedDate = `${month}/${day}/${year}`;
                                            setExpiredDate(formattedDate)

                                        } else {
                                            setExpiredDate('')

                                        }
                                    }}
                                    placeholder="m/d/Y"
                                    options={{
                                        altInput: true,
                                        altFormat: "m/d/Y",
                                        dateFormat: "m/d/Y"
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditReferralModal
