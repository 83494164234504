import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, TabContent, TabPane } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import deleteIcon from "../../../../../assets/images/delete-icon.png";
import axiosApi from "helpers/api_helper";

import useAdmin from "hooks/useAdmin";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import { Divider } from "primereact/divider";
import useEducard from "hooks/useEducard";
import makeid from "helpers/random";
import { availableLanguageList } from "common/languages";

const AddContentSectionModal = ({ section }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('');
    const { setRefresh, levelOptions } = useEducard();
    const { showToast } = useAuth();
    const [status, setStatus] = useState(true);
    const [activeLang, setActiveLang] = useState('id'); // Default active language is 'en'

    const toggleLanguage = (lang) => {
        setActiveLang(lang);
    };
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const isContentEmpty = (lang) => {
        return !validation.values[`title_${lang}`] || !validation.values[`description_${lang}`]; // Check if the content for this language is empty
    };
    // Generate dynamic initial values for titles
    const generateInitialValues = (languages) => {
        const initialValues = {
            order: '',
            title_color: '#70AD47',
            image: ''
        };

        languages.forEach((lang) => {
            initialValues[`title_${lang.lang}`] = ''; // Add title fields dynamically
            initialValues[`description_${lang.lang}`] = '';
        });

        return initialValues;
    };

    // Generate dynamic validation schema for titles
    const generateValidationSchema = (languages) => {
        const shape = {
            order: Yup.number()
                .transform((value) =>
                    isNaN(value) || value === null || value === undefined ? 0 : value
                )
                .moreThan(0, 'Order must be greater than 0')
                .required('This value is required'),
        };

        languages.forEach((lang) => {
            shape[`title_${lang.lang}`] = Yup.string().required('This value is required'); // Add validation for each language title
            shape[`description_${lang.lang}`] = Yup.string().required('This value is required');
        });

        return Yup.object().shape(shape);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const MAX_FILE_SIZE = 1024000; //100KB

    const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

    function isValidFileType(fileName, fileType) {
        return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
    }
    const deleteImage = (e) => {
        setPreview('');
        setSelectedFile(null)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: generateInitialValues(availableLanguageList),
        validationSchema: generateValidationSchema(availableLanguageList),
        onSubmit: async (values, { resetForm }) => {

            try {
                const formData = new FormData();
                formData.append('section_id', section._id);
                formData.append('order', values.order);
                formData.append('title_color', values.title_color);
                formData.append('status', status == true ? 1 : 0);
                availableLanguageList.map((language, index) => {

                    formData.append(`contents[${index}][language]`, language.lang);
                    formData.append(`contents[${index}][title]`, values[`title_${language.lang}`]);
                    formData.append(`contents[${index}][description]`, values[`description_${language.lang}`]);
                });

                // Append image file to the FormData
                if (selectedFile) {
                    formData.append('image', selectedFile); // Assuming 'image' is the name of the file input field
                }

                const response = await axiosApi.post('/api/admin/education/education-content', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })


                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response.data);
                setPreview('');
                setSelectedFile(null)
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Content </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Content</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div>
                            {/* Language selection buttons */}
                            <div className="mb-3">
                                {availableLanguageList.map((language) => (
                                    <Button
                                        key={language.lang}
                                        color={activeLang === language.lang || !isContentEmpty(language.lang) ? 'primary' : 'secondary'} // Grey if empty, primary if has content
                                        onClick={() => toggleLanguage(language.lang)}
                                        style={{ marginRight: 5, filter: isContentEmpty(language.lang) ? 'grayscale(100%)' : 'grayscale(0%)' }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={language.flagUrl}
                                                alt={`${language.label} flag`}
                                                style={{
                                                    marginRight: 5,
                                                    height: '10px', // Set a fixed height for the flag image
                                                    width: 'auto'   // Maintain aspect ratio
                                                }}
                                            />
                                            <span style={{ lineHeight: '20px' }}>{language.label}</span> {/* Match line height to image height */}
                                        </div>
                                    </Button>
                                ))}
                            </div>

                            {/* Input fields for each language */}
                            <TabContent activeTab={activeLang}>
                                {availableLanguageList.map((language) => (
                                    <TabPane tabId={language.lang} key={language.lang}>
                                        <div className="mb-3">
                                            <Label className="form-label">{`${language.label} Title`}</Label>
                                            <Input
                                                name={`title_${language.lang}`}
                                                placeholder={`Enter ${language.label} Title`}
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[`title_${language.lang}`] || ""}
                                                invalid={
                                                    validation.touched[`title_${language.lang}`] && validation.errors[`title_${language.lang}`]
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched[`title_${language.lang}`] && validation.errors[`title_${language.lang}`] ? (
                                                <FormFeedback type="invalid">{validation.errors[`title_${language.lang}`]}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Description</Label>
                                            <Input
                                                name={`description_${language.lang}`}
                                                placeholder={`Enter ${language.label} Description`}
                                                rows="5"
                                                type="textarea"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[`description_${language.lang}`] || ""}
                                                invalid={
                                                    validation.touched[`description_${language.lang}`] && validation.errors[`description_${language.lang}`]
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched[`description_${language.lang}`] && validation.errors[`description_${language.lang}`] ? (
                                                <FormFeedback type="invalid">{validation.errors[`description_${language.lang}`]}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Title Color</Label>
                            <div className="d-flex align-items-center">
                                <Input
                                    name="title_color"
                                    placeholder="Enter Color"
                                    type="color"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.title_color || ""}
                                    invalid={
                                        validation.touched.title_color && validation.errors.title_color ? true : false
                                    }
                                    style={{ marginRight: '10px', width: '100px', fontSize: '12px' }} // Adjust the styles here
                                />
                                <div style={{ fontSize: '12px' }}> {/* Adjust the font size here */}
                                    <span>Hex Value: </span>
                                    <span>{validation.values.title_color}</span>
                                </div>
                            </div>
                            {validation.touched.title_color && validation.errors.title_color ? (
                                <FormFeedback type="invalid">{validation.errors.title_color}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Order</Label>
                            <Input className="col-3"
                                name="order"
                                placeholder="Enter Order Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.order || ""}
                                invalid={
                                    validation.touched.order && validation.errors.order ? true : false
                                }
                            />
                            {validation.touched.order && validation.errors.order ? (
                                <FormFeedback type="invalid">{validation.errors.order}</FormFeedback>
                            ) : null}
                        </div>

                        <div className="row">
                            <div className="col-2">
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <h4 className="card-title mb-3">Active</h4>
                                        <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <Label className="form-label">Images</Label>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center mb-3">
                                        {/* <label for="image" className="btn btn-primary btn-sm">
                                            Add Image
                                        </label> */}
                                        <input className="form-control" id="image" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={handleFileInputChange} name="image" required />

                                    </div>

                                </div>
                            </div>
                            <div className="col-10">
                                {selectedFile && (
                                    <div className="mb-3">
                                        <div className="d-flex gap-2 box-images-container">
                                            <div className="image-container">
                                                {/* <img src={deleteIcon} className="box-delete-icon" width={28} height={28} onClick={deleteImage}></img> */}
                                                <img src={preview} width={200} height={200} className="box-image" alt="Preview" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <Input
                                name="image_url"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                type="hidden"
                                value={preview || ""}
                                invalid={
                                    validation.touched.image_url && validation.errors.image_url ? true : false
                                }
                            /> */}
                            <div className="error" >
                                {/* <ErrorMessage name="image" /> */}
                            </div>
                            {/* <div className="error" >
                                <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                            </div> */}
                            {validation.touched.image && validation.errors.image ? (
                                <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Save
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddContentSectionModal
