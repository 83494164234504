import React, { useEffect, useState } from "react";
import TierTable from "./TierTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBonusTier from "hooks/useBonusTier";
import useAuth from "hooks/useAuth";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setTiers, setLoading } = useBonusTier();
  const { auth } = useAuth();

  useEffect(() => {
    setLoading(true);

    const getTiers = async () => {
      try {
        const response = await axiosApi.get('/api/admin/setting/bonus-tier');
        setLoading(false);
        setTiers(response.data.result)

      } catch (err) {
        console.log(err);
      }
    }
    getTiers();

  }, [refresh]);

  return (
    <React.Fragment>
      <TierTable />
    </React.Fragment>
  );
};

export default AdminList;
