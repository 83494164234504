import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import TabMenu from "./TabMenu";
import boxIcon from "../../../assets/images/services-icon/deployed-boxes.png";
import tankIcon from "../../../assets/images/erp/tank.png";
import locationIcon from "../../../assets/images/erp/location.png";
import transportIcon from "../../../assets/images/erp/transport.png";
import personIcon from "../../../assets/images/erp/person.png";
import companyIcon from "../../../assets/images/erp/company.png";

const Assets = () => {
    // const [tankCount, setTankCount] = useState(0);
    // const [boxCount, setBoxCount] = useState(0);
    // const [locationCount, setLocationCount] = useState(0);
    // const [transportCount, setTransportCount] = useState(0);
    // const [personCount, setPersonCount] = useState(0);
    // const [companyCount, setCompanyCount] = useState(0);

    // const axiosApi = useAxiosPrivate();

    // const getDatas = async () => {
    //     try {
    //         const response = await axiosApi.get(`/api/erp/inventory/asset-summary`);
    //         if (response.data.results) {
    //             setTankCount(response.data.results.tank_count ?? 0)
    //             setBoxCount(response.data.results.box_count ?? 0)
    //             setLocationCount(response.data.results.location_count ?? 0)
    //             setTransportCount(response.data.results.transport_count ?? 0)
    //             setPersonCount(response.data.results.person_count ?? 0)
    //             setCompanyCount(response.data.results.company_count ?? 0)
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // useEffect(() => {
    //     getDatas();
    // }, []);
    document.title = `${process.env.REACT_APP_NAME || ""} | Assets`;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Users" breadcrumbItem="App User List" /> */}
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8} sm={12}>
                                <h6 className="page-title">Assets</h6>
                                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Inventory</a></li><li className="active breadcrumb-item" aria-current="page">Assets</li></ol>
                            </Col>

                            <Col md={4} sm={12}>

                            </Col>
                        </Row>
                    </div>
                    {/* <Row>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={boxIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Boxes
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {boxCount}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={tankIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Tanks
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {tankCount}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={locationIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Locations
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {locationCount}
                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={transportIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Transports
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {transportCount}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={personIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Individuals
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {personCount}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="mini-stat bg-primary text-white">
                                <CardBody style={{ 'padding': '1rem' }}>
                                    <div className="mb-4">
                                        <div className="float-start mini-stat-img me-1">
                                            <img src={companyIcon} alt="" />
                                        </div>
                                        <br></br>
                                        <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                            Total&nbsp;Companies
                                        </h5>
                                        <h4 className="fw-medium font-size-24">
                                            {companyCount}

                                        </h4>
                                    </div>
                                    <div className="pt-2">
                                        <div className="float-end">
                                            <Link to="#" className="text-white-50">
                                                <i className="mdi mdi-arrow-right h5 d-none"></i>
                                            </Link>
                                        </div>
                                        <p className="text-white-50 mb-0 mt-1"></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row> */}
                    <TabMenu />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Assets;
