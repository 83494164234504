import { createContext, useState } from "react";

const EducardContext = createContext({});

export const EducardProvider = ({ children }) => {
    const [sections, setSections] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const [refresh, setRefresh] = useState('');

    return (
        <EducardContext.Provider value={{ sections, setSections, totalRecords, setTotalRecords, expandedRows, setExpandedRows, refresh, setRefresh }}>
            {children}
        </EducardContext.Provider>
    )
}

export default EducardContext;