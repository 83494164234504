import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAuth from "hooks/useAuth";


const UserExportCSV = ({ lazyState }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const headers = [
        { label: "Box ID", key: "internal_id" },
        { label: "Name", key: "name" },
        { label: "Location", key: "tanklocation_name" },
        { label: "Company", key: "company_name" },
        // { label: "Capacity Type", key: "tankcapacitytype_name" },
        { label: "Capacity", key: "max_capacity" },
        { label: "Quantity", key: "capacity" },
        { label: "Fill Levels(%)", key: "fill_level_percentage" },
        { label: "isActive", key: "is_active" },

    ]
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Boxes_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()

        } else {
            // console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/erp/asset/tanks-box?searchText=${lazyState.searchText}&show_all=true`);
            const result = response.data.results;

            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            console.log(error);
        }
    }

    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default UserExportCSV