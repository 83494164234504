import { createContext, useState } from "react";

const PermissionContext = createContext({});

export const PermissionProvider = ({ children }) => {
    const [permissions, setPermissions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const [refresh, setRefresh] = useState('');
    const [loading, setLoading] = useState(false);


    return (
        <PermissionContext.Provider value={{ permissions, setPermissions, totalRecords, setTotalRecords, expandedRows, setExpandedRows, refresh, setRefresh, loading, setLoading }}>
            {children}
        </PermissionContext.Provider>
    )
}

export default PermissionContext;