import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import EditLicenseModal from "./modals/EditLicenseModal";
import DeleteLicenseModal from "./modals/DeleteLicenseModal";
import PreviewImageModal from "./modals/PreviewImageModal";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import useEducard from "hooks/useEducard";

const ContentTable = ({ row }) => {
    const location = useLocation();
    const propsData = location.state;
    const [contents, setContents] = useState([]);

    const { setTotalRecords, totalRecords, setExpandedRows, refresh } = useEducard()
    const items = Array.from({ length: 5 }, (v, i) => i);

    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getContents = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/person-licenses?person_id=${row.id}&&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            // console.log(response)

            setContents(response.data.results);
            // setTotalRecords(response.data.totalRecords)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">

                <div>
                    <EditLicenseModal rowData={data} />
                </div>
                <div>
                    <DeleteLicenseModal rowData={data} />
                </div>
            </div>
        )
    }
    const imagePreview = (data) => {
        if (data.license_image) {
            return <PreviewImageModal imageUrl={data.license_image}></PreviewImageModal>
        } else {
            return '-'
        }


    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'personlicensetype_name', header: 'Type', body: '' },
        { field: 'license_number', header: 'License Number', body: '' },
        { field: 'expiry_date', header: 'Expiring Date', body: '' },
        { field: 'license_image', header: 'Image', body: imagePreview },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    useEffect(() => {
        getContents();
    }, [lazyState]);

    return (
        <React.Fragment>
            <DataTable
                value={loading ? items : contents}
                stripedRows
                size={'normal'}
                scrollable
                scrollHeight="400px"
            >
                {columns.map((col, i) => (
                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                ))}
            </DataTable>

        </React.Fragment>
    );
};

export default ContentTable;
