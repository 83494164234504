import { createContext, useState } from "react";

const VolumePartnerContext = createContext({});

export const VolumePartnerProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [volumePartners, setVolumePartners] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    return (
        <VolumePartnerContext.Provider value={{ refresh, setRefresh, volumePartners, setVolumePartners, loading, setLoading, totalRecords, setTotalRecords }}>
            {children}
        </VolumePartnerContext.Provider>
    )
}

export default VolumePartnerContext;