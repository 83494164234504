import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import EditSubMenuModal from "./modals/EditSubMenuModal";
import DeleteSubMenuModal from "./modals/DeleteSubMenuModal";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import useMenu from "hooks/useMenu";

const SubMenuTable = ({ data }) => {
    const location = useLocation();
    const propsData = location.state;

    const { setTotalRecords, totalRecords, setExpandedRows, refresh } = useMenu()
    const items = Array.from({ length: 5 }, (v, i) => i);


    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">

                <div>
                    <EditSubMenuModal submenu={data} />
                </div>
                <div>
                    <DeleteSubMenuModal submenu={data} />
                </div>
            </div>
        )
    }
    const badgeStatus = (data) => {
        var badgetText = 'danger'
        var statusText = 'non active'
        if (data.status == true) {
            statusText = 'active'
            badgetText = 'success'
        }
        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>
        )
    }
    const iconImage = (data) => {
        if (data.icon) {
            return (
                <span className={data.icon}>
                </span>
            )

        } else {
            return "-"
        }
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'title', header: 'Title', body: '' },
        { field: 'url', header: 'Path', body: '' },
        { field: 'icon', header: 'Icon', body: iconImage },
        { field: 'order', header: 'Order', body: '' },
        { field: 'status', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <React.Fragment>
            <DataTable
                value={loading ? items : data.submenu}
                stripedRows
                size={'normal'}
                scrollable
                scrollHeight="400px"
            >
                {columns.map((col, i) => (
                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                ))}
            </DataTable>

        </React.Fragment>
    );
};

export default SubMenuTable;
