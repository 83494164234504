import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";


const TransactionHistoryExportCSV = ({lazyState, data}) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partner_Promotion_Transactions_List_${date}_${data?.name}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { label: "ID", key: "TA_ID" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Reward", key: "totalAmount" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/marketing/promotion/${data?._id}/transactions?searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.TA_UCO_Weight = simplify(obj.TA_UCO_Weight);
                obj.TA_UCO_Volume = simplify(obj.TA_UCO_Volume);
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>
        
    )
}

export default TransactionHistoryExportCSV