import React, { useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment";
import Editable from 'react-bootstrap-editable';
import makeid from 'helpers/random';
import { InputText } from 'primereact/inputtext';

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";



import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import useCurrency from "hooks/useCurrency";
import useAuth from "hooks/useAuth";
import { ROWS_PER_PAGE } from "constants/value";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const CurrencyTable = ({ updateCurrencies }) => {
    const { showToast } = useAuth();

    const items = Array.from({ length: 5 }, (v, i) => i);
    const axiosApi = useAxiosPrivate();

    const { datas, loading, setLoading, expandedRows, setExpandedRows, updating, setRefresh } = useCurrency();
    const [editRowId, setEditRowId] = useState(null);
    const [editValue, setEditValue] = useState('');

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const updateTime = (data) => {
        return moment(data.update).format('MM/DD/YYYY HH:mm:ss')
    }

    const rateFormat = (data) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 9
        }).format(data)
    }

    const handleKeyDown = async (e, rowData) => {
        if (e.key === 'Enter') {
            const currency = { value: editValue, currency: editRowId }
            const response = await axiosApi.post('/api/admin/finance/set-currencies', currency);
            setEditRowId(null);
            setEditValue('');
            showToast(response.data);
            setRefresh(makeid(5));
        }
    };

    const handleRowEditInit = (rowData) => {
        setEditRowId(rowData.currency); // Assuming each data row has a unique 'id' property
        setEditValue(rowData.rate);
    };
    const rateCellTemplate = (rowData, column) => {
        if (editRowId === rowData.currency && column.field === 'rate') {
            return (
                <InputText value={editValue}
                    onChange={(e) => {

                        const inputValue = e.target.value;
                        const regex = /^[0-9.]*$/; // Regular expression to allow only numbers and the decimal point
                        if (regex.test(inputValue)) {
                            setEditValue(inputValue);
                        }
                    }}
                    onBlur={(e) => {

                        setEditRowId(null);
                        setEditValue('');
                        
                    }}
                    onKeyDown={(e) => handleKeyDown(e, rowData)}
                    className=''
                    autoFocus
                />

            );
        } else {
            return (
                <div onDoubleClick={() => handleRowEditInit(rowData)} style={{ color: '#70ad47' }}>
                    {rateFormat(rowData.rate)}
                </div>
            );
        }
    };
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'currency', header: 'Currency', body: '' },
        { field: 'rate', header: 'Rate', body: rateCellTemplate },
        { field: '', header: 'Last Update', body: updateTime },
    ]

    // const onPage = (event) => {
    //     let _filters = { ...lazyState };
    //     _filters.config = event;
    //     setlazyState(_filters);
    // }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Exchange Rate</h5>
                USD 1 = {data.currency} {rateFormat(data.rate)}
            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        {/* <Button color="primary" onClick={updateCurrencies} className={'mb-3'} disabled={updating}>{updating ? 'Updating' : 'Update'}</Button> */}
                        <DataTable
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={5}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default CurrencyTable
