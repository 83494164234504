import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAuth from "hooks/useAuth";

const DataExportCSV = ({ paramStatus, lazyState }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Collection_${paramStatus}_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { key: 'collection_code', label: 'Collection ID' },
        { key: 'collection_type', label: 'Type' },
        { key: 'destination_tank.tank_name', label: 'Destination' },
        { key: 'max_capacity', label: 'To Be Collected Quantity (kg)' },
        { key: 'collection_real_weight', label: 'Actual Weight (kg)' },
        { key: 'tour.transport_code', label: 'Transport' },
        { key: 'driver_name', label: 'Driver' },
        { key: 'tour.tour_due_date ', label: 'Due Date' },
        { key: 'collectionstatus_name', label: 'Status' },

    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/erp/collections?status=${paramStatus}&searchText=${lazyState.searchText}&show_all=true`);
            const result = response.data.results;
            result.forEach(obj => {
                obj.driver_name = obj.tour.person_first_name + ' ' + obj.tour.person_last_name;
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>

        </>


    )
}

export default DataExportCSV