import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Container, Label, Form, FormFeedback, Input, InputGroup, InputGroupText } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import withRouter from 'components/Common/withRouter';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import logoSm from "../../assets/images/icon1.png";
import bg from "../../assets/images/bg-2.png";
import logoDark from "../../assets/images/noov-green.svg";

import { post, del, get, put } from "../../helpers/api_helper";
import useAuth from 'hooks/useAuth';

const Login = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const [userLogin, setUserLogin] = useState([]);
  const [passwordType, setPasswordType] = useState('password')

  const changeType = () => {
    if(passwordType === "password")
      {
        setPasswordType("text")
        return;
      }
      setPasswordType("password")
  }
  const handleNumericInput = (event) => {
    const { value } = event.target;
    if (!/^\+?\d*$/.test(value)) {
      event.preventDefault();
    } else {
      validation.handleChange(event);
    }
  };
  // const { user } = useSelector(state => ({
  //   user: state.Account.user,
  // }));

  // useEffect(() => {
  //   if (user && user) {
  //     setUserLogin({
  //       email: user.email,
  //       password: user.password
  //     });
  //   }
  // }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      code: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
    }),
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      // dispatch(loginUser(values, props.router.navigate));
      try {
        const response = await post('/admin/login', values);
        localStorage.setItem("authUser", JSON.stringify(response.auth));
        setAuth(response);
        navigate('/dashboard')
      } catch (error) {
        if (error.response && error.response.data) {
          validation.setErrors(error.response.data);
        } else {
          console.error(error);
        }
      }
    }
  });

  document.title = `${process.env.REACT_APP_NAME || ''} | Login`;
  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages">
        <div
          className="accountbg"
          style={{
            background: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transform: "scaleX(-1)",
          }}
        ></div>

        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logoDark} height="45" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue.
                    </p>

                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="email">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3" style={{ position: 'relative' }}>
                        <Label className="form-label" htmlFor="password">Password</Label>
                        <InputGroup>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordType}
                              className="form-control"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                          <InputGroupText style={{ padding: '0.375rem 0.55rem', lineHeight: '0.5' }} role="button" onClick={changeType}>
                            <i className={`mdi mdi-eye${passwordType === 'password' ? '-off' : ''}`} style={{ fontSize: '20px' }}></i>
                          </InputGroupText>
                        </InputGroup>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid" style={{ display: validation.touched.password && validation.errors.password ? 'block' : 'none' }}>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3" style={{ position: 'relative' }}>
                        <Label className="form-label" htmlFor="code">2FA Code (if enabled)</Label>
                        <Input
                          name="code"
                          value={validation.values.code}
                          type="text"
                          autoComplete='one-time-code'
                          className="form-control"
                          placeholder="Enter code from authenticator app"
                          onChange={handleNumericInput}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.code && validation.errors.code ? true : false
                          }
                        />
                        {validation.touched.code && validation.errors.code ? (
                          <FormFeedback type="invalid" style={{ display: validation.touched.code && validation.errors.code ? 'block' : 'none' }}>{validation.errors.code}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          {/* <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                          </div> */}
                        </div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light text-white" type="submit" disabled={validation.isSubmitting}>Log In</button>
                        </div>
                      </div>

                      {/* <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div> */}

                    </Form>

                    <div className="mt-5 pt-4 text-center">
                      {/* <p>
                        Don't have an account ?{" "}
                        <Link
                          to="/pages-register-2"
                          className="fw-medium-medium text-primary"
                        >
                          {" "}
                          Signup now{" "}
                        </Link>{" "}
                      </p> */}
                      <p>
                        © {new Date().getFullYear()} noovoleum
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>


      {/* <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className='bg-primary'>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome Back !
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="35" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="email">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3" style={{ position: 'relative' }}>
                        <Label className="form-label" htmlFor="password">Password</Label>
                        <InputGroup>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordType}
                              className="form-control"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                          <InputGroupText style={{ padding: '0.375rem 0.55rem', lineHeight: '0.5' }} role="button" onClick={changeType}>
                            <i className={`mdi mdi-eye${passwordType === 'password' ? '-off' : ''}`} style={{ fontSize: '20px' }}></i>
                          </InputGroupText>
                        </InputGroup>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid" style={{ display: validation.touched.password && validation.errors.password ? 'block' : 'none' }}>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">

                        </div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light text-white" type="submit" disabled={validation.isSubmitting}>Log In</button>
                        </div>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">

                <p>
                  © {new Date().getFullYear()} {process.env.REACT_APP_NAME || ''} by noovoleum
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </React.Fragment>
  );
};

// const mapStateToProps = state => {
//   const { error } = state.Login;
//   return { error };
// };

export default withRouter(
  // connect(mapStateToProps, { loginUser, apiError })(Login)
  Login
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};