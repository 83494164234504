import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import useAuth from "hooks/useAuth";
import useBox from "hooks/useBox";

import makeid from 'helpers/random';
import { INITIAL_OPENING_HOURS } from "constants/value";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const DeleteBoxModal = ({ modal_center, tog_center, setVolume, setWeight, setSeries }) => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { box, setRefresh, setBox, toggleRightCanvas, setSelectedMode, setBoxImages, transaction, setPin, filterTransaction, setOpeningHoursArray } = useBox();

    const refreshContent = () => {
        setBox(null);
        setBoxImages([]);
        setPin(true);
        setRefresh(makeid(5));
        filterTransaction(null, transaction, setVolume, setWeight, setSeries)
        setSelectedMode('');
        setOpeningHoursArray(INITIAL_OPENING_HOURS);
    }

    // Form validation 
    const validation2 = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: box?.id,
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await axiosApi.post('/api/admin/engineer/box/delete', values);
                setRefresh(response.random);
                setBox(null)
                setBoxImages([])
                setSelectedMode('')
                setOpeningHoursArray(INITIAL_OPENING_HOURS)
                resetForm();
                tog_center();
                toggleRightCanvas();
                refreshContent();
                showToast(response);
            } catch (error) {
                console.log(error);
            }
        }
    });


    return (
        <>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Delete {box?.name}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation2.handleSubmit();
                            return false;
                        }}>
                        <h5>You sure want to delete {box?.name} ?</h5>
                        <div className="mb-3">
                            <input type="hidden" name="id" value={validation2.values.id || box?.id} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="danger" >
                                {validation2.isSubmitting ? 'Deleting...' : 'Delete'}
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DeleteBoxModal
