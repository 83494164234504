import axiosApi from "helpers/api_helper";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axiosApi.get('/api/admin/refresh-token');
        setAuth(response.data)
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken;