import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { Row, Modal, ModalBody, ModalHeader } from "reactstrap"
import { simplify } from "helpers/functions";
import useTransaction from "hooks/useTransaction";

const TransactionExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { rejectedClicked, bonusClicked } = useTransaction();
    const [acceptedOnly,setAcceptedOnly] = useState(true);

    //show Error Text
    const [error,setError] = useState("");
 
    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setAcceptedOnly(true)
        setSelectedMethods(["box"]);
        setError("")
        setmodal_center(!modal_center);
    };

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Transactions_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${rejectedClicked ? '_Rejected' : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { label: "ID", key: "TA_ID" },
        { label: "Box", key: "box[0].name" },
        { label: "User", key: "user[0].name" },
        { label: "Username", key: "user[0].username" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Rejected Weight (kg)", key: "TA_Slops_Weight" },
        { label: "Rejected Volume (liter)", key: "TA_Slops_Volume" },
        { label: "Currency", key: "user[0].currency" },
        { label: "Amount", key: "totalAmount" },
        { label: "Start", key: "TA_Start_Time" },
        { label: "End", key: "TA_End_Time" },
        { label: "Address", key: "address.address" },
        { label: "Postcode", key: "address.postcode" },
        { label: "City", key: "address.city" },
        { label: "Country", key: "address.country" },
        { label: "Latitude", key: "address.lat" },
        { label: "Longitude", key: "address.long" },
        { label: "Method", key: "method" },
        { label: "Alcohol Level", key: "extraData.alcohol_level" },
        { label: "Hydrocarbon Level", key: "extraData.hydrocarbon_level" },
        { label: "Raw Max Level", key: "extraData.raw_max_level" },
        { label: "Raw Interface Level", key: "extraData.raw_interface_level" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            setError("");
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;

            if (rejectedClicked) {
                if (acceptedOnly) {
                    setError("Cannot have accepted only while looking at rejected transaction") 
                    setLoading(false)
                    return;
                }
                queryParams += '&approved=false';
                
            }
            if (acceptedOnly) {
                queryParams += '&approved=true';
            }

            const method = getSelectedMethodsString();
            if (method == "") {
                setError("No method is selected") 
                setLoading(false)
                return;
            }

            queryParams += '&method=' + method;
            
            const response = await axiosApi.get(`/api/admin/engineer/export-transaction?${queryParams}`);

            const result = response.data.result;
            
            if (result.length == 0) {
                setError("No data to export") 
                setLoading(false)
                return;
            }
            result.forEach(obj => {
                obj.TA_UCO_Weight = obj.UCO_Approved ? simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight) : 0;
                obj.TA_UCO_Volume = obj.UCO_Approved ? simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume) : 0;
                obj.TA_Slops_Weight = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight);
                obj.TA_Slops_Volume = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume);
                obj.address.lat = obj.address.lat || obj?.box[0]?.location?.latitude || "";
                obj.address.long = obj.address.long || obj?.box[0]?.location?.longitude || "";
                obj.address.address = obj.address?.address?.replace(/\n/g, ', ') || "";
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            tog_center();
        } catch (error) {
            setError(error.message) 
            setLoading(false)
            console.log(error);
        }
    }

    const [selectedMethods, setSelectedMethods] = useState(["box"]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setSelectedMethods((prev) => {
            if (checked) {
                // Add the value if not already present
                return Array.from(new Set([...prev, value]));
            } else {
                // Remove the value if unchecked
                return prev.filter((method) => method !== value);
            }
        });
    };

    const getSelectedMethodsString = () => selectedMethods.join(',');
    const changeAcceptedOnly = () =>  setAcceptedOnly(!acceptedOnly);
    return (
        <>
          <Button
                type="button"
                icon={`mdi mdi-24px mdi-file`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={tog_center}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>

                <Modal isOpen={modal_center} toggle={tog_center} centered size="sm">
                <ModalHeader className="mt-0" toggle={tog_center}>Export Transactions</ModalHeader>
                <ModalBody>
                    <Row>
                    <div className="col-12">
                        <label><b>Transactions Method:</b></label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="box"
                                id="checkBoxMethodBox"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("box")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodBox">
                                Box
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="manual"
                                id="checkBoxMethodManual"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("manual")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodManual">
                                Manual
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="promotion"
                                id="checkBoxMethodPromotion"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("promotion")} // Controlled input
                              
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodPromotion">
                                Promotion
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="bonus"
                                id="checkBoxMethodBonus"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("bonus")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodBonus">
                                Monthly Bonus
                            </label>
                        </div>
                    </div>
                    <div className="col-12">
                        <label><b>Transactions Status:</b></label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="box"
                                id="checkBoxAcceptedOnly"
                                onChange={changeAcceptedOnly}
                                checked={acceptedOnly} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxAcceptedOnly">
                                Accepted Transactions Only
                            </label>
                        </div>
                     
                    </div>


                    {error != "" && 
                    <span style={{ color: 'red' }}><b>{error}</b></span>
                    }
                        <hr></hr>
                        <div className="col-12">
                            <Button
                                type="button"
                                icon={`mdi  ${loading ?  'mdi-loading mdi-spin'  : 'mdi-file'}`}
                                class="btn btn-primary"
                                onClick={() => fetchDataReq()}
                                >Export to CSV</Button>
                            
               
                        </div>
                        </Row>
                </ModalBody>

            </Modal>


        </>

    )
}

export default TransactionExportCSV