import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";
import useAsset from "hooks/useAsset";

const BoxAssets = ({ setTotalTabRecords }) => {
    const location = useLocation();
    const { boxes } = location.state || {};
    const propsData = boxes;
    const queryParams = new URLSearchParams(location.search);
    const searchText = queryParams.get('searchBox');
    const { setBoxDatas, setBoxTotalRecords, boxRefresh, setBoxRefresh } = useAsset();
    const [lazyState, setlazyState] = useState({
        searchText: searchText || propsData || '',
        config: {
            first: 0,
            rows: 20,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();
    const [lastSync, setLastSync] = useState(null);

    const getBoxDatas = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tanks-box?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setBoxDatas(response.data.results);
            setBoxTotalRecords(response.data.total_records ?? 0)
            setTotalTabRecords(response.data.total_records ?? 0)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                // setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    const getLastSync = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/settings/tank_last_sync`);
            if (response?.data?.results?.value) {
                setLastSync(response?.data?.results?.value)
            } else {
                setLastSync(null)
            }           
        } catch (error) {
            setLoading(false);
            console.log(error);
            setLastSync(null);

        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getLastSync();
            getBoxDatas();
        }, 300);
    }, [lazyState, boxRefresh]);

    return (
        <React.Fragment>
            <DataTable loading={loading} id={searchText || propsData} lazyState={lazyState} setlazyState={setlazyState} setRefresh={setBoxRefresh} lastSync={lastSync} />
        </React.Fragment>
    );
};

export default BoxAssets;
