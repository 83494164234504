import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const BoxCollectionPoint = ({ data }) => {
    const axiosApi = useAxiosPrivate();
    const userId = data.id;
    var isEnabled = true;
    if (data && data.hasOwnProperty('collectionPoint')) {
        isEnabled = data.collectionPoint;
    }
    const [collectionPoint, setCollectionPoint] = useState(isEnabled);
    const { showToast } = useAuth();

    const handleSwitchChange = async (value) => {
        setCollectionPoint(value);

        const body = { phonePrefix: data.phone.prefix, phoneNumber:data.phone.number,collectionPoint: value, id: userId };
        const response = await axiosApi.post('/api/admin/engineer/box/update-collection-point', body)
        showToast(response.data);
    };

    return (
        <div>
            <InputSwitch checked={collectionPoint} onChange={(e) => handleSwitchChange(e.value)} />
            {/* Other JSX */}
        </div>
    );
};

export default BoxCollectionPoint;
