import React, { useEffect, useState } from "react";

import {
    Label,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import { Dropdown } from 'primereact/dropdown';
import PaymentPeriodModal from "./modals/PaymentPeriodModal";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import PartnerPromotionTable from "./PartnerPromotionTable";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { getFormattedUTCDate, balanceFormatter } from 'helpers/functions';

const PartnerPromotions = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const propsData = location.state;
    const [refresh, setRefresh] = useState('');
    const [promoTable, setPromoTable] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalRewards, setTotalRewards] = useState(0);

    const today = new Date()
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    endOfMonth.setHours(23, 59, 59, 999)

    const startDate = getFormattedUTCDate(startOfMonth)
    const endDate = getFormattedUTCDate(endOfMonth)
    const [selectedMode, setSelectedMode] = useState('')
    const [promotions, setPromotions] = useState('')
    const [selectedPeriod, setSelectedPeriod] = useState('month')

    const [lazyState, setlazyState] = useState({
        id: selectedMode||'',
        searchText: propsData || '',
        startDate: startDate,
        endDate: endDate,
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const changeMode = (e) => {
        setSelectedMode(e.value);
        // let _filters = { ...lazyState };
        // _filters.id = e.value;
        // setlazyState(_filters)
    }
    const getReports = async () => {
        try {
            let _id = ''
            if (selectedMode) {
                _id = selectedMode._id
            }
            // const response = await axiosApi.get(`/api/admin/marketing/promotion/report/Partner?startDate=${options.startDate}&endDate=${options.endDate}&id=${_id}`);
            // const response = await axiosApi.get(`/api/admin/marketing/promotion/report/Partner?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&id=${_id}`);
            const response = await axiosApi.get(`/api/admin/marketing/promotion/report/Partner?skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&id=${_id}`);
             setLoading(false);
            setPromoTable(response.data.result)
            setTotalRecords(response.data.totalRecords)
            setTotalRewards(response.data.totalRewardPartner)
        } catch (err) {
            console.log(err);
        }
    }
    const getPartnerPromotion = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/marketing/promotion?type=Partner`);
            const allOption = { name: 'All', _id: '' };
            const partnerPromoWithAll = [allOption, ...response.data.result];
            setPromotions(partnerPromoWithAll);
            if (response.data.result) {
                setSelectedMode(partnerPromoWithAll[0])
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
            getPartnerPromotion();
    }, []);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getReports();
        }, 300);
    }, [lazyState, refresh, selectedMode]);
  
    return (
        <React.Fragment>
            <Card>
                <CardTitle>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'right' }} className="my-3">
                        {/* <div style={{ marginRight: 'auto' }}>
                            <p className="text-muted mb-4">Total Partner Reward</p>
                            <h3>{balanceFormatter(totalRewards || 0, 'IDR')}</h3>
                        </div> */}
                        <Dropdown
                            value={selectedMode || ''}
                            onChange={changeMode}
                            options={promotions}
                            optionLabel="name"
                            className="h-1 payment-status-dropdown"
                        />
                        <PaymentPeriodModal
                            selectedPeriod={selectedPeriod}
                            mainOptions={lazyState}
                            setMainOptions={setlazyState}
                            setSelectedPeriod={setSelectedPeriod}
                        />
                    </div>
                </CardTitle>
                <CardBody>
                    <PartnerPromotionTable loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} refresh={refresh} setRefresh={setRefresh} promoTable={promoTable} setPromoTable={setPromoTable} setTotalRecords={setTotalRecords} totalRecords={totalRecords} totalRewards={totalRewards} setTotalRewards={setTotalRewards} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default PartnerPromotions;
