import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAuth from "hooks/useAuth";


const UnitExportCSV = ({ lazyState, data, datas, selectedFilter, isExport, setIsExport }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `UCO_Unit_History_${data.title}_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
            setIsExport(false);
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    useEffect(() => {
        if (isExport) {
            fetchDataReq();
        }
    }, [isExport]);
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            var response;
            if (data.type_id == 1) {
                if (selectedFilter?.id) {
                    response = await axiosApi.get(`/api/erp/inventory/tank-uco-units?tank_location=${selectedFilter?.id}&date=${data?.date}&searchText=${lazyState.searchText}&show_all=true&is_anonymize=${datas.isAnonymous}`);
                } else {
                    response = await axiosApi.get(`/api/erp/inventory/tank-uco-units?date=${data?.date}&searchText=${lazyState.searchText}&show_all=true&is_anonymize=${datas.isAnonymous}`);
                }
            } else if (data.type_id == 2) {
                response = await axiosApi.get(`/api/erp/inventory/get-uco-units/by-offtake?date=${data?.date}&searchText=${lazyState.searchText}&show_all=true&is_anonymize=${datas.isAnonymous}`);
            } else {
                response = await axiosApi.get(`/api/erp/inventory/get-uco-units/by-collected?tank_location=${data?.tank_id}&date=${data?.date}&searchText=${lazyState.searchText}&show_all=true&is_anonymize=${datas.isAnonymous}`);
            }
            const result = response.data.results;
            result.forEach(obj => {
                obj.box_name = datas.isAnonymous ? obj.uco_unit.external_box_code : obj.uco_unit.external_box_name;
                obj.coordinates = obj.uco_unit.external_box_latitude + ', ' + obj.uco_unit.external_box_longitude;
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setIsExport(false);
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={datas.headers}
            >
            </CSVLink>
        </>

    )
}

export default UnitExportCSV