import {
    GET_ADMINS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL
  } from "./actionTypes"
  
  export const getAdmins = token => ({
    type: GET_ADMINS,
    payload: token,
  })
  
  export const getUsersSuccess = users => ({
    type: GET_USERS_SUCCESS,
    payload: users,
  })
  
  export const getUsersFail = error => ({
    type: GET_USERS_FAIL,
    payload: error,
  })

  