import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";


const PaymentHistoryExportCSV = ({lazyState, data}) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Payment_Request_List_${lazyState.status}_${date}_${data?.name}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { label: "Payment Request ID", key: "reqId" },
        { label: "Input Date Time", key: "createdAt" },
        { label: "Amount", key: "amount" },
        { label: "Currency", key: "currency" },
        { label: "Status", key: "status" },
        { label: "Method", key: "method" },
        { label: "Type", key: "type" },
        { label: "Account", key: "account" },
        { label: "Method Transaction ID", key: "mtId" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/finance/export-payment-request?&id=${data?._id}&status=${lazyState.status}&searchText=${lazyState.searchText}`);
            setDataReq(response.data.result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>
        
    )
}

export default PaymentHistoryExportCSV