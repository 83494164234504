import { createContext, useState } from "react";

const NotifLogContext = createContext({});

export const NotifLogProvider = ({ children }) => {
    const [notifLogs, setNotifLogs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [refresh, setRefresh] = useState('');

    return (
        <NotifLogContext.Provider value={{ notifLogs, setNotifLogs, totalRecords, setTotalRecords, refresh, setRefresh }}>
            {children}
        </NotifLogContext.Provider>
    )
}

export default NotifLogContext;