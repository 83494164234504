import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import DataTable from "./DataTable";
import { useLocation } from "react-router-dom";
import useAsset from "hooks/useAsset";

const UserList = ({ setTotalTabRecords }) => {
    const location = useLocation();
    const { person } = location.state || {};
    const propsData = person;
    const { setPersonDatas, setPersonTotalRecords, setPersonExpandedRows, setPersonLoading, personRefresh } = useAsset();
    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 20,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const axiosApi = useAxiosPrivate();

    const getDatas = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/persons?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setPersonDatas(response.data.results);
            setPersonTotalRecords(response.data.total_records ?? 0)
            setTotalTabRecords(response.data.total_records ?? 0)

            setPersonLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setPersonExpandedRows(obj)
            }
        } catch (error) {
            setPersonLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setPersonLoading(true);
        setTimeout(() => {
            getDatas();
        }, 300);
    }, [lazyState, personRefresh]);

    return (
        <React.Fragment>
            <DataTable id={propsData} lazyState={lazyState} setlazyState={setlazyState} />
        </React.Fragment>
    );
};

export default UserList;
