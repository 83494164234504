import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import usePartner from "hooks/usePartner";
import makeid from "helpers/random";
const DeleteVolumePartner = ({ rowData }) => {
    const { showToast } = useAuth();
    const { setPartnerRefresh } = usePartner();
    const [modal_center, setmodal_center] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await del('/api/admin/partner-configurations/' + rowData.id);
                setPartnerRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="danger" onClick={tog_center}>
                    <i className="fas fa-trash"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Delete Partner</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <h5>You sure want to delete partner {rowData.partner_account}?</h5>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="danger" >
                                Delete
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default DeleteVolumePartner
