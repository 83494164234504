import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, Row, Col } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axiosApi from "helpers/api_helper";


import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useMenu from "hooks/useMenu";
import makeid from "helpers/random";
import { refreshSidebar } from "store/actions";

const AddSubMenuModal = ({ menu }) => {
    const dispatch = useDispatch();
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh, levelOptions } = useMenu();
    const { showToast } = useAuth();
    const [status, setStatus] = useState(true);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: '',
            icon: '',
            url: '',
            order: menu.submenu.length + 1,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(
                "This value is required"
            ),
            url: Yup.string().required(
                "This value is required"
            ),
            order: Yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).moreThan(0, 'Order must be greater than 0').required(
                "This value is required"
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { title, url, icon, order } = values;
                const obj = { title, url, parent_id: menu._id, order, icon, status: status == true ? 1 : 0 }
                const response = await axiosApi.post('/api/admin/menu', obj)


                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response.data);
                setPreview('');
                setSelectedFile(null)
                dispatch(refreshSidebar());
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Sub Menu </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Add New SubMenu | {menu.title}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">Title</Label>
                            <Input
                                name="title"
                                placeholder="Enter Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                    validation.touched.title && validation.errors.title ? true : false
                                }
                            />
                            {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Path</Label>
                            <Input
                                name="url"
                                placeholder="Enter Path"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.url || ""}
                                invalid={
                                    validation.touched.url && validation.errors.url ? true : false
                                }
                            />
                            {validation.touched.url && validation.errors.url ? (
                                <FormFeedback type="invalid">{validation.errors.url}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Icon</Label>
                            <Input
                                name="icon"
                                placeholder="Enter Icon"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.icon || ""}
                                invalid={
                                    validation.touched.icon && validation.errors.icon ? true : false
                                }
                            />
                            {validation.touched.icon && validation.errors.icon ? (
                                <FormFeedback type="invalid">{validation.errors.icon}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Order</Label>
                            <Input className="col-3"
                                name="order"
                                placeholder="Enter Order Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.order || ""}
                                invalid={
                                    validation.touched.order && validation.errors.order ? true : false
                                }
                            />

                            {validation.touched.order && validation.errors.order ? (
                                <FormFeedback type="invalid">{validation.errors.order}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-5">
                            <h4 className="card-title mb-3">Active</h4>
                            <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Save
                            </Button>{" "}
                        </div>
                    </Form>

                </ModalBody>
            </Modal>
        </>
    )

}

export default AddSubMenuModal
