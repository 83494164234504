import useAuth from "hooks/useAuth";
import useRefreshToken from "hooks/useRefreshToken";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Loading from "pages/Loading";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        console.log('verifying refresh token')
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) { 
                console.log(err)
            }
            finally { 
                setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    
    }, [])
    
    return (
        <>
            {isLoading
                ? <Loading/>
                : <Outlet/>
            }
        </>
    )
}

export default PersistLogin