import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
const DataExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
   
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partner_Overview_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`
    const headers = [
        { key: 'partner_account', label: 'Account Name', body: '', className: '' },
        { key: 'partner_types', label: 'Partner Type', body: '', className: '' },
        { key: 'username', label: 'Username', body: '', className: '' },
        { key: 'volume', label: 'Volume (Act / Exp)' },
        { key: 'volume_user', label: 'Volume / User' },
        { key: 'volume_location', label: 'Volume / Location' },
        { key: 'events_count', label: 'Events' },
        { key: 'reports_count', label: 'Reports', },
    ]
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
            const response = await axiosApi.get(`/api/admin/partner-configurations?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.partner_types = obj?.partner_type_list?.join(", ")
                obj.volume = (obj.partner_volume?.total_collected_volume ?? 0) + " / " + (obj.partner_volume?.expected_volume ?? 0);
                obj.volume_user = (obj.user_partner?.total_collected_volume ?? 0) + " / " + (obj.obj?.user_count ?? 0);
                obj.volume_location = (obj.location_partner?.total_collected_volume ?? 0) + " / " + (obj.obj?.location_count ?? 0);
            });
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV