import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"

const languages = {
  sp: {
    label: "Spanish",
    flag: spain,
  },
  gr: {
    label: "German",
    flag: germany,
  },
  it: {
    label: "Italian",
    flag: italy,
  },
  rs: {
    label: "Russian",
    flag: russia,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

const availableLanguageList = [
  { label: 'Indonesia', lang: 'id', value: "id", flagUrl: 'https://ucollect-assets.s3.ap-southeast-3.amazonaws.com/flag/id.png' },
  { label: 'English', lang: 'en', value: "en", flagUrl: 'https://ucollect-assets.s3.ap-southeast-3.amazonaws.com/flag/gb.png' },
  { label: 'Chinese', lang: 'zh', value: "zh", flagUrl: 'https://ucollect-assets.s3.ap-southeast-3.amazonaws.com/flag/cn.png' },
  { label: 'Malay', lang: 'ms', value: "ms", flagUrl: 'https://ucollect-assets.s3.ap-southeast-3.amazonaws.com/flag/my.png' },
];

export { languages, availableLanguageList };
