import React, { useRef, useEffect } from "react"
import Leaflet from "leaflet"
import { MapContainer, TileLayer, Marker, useMapEvent, useMap } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import { Col, Row } from "reactstrap"
import '../../../../assets/css/style.css'
import validate from "pages/Forms/FormWizardSteps/validate"

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { MaplibreLayer } from '@maplibre/maplibre-gl-leaflet';
Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

const SetViewOnClick = ({ animateRef, setLatitude, setLongitude }) => {
    const map = useMapEvent('click', (e) => {
        setLatitude(e.latlng.lat);
        setLongitude(e.latlng.lng);
        map.setView(e.latlng, map.getZoom(), {
            animate: animateRef.current || false,
        })
    })
    return null
}

const MapLibreLayerComponent = () => {
    const map = useMap();

    useEffect(() => {
        // Create the MapLibre layer
        const glLayer = L.maplibreGL({
            style: `https://maps.geo.${process.env.REACT_APP_MAP_REGION}.amazonaws.com/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/style-descriptor?key=${process.env.REACT_APP_MAP_API_KEY}`,
        }).addTo(map);

        // Cleanup function to remove the layer and avoid memory leaks
        return () => {
            if (map.hasLayer(glLayer)) {
                map.removeLayer(glLayer);
            }
        };
    }, [map]);

    return null;
};

const MapPicker = ({ latitude, setLatitude, longitude, setLongitude }) => {

    const animateRef = useRef(true);
    const iconSize = { w: 37, h: 41 };

    return (
        <Row className="mb-4">
            <Col>
                <MapContainer
                    center={[latitude && latitude.toString().length > 5 ? latitude : -6.8909046, longitude && longitude.toString().length > 5 ? longitude : 107.6122523]}
                    zoom={10}
                    style={{ height: "300px" }}
                    scrollWheelZoom={false}
                >
                    {/* <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> */}
                    <MapLibreLayerComponent />

                    <Marker
                        icon={new Leaflet.Icon({
                            iconUrl: require(`../../../../assets/images/map/uco_flag_active.png`),
                            iconRetinaUrl: require(`../../../../assets/images/map/uco_flag_active.png`),
                            iconAnchor: [(0.5 * iconSize.w), iconSize.h],
                            popupAnchor: null,
                            shadowUrl: null,
                            shadowSize: null,
                            shadowAnchor: null,
                            iconSize: [iconSize.w, iconSize.h],
                        })}
                        position={[latitude &&latitude.toString().length > 5 ? latitude : -6.8909046, longitude && longitude.toString().length > 5 ? longitude : 107.6122523]}></Marker>
                    <SetViewOnClick animateRef={animateRef} setLatitude={setLatitude} setLongitude={setLongitude} />
                </MapContainer>
            </Col>
        </Row>
    )
}

export default MapPicker