import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
} from "helpers/functions"
const ConfirmationModal = ({ isOpen, toggle, onConfirm, loading, title, newStatus }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Incident Confirmation</ModalHeader>
            <ModalBody>
                <h5>Are you sure want to change the UCO status of {title} to "{newStatus}" ?</h5>
             
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={loading} onClick={onConfirm}>{loading ? <span className='mdi mdi-loading mdi-spin'></span>:"Submit"}</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
