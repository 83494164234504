import moment from "moment";
import { createContext, useRef, useState } from "react";

const DonationContext = createContext({});

export const DonationProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalAmounts, setTotalAmounts] = useState(0);
    const [wallets, setWallets] = useState({"IDR":0,"SGD":0.0});
    const [totalUserBalance, setTotalUserBalance] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [lazyState, setlazyState] = useState({
        searchText: '',
        status: 'all',
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                status: { value: '', matchMode: 'contains' },
            }
        }
    });


    return (
        <DonationContext.Provider value={{lazyState, wallets,setWallets,setlazyState, totalRecords, setTotalRecords, selectedStatus, setSelectedStatus, totalAmounts, setTotalAmounts, refresh, setRefresh, totalUserBalance, setTotalUserBalance}}>
            {children}
        </DonationContext.Provider>
    )
}

export default DonationContext;