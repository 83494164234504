import React, { useEffect, useState } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SendNotificationModal from "./modals/SendNotificationModal";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import NotificationLogTable from "./NotificationLogTable";
import useNotifLog from "hooks/useNotifLog";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

const BroadcastSupports = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const propsData = location.state;
    const { setNotifLogs, setTotalRecords, refresh } = useNotifLog();
    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getNotifLogs = async () => {
        try {
            // const response = await axiosApi.get(`/api/admin/support/getUsers?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const response = await axiosApi.get(`/api/support/broadcast-notification?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setNotifLogs(response.data.logs)
            setTotalRecords(response.data.totalRecords)
            setLoading(false)
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                // setExpandedRows(obj)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            console.log('test')
            getNotifLogs();
        }, 300);
    }, [lazyState]);
    useEffect(() => {
        getNotifLogs();
    }, [refresh]);
    document.title = `${process.env.REACT_APP_NAME || ''} | PN Broadcast`;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Support" breadcrumbItem="PN Broadcast" />
                    <SendNotificationModal />

                    <NotificationLogTable loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default BroadcastSupports;
