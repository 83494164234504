import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { connect, useDispatch } from "react-redux";
import withRouter from 'components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user-blank.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { post, del, get, put } from "../../helpers/api_helper";
import useAuth from 'hooks/useAuth';
import GenerateOTPModal from "./modals/generateOTPModal"
import DisableOTPModal from "./modals/disableOTPModal"
const UserProfile = props => {
  const [username, setUsername] = useState('')
  const dispatch = useDispatch();
  const { auth } = useAuth();

  useEffect(() => {
    setUsername(auth?.auth.username);
  }, []);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: auth?.auth.username || '',
      id: auth?.auth._id || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
    }),
    onSubmit: (values) => {
      // dispatch(editProfile(values));
    }
  });

  document.title = `${process.env.REACT_APP_NAME || ''} | Profile`;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
            {(!auth?.auth?.otp_enabled) &&    <Alert color="warning">Please Enable 2FA (2 Factor Authentication) to continue using Noovoleum Admin</Alert> }
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={ auth?.auth.img || avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{auth?.auth.name}</h5>
                        <p className='mb-1'>{auth?.auth.username}</p>
                        <p className="mb-1">{auth?.auth.email}</p>
                        <p className="mb-1">Id no: #{'1'}</p>
                        <p className="mb-1">OTP / 2FA Enabled : {auth?.auth.otp_enabled ? "Yes" :"No"}</p>
                        {auth?.auth.otp_enabled ? <DisableOTPModal /> : <GenerateOTPModal />}
                        
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <h4 className="card-title mb-4">Change Username</h4>

          <Card>
            <CardBody>

              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Username</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={'1'} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Edit User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  // connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
  (UserProfile)
);
