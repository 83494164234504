import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import Flatpickr from "react-flatpickr";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

import { post, del, get, put } from "../../../../helpers/api_helper";
import deleteIcon from "../../../../assets/images/delete-icon.png";

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useCollection from "hooks/useCollection";
const ApprovalModal = ({ rowData, submitLoading, setSubmitLoading }) => {
    const { showToast } = useAuth();

    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useCollection();
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [confirmList, setConfirmList] = useState([]);
    const startTime = dataString => {
        return moment(dataString).format("MM/DD/YYYY HH:mm:ss")
    }
    const getOrderStatus = async () => {
        try {
            const logResponse = await axiosApi.get(`/api/erp/inventory/collection-status-logs/${rowData.id}`);
            var logList = logResponse.data.results;
            // console.log('logList', logList);


            const response = await axiosApi.get(`/api/erp/master/collection-statuses`);
            let statusProgress = []
            let actionList = []
            if (response.data.results.length > 0) {
                response.data.results.forEach((element, index) => {
                    let additionalMessage = '';
                    if (logList && logList.hasOwnProperty(element.id)) {
                        additionalMessage = startTime(logList[element.id].created_at);
                    } else if (element.id == 2 && logList.hasOwnProperty(3)) {
                        additionalMessage = startTime(logList[3].created_at);

                    }

                    var boxDetail = {
                        ...element,
                        additionalMessage
                    }
                    if (parseInt(element.id) <= 3) {
                        statusProgress.push(boxDetail)
                    } else {
                        actionList.push(boxDetail)
                    }
                    if (parseInt(element.id) == rowData.collection_status_id) {
                        setSelectedStatus(boxDetail.id)
                    }
                });
            }

            setStatusList(statusProgress);
            setConfirmList(actionList);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    useEffect(() => {
        if (modal_center) {
            getOrderStatus();
        }
    }, [refresh, modal_center]);


    const changeStatus = (e) => {
        // console.log('e',e);
        setSelectedStatus(e)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {

        },
        validationSchema: Yup.object().shape({


        }),
        onSubmit: async (values, { resetForm }) => {
            if (submitLoading) return;
            setSubmitLoading(true);
            tog_center();
            try {
                const opts = {
                    collection_status_id: selectedStatus ? selectedStatus : null
                };
                // console.log("opts", opts);
                const response = await put('/api/erp/collections/' + rowData.id, opts);
                setRefresh(makeid(5));
                resetForm();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
            setSubmitLoading(false);

        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="warning" onClick={tog_center}>
                    <i className="fas fa-check"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Change Collection Status | {rowData.collection_code}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        {/* Status ID */}
                        <div className="btn-group text-primary">
                            {statusList.map((option) => (
                                <div className="btn-wrapper" key={option.id}>
                                    <input
                                        type="radio"
                                        id={`option${option.id}`}
                                        name="options"
                                        disabled={option.id <= 3}
                                        value={option.id}
                                        checked={selectedStatus === option.id}
                                        onChange={() => changeStatus(option.id)}
                                        style={{ display: 'none' }} // Hide the actual radio button
                                    />
                                    <label
                                        htmlFor={`option${option.id}`}
                                        className={`btn ${selectedStatus === option.id ? 'active' : ''}`}
                                    >
                                        {option.name}
                                    </label>
                                    <div className="extra-info"><p>{option.additionalMessage}</p></div>
                                </div>
                            ))}
                        </div>
                        <div className="btn-group-hz text-primary mb-3">
                            {confirmList.map((option) => (
                                <div className={`btn-wrapper ${option.id == 4 ? 'green' : 'red'}`} key={option.id}>
                                    <input
                                        type="radio"
                                        id={`option${option.id}`}
                                        name="options"
                                        value={option.id}
                                        checked={selectedStatus === option.id}
                                        onChange={() => changeStatus(option.id)}
                                        style={{ display: 'none' }} // Hide the actual radio button
                                    />
                                    <label
                                        htmlFor={`option${option.id}`}
                                        className={`btn ${option.id == 4 ? 'green' : 'red'} ${selectedStatus === option.id ? 'active' : ''}`}
                                    >
                                        {option.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default ApprovalModal
