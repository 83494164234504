import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, Row, Col, InputGroup, ListGroup, ListGroupItem } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import moment from "moment";

import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axiosApi from 'helpers/api_helper';
import { Column } from "primereact/column";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { TON_TO_LITER } from "constants/value";
import useAuth from "hooks/useAuth";

import notFoundImage from "../../../../assets/images/users/user-blank.png";

const PickupManualTransaction = ({ user, setRefresh }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [username, setUsername] = useState('')
    const [weight, setWeight] = useState(0);
    const [volume, setVolume] = useState(0);
    const [amount, setAmount] = useState(0);
    const [accepted, setAccepted] = useState(true);
    const [notify, setNotified] = useState(true);

    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false);
    const { showToast } = useAuth();
    const [showResults, setShowResults] = useState(false);
    const tog_manual = () => {
        setmodal_center(!modal_center);
    };
    const handleConversionWeightToVolume = (e) => {
        validation.handleChange(e);
        const value = e.target.value
        if (value !== '') {
            const weightInput = parseFloat(value);
            setWeight(weightInput)
            setVolume(simplify(weightInput * conversionFactor, 3))
        } else {
            setWeight(0)
            setVolume(0)
        }
    }
    const conversionFactor = TON_TO_LITER / 1000;

    const handleAmount = async (e) => {
        validation.handleChange(e);
        const value = e.target.value;
        setAmount(e.target.value);
    }

    const handleConversionVolumeToWeight = (e) => {
        validation.handleChange(e);
        const value = e.target.value
        if (value !== '') {
            const volumeInput = parseFloat(value);
            setVolume(volumeInput)
            setWeight(simplify(volumeInput / conversionFactor, 3))
        } else {
            setWeight(0)
            setVolume(0)
        }
    }

    const resetModalForm = () => {
        setUsername('')
        setVolume(0)
        setWeight(0)
        setAmount(0)
        setAccepted(true)
        setNotified(true)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // box: process.env.REACT_APP_ENV == 'prod' ? 'Noovoleum HQ' : (process.env.REACT_APP_ENV == 'dev' ? 'Unit 1 QA' : 'UCOBOX-1'),
            weight: String(weight),
            volume: String(volume),
            amount: String(amount),
        },
        validationSchema: Yup.object().shape({
            weight: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
                .required('This value is required'),
            volume: Yup.string()
                .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, 'Invalid input')
                .required('This value is required'),
            amount: Yup.string()
        }),

        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, box_id: user.box_id, user_id: user.user_id, pickup_id: user._id, volume: parseFloat(volume), weight: parseFloat(weight), accepted, notify, start: moment.utc().format(), end: moment.utc().add(1, 'minute').format(), amount: parseFloat(amount) };
                // console.log(opts)
                const response = await axiosApi.post('/api/admin/pickup/manual-transaction', opts);
                // console.log(response)
                setRefresh(response.data.random);
                resetForm();
                tog_manual();
                showToast(response.data);
                resetModalForm();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2 d-flex justify-content-center">
                <Button color="primary" onClick={tog_manual}>Enter Pick Up Manually</Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_manual} centered>
                <ModalHeader className="mt-0" toggle={tog_manual}>Enter Pick Up Manually : {user.username}</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>

                        {/* <div className="mb-3">
                            <Label className="form-label">Box</Label>
                            <Input
                                name="box"
                                placeholder="Enter Box"
                                type="text"
                                disabled
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.box || ""}
                                invalid={
                                    validation.touched.box && validation.errors.box ? true : false
                                }
                            />
                            {
                                validation.touched.box && validation.errors.box ? (
                                    <FormFeedback type="invalid">{validation.errors.box}</FormFeedback>
                                ) : null}
                        </div> */}
                        <div className="mb-3">
                            <Row>
                                <Col xs={6}>
                                    <Label className="form-label">Weight ({simplify(user.uco_weight, 3)} kg estimated)</Label>
                                    <Input
                                        name="weight"
                                        placeholder="Enter Weight"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleConversionWeightToVolume}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.weight || ""}
                                        invalid={
                                            validation.touched.weight && validation.errors.weight ? true : false
                                        }
                                    />
                                    {validation.touched.weight && validation.errors.weight ? (
                                        <FormFeedback type="invalid">{validation.errors.weight}</FormFeedback>
                                    ) : null}
                                </Col>
                                <Col xs={6}>
                                    <Label className="form-label">Volume ({simplify(user.uco_volume, 3)} liter estimated)</Label>
                                    <Input
                                        name="volume"
                                        placeholder="Enter Volume"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleConversionVolumeToWeight}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.volume || ""}
                                        invalid={
                                            validation.touched.volume && validation.errors.volume ? true : false
                                        }
                                    />
                                    {validation.touched.volume && validation.errors.volume ? (
                                        <FormFeedback type="invalid">{validation.errors.volume}</FormFeedback>
                                    ) : null}
                                </Col>

                            </Row>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col xs={12}>
                                    <Label className="form-label">Bonus (optional)</Label>
                                    <Input
                                        name="amount"
                                        placeholder="Enter Amount Override"
                                        type="text"
                                        autoComplete="off"
                                        inputMode="numeric"
                                        onChange={handleAmount}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.amount || ""}
                                        invalid={
                                            validation.touched.amount && validation.errors.amount ? true : false
                                        }
                                    />
                                    {validation.touched.amount && validation.errors.amount ? (
                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                    ) : null}
                                </Col>

                            </Row>
                        </div>
                        <div className="mb-3 d-flex align-items-center gap-2">
                            <Label className="form-label m-0">Accepted :</Label>
                            <InputSwitch checked={accepted} onChange={(e) => setAccepted(e.value)} />
                        </div>
                        <div className="mb-3 d-flex align-items-center gap-2">
                            <Label className="form-label m-0">Notify user :</Label>
                            <InputSwitch checked={notify} onChange={(e) => setNotified(e.value)} />
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default PickupManualTransaction
