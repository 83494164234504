import { currencyFormatter } from 'helpers/functions';
import usePrice from 'hooks/usePrice';
import React, { Component, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const TradingChart = () => {
    const { tradingSeries, tradingOptions } = usePrice();
    // const [tradingOptions, setTradingOptions] = useState({
    //     colors: ['#70ad47'],
    //     chart: {
    //         zoom: {
    //             enabled: false
    //         },
    //         toolbar: {
    //             show: false,
    //         }
    //     },
    //     legend: {
    //         show: false
    //     },
    //     dataLabels: {
    //         enabled: false
    //     },
    //     stroke: {
    //         curve: 'straight',
    //         width: 2,
    //     },
    //     grid: {
    //         borderColor: '#f8f8fa',
    //         row: {
    //             colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
    //             opacity: 0.5
    //         },
    //     },
    //     yaxis: {
    //         labels: {
    //             formatter: (value) => {
    //                 return `$${currencyFormatter(value)}`
    //             }
    //         },
    //         title: {
    //             text: 'Price'
    //         }
    //     },
    //     xaxis: {
    //         categories: [],
    //         type: 'category',
    //     },
    //     tooltip: {

    //     },
    //     annotations: {

    //     }
    // })
    return (
        <React.Fragment>
            <ReactApexChart options={tradingOptions} series={tradingSeries} type="line" height="250" />
        </React.Fragment>
    );
}

export default TradingChart;