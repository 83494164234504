import { createContext, useState } from "react";

const DailyTipContext = createContext({});

export const DailyTipProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [datas, setDatas] = useState();
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const availableLanguageList = [
        { name: 'English', value: 'en' },
        { name: 'Indonesian', value: 'id' }, 
    ];
    return (
        <DailyTipContext.Provider value={{ availableLanguageList, refresh, setRefresh, datas, setDatas, loading, setLoading, totalRecords, setTotalRecords }}>
            {children}
        </DailyTipContext.Provider>
    )
}

export default DailyTipContext;