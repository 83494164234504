import { createContext, useState } from "react";

const GroupContext = createContext({});

export const GroupProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [groups, setGroups] = useState();
    const [loading, setLoading] = useState(false);

    const groupType = [
        { name: 'Event', value: 'Event' },
        { name: 'Partner', value: 'Partner' },
        { name: 'NGO', value: 'NGO' },
    ];
    return (
        <GroupContext.Provider value={{ refresh, setRefresh, groups, setGroups, loading, setLoading, groupType }}>
            {children}
        </GroupContext.Provider>
    )
}

export default GroupContext;