import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import { post, del, get, put } from "../../../helpers/api_helper";
import useAdmin from "hooks/useAdmin";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import '../../../assets/css/style.css'
import useAuth from "hooks/useAuth";

const AddUserModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [generatedQRCode, setGeneratedQRCode] = useState(null);
    const { setRefresh, levelOptions } = useAdmin();
    const { showToast } = useAuth();

    const tog_center = async () => {
        setButtonDisable(true);
        try {
            const response = await axiosApi.get('/api/admin/otp/generate');
            setGeneratedQRCode(response.data.base32_url ?? null);
            
            setmodal_center(!modal_center);
      
            
        } catch (error) {
            console.error(error);
        }

    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            code:''
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required(
                "This value is required"
            ).matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits')}),
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await axiosApi.post('/api/admin/otp/apply', values);
                //change this to a nicer one
            
                if (response.data.otp_verified) {
                    //reload page
                    window.location.reload();        
                } else {
                    showToast(response.data);
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                    showToast(response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });
    const OTPQRImage = () => (
            <center><img src={generatedQRCode} width="250" alt="image"/> </center> 
    )


    return (
        <>
        <div className="my-2">
            <Button disabled={buttonDisable} color="primary" onClick={tog_center}> Enable 2FA</Button>
        </div>
        <Modal id="2faModal" isOpen={modal_center}  centered>
            <ModalHeader className="mt-0" >Enable 2 Factor Authentication</ModalHeader>
            <ModalBody>
                <Form 
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <p>To initiate the Two-Factor Authentication (2FA) process, please follow this steps:</p>
        <ol>
            <li>Scan the provided QR code using your preferred authenticator application (Google Authenticator , Authy , Microsoft Authenticator , etc).</li>
            <li>After Scanning the QR. a new account will appear. Enter the code for <b>Noovoleum.com</b> displayed in the authenticator app below:</li>
            <li><b>Warning : Once 2FA is enabled you must enter the 2FA code to login. Don't lose the key. Create a backup of the key if possible</b></li>
        </ol>

<hr/>

<div className="mb-3">
    <OTPQRImage />
    
    </div>
                    <div className="mb-3">
                        <Label className="form-label">Enter Code</Label>
                        <Input
                            name="code"
                            placeholder="Code from Authenticator App"
                            type="text"
                            maxLength="6"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.code || ""}
                            invalid={
                                validation.touched.code && validation.errors.code ? true : false
                            }
                        />
                        {validation.touched.code && validation.errors.code ? (
                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                        ) : null}
                    </div>
                 
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
               
                        <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                            Enable 2FA
                        </Button>{" "}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
        </>
    )
    
}

export default AddUserModal
