import { createContext, useState } from "react";

const IncidentContext = createContext({});

export const IncidentProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    return (
        <IncidentContext.Provider value={{
            refresh, setRefresh, loading, setLoading, datas, setDatas, totalRecords, setTotalRecords
        }}>
            {children}
        </IncidentContext.Provider>
    )
}

export default IncidentContext;