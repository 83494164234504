import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import useBonusTier from "hooks/useBonusTier";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import { update } from "lodash";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { InputSwitch } from 'primereact/inputswitch';

const AddTierModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh } = useBonusTier();
    const { showToast } = useAuth();
    const [status, setStatus] = useState(true);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            bonus_value: '',
            minimum_volume: '',
            color: '#FFFFFF',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            bonus_value: Yup.number()
                .required("This value is required")
                .moreThan(0, "Amount Paid must be greater than 0")
                .max(100, "Amount Paid must be less than or equal to 100"),
            minimum_volume: Yup.number()
                .required("This value is required"),
            color: Yup.string().required(
                "This value is required"
            )
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, active: status == true ? 1 : 0 };

                const response = await post('/api/admin/setting/bonus-tier', opts);
                status: status == true ? 1 : 0
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Bonus Tier </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Bonus Tier</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>

                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Bonus Percentage (%)</Label>
                            <Input
                                name="bonus_value"
                                placeholder="Enter Percentage"
                                type="number"
                                style={{ width: '200px' }}
                                step={0.001}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bonus_value || ""}
                                invalid={validation.touched.bonus_value && validation.errors.bonus_value}
                            />

                            {validation.touched.bonus_value && validation.errors.bonus_value ? (
                                <FormFeedback type="invalid">{validation.errors.bonus_value}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Minimum Collected (liter)</Label>
                            <Input
                                name="minimum_volume"
                                placeholder="Enter Minimum Volume"
                                type="number"
                                step={0.001}
                                style={{ width: '200px' }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.minimum_volume || ""}
                                invalid={
                                    validation.touched.minimum_volume && validation.errors.minimum_volume ? true : false
                                }
                            />
                            {validation.touched.minimum_volume && validation.errors.minimum_volume ? (
                                <FormFeedback type="invalid">{validation.errors.minimum_volume}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Color</Label>
                            <div className="d-flex align-items-center">
                                <Input
                                    name="color"
                                    placeholder="Enter Color"
                                    type="color"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.color || ""}
                                    invalid={
                                        validation.touched.color && validation.errors.color ? true : false
                                    }
                                    style={{ marginRight: '10px', width: '100px', fontSize: '12px' }} // Adjust the styles here
                                />
                                <div style={{ fontSize: '12px' }}> {/* Adjust the font size here */}
                                    <span>Hex Value: </span>
                                    <span>{validation.values.color}</span>
                                </div>
                            </div>
                            {validation.touched.color && validation.errors.color ? (
                                <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-5">
                            <h4 className="card-title mb-3">Active</h4>
                            <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddTierModal
