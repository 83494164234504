import { createContext, useState } from "react";
import { getFormattedUTCDate } from "helpers/functions"

const PromotionContext = createContext({});

export const PromotionProvider = ({ children }) => {
    const [referralRefresh, setReferralRefresh] = useState('');
    const [referrals, setReferrals] = useState();
    const [referralLoading, setReferralLoading] = useState(false);
    const [promotionRefresh, setPromotionRefresh] = useState('');
    const [promotions, setPromotions] = useState();
    const [promotionLoading, setPromotionLoading] = useState(false);
    const [partnerPromotionRefresh, setPartnerPromotionRefresh] = useState('');
    const [partnerPromotions, setPartnerPromotions] = useState();
    const [partnerPromotionLoading, setPartnerPromotionLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [promoTotalRecords, setPromoTotalRecords] = useState(0);
    const [partnerPromoTotalRecords, setPartnerPromoTotalRecords] = useState(0);
    const [partnerTotalRecords, setPartnerTotalRecords] = useState(0);
    const [partnerRefresh, setPartnerRefresh] = useState('');
    const [partners, setPartners] = useState();
    const [partnerLoading, setPartnerLoading] = useState(false);

    const today = new Date()
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    endOfMonth.setHours(23, 59, 59, 999)
    const startDate = getFormattedUTCDate(startOfMonth)
    const endDate = getFormattedUTCDate(endOfMonth)

    const paymentPeriod = [
        { name: "All Time", value: "all" },
        { name: "Today", value: "today" },
        { name: "Last 7 Days", value: "week" },
        { name: "This Month", value: "month" },
        { name: "Last Month", value: "lastMonth" },
        { name: "This Year", value: "year" },
        // { name: 'Custom', value: 'custom' },
    ]
    const typeList = [
        { label: 'User', value: 'User' },
        { label: 'Partner', value: 'Partner' },
    ]
    const frequencyList = [
        { label: 'Single-use Promotion', value: 'single' },
        { label: 'Recurring Promotion', value: 'recurrent' },
    ]
    const triggeredList = [
        { label: 'Referral', value: 'Referral' },
        { label: 'Global', value: 'Global' },
        { label: 'Registration', value: 'Registration' },

    ]

    const [promoLazyState, setPromolazyState] = useState({
        searchText: '',
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            }
        }
    });
    const [partnerPromoLazyState, setPartnerPromolazyState] = useState({
        searchText: '',
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            }
        }
    });
    const [partnerLazyState, setPartnerLazyState] = useState({
        searchText: '',
        startDate: startDate,
        endDate: endDate,
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            }
        }
    });
    const countryList = [
        { label: 'Indonesia', value: 'IDR' },
        { label: 'Singapore', value: 'SGD' },
    ]
    return (
        <PromotionContext.Provider value={
            {
                referralRefresh, setReferralRefresh, referrals, setReferrals, referralLoading, setReferralLoading,
                promotionRefresh, setPromotionRefresh, promotions, setPromotions, promotionLoading, setPromotionLoading,
                partnerRefresh, setPartnerRefresh, partners, setPartners, partnerLoading, setPartnerLoading,
                totalRecords, setTotalRecords, promoTotalRecords, setPromoTotalRecords, partnerTotalRecords, setPartnerTotalRecords,
                promoLazyState, setPromolazyState, partnerLazyState, setPartnerLazyState,
                partnerPromotionRefresh, setPartnerPromotionRefresh, partnerPromotions, setPartnerPromotions,
                partnerPromotionLoading, setPartnerPromotionLoading,
                partnerPromoLazyState, setPartnerPromolazyState,
                partnerPromoTotalRecords, setPartnerPromoTotalRecords,
                typeList, triggeredList, countryList, paymentPeriod, frequencyList
            }}>
            {children}
        </PromotionContext.Provider>
    )
}

export default PromotionContext;