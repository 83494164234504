import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap'

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { simplify, startTime, endTime, debounce, badgeAttributes } from "helpers/functions";
const TrackingModal = ({ rowData, unitIdParam }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const unitId = queryParams.get('unit_id');
    const spinnerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional
        zIndex: 9999
    };
    const connectorStyle = {
        width: '50px',
        height: '5px',
        alignItems: 'center',
        backgroundColor: '#70ad47',
        margin: '0 auto'

    };
    const cardBodyStyle = {
        position: 'relative',
        minHeight: '200px' // Set a minimum height if needed
    };
    const containerStyle = {
        display: 'flex',
        alignItems: 'flex-end', // Vertically centers the children
        flexDirection: 'row',
        flexWrap: 'wrap'
    };
    const [loading, setLoading] = useState(false);
    const [trans, setTrans] = useState([]);
    const [modal_center, setmodal_center] = useState(false);
    const [datas, setDatas] = useState([]);

    const axiosApi = useAxiosPrivate();

    const roundedWeight = (data) => {
        return simplify(data.weight)
    }
    const fullName = (data) => {
        return <Link to={`/userlist`} state={data?.uco_unit?.fullname} >
            {data?.uco_unit?.fullname}
        </Link>
    }
    const handleRedirect = () => {
        // window.location.href = `/assets?tab=1`;
    };

    const boxLink = (data) => {
        return <Link to={`/deployments`} state={data?.uco_unit?.external_box_name} >
            {data?.uco_unit?.external_box_name}
        </Link>
    }

    const currentLocation = (data) => {
        var valueState = data?.tank?.type_id == 1 ? `searchBox=${data?.tank?.name}` : data?.tank?.type_id == 2 ? `searchTank=${data?.tank?.name}` : `searchTransport=${data?.tank?.name}`;
        return <a
            href={`/assets?tab=${data?.tank?.type_id == '3' ? 4 : data?.tank?.type_id}&${valueState}`}
        >
            {data?.tank?.name}
        </a >
    }
    const inputOrder = (data) => {
        let orderStatus = data?.order?.order_status_id
        var activeTab = 1;
        if (orderStatus == 3) {
            activeTab = 2;
        } else if (orderStatus == 4) {
            activeTab = 3;
        }

        return data?.order?.order_code ? <Link to={'/orders?tab=' + activeTab} state={data?.order?.order_code}> {data?.order?.order_code} </Link> : '-'
    }
    const badgeStatus = (data) => {
        var badgetText = 'success';
        var statusText = 'Open';
        if (data.is_sold == true) {
            badgetText = 'danger';
            statusText = 'Sold';
        }


        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>

        )
    }

    const formatDateTime = (dateString) => {
        return dateString ? moment(dateString).format('MM/DD/YYYY HH:mm:ss') : ''
    }

    const getTransactionHistory = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/inventory/tank-uco-units/get-by-code/${unitId || unitIdParam}`);
            const result = response.data.results;
            setDatas(result)
            setTrans(result.logs);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        if (modal_center) {
            handleModalClose()
        }
        setmodal_center(!modal_center);
        removeBodyCss();
    };
    const trxId = (data) => {
        return <Link to={`/transactions/${data?.uco_unit?.external_transaction_code}`} >
            {data?.uco_unit?.external_transaction_code}
        </Link>
    }
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const qualityData = (data, field) => {
        let quality = JSON.parse(data?.quality || '{}')?.[field] ?? '-'
        return quality;

    };

    useEffect(() => {
        if (modal_center) {
            setLoading(true)
            setTimeout(() => {
                getTransactionHistory()
            }, 300)
        };
    }, [modal_center, unitIdParam, unitId]);
    const handleModalClose = () => {
        queryParams.delete('unit_id');
        navigate(`?${queryParams.toString()}`);
    };
    return (
        <>
            <span style={{ cursor: 'pointer' }} className="text-primary" onClick={tog_center}>
                {rowData?.uco_unit_code}
            </span>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">Track | {datas?.uco_unit_code}</h4>
                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">

                    <Row>
                        <Col xl={4}>
                            <Card style={cardBodyStyle}>
                                <div className="card-header">
                                    <h4 className="card-title">UCO Unit Information</h4>
                                </div>
                                {loading ?
                                    <div style={spinnerStyle}>
                                        <Spinner color="primary" />
                                    </div>
                                    :
                                    <CardBody>
                                        {loading ?
                                            <div style={spinnerStyle}>
                                                <Spinner color="primary" />
                                            </div> :

                                            <div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">User</p>
                                                    <p className="font-size-15 mb-1">{fullName(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">UCO Address</p>
                                                    <p className="font-size-15 mb-1">{datas?.uco_unit?.address}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Quantity (kg)</p>
                                                    <p className="font-size-15 mb-1">{roundedWeight(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Transaction ID</p>
                                                    <p className="font-size-15 mb-1">{trxId(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Order ID</p>
                                                    <p className="font-size-15 mb-1">{inputOrder(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Current Location</p>
                                                    <p className="font-size-15 mb-1">{currentLocation(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Origin Location</p>
                                                    <p className="font-size-15 mb-1">{boxLink(datas)}</p>
                                                </div>
                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Collected Time</p>
                                                    <p className="font-size-15 mb-1">{formatDateTime(datas?.uco_unit?.external_created_date)}</p>
                                                </div>

                                                <div className="pb-1">
                                                    <p className="text-muted mb-0">Quality</p>
                                                    <ul className="list-unstyled mb-0 ">
                                                        <li><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Hydrocarbon Level : {qualityData(datas, 'hydrocarbon_level')}</li>
                                                        <li><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Alcohol Level : {qualityData(datas, 'alcohol_level')}</li>
                                                        <li><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Raw Max Level : {qualityData(datas, 'raw_max_level')}</li>
                                                        <li><i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>Raw Interface Level : {qualityData(datas, 'raw_interface_level')}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                }
                            </Card>
                        </Col>
                        <Col xl={8}>
                            <Card>
                                <div className="card-header">
                                    <h4 className="card-title">UCO Unit History</h4>
                                </div>
                                <CardBody style={cardBodyStyle}>
                                    {loading ?
                                        <div style={spinnerStyle}>
                                            <Spinner color="primary" />
                                        </div>
                                        :
                                        <div>
                                            <ul className="activity-checkout mb-0 px-4 mt-3">
                                                {trans.map((element, index) => (
                                                    <li className="checkout-item" style={{ borderLeft: `0px solid transparent` }}>
                                                        <div className="avatar-sm checkout-icon p-1">
                                                            <div className="avatar-title rounded-circle" style={{
                                                                backgroundColor: `${element.created_at ? '#70AD47' : '#E9ECEF'}`,
                                                            }}>
                                                                <i className={`mdi ${element.created_at ? 'mdi-map-marker-check text-white' : 'mdi-map-marker'} font-size-20`} style={{ color: '#70ad47' }}></i>
                                                            </div>
                                                        </div>
                                                        <div className="feed-item-list">
                                                            <Row>
                                                                <Col xl={5} lg={5} md={5}>
                                                                    <h5 className="font-size-16 mb-1">{element.tank_destination_name}</h5>
                                                                    <p className="text-info mb-0"> {element.tankucounit_uco_unit_code}</p>
                                                                    <p className="text-muted text-truncate mb-0"> {formatDateTime(element.created_at)}</p>
                                                                    <div className="">
                                                                        {/* <span className={`badge bg-success text-t-success`}> */}
                                                                        {element.weight} kg
                                                                        {/* </span> */}
                                                                    </div>
                                                                    <p className="text-muted text-truncate mb-0">
                                                                        {badgeStatus(element)}
                                                                    </p>
                                                                    <p className="text-muted text-truncate mb-0">
                                                                        {badgeAttributes(element)}
                                                                    </p>
                                                                </Col>
                                                                {Object.keys(element.sibling).length > 0 && <Col xl={2} lg={2} md={2} className="text-center align-items-end d-flex">
                                                                    <span className={`badge bg-warning text-t-warning`}>
                                                                        Slice
                                                                    </span>
                                                                    {/* <div style={connectorStyle}></div> */}
                                                                </Col>}
                                                                {Object.keys(element.sibling).length > 0 &&
                                                                    <Col xl={5} lg={5} md={5} style={{ opacity: 0.5, textAlign: 'right' }}>
                                                                        {element.sibling.is_have_child ?
                                                                            <Link to={`/assets?tab=4&unit_id=${element.sibling.tankucounit_uco_unit_code}`} className="text-dark">
                                                                                <div>
                                                                                    <h5 className="font-size-16 mb-1">{element.sibling.tank_destination_name}</h5>
                                                                                    <p className="text-info mb-0"> {element.sibling.tankucounit_uco_unit_code}</p>
                                                                                    <p className="text-muted text-truncate mb-0"> {formatDateTime(element.sibling.created_at)}</p>
                                                                                    <div className="">
                                                                                        {/* <span className={`badge bg-success text-t-success`}> */}
                                                                                        {element.sibling.weight} kg
                                                                                        {/* </span> */}
                                                                                    </div>
                                                                                    <p className="text-muted text-truncate mb-0">
                                                                                        {badgeStatus(element.sibling)}
                                                                                    </p>
                                                                                    <p className="text-muted text-truncate mb-0">
                                                                                        {badgeAttributes(element.sibling)}
                                                                                    </p>
                                                                                </div>
                                                                            </Link>
                                                                            :
                                                                            <div>
                                                                                <h5 className="font-size-16 mb-1">{element.sibling.tank_destination_name}</h5>
                                                                                <p className="text-info mb-0"> {element.sibling.tankucounit_uco_unit_code}</p>
                                                                                <p className="text-muted text-truncate mb-0"> {formatDateTime(element.sibling.created_at)}</p>
                                                                                <div className="">
                                                                                    {/* <span className={`badge bg-success text-t-success`}> */}
                                                                                    {element.sibling.weight} kg
                                                                                    {/* </span> */}
                                                                                </div>
                                                                                <p className="text-muted text-truncate mb-0">
                                                                                    {badgeStatus(element.sibling)}
                                                                                </p>
                                                                                <p className="text-muted text-truncate mb-0">
                                                                                    {badgeAttributes(element.sibling)}
                                                                                </p>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            {Object.keys(element.sibling).length > 0 &&
                                                                <div>
                                                                    <div class="horizontal-line"></div>
                                                                    <div class="vertical-line"></div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </ModalBody>
            </Modal>
        </>
    )
}

export default TrackingModal
