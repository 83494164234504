import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
//import row and col
import { Row, Col,Input } from 'reactstrap';

const PhoneNumberInput = ({ data }) => {

  
    const axiosApi = useAxiosPrivate();
    const userId = data.id;
    var isEnabled = true;
    if (data && data.hasOwnProperty('collectionPoint')) {
        isEnabled = data.collectionPoint;
    }
    const [collectionPoint, setCollectionPoint] = useState(isEnabled);

    const [phonePrefix,setPhonePrefix] = useState(data.phone?.prefix || "")
    const [phoneNumber,setPhoneNumber] = useState(data.phone?.number || "")

    const [prevPhonePrefix,setPrevPhonePrefix] = useState("")
    const [prevPhoneNumber,setPrevPhoneNumber] = useState("")
    const { showToast } = useAuth();
    


    const handleSwitchChange = async (value) => {
        if (phoneNumber == prevPhoneNumber) {
            return
        }

        const body = { phonePrefix: phonePrefix, phoneNumber:phoneNumber,collectionPoint:data.collectionPoint, id: userId };
        const response = await axiosApi.post('/api/admin/engineer/box/update-collection-point', body)

        setPrevPhoneNumber(phoneNumber);
        setPrevPhonePrefix(phonePrefix)
        showToast(response.data);
    };

    return (
        <div>
           <Row style={{ zoom: 0.8}}>
                        <Col xs={4} style={{paddingRight:3}}>
                     
                        <Input
                            name="phonePrefix"
                         
                            type="text"
                            bsSize="lg"
                            className="mb-2"
                            onChange={(e) =>  {setPhonePrefix(e.target.value);}}
                            value={phonePrefix || ""}
                            onBlur={handleSwitchChange}
                            
                        
                        />
                        </Col>
                        <Col xs={8} style={{paddingLeft:3}}>
                        
                        
                        <Input
                            name="phoneNumber"
                       
                            type="text"
                            bsSize="lg"
                            className="mb-2"
                            onChange={(e) => {setPhoneNumber(e.target.value);}}
                            onBlur={handleSwitchChange}
                            value={phoneNumber || ""}
                            
                          
                        />
                        </Col>
                        </Row> 
        </div>
    );
};

export default PhoneNumberInput;
