import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import useAuth from "hooks/useAuth";

import axiosApi from 'helpers/api_helper';
const ChangeUserStatusModal = ({ user, setRefresh }) => {
    const { showToast } = useAuth();

    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const handleConfirm = async () => {
        var status = user.active == "1" ? false : true
        const opts = { status: status };

        const response = await axiosApi.post(`/api/admin/support/userStatus/${user._id}`, opts);
        setRefresh(response.data.random)
        showToast(response.data);
        toggleModal();
    };

    return (
        <>
            <Button type="button" color={user.active == "1" ? "danger" : "warning"} onClick={toggleModal}>
                {user.active == "1" ? "Ban / Disable User" : "Enable User"}
            </Button>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Confirm Action</ModalHeader>
                <ModalBody>
                    Are you sure want to {user.active == "1" ? "ban/disable" : "enable"} this user?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    );
};

export default ChangeUserStatusModal
