import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";


const NotifLogExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Notification_Log_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}.csv`

    const headers = [
        { label: "ID", key: "_id" },
        { label: "Title", key: "title" },
        { label: "Body", key: "body" },
        { label: "Url", key: "payload" },
        { label: "Sent to user", key: "user_count" },
        { label: "Read by user", key: "user_read_count" },
        { label: "Sender", key: "admin_name" },
        { label: "Date", key: "createdAt" },
        { label: "Status", key: "status" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/support/broadcast-notification?searchText=${lazyState.searchText}`);
            const result = response.data.logs;
            result.forEach(obj => {
                obj.createdAt = moment(obj.createdAt).format('MM/DD/YYYY HH:mm:ss');
                if (obj.status) {
                    obj.status = 'success'
                } else {
                    obj.status = 'fail'
                }
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default NotifLogExportCSV