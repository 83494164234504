import { createContext, useState } from "react";

const BonusTierContext = createContext({});

export const BonusTierProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [tiers, setTiers] = useState();
    const [loading, setLoading] = useState(false);

    return (
        <BonusTierContext.Provider value={{ refresh, setRefresh, tiers, setTiers, loading, setLoading}}>
            {children}
        </BonusTierContext.Provider>
    )
}

export default BonusTierContext;