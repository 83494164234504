import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, Row, Col } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import Flatpickr from "react-flatpickr";
import { formatThousandSeparator } from "helpers/functions";
import { TON_TO_LITER } from "constants/value";
import InputMask from "react-input-mask";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../helpers/api_helper";
import deleteIcon from "../../../../assets/images/delete-icon.png";
import Select from "react-select"

import '../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useOrder from "hooks/useOrder";

const EditModal = ({ rowData }) => {
    const { showToast } = useAuth();
    const [defaultId, setDefaultId] = useState(rowData.order_code);

    const existFile = JSON.parse(rowData?.document_images) || [];
    function formatDate(dateString) {
        const dateObject = new Date(dateString);
        // Extracting year, month, and day
        const year = dateObject.getFullYear();
        // JavaScript months are zero-based, so we add 1
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        // Forming the desired date string
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
    }
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { refresh, setRefresh } = useOrder();
    const [selectedCompany, setSelectedCompany] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [dueDate, setDueDate] = useState(rowData.due_date ? formatDate(rowData.due_date) : null);
    const [boxImages, setBoxImages] = useState(existFile);
    const limitImagesCount = 5
    const [limitImages, setLimitImages] = useState(false);
    const phoneRegExp = /^[\+0-9]?[1-9]{1,4}[ \-]*(\([0-9]{2,3}\)[ \-]*|[0-9]{2,4}[ \-]*)*[0-9]{3,4}[ \-]*[0-9]{3,4}?$/

    const conversionFactor = TON_TO_LITER / 1000;
    const [weight, setWeight] = useState(rowData.weight);
    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ name: "None", value: "", id: '' })
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
                if (element.id === rowData.company_id) {
                    setSelectedCompany(detail)
                }
            })
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setBoxImages(existFile);
        setDueDate(rowData.due_date);
        setDefaultId(rowData.order_code)
        setWeight(rowData.weight);
        getCompanies();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getCompanies();
        }
    }, [refresh, modal_center]);

    const [uploading, setUploading] = useState(false);

    const storeFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)

        // validation for no more than 10 images per box
        if (validFiles.length + boxImages.length > limitImagesCount) {
            setLimitImages(true)
            return
        }

        setUploading(true);
        await Promise.all(
            validFiles.map(async (image) => {
                const formData = new FormData();
                formData.append('image', image)

                const response = await axiosApi.post('/api/admin/asset/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })

                setBoxImages(boxImages => [response.data.url, ...boxImages])
            })
        )
        setUploading(false)
    }

    const deleteImage = (e) => {
        const value = e.currentTarget.getAttribute('data-src');
        const newArray = boxImages.filter(image => image !== value);
        setBoxImages(newArray);
    }
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    const handleInputChange = (e) => {
        let { name, value } = e.target;

        // Allow only digits and decimal points
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        const formattedValue = formatThousandSeparator(value);
        validation.setFieldValue(name, formattedValue); // Update formik's value
    };
    const removeCommas = (value) => value.replace(/,/g, "");

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            order_code: rowData.order_code,
            purchase_id: rowData.purchase_id,
            customer_name: rowData.customer_name,
            customer_phone: rowData.customer_phone,
            weight: formatThousandSeparator(String(rowData.weight)),
            // price: rowData.price,
            // total_price: rowData.total_price,
            customer_email: rowData.customer_email,
        },
        validationSchema: Yup.object().shape({
            order_code: Yup.string().required("Order ID is required"),
            customer_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
            weight: Yup.string()
                // .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
                .required("This value is required"),
            // price: Yup.number()
            //     .required("This value is required")
            //     .moreThan(0, "Price must be greater than 0"),
            // total_price: Yup.number()
            //     .required("This value is required")
            //     .moreThan(0, "Total Price must be greater than 0"),

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    ...values,
                    weight: removeCommas(validation.values.weight), // Clean up the weight input
                    company_id: selectedCompany?.id ? selectedCompany.id : null,
                    due_date: dueDate ? dueDate : null,
                    document_images: boxImages
                };
                // console.log("opts", opts);
                const response = await put('/api/erp/orders/' + rowData.id, opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Order</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Order ID</Label>
                            {/* <Input
                                name="order_code"
                                placeholder="Enter Order ID"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.order_code || ""}
                                invalid={validation.touched.order_code && validation.errors.order_code}
                            /> */}
                            <InputMask
                                name="order_code"
                                mask="aaaa-aa-aa-99999"  // Mask for date format
                                value={defaultId}  // Controlled input value
                                onChange={handleIdChange}  // Update state on change
                                invalid={validation.touched.order_code && validation.errors.order_code}
                                maskChar={null}  // Removes default mask characters (like "_")
                            >
                                {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                            </InputMask>
                            {validation.touched.order_code && validation.errors.order_code ? (
                                <FormFeedback type="invalid">{validation.errors.order_code}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Purchasing ID */}
                        <div className="mb-3">
                            <Label className="form-label">Buyer Purchase Order Number</Label>
                            <Input
                                name="purchase_id"
                                placeholder="Enter Buyer Purchase Order Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.purchase_id || ""}
                                invalid={validation.touched.purchase_id && validation.errors.purchase_id}
                            />
                            {validation.touched.purchase_id && validation.errors.purchase_id ? (
                                <FormFeedback type="invalid">{validation.errors.purchase_id}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Company ID */}
                        <div className="mb-3">
                            <Label className="form-label">Buyer ID</Label>
                            <div className="col">
                                <Select
                                    name={`company_id`}
                                    value={selectedCompany}
                                    onChange={value => {
                                        setSelectedCompany(value)
                                        validation.setFieldValue("customer_email", value.contact_email)
                                        validation.setFieldValue("customer_name", value.contact_name)
                                        validation.setFieldValue("customer_phone", value.contact_phone)
                                    }}
                                    options={companyList}
                                    optionLabel="label"
                                    classNamePrefix="select2-selection"
                                />
                                {/* <Dropdown
                                    value={selectedCompany || ''}
                                    onChange={changeCompany}
                                    options={companyList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                /> */}
                            </div>
                        </div>
                        {/* Buyer name */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Name</Label>
                            <Input
                                name="customer_name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.customer_name || ""}
                                invalid={validation.touched.customer_name && validation.errors.customer_name}
                            />
                            {validation.touched.customer_name && validation.errors.customer_name ? (
                                <FormFeedback type="invalid">{validation.errors.customer_name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Buyer contact number */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Phone Number</Label>
                            <Input
                                name="customer_phone"
                                placeholder="Enter Phone Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.customer_phone || ""}
                                invalid={validation.touched.customer_phone && validation.errors.customer_phone}
                            />
                            {validation.touched.customer_phone && validation.errors.customer_phone ? (
                                <FormFeedback type="invalid">{validation.errors.customer_phone}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                                name="customer_email"
                                placeholder="Enter Valid Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.customer_email || ""}
                                invalid={
                                    validation.touched.customer_email && validation.errors.customer_email ? true : false
                                }
                            />
                            {validation.touched.customer_email && validation.errors.customer_email ? (
                                <FormFeedback type="invalid">{validation.errors.customer_email}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Quantity */}
                        <div className="mb-3">
                            <Label className="form-label">Quantity (kg)</Label>
                            <Input
                                name="weight"
                                placeholder="Enter Quantity"
                                type="text"
                                autoComplete="off"
                                inputMode="numeric"
                                onChange={handleInputChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.weight || ""}
                                invalid={
                                    validation.touched.weight && validation.errors.weight
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.weight && validation.errors.weight ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.weight}
                                </FormFeedback>
                            ) : null}
                        </div>

                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
                        {/* Price per liter */}
                        {/* <div className="mb-3 pe-2">
                                <Label className="form-label">Price / liter (IDR)</Label>
                                <Input
                                    name="price"
                                    placeholder="Enter Price"
                                    type="number"
                                    min={0}
                                    onChange={handleConversionPriceToAmount}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.price || ""}
                                    invalid={
                                        validation.touched.price && validation.errors.price ? true : false
                                    }
                                />
                                {validation.touched.price && validation.errors.price ? (
                                    <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                                ) : null}
                            </div> */}
                        {/* Final Price*/}
                        {/* <div className="mb-3">
                                <Label className="form-label">Total Price (IDR)</Label>
                                <Input
                                    name="total_price"
                                    placeholder="Enter Total Price"
                                    type="number"
                                    min={0}
                                    onChange={handleConversionAmountToPrice}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.total_price || ""}
                                    invalid={
                                        validation.touched.total_price && validation.errors.total_price ? true : false
                                    }
                                />
                                {validation.touched.total_price && validation.errors.total_price ? (
                                    <FormFeedback type="invalid">{validation.errors.total_price}</FormFeedback>
                                ) : null}
                            </div> */}
                        {/* </div> */}
                        {/* Due Date */}
                        <div className="mb-3">
                            <Label className="form-label">Due Date</Label>
                            <Flatpickr
                                name="test"
                                value={dueDate}
                                className="form-control d-block"
                                placeholder="dd M,yyyy"
                                options={{
                                    altInput: true,
                                    altFormat: "m/d/Y",
                                    // dateFormat: "Y-m-d"
                                }}
                                onChange={value => {
                                    setDueDate(value[0])
                                }}
                            />
                        </div>
                        {/* Photo */}
                        <div className="mb-3">
                            <div className="d-flex gap-2 align-items-center">
                                <Label className="form-label">Documentation : </Label>
                                <label for="fileInput" className="btn btn-primary btn-sm">
                                    Add File
                                </label>
                                {uploading && <Label className="form-label text-danger">uploading...</Label>}
                                {limitImages && <Label className="form-label text-danger">Max {limitImagesCount} images allowed!</Label>}
                                <input className="d-none" id="fileInput" type="file" multiple onChange={storeFiles} name="image" />
                            </div>
                            <div className="">
                                {boxImages.map((src, index) => (
                                    <div className="">
                                        <a className="pe-2" href={src}>Documentation {index + 1}</a>
                                        <a data-src={src} onClick={deleteImage}><span className="text-danger fa fa-times"></span></a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
