import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_ADMINS,
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAdmins,
} from "../../helpers/fakebackend_helper"

function* onGetUsers({payload: token}) {
  try {
    const response = yield call(getUsers, token)
    yield put(getUsersSuccess(response))
  } catch (error) {
    // if(error.response.status === 401) {
    //   localStorage.removeItem("authUser")
    // }
    yield put(getUsersFail(error))
  }
}

export function* usersSaga() {
    yield takeEvery(GET_ADMINS, onGetUsers)
}

export default usersSaga
