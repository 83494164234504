import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import axiosApi from 'helpers/api_helper';

const UserPickupService = ({ data }) => {
    const userId = data._id;
    var isEnabled = true;
    if (data && data.data && data.data.hasOwnProperty('pickupEnabled')) {
        isEnabled = data.data.pickupEnabled;
    }
    const [pickupAvailable, setPickupAvailable] = useState(isEnabled);
    const { showToast } = useAuth();

    const handleSwitchChange = async (value) => {
        setPickupAvailable(value);

        const body = { pickupEnabled: value, user_id: userId };
        const response = await axiosApi.post('/api/admin/support/user-pickup-service', body)
        showToast(response.data);
    };

    return (
        <div>
            <InputSwitch checked={pickupAvailable} onChange={(e) => handleSwitchChange(e.value)} />
            {/* Other JSX */}
        </div>
    );
};

export default UserPickupService;
