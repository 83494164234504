import moment from "moment";
import { createContext, useRef, useState } from "react";

const PeriodContext = createContext({});

export const PeriodProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('all');
    const [lazyState, setlazyState] = useState({
        searchText: '',
        status: 'all',
        startDate: '',
        endDate: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                status: { value: '', matchMode: 'contains' },
            }
        }
    });
    const periodOptions = [
        { name: 'All Time', value: 'all' },
        { name: 'Today', value: 'today' },
        { name: 'Last 7 Days', value: 'week' },
        { name: 'This Month', value: 'month' },
        { name: 'Last Month', value: 'lastMonth' },
        { name: 'This Year', value: 'year' }
    ];


    return (
        <PeriodContext.Provider value={{  lazyState, setlazyState, refresh, setRefresh, periodOptions, selectedPeriod, setSelectedPeriod }}>
            {children}
        </PeriodContext.Provider>
    )
}

export default PeriodContext;