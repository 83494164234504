import React, { useEffect, useState } from "react";
import PromotionTable from "./PromotionTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePromotion from "hooks/usePromotion";
import useAuth from "hooks/useAuth";
import { useLocation } from "react-router-dom"

const Promotion = () => {

  const location = useLocation();
  const propsData = location.state;

  const axiosApi = useAxiosPrivate();
  const { promotionRefresh, setPromotions, setPromotionLoading, setPromoTotalRecords } = usePromotion();
  const { auth } = useAuth();
  const [promoLazyState, setPromoLazyState] = useState({
    searchText: propsData || '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });
  useEffect(() => {
    setPromotionLoading(true);

    const getPromotions = async () => {
      try {
        const response = await axiosApi.get(`/api/admin/marketing/promotion?type=User&rows=${promoLazyState.config.rows}&skip=${promoLazyState.config.first}&searchText=${promoLazyState.searchText}`);
        setPromotionLoading(false);
        setPromotions(response.data.result)

        setPromoTotalRecords(response.data.totalRecords)

      } catch (err) {
        console.log(err);
      }
    }
    getPromotions();

  }, [promotionRefresh, promoLazyState]);

  return (
    <React.Fragment>
      <PromotionTable id={propsData} promoLazyState={promoLazyState} setPromoLazyState={setPromoLazyState} />
    </React.Fragment>
  );
};

export default Promotion;
