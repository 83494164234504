import '../../../assets/css/style.css'
import useOverview from "hooks/useOverview";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { Link } from "react-router-dom"

const Orders = () => {
    const { orders, loading } = useOverview();

    const items = Array.from({ length: 10 }, (v, i) => i);

    const inputWeight = data => {
        return simplify(data.weight, 3)
    }
    const inputCompany = data => {
        return data.company_id ? data.company_name : '-'
    }
    const idLink = (data) => {
        return <Link to={'/orders?tab=1'} state={data.order_code}> {data.order_code}</Link>
    }
    const inputRealWeight = data => {
        return data.real_weight ?? 0
    }
    const columns = [
        // { field: '', header: '#', body: incrementId },
        { field: 'order_code', header: 'Order ID', body: idLink, className: '' },
        { field: '', header: 'Company', body: inputCompany, className: '' },
        { field: '', header: 'Quantity (kg)', body: inputWeight, className: '' },
        { field: '', header: 'Actual Weight (kg)', body: inputRealWeight, className: '' },
        // { field: '', header: 'Price/Liter (IDR)', body: inputPrice, className: '' },
        // { field: '', header: 'Total Price (IDR)', body: inputTotalPrice, className: '' },
        { field: 'orderstatus_name', header: 'Status', body: '', className: '' },

    ]
    return <>
        <DataTable

            value={loading ? items : orders}
            stripedRows
            showGridlines
            size={'normal'}
            metaKeySelection={true}

            scrollable
        >
            {columns.map((col, i) => (
                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
            ))}
        </DataTable>
        <div className="d-flex flex-wrap gap-2 justify-content-end py-2">
            <Link to={'/orders'}>See More ...</Link>
        </div>
    </>
}

export default Orders