import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap"
import TradingChart from "./charts/TradingChart"
import '../../../assets/css/style.css'
import usePrice from "hooks/usePrice"
import { currencyFormatter } from "helpers/functions"

const Trading = () => {
    const {currentPrice, previousPrice } = usePrice();
    

    const comparePrice = () => {
        if (currentPrice === previousPrice) {
            return '0%'
        }
        if (previousPrice === 0) {
            return '+100%';
        }
        let result;
        result = Math.round(((currentPrice / previousPrice) - 1) * 100) / 100;
        if (result > 0) {
            return '+' + result + '%';
        }
        return result + '%';
    }
    return (
        <>
        <Row className="align-items-center mb-2">
            <Col md={8} className="d-flex flex-row align-items-center gap-3">
                <h4 className="card-title mb-0">UCO Trading</h4>
            </Col>
        </Row>
        <Row>
            <Col md={8} sm={12}>
                
                <TradingChart/>
            </Col>
            <Col md={4} sm={12}>
                <Row>
                    <Col md={6}>
                        <div className="text-center">
                            <p className="text-muted mb-4">Current UCO Price</p>
                                <h3>${currencyFormatter(currentPrice)}</h3>
                            <p className="text-muted mb-4">
                                /TON
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="text-center">
                            <p className="text-muted mb-4">Previous UCO Price</p>
                            <h3>${currencyFormatter(previousPrice)}</h3>
                            <p className="text-muted mb-4">
                                /TON
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center">
                            <p className="text-muted mb-4">Compare</p>
                            <h3>{comparePrice()}</h3>
                        </div>
                    </Col>
                </Row>
                
            </Col>
        </Row>
        </>
    )
}

export default Trading