import { createContext, useState } from "react";

const OrderContext = createContext({});

export const OrderProvider = ({ children }) => {
    const [refresh, setRefresh] = useState('');
    const [boxImages, setBoxImages] = useState([]);

    return (
        <OrderContext.Provider value={{
            refresh, setRefresh, boxImages, setBoxImages
        }}>
            {children}
        </OrderContext.Provider>
    )
}

export default OrderContext;