import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";

import "chartist/dist/scss/chartist.scss";
import { Link, useParams } from "react-router-dom";
import PaymentPeriodModal from "pages/PaymentPeriodModal";

//i18n
import { withTranslation } from "react-i18next";
import VolumePartnersTable from './VolumePartnersTable';
import { useLocation } from "react-router-dom";
import useVolumePartner from "hooks/useVolumePartner";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const VolumePartners = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setLoading, setVolumePartners, setTotalRecords } = useVolumePartner();
  const [selectedPeriodName, setSelectedPeriodName] = useState('All Time');
  const [sortField, setSortField] = useState(null);

  const location = useLocation();
  const propsData = location.state;

  const [lazyState, setlazyState] = useState({
    searchText: propsData || '',
    periodName: selectedPeriodName,
    startDate: '',
    endDate: '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });

  const getVolumePartners = async () => {
    setLoading(true)
    try {
      // let queryParams = `rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.searchText == '' ? lazyState.startDate : ''}&endDate=${lazyState.searchText == '' ? lazyState.endDate : ''}`;
      let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate ? lazyState.startDate : ''}&endDate=${lazyState.endDate  ? lazyState.endDate : ''}`;
      if (sortField) {
        queryParams += `&sorts=${sortField}`;
      }
      const response = await axiosApi.get(`/api/admin/partner-volume?${queryParams}`);
      const result = response.data.result;
      setVolumePartners(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setTotalRecords(response.data.totalRecords)
      } else {
        setTotalRecords(0)
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)

  }

  useEffect(() => {
    getVolumePartners();
  }, [lazyState, refresh, sortField]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Volume Partners`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/*<Breadcrumbs title="Support" breadcrumbItem="VolumePartners" />*/}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8} sm={12}>
                <h6 className="page-title">Volume Partners</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Partners</a></li><li className="active breadcrumb-item" aria-current="page">Volume Partners</li></ol>
              </Col>

              <Col md={4} sm={12}>
                <div> {/* Auto width for PaymentPeriodModal */}
                  <PaymentPeriodModal
                    mainOptions={lazyState}
                    setMainOptions={setlazyState}
                    selectedPeriodName={selectedPeriodName}
                    setSelectedPeriodName={setSelectedPeriodName}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <VolumePartnersTable id={propsData} lazyState={lazyState} setlazyState={setlazyState} setSortField={setSortField} />
        </div>
      </div>
    </React.Fragment>
  );
};

VolumePartners.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(VolumePartners);
