import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";
import useAuth from "hooks/useAuth";

const DetailModal = ({ data }) => {
    const { showToast } = useAuth();
    const title = "Routes/Tours Detail";
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [datas, setDatas] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);
    let numberCount = data.tour_count ?? 0;

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const destinationTank = (data) => {
        return data?.destination_tank?.tank_name ?? '-'
    }
    const orderCode = (data) => {
        return data?.order?.order_code ?
            <Link to={`/orders`} state={data?.order?.order_code}>
                {data?.order?.order_code}
            </Link>

            : '-'
    }
    const collectionCode = (data) => {
        return data?.collection?.collection_code ?
            <Link to={`/collections`} state={data?.collection?.collection_code}>
                {data?.collection?.collection_code}
            </Link>

            : '-'
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'collection.collection_code', header: 'Collection ID', body: collectionCode },
        { field: 'collection.collection_status_name', header: 'Collection Status', body: '' },
        { field: '', header: 'Destination', body: destinationTank },
        { field: '', header: 'Order ID', body: orderCode },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="datas-detail">
                <div className="d-flex flex-row justify-content-around p-3">
                    <div>
                        <label>Title</label>
                        <ul>
                            <li>Field 1 : </li>
                            <li>Field 2 : </li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Title 2</label>
                        <ul>
                            <li>Field 3 : </li>
                            <li>Field 4 : </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/erp/asset/transport-collections?transport_id=${data?.id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const result = response.data.results;
            setDatas(result);
            setTotalRecords(response.data.total_records || 0)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
            {
                numberCount > 0 ?
                    <span style={{ cursor: 'pointer' }} className="text-primary" onClick={tog_center}>
                        {numberCount}
                    </span>
                    :
                    numberCount
            }
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">{title}</h4>

                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <DataTable
                        lazy
                        value={loading ? items : datas}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"

                        // expandedRows={loading ? null : expandedRows}
                        // rowExpansionTemplate={rowExpansionTemplate}
                        // onRowToggle={onRowToggle}
                        dataKey={loading ? '' : 'id'}
                    >
                        {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DetailModal
