import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import MenuTable from "./MenuTable";
import { useLocation } from "react-router-dom";
import useMenu from "hooks/useMenu";

const MenuSettings = () => {
    const location = useLocation();
    const propsData = location.state;
    const { setMenus, setTotalRecords, setExpandedRows, refresh } = useMenu()

    const [lazyState, setlazyState] = useState({
        searchText: propsData || '',
        config: {
            first: 0,
            rows: 10,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const axiosApi = useAxiosPrivate();

    const getEducards = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/menu?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            setMenus(response.data.result)
            setTotalRecords(response.data.totalRecords)
            setLoading(false);
            if (propsData) {
                let obj = {};
                obj[propsData] = true
                setExpandedRows(obj)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getEducards();
        }, 300);
    }, [lazyState]);

    useEffect(() => {
        getEducards();
    }, [refresh]);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <MenuTable loading={loading} id={propsData} lazyState={lazyState} setlazyState={setlazyState} />
                </div>
            </div>

        </React.Fragment>
    );
};

export default MenuSettings;
