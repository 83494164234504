import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import { Dropdown } from 'primereact/dropdown';
import InputMask from "react-input-mask";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import { InputSwitch } from 'primereact/inputswitch';

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import Select from "react-select";

const EditModal = ({ rowData }) => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setTransportRefresh, transportRefresh, refresh } = useAsset();
    const { showToast } = useAuth();

    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(rowData.is_active);
    const [selectedType, setSelectedType] = useState("")
    const [typeList, setTypeList] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(rowData.is_destination)
    const [defaultId, setDefaultId] = useState(rowData.transport_code);

    const changeType = (e) => {
        setSelectedType(e.value)
    }

    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
                if (element.id === rowData.company_id) {
                    setSelectedCompany(detail)
                }
            })
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }
    const getCountries = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/countries`);
            setCountryList(response.data.results);
            if (response.data.results.length > 0) {
                setSelectedCountry(response.data.results[0])
                for (let idx = 0; idx < response.data.results.length; idx++) {
                    if (response.data.results[idx].id == rowData.country_id) {
                        setSelectedCountry(response.data.results[idx])
                        break;
                    }
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    const getTransportTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/transport-types`);
            setTypeList(response.data.results);
            if (response.data.results.length > 0) {
                for (let idx = 0; idx < response.data.results.length; idx++) {
                    if (response.data.results[idx].id == rowData.transport_type_id) {
                        setSelectedType(response.data.results[idx])
                        break;
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
        setSelectedStatus(rowData.is_active);
        setSelectedDestination(rowData.is_destination);
        setDefaultId(rowData.transport_code);
        // getCompanies();
        // getCountries();
        // getTransportTypes();
    };

    useEffect(() => {
        if (modal_center) {
            getCompanies();
            getTransportTypes();
            getCountries();
        }
    }, [transportRefresh, modal_center]);
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const changeCountry = (e) => {
        setSelectedCountry(e.value)
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            transport_code: rowData.transport_code,
            production_model: rowData.production_model,
            production_year: rowData.production_year,
            // name: rowData.,
            identify_number: rowData.identify_number,
            max_capacity: rowData.max_capacity,
            // country: "Indonesia",
        },
        validationSchema: Yup.object().shape({
            transport_code: Yup.string().required("Transport ID is required"),
            identify_number: Yup.string().required("Identification Number is required"),
            // name: Yup.string().required("Name is required"),
            // identify_number: Yup.string().required("Type is required"),
            max_capacity: Yup.number()
                .required("This value is required")
                .moreThan(0, "Buying Price must be greater than 0"),
            // country: Yup.string().required("Country is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, transport_type_id: selectedType.id, company_id: selectedCompany.id, is_active: selectedStatus, is_destination: selectedDestination, location_country_id: selectedCountry.id };
                const response = await put('/api/erp/asset/transports/' + rowData.id, opts);
                setTransportRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Edit Transport</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Transport ID</Label>
                            <InputMask
                                placeholder="Enter Transport ID"
                                name="transport_code"
                                mask="aaaa-aa-aa-99999"  // Mask for date format
                                value={defaultId}  // Controlled input value
                                onChange={handleIdChange}  // Update state on change
                                invalid={validation.touched.transport_code && validation.errors.transport_code}
                                maskChar={null}  // Removes default mask characters (like "_")
                            >
                                {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                            </InputMask>
                            {validation.touched.transport_code && validation.errors.transport_code ? (
                                <FormFeedback type="invalid">{validation.errors.transport_code}</FormFeedback>
                            ) : null}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                            {/* Type dropdown */}
                            <div className="mb-3">
                                <Label className="form-label">Type</Label>
                                <div className="col">
                                    <Dropdown
                                        value={selectedType || ''}
                                        onChange={changeType}
                                        options={typeList}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    />
                                </div>
                            </div>
                            {/* Country ID */}
                            <div className="mb-3 px-2">
                                <Label className="form-label">Country</Label>
                                <div className="col">
                                    <Dropdown
                                        value={selectedCountry || ''}
                                        onChange={changeCountry}
                                        options={countryList}
                                        optionLabel="name"
                                        className="h-1 payment-status-dropdown"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Company ID */}
                        <div className="mb-3">
                            <Label className="form-label">Company</Label>
                            <div className="col">
                                <Select
                                    name={`company_id`}
                                    value={selectedCompany}
                                    onChange={value => {
                                        setSelectedCompany(value)
                                    }}
                                    options={companyList}
                                    optionLabel="label"
                                    classNamePrefix="select2-selection"
                                />
                                {/* <Dropdown
                                    value={selectedCompany || ''}
                                    onChange={changeCompany}
                                    options={companyList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown"
                                /> */}
                            </div>
                        </div>
                        {/* Name */}
                        {/* <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={validation.touched.name && validation.errors.name}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div> */}
                        {/* Capacity */}
                        <div className="mb-3">
                            <Label className="form-label">Capacity</Label>
                            <Input
                                name="max_capacity"
                                placeholder="Enter Capacity"
                                type="number"
                                min={0}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.max_capacity || ""}
                                invalid={
                                    validation.touched.max_capacity && validation.errors.max_capacity ? true : false
                                }
                            />
                            {validation.touched.max_capacity && validation.errors.max_capacity ? (
                                <FormFeedback type="invalid">{validation.errors.max_capacity}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Plate No */}
                        <div className="mb-3">
                            <Label className="form-label">Identification Number</Label>
                            <Input
                                name="identify_number"
                                placeholder="Enter Identification Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.identify_number || ""}
                                invalid={validation.touched.identify_number && validation.errors.identify_number}
                            />
                            {validation.touched.identify_number && validation.errors.identify_number ? (
                                <FormFeedback type="invalid">{validation.errors.identify_number}</FormFeedback>
                            ) : null}
                        </div>
                        {/* <div className="mb-3">
                            <Label className="form-label">Production Model</Label>
                            <Input
                                name="production_model"
                                placeholder="Enter Model"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.production_model || ""}
                                invalid={validation.touched.production_model && validation.errors.production_model}
                            />
                            {validation.touched.production_model && validation.errors.production_model ? (
                                <FormFeedback type="invalid">{validation.errors.production_model}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Production Year</Label>
                            <Input
                                name="production_year"
                                placeholder="Enter Model"
                                type="number"
                                maxLength={4}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.production_year || ""}
                                invalid={validation.touched.production_year && validation.errors.production_year}
                            />
                            {validation.touched.production_year && validation.errors.production_year ? (
                                <FormFeedback type="invalid">{validation.errors.production_year}</FormFeedback>
                            ) : null}
                        </div> */}
                        {/* Country */}
                        {/* <div className="mb-3">
                            <Label className="form-label">Country</Label>
                            <Input
                                name="country"
                                placeholder="Enter Country"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country || ""}
                                invalid={validation.touched.country && validation.errors.country}
                            />
                            {validation.touched.country && validation.errors.country ? (
                                <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                            ) : null}
                        </div> */}
                        {/* Destination */}
                        <div className="mb-3">
                            <Label className="form-label">Set as Destination</Label><br />
                            <InputSwitch checked={selectedDestination} onChange={(e) => setSelectedDestination(e.value)} />
                        </div>
                        {/* Status */}
                        <div className="mb-3">
                            <Label className="form-label">Active</Label><br />
                            <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                        </div>
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting || selectedCompany == ''}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default EditModal
