import moment from "moment";
import { createContext, useRef, useState } from "react";

const PriceContext = createContext({});

export const PriceProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState('');
    const [currentPrice, setCurrentPrice] = useState(0);
    const [previousPrice, setPreviousPrice] = useState(0);
    const [share, setShare] = useState(0);
    const [comparePrice, setComparePrice] = useState(true);
    const [compareShare, setCompareShare] = useState(true);
    const [selectedRange, setSelectedRange] = useState('24h');
    const [tradingOptions, setTradingOptions] = useState({
        colors: ['#70ad47'],
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        grid: {
            borderColor: '#f8f8fa',
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return `$ ...`
                }
            },
            title: {
                text: 'Price'
            }
        },
        xaxis: {
            categories: [],
            type: 'category',
        },
        tooltip: {

        },
        annotations: {

        }
    });

    const [tradingSeries, setTradingSeries] = useState([
        {
            name: 'Trading',
            data: [],
        }
    ]);

    const [buySeries, setBuySeries] = useState([
        {
            name: 'Buying Price',
            data: []
        },
        {
            name: 'Payout Price',
            data: []
        },
    ]);

    const priceRange = [
        { name: 'Last 24 Hours', value: '24h' },
        { name: 'Last 7 Days', value: '7d' },
        { name: 'Last Month', value: '1m' },
        { name: 'Last 3 Months', value: '3m' },
        { name: 'Last 6 Months', value: '6m' },
        { name: 'Last 12 Months', value: '12m' },
    ];

    return (
        <PriceContext.Provider value={{selectedRange, setSelectedRange, priceRange, loading, setLoading, tradingOptions, setTradingOptions, tradingSeries, setTradingSeries, currentPrice, setCurrentPrice, previousPrice, setPreviousPrice, share, setShare, refresh, setRefresh, comparePrice, setComparePrice, compareShare, setCompareShare, buySeries, setBuySeries}}>
            {children}
        </PriceContext.Provider>
    )
}

export default PriceContext;