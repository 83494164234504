import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import useAuth from "hooks/useAuth";


const UnitExportCSV = ({ data, lazyState, datas, isExport, setIsExport }) => {
    const { showToast } = useAuth();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `UCO_Unit_History_${data.incident_code}_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.filterDate ? `_${lazyState.filterDate}` : ''}.csv`


    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
            setIsExport(false);

        } else {
            console.log('data is empty')
        }
    }, [refresh])

    useEffect(() => {
        if (isExport) {
            fetchDataReq();
        }
    }, [isExport]);

    const fetchDataReq = async () => {
        const today = new Date();
        const month = today.getMonth() + 1; // Months are zero-indexed
        const day = today.getDate();
        const year = today.getFullYear();

        // Pad month and day with leading zeros if needed
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/erp/inventory/tank-uco-units?incident_id=${data?.id}&searchText=${lazyState.searchText}&show_all=true&is_anonymize=${datas.isAnonymous}`);
            const result = response.data.results;
            result.forEach(obj => {
                obj.box_name = datas.isAnonymous ? obj.uco_unit.external_box_code : obj.uco_unit.external_box_name;
                obj.coordinates = obj.uco_unit.external_box_latitude + ', ' + obj.uco_unit.external_box_longitude;
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setIsExport(false);
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <>
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={datas.headers}
            >
            </CSVLink>
        </>

    )
}

export default UnitExportCSV