import React, { useState } from "react"
import AddMenuModal from "./modals/AddMenuModal";


import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import AddSubMenuModal from "./modals/AddSubMenuModal";
import EditMenuModal from "./modals/EditMenuModal";
import DeleteMenuModal from "./modals/DeleteMenuModal";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useMenu from "hooks/useMenu";
import { Skeleton } from "primereact/skeleton";
// import PaymentHistoryModal from "./modals/PaymentHistoryModal";
import { debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import UserExportCSV from "./exports/UserExportCSV";
import SubMenuTable from "./SubMenuTable";

const MenuTable = ({ loading, id, lazyState, setlazyState }) => {
    const { menus, totalRecords, expandedRows, setExpandedRows } = useMenu();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <AddSubMenuModal menu={data} />
                </div>
                <div>
                    <EditMenuModal menu={data} />
                </div>
                <div>
                    <DeleteMenuModal menu={data} />
                </div>
            </div>
        )
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const badgeStatus = (data) => {
        var badgetText = 'danger'
        var statusText = 'non active'
        if (data.status == true) {
            statusText = 'active'
            badgetText = 'success'
        }
        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>
        )
    }
    const iconImage = (data) => {
        if (data.icon) {
            return (
                <span className={data.icon}>
                </span>
            )

        } else {
            return "-"
        }
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'title', header: 'Title', body: '' },
        { field: 'url', header: 'Path', body: (data) => data?.url || '-' },
        { field: 'icon', header: 'Icon', body: iconImage },
        { field: 'order', header: 'Order', body: '' },
        { field: 'status', header: 'Status', body: badgeStatus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const rowExpansionTemplate = (data) => {

        return <>
            <SubMenuTable data={data}></SubMenuTable>

        </>
    };
    return (
        <Row>
            <Col xl={12}>
                <Card>

                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddMenuModal />

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            {/* <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Title" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col> */}
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : menus}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            dataKey={loading ? '' : '_id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default MenuTable
