import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Col, InputGroup } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import deleteIcon from "../../../../../assets/images/delete-icon.png";
import InputMask from "react-input-mask";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import AddressForm from "pages/AddressForm";
import Select from "react-select";
import { phoneRegExp, positiveNumericRegExp } from "constants/value";
const AddModal = () => {
    const axiosApi = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [modal_center, setmodal_center] = useState(false);
    const { personRefresh, setPersonRefresh, identityTypes, companyRefresh, refresh } = useAsset();
    const { showToast } = useAuth();
    const [selectedType, setSelectedType] = useState('IDCARD');
    const [selectedStatus, setSelectedStatus] = useState(true)
    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedPersonType, setSelectedPersonType] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [personTypeList, setPersonTypeList] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedPostcode, setSelectedPostcode] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [defaultId, setDefaultId] = useState(null);

    const [availableLicenseTypeList, setAvailableLicenseTypeList] = useState([]);
    const addForm = (arrayHelpers, forms) => {
        let defaultAvailableLicenseType = null;
        let usedLicenseType = []
        if (forms.length > 0) {
            for (let i = 0; i < forms.length; i++) {
                if (forms[i].person_license_type_id) {
                    usedLicenseType.push(forms[i].person_license_type_id.id)
                }
            }
        }
        if (usedLicenseType.length > 0) {
            defaultAvailableLicenseType = availableLicenseTypeList.filter((element) => !usedLicenseType.includes(element.id));

        }
        else {
            defaultAvailableLicenseType = availableLicenseTypeList;
        }

        let object = {
            person_license_type_id: defaultAvailableLicenseType[0],
            license_image: '',
            license_number: '',
            expiry_date: '',
            availableLicenseType: defaultAvailableLicenseType,
        }
        arrayHelpers.push(object)
    }
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        // validation.resetForm();
        setImageUrl('')

    };
    const getLicenseTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/person-license-types`);
            setAvailableLicenseTypeList(response.data.results);
        } catch (error) {
            console.log(error);
        }
    }
    const getDefaultID = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/persons/get-code`);
            setDefaultId(response.data.results.person_code)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                // validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
        }
    }

    const getPersonTypes = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/master/person-types`);
            setPersonTypeList(response.data.results);
            if (response.data.results.length > 0)
                setSelectedPersonType(response.data.results[0])
        } catch (error) {
            console.log(error);
        }
    }

    const getCompanies = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies?is_active=true&show_all=true`);
            var locList = response.data.results;
            locList.unshift({ name: "None", value: "", id: null })
            let allData = [];
            locList.forEach((element, index) => {
                var detail = {
                    ...element,
                    label: element.name
                }
                index == 0 ? setSelectedCompany(detail) : '';
                allData.push(detail);
            });
            setCompanyList(allData);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (modal_center) {
            getDefaultID();
            getCompanies();
            getPersonTypes();
            getLicenseTypes();
        }
    }, [personRefresh, companyRefresh, modal_center]);
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);

    };
    const storeFiles = async (e) => {
        const { files } = e.target
        const validFiles = Array.from(files)
        setUploading(true);
        await Promise.all(
            validFiles.map(async (photo) => {
                const formData = new FormData();
                formData.append('image', photo)
                const response = await axiosApi.post('/api/admin/asset/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                setImageUrl(response.data.url)
            })
        )
        setUploading(false)
    }

    const deleteImage = (e) => {
        setImageUrl('');
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const changePersonType = (e) => {
        setSelectedPersonType(e.value)
    }

    const changeCompany = (e) => {
        setSelectedCompany(e.value)
    }
    const changeType = (e) => {
        setSelectedType(e.value)
    }


    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Individual </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Individual</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            collections: [],
                            person_code: defaultId,
                            first_name: "",
                            last_name: "",
                            phone: "",
                            identity_number: "",
                        }}
                        validationSchema={Yup.object({
                            person_code: Yup.string().required("ID is required"),
                            first_name: Yup.string().required("First Name is required"),
                            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
                            // last_name: Yup.string().required("Name is required"),
                            identity_number: Yup.string().matches(positiveNumericRegExp, 'Only input number').required("ID Number is required"),
                            // collections: Yup.array().required('Driving Licenses is required').min(1, 'Driving Licenses is required')
                        })}
                        resetForm
                        onSubmit={async (values) => {
                            setSubmitLoading(true);
                            try {
                                const updatedValues = values.collections.map(collection => ({
                                    license_image: collection.license_image,
                                    license_number: collection.license_number,
                                    expiry_date: collection.expiry_date,
                                    person_license_type_id: collection.person_license_type_id?.id ?? null

                                }));
                                const opts = { ...values, person_licenses: updatedValues, identity_type: selectedType, photo: imageUrl, is_active: selectedStatus, company_id: selectedCompany.id, person_type_id: selectedPersonType.id, location_city_id: selectedCity.id, location_region_id: selectedRegion.id, location_country_id: selectedCountry.id, address: selectedAddress, postcode: selectedPostcode };
                                // console.log(opts);
                                const response = await post('/api/erp/asset/persons', opts);
                                setPersonRefresh(makeid(5));
                                tog_center();
                                showToast(response);
                            } catch (error) {
                                if (error.response && error.response.data && error.response.data.state) {
                                    showToast(error.response.data);
                                } else {
                                    let response = {};
                                    response.state = "error";
                                    response.toast = true;
                                    response.message = "Internal Server Error"
                                    showToast(response);
                                }
                            }

                            setSubmitLoading(false)

                        }}
                        render={({ values, touched, errors, setFieldValue }) => (
                            <Form>
                                <FieldArray
                                    name="collections"
                                    render={(arrayHelpers) => {
                                        const forms = values.collections;
                                        return (
                                            <div>

                                                {/* Person ID */}
                                                <div className="mb-3">
                                                    <Label className="form-label">Individual ID</Label>
                                                    <InputMask
                                                        placeholder="Enter Individual ID"
                                                        name="person_code"
                                                        mask="aaaa-aa-aa-99999"  // Mask for date format
                                                        value={defaultId}  // Controlled input value
                                                        onChange={handleIdChange}  // Update state on change
                                                        maskChar={null}  // Removes default mask characters (like "_")
                                                    >
                                                        {(inputProps) => <Input {...inputProps} tag={Field} type="text" />}
                                                    </InputMask>
                                                    <ErrorMessage component="div" className="error-text" name="person_code" />

                                                </div>
                                                {/* Person Type */}
                                                <div className="mb-3">
                                                    <Label className="form-label">Type</Label>
                                                    <div className="col">
                                                        <Dropdown
                                                            value={selectedPersonType || ''}
                                                            onChange={changePersonType}
                                                            options={personTypeList}
                                                            optionLabel="name"
                                                            className="h-1 payment-status-dropdown"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Company ID */}
                                                <div className="mb-3">
                                                    <Label className="form-label">Company</Label>
                                                    <div className="col">
                                                        <Select
                                                            name={`company_id`}
                                                            value={selectedCompany}
                                                            onChange={value => {
                                                                setSelectedCompany(value)
                                                            }}
                                                            options={companyList}
                                                            optionLabel="label"
                                                            classNamePrefix="select2-selection"
                                                        />
                                                        {/* <Dropdown
                                                                value={selectedCompany || ''}
                                                                onChange={changeCompany}
                                                                options={companies}
                                                                optionLabel="name"
                                                                className="h-1 payment-status-dropdown"
                                                            /> */}
                                                    </div>
                                                </div>

                                                {/* First Name */}
                                                <div className="mb-3">
                                                    <Label className="form-label">First Name</Label>
                                                    <Input
                                                        tag={Field}
                                                        name="first_name"
                                                        placeholder="Enter First Name"
                                                        type="text"
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="first_name" />
                                                </div>
                                                {/* Last Name */}
                                                <div className="mb-3">
                                                    <Label className="form-label">Last Name</Label>
                                                    <Input
                                                        tag={Field}
                                                        name="last_name"
                                                        placeholder="Enter Last Name"
                                                        type="text"
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="last_name" />
                                                </div>
                                                {/* Contact Email */}
                                                <div className="mb-3">
                                                    <Label className="form-label"> Email</Label>
                                                    <Input
                                                        tag={Field}
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        type="text"
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="email" />
                                                </div>
                                                {/* Contact Number */}
                                                <div className="mb-3">
                                                    <Label className="form-label">Phone Number</Label>
                                                    <Input
                                                        tag={Field}
                                                        name="phone"
                                                        placeholder="Enter Phone Number"
                                                        type="text"
                                                    />
                                                    <ErrorMessage component="div" className="error-text" name="phone" />
                                                </div>
                                                <AddressForm
                                                    refresh={modal_center}
                                                    selectedCountry={selectedCountry}
                                                    selectedCity={selectedCity}
                                                    selectedRegion={selectedRegion}
                                                    selectedPostcode={selectedPostcode}
                                                    selectedAddress={selectedAddress}
                                                    setSelectedCountry={setSelectedCountry}
                                                    setSelectedCity={setSelectedCity}
                                                    setSelectedRegion={setSelectedRegion}
                                                    setSelectedPostcode={setSelectedPostcode}
                                                    setSelectedAddress={setSelectedAddress}
                                                    countryId={''}
                                                    regionId={''}
                                                    cityId={''}
                                                />
                                                <div style={{ display: "flex" }}>
                                                    {/* Identity Type */}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Identity Type</Label>
                                                        <div className="col">
                                                            <Dropdown
                                                                value={selectedType || ''}
                                                                onChange={changeType}
                                                                options={identityTypes}
                                                                optionLabel="name"
                                                                className="h-1 payment-status-dropdown"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* ID/Passport Number */}
                                                    <div className="mb-3 px-2">
                                                        <Label className="form-label">{selectedType} Number</Label>
                                                        <Input
                                                            tag={Field}
                                                            name="identity_number"
                                                            placeholder={`Enter ${selectedType}`}
                                                            type="text"
                                                        />
                                                        <ErrorMessage component="div" className="error-text" name="identity_number" />

                                                    </div>
                                                </div>

                                                {/* Status */}
                                                <div className="mb-3 d-flex gap-2 align-items-center">
                                                    <Label className="form-label">Active : </Label>
                                                    <InputSwitch checked={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} />
                                                </div>
                                                {/* Photo */}
                                                <div className="mb-3">
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <Label className="form-label">Photo : </Label>
                                                        <label for="fileInput" className="btn btn-primary btn-sm">
                                                            {imageUrl == '' ? 'Add' : "Change"}
                                                        </label>
                                                        {uploading && <Label className="form-label text-danger">uploading...</Label>}
                                                        <input className="d-none" id="fileInput" type="file" multiple accept="image/jpeg, image/jpg, image/png" onChange={storeFiles} name="image" />
                                                    </div>
                                                    <div className="d-flex gap-2 box-images-container">
                                                        {imageUrl != '' && <div className="image-container">
                                                            <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={imageUrl} onClick={deleteImage}></img>
                                                            <img src={imageUrl} width={200} height={200} className="box-image"></img>
                                                        </div>}
                                                    </div>
                                                </div>

                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <div className="py-3 mb-3">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <h3>Driving Licenses</h3>
                                                                {forms.length == 0 && <div className="d-flex flex-wrap gap-2 justify-content-end px-3">
                                                                    <Button onClick={() => addForm(arrayHelpers, forms)} color="primary" className="btn btn-primary">
                                                                        <i className="fas fa-plus">
                                                                        </i></Button>
                                                                </div>}
                                                            </div>
                                                            <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }} />

                                                            {touched.collections && errors.collections && typeof errors.collections === 'string' && (
                                                                <div className="error-text mb-3 px-3">{errors.collections}</div>
                                                            )}
                                                            {/* <ErrorMessage className="error-text mb-3 px-3" name="collections" /> */}

                                                            {forms.map((form, index) => {
                                                                return <>
                                                                    <div className="" key={index}>
                                                                        <Row>
                                                                            {/* Identity Type */}
                                                                            <Col xl={10} lg={10} md={10}>
                                                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                                                    <div className="mb-3">
                                                                                        <Label className="form-label">License Type</Label>
                                                                                        <div className="col">
                                                                                            <Dropdown
                                                                                                tag={Field}
                                                                                                name={`collections.${index}.person_license_type_id`}
                                                                                                value={form.person_license_type_id || ''}
                                                                                                onChange={e => {
                                                                                                    setFieldValue(`collections.${index}.person_license_type_id`, e.target.value)
                                                                                                }}
                                                                                                options={form.availableLicenseType}
                                                                                                optionLabel="name"
                                                                                                className="h-1 payment-status-dropdown"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 px-2">
                                                                                        <Label className="form-label">Driving License</Label>
                                                                                        <Input
                                                                                            tag={Field}
                                                                                            name="license_number"
                                                                                            placeholder="Enter License No"
                                                                                            type="text"
                                                                                            onChange={(event) => {
                                                                                                const newValue = event.target.value;
                                                                                                if (positiveNumericRegExp.test(newValue)) {
                                                                                                    setFieldValue(`collections.${index}.license_number`, newValue);
                                                                                                }
                                                                                                if(!newValue){
                                                                                                    setFieldValue(`collections.${index}.license_number`, newValue);
                                                                                                }
                                                                                                // setFieldValue(`collections.${index}.license_number`, newValue);
                                                                                            }}
                                                                                            value={form.license_number}
                                                                                        />
                                                                                        <ErrorMessage component="div" className="error-text" name={`collections.${index}.license_number`} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <Label className="form-label">Expiring date</Label>
                                                                                    <br />
                                                                                    <InputGroup style={{ width: "200px" }}>
                                                                                        <Flatpickr
                                                                                            name="expiry_date"
                                                                                            className="form-control d-block"
                                                                                            value={form.expiry_date}
                                                                                            onChange={value => {
                                                                                                if (value.length > 0) {

                                                                                                    const dateObject = new Date(value[0]);
                                                                                                    // Extracting year, month, and day
                                                                                                    const year = dateObject.getFullYear();
                                                                                                    // JavaScript months are zero-based, so we add 1
                                                                                                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                                                                                                    const day = dateObject.getDate().toString().padStart(2, '0');

                                                                                                    // Forming the desired date string
                                                                                                    const formattedDate = `${month}/${day}/${year}`;
                                                                                                    setFieldValue(`collections.${index}.expiry_date`, formattedDate);

                                                                                                } else {
                                                                                                    setFieldValue(`collections.${index}.expiry_date`, null);

                                                                                                }
                                                                                            }}
                                                                                            placeholder="m/d/Y"
                                                                                            options={{
                                                                                                altInput: true,
                                                                                                altFormat: "m/d/Y",
                                                                                                dateFormat: "m/d/Y"
                                                                                            }}
                                                                                        />
                                                                                    </InputGroup>
                                                                                </div>
                                                                                {/* License Photo */}
                                                                                <div className="mb-3">
                                                                                    <div className="d-flex gap-2 align-items-center">
                                                                                        <Label className="form-label">License Photo : </Label>
                                                                                        <label htmlFor={`fileInputLicense${index}`} className="btn btn-primary btn-sm">
                                                                                            {form.license_image === '' ? 'Add' : "Change"}
                                                                                        </label>
                                                                                        <input
                                                                                            className="d-none"
                                                                                            id={`fileInputLicense${index}`}
                                                                                            type="file"
                                                                                            multiple
                                                                                            accept="image/jpeg, image/jpg, image/png"
                                                                                            onChange={async (event) => {
                                                                                                const { files } = event.target;
                                                                                                const validFiles = Array.from(files);
                                                                                                // setUploading(true);
                                                                                                const uploadedUrls = await Promise.all(
                                                                                                    validFiles.map(async (photo) => {
                                                                                                        const formData = new FormData();
                                                                                                        formData.append('image', photo);
                                                                                                        const response = await axiosApi.post('/api/admin/asset/upload', formData, {
                                                                                                            headers: {
                                                                                                                'Content-Type': 'multipart/form-data',
                                                                                                            }
                                                                                                        });
                                                                                                        return response.data.url;
                                                                                                    })
                                                                                                );
                                                                                                // setUploading(false);
                                                                                                setFieldValue(`collections.${index}.license_image`, uploadedUrls[0]);
                                                                                            }}
                                                                                            name="image"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="d-flex gap-2 box-images-container">
                                                                                        {form.license_image && (
                                                                                            <div className="image-container">
                                                                                                <img src={deleteIcon} className="box-delete-icon" width={28} height={28} data-src={form.license_image} onClick={() => {
                                                                                                    setFieldValue(`collections.${index}.license_image`, '');
                                                                                                }} />
                                                                                                <img src={form.license_image} width={200} height={200} className="box-image" />
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={2} lg={2} md={2}>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <Button className="btn btn-danger" onClick={(event) => {
                                                                                        arrayHelpers.remove(index);
                                                                                    }}>
                                                                                        <i className="fas fa-times"></i>
                                                                                    </Button>

                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                    </div>
                                                                    {/* <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }} /> */}

                                                                </>
                                                            })}
                                                            {forms.length > 0 && (
                                                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                                    {/* Check if all required fields are filled for all form instances */}

                                                                    {forms.every(form => (
                                                                        form.person_license_type_id &&
                                                                        form.license_number
                                                                    )) ? (
                                                                        <Button onClick={() => { addForm(arrayHelpers, forms) }} color="primary" className="btn btn-primary"><i className="fas fa-plus"></i></Button>
                                                                    ) : (
                                                                        <Button disabled color="primary" className="btn btn-primary"><i className="fas fa-plus"></i></Button>
                                                                    )}
                                                                </div>
                                                                // <div className="px-3">
                                                                //     <div className="d-flex flex-wrap gap-2 justify-content-end">
                                                                //         <Button onClick={() => { addForm(arrayHelpers, forms) }} color="primary" className="btn btn-primary">Add</Button>
                                                                //     </div>
                                                                // </div>
                                                            )}

                                                        </div>


                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }}
                                />
                                {/* Submit Button */}
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    {values.max_capacity == 0 || values.max_capacity == null || totalCollected == values.max_capacity ? (
                                        <Button disabled={submitLoading} type="submit" color="primary">
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button disabled color="primary" className="btn btn-primary">Submit</Button>
                                    )}{" "}

                                </div>
                            </Form>
                        )}
                    />
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddModal
