import { createContext, useState } from "react";

const CollectionContext = createContext({});

export const CollectionProvider = ({ children }) => {

    const [refresh, setRefresh] = useState('');
    const [datas, setDatas] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [upcomingDatas, setUpcomingDatas] = useState(null);
    const [historyDatas, setHistoryDatas] = useState(null);
    const [totalUpcomingRecords, setTotalUpcomingRecords] = useState(0);
    const [totalHistoryRecords, setTotalHistoryRecords] = useState(0);

    return (
        <CollectionContext.Provider value={{
            refresh, setRefresh, datas, setDatas, loading, setLoading, totalRecords, setTotalRecords, expandedRows, setExpandedRows,
            upcomingDatas, setUpcomingDatas, historyDatas, setHistoryDatas,
            totalUpcomingRecords, setTotalUpcomingRecords, totalHistoryRecords, setTotalHistoryRecords
        }}>
            {children}
        </CollectionContext.Provider>
    )
}

export default CollectionContext;