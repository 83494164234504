import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, TabContent, TabPane, } from "reactstrap"
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Formik validation
import * as Yup from "yup";

import { useFormik } from "formik";
import { post, del, get, put } from "../../../../../helpers/api_helper";
import deleteIcon from "../../../../../assets/images/delete-icon.png";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useDailyTip from "hooks/useDailyTip";
import { availableLanguageList } from "common/languages";

const AddModal = () => {
    const axiosApi = useAxiosPrivate();
    const [uploading, setUploading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh } = useDailyTip();
    const { showToast } = useAuth();
    const [activeLang, setActiveLang] = useState('en'); // Default active language is 'en'
    const [status, setStatus] = useState(true);
    const toggleLanguage = (lang) => {
        setActiveLang(lang);
    };


    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        // validation.resetForm();
        setImageUrl('')

    };
    const isContentEmpty = (lang) => {
        return !validation.values[`description_${lang}`]; // Check if the content for this language is empty
    };

    const generateInitialValues = (languages) => {
        const initialValues = {
        };
        languages.forEach((lang) => {
            initialValues[`description_${lang.lang}`] = '';
        });

        return initialValues;
    };

    // Generate dynamic validation schema for titles
    const generateValidationSchema = (languages) => {
        const shape = {
        };

        languages.forEach((lang) => {
            shape[`description_${lang.lang}`] = lang.lang == 'en' ? Yup.string().required('This value is required') : Yup.string().nullable();
        });

        return Yup.object().shape(shape);
    };
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: generateInitialValues(availableLanguageList),
        validationSchema: generateValidationSchema(availableLanguageList),
        onSubmit: async (values, { resetForm }) => {
            try {
                const titlesArray = availableLanguageList.map((language) => ({
                    language: language.lang,
                    description: values[`description_${language.lang}`], // Get the corresponding title value dynamically
                }));
                const opts = { descriptions: titlesArray, active: status == true ? 1 : 0 }

                const response = await post('/api/admin/daily-tip/', opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Daily Tip</Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Daily Tip</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div>
                            {/* Language selection buttons */}
                            <div className="mb-3">
                                {availableLanguageList.map((language) => (
                                    <Button
                                        key={language.lang}
                                        color={activeLang === language.lang || !isContentEmpty(language.lang) ? 'primary' : 'secondary'} // Grey if empty, primary if has content
                                        onClick={() => toggleLanguage(language.lang)}
                                        style={{ marginRight: 5, filter: isContentEmpty(language.lang) ? 'grayscale(100%)' : 'grayscale(0%)' }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={language.flagUrl}
                                                alt={`${language.label} flag`}
                                                style={{
                                                    marginRight: 5,
                                                    height: '10px', // Set a fixed height for the flag image
                                                    width: 'auto'   // Maintain aspect ratio
                                                }}
                                            />
                                            <span style={{ lineHeight: '20px' }}>{language.label}</span> {/* Match line height to image height */}
                                        </div>
                                    </Button>
                                ))}
                            </div>

                            {/* Input fields for each language */}
                            <TabContent activeTab={activeLang}>
                                {availableLanguageList.map((language) => (
                                    <TabPane tabId={language.lang} key={language.lang}>
                                        <div className="mb-3">
                                            <Label className="form-label">{`${language.label} Description`}</Label>
                                            <Input
                                                name={`description_${language.lang}`}
                                                placeholder={`Enter ${language.label} Description`}
                                                rows="5"
                                                type="textarea"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values[`description_${language.lang}`] || ""}
                                                invalid={
                                                    validation.touched[`description_${language.lang}`] && validation.errors[`description_${language.lang}`]
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched[`description_${language.lang}`] && validation.errors[`description_${language.lang}`] ? (
                                                <FormFeedback type="invalid">{validation.errors[`description_${language.lang}`]}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                        <div className="mb-5">
                            <h4 className="card-title mb-3">Active</h4>
                            <InputSwitch checked={status} onChange={(e) => setStatus(e.value)} />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Save
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddModal