import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CurrencyTable from "./CurrencyTable";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useCurrency from "hooks/useCurrency";
import useAuth from "hooks/useAuth";

const FinanceCurrencies = () => {
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const { setDatas, refresh, setRefresh, loading, setLoading, updating, setUpdating } = useCurrency();

    const updateCurrencies = async () => {
        try {
            setUpdating(true);
            const response = await axiosApi.get('/api/admin/finance/update-currencies');
            setRefresh(response.data.random);
            showToast(response.data);
            setUpdating(false);
        } catch (error) {
            console.log(error);
        }
    }

    // const countRates = (obj, keysToCheck) => {
    //     let count = 0;
    //     // Loop through the keys to check
    //     for (const key of keysToCheck) {
    //         // Check if the key exists in the object
    //         if (obj.hasOwnProperty(key)) {
    //         count++;
    //         }
    //     }
    //     return count;
    // }

    const filterCurrencies = (inputObj, currenciesToInclude, update) => {
        return Object.entries(inputObj)
            .filter(([curr]) => currenciesToInclude.includes(curr))
            .map(([curr, val]) => ({
                currency: curr,
                rate: val,
                update
            }));
    }

    const fetchCurrencies = async () => {
        setLoading(true);
        try {
            const response = await axiosApi.get(`/api/admin/finance/get-currencies`);
            const currencies = response.data.rates;
            const update = response.data.update;
            const currenciesToInclude = ['IDR', 'SGD'];
            const resultArray = filterCurrencies(currencies, currenciesToInclude, update);
            // setTotalRecords(response.data.totalRecords);
            setDatas(resultArray);
            setLoading(false);
        } catch (error) {
            setDatas([]);
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCurrencies();
    }, [refresh])
    
    document.title = `${process.env.REACT_APP_NAME || ''} | Price Management`;
    return (
        <React.Fragment>
        <div className="page-content">
            <div className="container-fluid">
            <Breadcrumbs title="Finance" breadcrumbItem="Currencies" />
                {/* On Development */}
                <CurrencyTable updateCurrencies={updateCurrencies} />
            </div>
        </div>
        </React.Fragment>
    );
}

export default FinanceCurrencies;