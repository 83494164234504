import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import usePromotion from "hooks/usePromotion";
import makeid from "helpers/random";

const ReferralExportCSV = ({lazyState}) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { countryList } = usePromotion();
    const validityPeriod = (data) => {
        return data.expired_date ? formatDate(data.expired_date) : '-'
    }
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Referrals_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}}.csv`
    function formatDate(dateString) {
        const dateObject = new Date(dateString);
        // Extracting year, month, and day
        const year = dateObject.getFullYear();
        // JavaScript months are zero-based, so we add 1
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        // Forming the desired date string
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
    }
    const headers = [
        { label: "Name", key: "name" },
        { label: "Referrer", key: "partner" },
        { label: "Code", key: "code" },
        { label: "Limit", key: "limit" },
        { label: "Country", key: "country_name" },
        { label: "Expiring Date", key: "expired_date" },
        { label: "User", key: "user_count" },
    ];
    const countryData = (data) => {
        const countryObject = countryList.find(item => item.value === data.currency);
        const label = countryObject ? countryObject.label : '-';

        return label
    }
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}`;

            const response = await axiosApi.get(`/api/admin/marketing/referral?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.expired_date = validityPeriod(obj);
                obj.country_name = countryData(obj);
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default ReferralExportCSV