import { createContext, useState } from "react";

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
    const [menus, setMenus] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const [refresh, setRefresh] = useState('');
    

    return (
        <MenuContext.Provider value={{ menus, setMenus, totalRecords, setTotalRecords, expandedRows, setExpandedRows, refresh, setRefresh }}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContext;