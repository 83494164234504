import React from "react"
import { Link } from "react-router-dom"
import AddTierModal from "./modals/AddTierModal";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import avatar from "../../../../assets/images/users/user-blank.png";
import EditTierModal from "./modals/EditTierModal";
import DeleteTierModal from "./modals/DeleteTierModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import useBonusTier from "hooks/useBonusTier";

const TierModal = () => {
    const { tiers, loading } = useBonusTier();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const bonusValuePercentage = (data) => {
        return (
            <div>
                {data.bonus_value*100}
            </div>
        )
    }
    const badgeStatus = (data) => {
        return (
            <span className={`badge bg-${data.active == true ? 'active' : 'offline'} text-t-${data.active == true ? 'active' : 'offline'}`}>
                {data.active === true ? 'Active' : 'Not Active'}
            </span>
        )
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditTierModal tier={data} />
                </div>
                <div>
                    <DeleteTierModal tier={data} />
                </div>
            </div>
        )
    }
    const renderColorCircle = (data) => {
        const colorStyle = {
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: data.color,
            border: '1px solid black', // Example border style with thickness
        };

        return (
            <div style={colorStyle}></div>
        );
    };
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Tier Name', body: '' },
        { field: '', header: 'Bonus Percentage', body: bonusValuePercentage },
        { field: 'minimum_volume', header: 'Minimum Volume Collected', body: '' },
        { field: '', header: 'Status', body: badgeStatus },
        { field: '', header: 'Color', body: renderColorCircle },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddTierModal />

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            {/* <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col> */}
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            value={loading ? items : tiers}
                            stripedRows
                            size={'normal'}
                        >
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default TierModal
