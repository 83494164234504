import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"
import useAuth from "hooks/useAuth";

import axiosApi from 'helpers/api_helper';
const DecryptBank = ({ data,show_field = true}) => {
    const { showToast } = useAuth();

    const [modal, setModal] = useState(true);
    const [decryptedData,setDecryptedData] = useState("");
    const [isLoading , setIsLoading] = useState(false);

    const toggleModal = async () => setModal(!modal);

    const buttonPress = async () => {
        setIsLoading(true);
        const opts = { string: data.account_encrypted };
        const response = await axiosApi.post(`/api/transaction/decrypt`, opts);
        if (response?.data?.success) {
            setDecryptedData(response.data.decrypted_string)
            toggleModal();
        } else {
            showToast(response.data.message)
        }
        setIsLoading(false);
     
    }

    return (
        <>
        {isLoading && <span>Loading...</span>}
        {!isLoading && show_field &&
         <li>{data.method === 'banktrf' ? 'Account Number' : 'Phone'} :  
         

         {modal &&
         <a class="link-href" onClick={buttonPress}>
         {" " + (data.account_display ?? "🔐 Encrypted")}
         </a>
         }

         {!modal &&
         <b>
         {decryptedData ?? ""}
         </b>
         }
         
         </li>
            }
        {!isLoading && !show_field &&
        <>
         {modal &&
         <a class="link-href" onClick={buttonPress}>
         {" " + (data.account_display ?? "🔐 Encrypted")} 👁
         </a>
         }

         {!modal &&
         <b>
         {decryptedData ?? ""}
         </b>
         }
         
       </>
            }
        
        </>
    );
};

export default DecryptBank
