import { createContext, useState } from "react";

const Leadercontext = createContext({});

export const LeaderProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const [modal_center, setmodal_center] = useState(false);
    const [bonusHistory, setBonusHistory] = useState([]);
    const [bonusTotalRecords, setBonusTotalRecords] = useState(0);
    const [bonusHistoryTitle, setBonusHistoryTitle] = useState(null);

    return (
        <Leadercontext.Provider value={{ users, setUsers, totalRecords, setTotalRecords, modal_center, setmodal_center, bonusHistory, setBonusHistory, bonusTotalRecords, setBonusTotalRecords, bonusHistoryTitle, setBonusHistoryTitle }}>
            {children}
        </Leadercontext.Provider>
    )
}

export default Leadercontext;